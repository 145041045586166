(function() {
    'use strict';

    angular.module('customerVault.impersonate')
        .controller('ImpersonateLoginController', ImpersonateLoginController);

    ImpersonateLoginController.$inject = ['$location', '$rootScope', '$state', '$stateParams', 'endSession', 'flashMessage', 'impersonateService'];

    function ImpersonateLoginController($location, $rootScope, $state, $stateParams, endSession, flashMessage, impersonateService) {
        var vm = this;

        if (_.isNull($stateParams.token)) {
            runError();
            return;
        }
        var token = $stateParams.token;
        $location.search('token', null);
        $rootScope.$broadcast('endImpersonation');
        impersonateService.getLoginViaImpersonate(token, localStorage.getItem('auth_token'));

        function runError() {
            endSession.end();
            loginService.authInfo.authenticated = false;
            loginService.authInfo.user = null;
            $rootScope.$broadcast('authenticated');
            flashMessage.message.message = "Your are not permitted to perform that action. Please log in to continue.";
            flashMessage.message.show = true;
            $state.go('login');
            return;
        }
    }
})();
