(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .controller('CloudPlanInfoController', CloudPlanInfoController);

    CloudPlanInfoController.$inject = ['$rootScope', 'cloudResolve'];

    function CloudPlanInfoController($rootScope, cloudResolve) {
        var vm = this;
        var resolveData = cloudResolve.load.planinfo;
        var error = cloudResolve.error;

        vm.changeDirection = resolveData.changeDirection;
        vm.changeTab = changeTab;
        vm.error = false;
        vm.pageErrorView = 'view/errors.400';
        vm.plans = resolveData.plans;
        vm.subscription = resolveData.subscription;
        vm.upgrade = upgrade;

        if (!_.isEmpty(error)) {
            vm.error = true;
        }

        function changeTab(tab) {
            $rootScope.$broadcast('cloudChangeTab', {tab: tab});
        }

        function upgrade(plan) {
            $rootScope.$broadcast('cloudChangeTab', {tab: 'changePlan', other: {plan: plan}})
        }
    }
})();
