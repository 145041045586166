(function() {
    'use strict';

    angular.module('customerVault.subscription')
        .factory('signupService', signupService);

    signupService.$inject = ['$uibModal'];

    function signupService($uibModal) {
        var origValues = {
            class: null,
            message: null,
            show: false
        };

        var service = {
            openSignup: openSignup,
            reset: reset,
            value: origValues
        };

        return service;

        function openSignup() {
            $uibModal.open({
                templateUrl: 'view/components.signin',
                controller: 'SigninController',
                controllerAs: 'vm',
                size: 'md',
                keyboard: false,
                windowClass: 'zindex',
                backdrop: 'static'
            });
        }

        function reset() {
            service.value = origValues;
        }
    }
})();
