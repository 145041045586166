(function() {
    'use strict';

    angular.module('customerVault.filex')
        .controller('FilexLoginController', FilexLoginController);

    FilexLoginController.$inject = ['$location', '$rootScope', '$state', '$stateParams', 'endSession', 'filexService', 'flashMessage', 'loginService'];

    function FilexLoginController($location, $rootScope, $state, $stateParams, endSession, filexService, flashMessage, loginService) {
        if (_.isNull($stateParams.token)) {
            runError();
            return;
        }

        var token = $stateParams.token;
        $location.search('token', null);

        filexService.postCheckUser(token);

        function runError() {
            loginService.authInfo.authenticated = false;
            loginService.authInfo.user = null;
            $rootScope.$broadcast('authenticated');
            $rootScope.$broadcast('endImpersonation');
            endSession.end();
            flashMessage.message.message = "Your are not permitted to perform that action. Please log in to continue.";
            flashMessage.message.show = true;
            $state.go('login');
            return;
        }
    }
})();
