(function() {
    'use strict';

    angular.module('customerVault.insight')
        .controller('InsightController', InsightController);

    InsightController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$uibModal', '$window', 'breadcrumbsService', 'bsLoadingOverlayService', 'capabilitiesService', 'impersonateService', 'insightResolve', 'insightService', 'licenseService', 'loaderService', 'loginService', 'menuService', 'Notification'];

    function InsightController($rootScope, $scope, $state, $stateParams, $timeout, $uibModal, $window, breadcrumbsService, bsLoadingOverlayService, capabilitiesService, impersonateService, insightResolve, insightService, licenseService, loaderService, loginService, menuService, Notification) {
        var vm = this;
        var resolveData = null;
        var error = null;

        run();

        function run() {
            resolveData = insightResolve.load;
            error = insightResolve.error;

            if (!_.isEmpty(error)) {
                vm.error = error.status;
                switch (error.status) {
                    case 403:
                        vm.pageErrorView = 'view/errors.403';
                        vm.pageErrorViewMessage = error.message;
                        break;
                    case 404:
                        vm.pageErrorView = 'view/errors.404';
                        break;
                    default:
                        vm.pageErrorView = 'view/errors.400';
                }
                return;
            } else {
                vm.adminLink = resolveData.adminLink;
                vm.capabilities = resolveData.capabilities;
                vm.error = false;
                vm.files = resolveData.files;
                vm.insight = resolveData.insight;
                vm.insightKeys = resolveData.insightKeys;
                vm.isAdmin = resolveData.isAdmin;
                vm.licenseText = resolveData.licenseText;
                vm.pageErrorView = 'view/errors.400';
                vm.pageErrorViewMessage = null;
                vm.serverAddress = resolveData.serverAddress;
                vm.session = null;

                var params = {title: 'InSight Account Details'};
                console.log(vm.insight);
                if (vm.insight.license_type_id == 1 || vm.insight.license_type_id == 5) {
                    params.parent = {state: 'trials', title: 'Trials'};
                } else {
                    params.parent = {state: 'products', title: 'Products'};
                }
                if (loginService.impersonate.mode) {
                    if (loginService.impersonate.user.id != resolveData.user.id) {
                        params.user = resolveData.user;
                    }
                } else {
                    if (loginService.authInfo.user.id != resolveData.user.id) {
                        params.user = resolveData.user;
                    }
                }
                vm.steps = breadcrumbsService.get('insight.home', params);
                vm.title = "InSight Account Details for "+resolveData.user.username;

                encrypt();
            }
        }

        $rootScope.$on('reloadInsightBreadcrumbs', function() {
            if (vm.error) {
                loaderService.confirm();
                insightResolve.load = {};
                insightResolve.run({name: 'insight', params: $stateParams}).then(function() {
                    run();
                    loaderService.close();
                });
            } else {
                vm.steps = null;
                $timeout(function() {
                    if (loginService.impersonate.mode || resolveData.user.id == loginService.authInfo.user.id) {
                        var params = {title: 'InSight Account Details'};
                        if (vm.insight.license_type_id == 1 || vm.insight.license_type_id == 5) {
                            params.parent = {state: 'trials', title: 'Trials'};
                        } else {
                            params.parent = {state: 'products', title: 'Products'};
                        }
                        vm.steps = breadcrumbsService.get('insight.home', params);
                        bsLoadingOverlayService.start({
                            referenceId: 'insight-admin'
                        });
                        impersonateService.getCheckImpersonateIsAdmin(loginService.impersonate.user.id, 'license', $stateParams.id).then(function(response) {
                            vm.isAdmin = response.data.admin;
                        }).catch(function() {
                            vm.isAdmin = false
                        }).finally(function() {
                            bsLoadingOverlayService.stop({
                                referenceId: 'insight-admin'
                            });
                        });
                    } else {
                        vm.isAdmin = true;
                        var params = {user: resolveData.user, title: 'InSight Account Details'}
                        if (vm.insight.license_type_id == 1 || vm.insight.license_type_id == 5) {
                            params.parent = {state: 'trials', title: 'Trials'};
                        } else {
                            params.parent = {state: 'products', title: 'Products'};
                        }
                        vm.steps = breadcrumbsService.get('insight.home', params);
                    }
                }, 100);
            }
        });

        function encrypt() {
            menuService.getSession(loginService.authInfo.user.id).then(function(response) {
                vm.session = response.data.session;
            });
        }

        setInterval(function() {
            if (loginService.authInfo.authenticated && vm.isAdmin) {
                encrypt();
            }
        }, 60*1000);
    }
})();
