(function() {
    'use strict';

    angular.module('customerVault.layout')
        .controller('FooterController', FooterController);

    FooterController.$inject = ['menuService'];

    function FooterController(menuService) {
        var vm = this;

        vm.footerLinks = null;
        vm.navFooterLinks = null;

        menuService.getFooter().then(function(response) {
            vm.footerLinks = response.data;
        }).catch(function(error) {
            console.log(error);
        });

        menuService.getNavFooter().then(function(response) {
            vm.navFooterLinks = response.data;
        }).catch(function(error) {
            console.log(error);
        });
    }
})();
