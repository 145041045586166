(function() {
    'use strict';

    angular.module('customerVault.license')
        .controller('LicenseCapabilitiesController', LicenseCapabilitiesController);

    LicenseCapabilitiesController.$inject = ['$scope', '$stateParams', '$uibModal', 'licenseAction', 'licenseCapabilitiesService', 'licenseResolve', 'licenseService', 'Notification'];

    function LicenseCapabilitiesController($scope, $stateParams, $uibModal, licenseAction, licenseCapabilitiesService, licenseResolve, licenseService, Notification) {
        var vm = this;
        var resolveData = licenseResolve.load.capabilities;
        var error = licenseResolve.error;

        vm.capabilities = resolveData.capabilities;
        vm.capabilityCount = resolveData.capabilityCount;
        vm.check = check;
        vm.checkValueTypes = checkValueTypes;
        vm.checkedValueTypes = {
            string: false,
            numeric: false,
            enumerable: false,
            boolean: false
        };
        vm.checkedGroups = resolveData.checkedGroups;
        vm.chunkedCapabilities = resolveData.chunkedCapabilities;
        vm.defaultCaps = resolveData.defaultCaps;
        vm.error = false;
        vm.evaluationCaps = resolveData.evaluationCaps;
        vm.groups = resolveData.groups;
        vm.groupTitle = resolveData.groupTitle;
        vm.isEvaluation = resolveData.isEvaluation;
        vm.license = resolveData.license;
        vm.loadMore = loadMore;
        vm.none = resolveData.none;
        vm.open = {
            category: false,
            value: false
        };
        vm.pageErrorView = 'view/errors.400';
        vm.search = undefined;
        vm.showLoad = true;
        vm.toggleModal = toggleModal;
        vm.valueTypes = ['string', 'numeric', 'enumerable', 'boolean'];

        licenseCapabilitiesService.set(resolveData);

        if (!_.isEmpty(error)) {
            vm.error = true;
        }

        function runFilter() {
            licenseCapabilitiesService.runFilter(vm.checkedGroups, vm.checkedValueTypes, vm.search);
            vm.capabilityCount = licenseCapabilitiesService.currentCapabilities.length;
            vm.chunkedCapabilities = _.flatten(licenseCapabilitiesService.currentChunkedCapabilities.slice(0, licenseCapabilitiesService.chunks));
            vm.showLoad = vm.chunkedCapabilities.length < licenseCapabilitiesService.currentCapabilities.length;
        }

        $scope.$watch('vm.search', function(newVal) {
            if (!_.isUndefined(vm.search)) {
                runFilter();
            }
        });

        function check(item) {
            vm.checkedGroups[item] = !vm.checkedGroups[item];
            runFilter();
        }

        function checkValueTypes(item) {
            vm.checkedValueTypes[item] = !vm.checkedValueTypes[item];
            runFilter();
        }

        function loadMore() {
            licenseCapabilitiesService.chunks++;
            vm.chunkedCapabilities = _.flatten(licenseCapabilitiesService.currentChunkedCapabilities.slice(0, licenseCapabilitiesService.chunks));
            vm.showLoad = vm.chunkedCapabilities.length < licenseCapabilitiesService.currentCapabilities.length;
        }

        function toggleModal(capability) {
            licenseAction.openToggleModal(capability, vm.groups);
        }
    }
})();
