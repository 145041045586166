(function() {
    'use strict';

    angular.module('customerVault.wurfljs')
        .controller('WurfljsUsageController', WurfljsUsageController);

    WurfljsUsageController.$inject = ['$filter', '$rootScope', '$stateParams', 'bsLoadingOverlayService', 'Notification', 'wurfljsResolve', 'wurfljsService'];

    function WurfljsUsageController($filter, $rootScope, $stateParams, bsLoadingOverlayService, Notification, wurfljsResolve, wurfljsService) {
        var vm = this;
        var resolveData = wurfljsResolve.load.summary;
        var error = wurfljsResolve.error;

        vm.accountDetectionLimit = resolveData.accountDetectionLimit;
        vm.accountDetectionPrediction = resolveData.accountDetectionPrediction
        vm.accountDetectionPredictionMillion = resolveData.accountDetectionPredictionMillion;
        vm.changeTab = changeTab;
        vm.chart = resolveData.chart;
        vm.cnames = resolveData.cnames;
        vm.dataExists = resolveData.dataExists;
        vm.defaults = resolveData.defaults;
        vm.domains = resolveData.domains;
        vm.error = false;
        vm.lastPeriod = resolveData.lastPeriod;
        vm.onlinePurchaseAllowed = resolveData.onlinePurchaseAllowed;
        vm.pageErrorView = 'view/errors.400';
        vm.periods = resolveData.periods;
        vm.plan = resolveData.plan;
        vm.predictionStyle = resolveData.predictionStyle;
        vm.selectedPeriod = resolveData.selectedPeriod;
        vm.updateDetections = updateDetections;
        vm.updating = false;
        vm.usageInPeriod = resolveData.usageInPeriod

        if (!_.isEmpty(error)) {
            vm.error = true;
        }

        function changeTab(tab) {
            $rootScope.$broadcast('wjsChangeTab', {tab: tab});
        }

        function updateDetections() {
            var start = new Date(vm.periods[vm.selectedPeriod].start);
            var end = new Date(vm.periods[vm.selectedPeriod].end);
            bsLoadingOverlayService.start({
                referenceId: 'wjs-usage-form'
            });
            wurfljsService.getDetections($stateParams.id, $filter('date')(start, 'yyyy-MM-dd 00:00:00'), $filter('date')(end, 'yyyy-MM-dd 23:59:59')).then(function(response) {
                vm.usageInPeriod = Math.round((response.data.total / 1000000) * 100) / 100;
                vm.chart.data[1][1] = response.data.total;
            }).catch(function(error) {
                Notification.error("Unable to determine detections");
                console.log(error);
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'wjs-usage-form'
                });
            });
        }
    }
})();
