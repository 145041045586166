(function() {
    'use strict';

    angular.module('customerVault.license')
        .factory('licenseResolve', licenseResolve);

    licenseResolve.$inject = ['filexService', 'impersonateService', 'licenseService', 'loginService'];

    function licenseResolve(filexService, impersonateService, licenseService, loginService) {
        var service = {
            error: {},
            load: {
                main: {}
            },
            mainError: {},
            run: run
        };

        return service;

        function run(args) {
            service.error = {};
            service.mainError = {};

            if (loginService.impersonate.mode) {
                // Check if impersonate user is admin
                return impersonateService.getCheckImpersonateIsAdmin(loginService.impersonate.user.id, 'license', args.params.id).then(function() {
                    return runIndex(args);
                }).catch(function(error) {
                    service.mainError.status = error.status;
                    service.mainError.message = error.data.message;
                    return runIndex(args);
                });
            }

            return runIndex(args);
        }

        function chunk(currentCapabilities) {
            var arr = [];
            var arrChunk = [];
            angular.forEach(currentCapabilities, function(val, key) {
                if (arrChunk.length < 10) {
                    arrChunk.push(val);
                } else {
                    arr.push(arrChunk);
                    arrChunk = [];
                    arrChunk.push(val);
                }
            });
            arr.push(arrChunk);
            return arr;
        }

        function runIndex(args) {
            if (
                (_.isEmpty(service.load.main) || service.load.main.id != args.params.id) &&
                !_.isUndefined(args.params.id)
            ) {
                service.load.main = {};
                service.load.main.id = args.params.id;
                service.load.main.topClasses = {
                    capabilities: 'col-md-3'
                };
                return licenseService.getAccount(args.params.id).then(function(response) {
                    service.load.main.allowedCapabilities = response.data.allowed_capabilities;
                    service.load.main.config = response.data.config;
                    service.load.main.daysToExpire = response.data.days_to_expire;
                    service.load.main.downloadKey = response.data.download_key;
                    service.load.main.isExpired = response.data.is_expired;
                    service.load.main.isMicroservice = response.data.is_microservice;
                    service.load.main.inUse = response.data.in_use;
                    service.load.main.product = response.data.product;
                    service.load.main.productLabel = response.data.product_label;
                    service.load.main.requiredCaps = response.data.required_caps;
                    service.load.main.title = response.data.product.product_name;
                    service.load.main.isEvaluation = response.data.is_evaluation;
                    if (service.load.main.isEvaluation) {
                        service.load.main.topClasses.capabilities = 'col-md-4 pull-right';
                    }
                    service.load.main.documentationUrl = response.data.documentation_url;
                    service.load.main.fileManagementAdmin = filexService.checkAdmin(response.data.user.id);
                    service.load.main.filexCustomerUrl = response.data.filex_customer_url;
                    service.load.main.filexToken = response.data.filex_token;
                    service.load.main.isDataOnly = response.data.is_data_only;
                    service.load.main.isInfuze = response.data.is_infuze;
                    service.load.main.isInsight = response.data.is_insight;
                    service.load.main.isInsightModule = response.data.is_insight_module;
                    service.load.main.isOnSite = response.data.is_onsite;
                    service.load.main.productLabel = response.data.product;
                    service.load.main.license = response.data.license;
                    service.load.main.user = response.data.user;
                    return true;
                }).catch(function(error) {
                    console.log(error);
                    service.mainError.status = error.status;
                    service.mainError.message = error.data.message;
                    service.mainError.data = error.data.data;
                    return false;
                });
            } else if (args.main && !_.isUndefined(args.params.id)) {
                return Promise.resolve(true);
            } else {
                switch (args.name) {
                    case 'license.home.guide':
                        service.load.guide = {};
                        return licenseService.getGuide(args.params.id).then(function(response) {
                            service.load.guide.guide = response.data.guide;
                            service.load.guide.isInsightModule = response.data.isInsightModule;
                            service.load.guide.product = response.data.product;
                            service.load.guide.filex = response.data.filex;
                            if (response.data.isOnSite) {
                                if (response.data.isEvaluation) {
                                    service.load.guide.home = 'view/license.subpages.home.onsite_evaluation';
                                } else {
                                    service.load.guide.home = 'view/license.subpages.home.onsite';
                                }
                            } else if (response.data.isInfuze) {
                                service.load.guide.home = 'view/license.subpages.home.infuze';
                            } else if (service.load.guide.isInsightModule) {
                                service.load.guide.home = null;
                            } else if (response.data.isMicroservice) {
                                service.load.guide.home = 'view/license.subpages.home.microservice';
                            }
                            return true;
                        }).catch(function(error) {
                            service.error.status = error.status;
                            service.error.message = error.data.message;
                            console.log(error);
                            return false;
                        });
                        break;
                    case 'license.home.license':
                        service.load.text = {};
                        return licenseService.getLicenseText(args.params.id).then(function(response) {
                            service.load.text.licenseText = response.data.text;
                            service.load.text.product = response.data.product;
                            service.load.text.filexCustomerUrl = response.data.filex_customer_url;
                            service.load.text.filexToken = response.data.filex_token;
                            service.load.text.isOnSite = response.data.isOnSite;
                            service.load.text.isInsightModule = response.data.isInsightModule;
                            return true;
                        }).catch(function(error) {
                            console.log(error);
                            service.error.status = error.status;
                            service.error.message = 'Something went wrong while gathering the license text';
                            return false;
                        });
                    case 'license.home.capabilities':
                        service.load.capabilities = {
                            allCapabilities: [],
                            checkedGroups: {},
                            chunks: 1,
                            currentCapabilities: [],
                            currentChunkedCapabilities: [],
                            groups: [],
                            none: false
                        };
                        return licenseService.getCapabilities(args.params.id).then(function(response) {
                            service.load.capabilities.defaultCaps = response.data.product_caps;
                            service.load.capabilities.evaluationCaps = response.data.evaluation_caps;
                            angular.forEach(response.data.filter, function(val, key) {
                                service.load.capabilities.groups.push(key);
                                service.load.capabilities.checkedGroups[key] = false;
                                val.group_name = key;
                                service.load.capabilities.allCapabilities.push(val);
                                service.load.capabilities.allCapabilities = _.flatten(service.load.capabilities.allCapabilities);
                                service.load.capabilities.currentCapabilities = angular.copy(service.load.capabilities.allCapabilities);
                            });
                            // Chunk the capabilities
                            service.load.capabilities.currentChunkedCapabilities = chunk(service.load.capabilities.currentCapabilities);
                            service.load.capabilities.chunkedCapabilities = _.flatten(service.load.capabilities.currentChunkedCapabilities.slice(0, service.load.capabilities.chunks));
                            service.load.capabilities.showLoad = service.load.capabilities.chunkedCapabilities.length < service.load.capabilities.currentCapabilities.length;
                            service.load.capabilities.capabilityCount = service.load.capabilities.currentCapabilities.length;

                            service.load.capabilities.groupTitle = response.data.license.max_capabilities == -1 ?
                                'Existing Capabilities' : 'Capabilities Available for Purchase';
                            service.load.capabilities.isEvaluation = response.data.is_evaluation;
                            service.load.capabilities.license = response.data.license;
                            if (!service.load.capabilities.defaultCaps.length && !service.load.capabilities.license.wurfl_capabilities.length) {
                                service.load.capabilities.none = true;
                            }
                            return true;
                        }).catch(function(error) {
                            service.error.status = error.status;
                            service.error.message = error.data.message;
                            return false;
                        });
                    default:
                        return Promise.resolve(false);
                }
            }
        }
    }
})();
