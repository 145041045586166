(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .factory('cloudCapabilitiesService', cloudCapabilitiesService);

    cloudCapabilitiesService.$inject = [];

    function cloudCapabilitiesService() {
        var start = {
            bypass: true,
            originalList: null,
            currentList: null
        };
        var service = {
            reset: reset,
            variables: angular.copy(start)
        }

        return service;

        function reset() {
            service.variables = angular.copy(start);
        }
    }
})();
