(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .factory('cloudResolve', cloudResolve);

    cloudResolve.$inject = ['$filter', '$ocLazyLoad', 'cloudService', 'homeService', 'impersonateService', 'loginService'];

    function cloudResolve($filter, $ocLazyLoad, cloudService, homeService, impersonateService, loginService) {
        var service = {
            error: {},
            load: {
                main: {}
            },
            mainError: {},
            run: run
        };

        return service;

        function run(args) {
            service.error = {};
            service.mainError = {};

            if (loginService.impersonate.mode) {
                // Check if impersonate user is admin
                return impersonateService.getCheckImpersonateIsAdmin(loginService.impersonate.user.id, 'subscription', args.params.id).then(function() {
                    return runIndex(args);
                }).catch(function(error) {
                    service.mainError.status = error.status;
                    service.mainError.message = error.data.message;
                    return runIndex(args);
                });
            }

            return runIndex(args);
        }

        function runIndex(args) {
            if (
                (_.isEmpty(service.load.main) || service.load.main.id != args.params.id) &&
                !_.isUndefined(args.params.id)
            ) {
                service.load.main = {};
                service.load.main.id = args.params.id;
                return cloudService.getAccount(args.params.id).then(function(response) {
                    if (!_.isUndefined(response.data.statusWrapper)) {
                        service.load.main.statusWrapper = 'view/subscriptions.'+response.data.statusWrapper;
                        service.load.main.title = 'Account Status';
                    } else {
                        service.load.main.accounts = response.data.accounts;
                        service.load.main.activeCapabilities = response.data.activeCapabilities;
                        service.load.main.addonsAllowed = response.data.addonsAllowed;
                        service.load.main.apiKeys = response.data.apiKeys;
                        service.load.main.capabilities = response.data.capabilities;
                        service.load.main.currentDetections = response.data.stats['total_detections'];
                        service.load.main.defaults = response.data.defaults;
                        service.load.main.inactiveCapabilities = response.data.inactiveCapabilities;
                        service.load.main.isFreeCloudAccount = response.data.isFreeCloudAccount;
                        service.load.main.isLegacy = response.data.isLegacy;
                        service.load.main.isPremium = response.data.isPremium;
                        service.load.main.maxCapabilities = response.data.defaults['capabilities'].limit + response.data.subscription.num_capability_addons;
                        service.load.main.maxDetections = response.data.defaults['detections'].limit;
                        service.load.main.onlinePurchaseAllowed = response.data.onlinePurchaseAllowed;
                        service.load.main.percentDetections = Math.round((service.load.main.currentDetections / service.load.main.maxDetections) * 100);
                        if (service.load.main.percentDetections >= 100) {
                            service.load.main.percentDetections = 100;
                            service.load.main.percentClass = "progress-bar-danger";
                        }
                        service.load.main.percentStyle = "width: "+service.load.main.percentDetections+"%;";
                        service.load.main.subscription = response.data.subscription;
                    }
                    service.load.main.user = response.data.user;
                    return true;
                }).catch(function(error) {
                    console.log(error);
                    service.mainError.status = error.status;
                    if (error.status != 404) {
                        service.mainError.message = error.data.message;
                    }
                    return false;
                });
            } else if (args.main && !_.isUndefined(args.params.id)) {
                return Promise.resolve(true);
            } else {
                switch (args.name) {
                    case 'cloud.home.usage':
                        service.load.usage = {};
                        return cloudService.getDetectionPeriods(args.params.id).then(function(response) {
                            service.load.usage.periods = response.data;
                            service.load.usage.selectedPeriod = _.findWhere(service.load.usage.periods, {id: 0});
                            return cloudService.getDetections(args.params.id, service.load.usage.selectedPeriod.start_timestamp, service.load.usage.selectedPeriod.end_timestamp).then(function(response) {
                                service.load.usage.totalDetections = response.data.detection_data.total_detections;
                                service.load.usage.detections = response.data.detection_data.detections;
                                service.load.usage.apiKeys = response.data.api_keys;
                                return true;
                            }).catch(function(error) {
                                service.error.status = error.status;
                                service.error.message = error.data.message;
                                service.error.data = error.data.data;
                                console.log(error);
                                return false;
                            });
                        }).catch(function(error) {
                            service.error.status = error.status;
                            service.error.message = error.data.message;
                            service.error.data = error.data.data;
                            console.log(error);
                            return false;
                        });
                    case 'cloud.home.billinghistory':
                        if (!args.params.reload) {
                            return Promise.resolve(true);
                        }
                        service.load.billinghistory = {};
                        return cloudService.getBillingHistory(args.params.id).then(function(response) {
                            service.load.billinghistory.transactions = response.data.transactions;
                            return true;
                        }).catch(function(error) {
                            console.log(error);
                            service.error.status = error.status;
                            service.error.message = error.data.message;
                            service.error.data = error.data.data;
                            return false;
                        });
                    case 'cloud.home.billinghistory.transaction':
                        service.load.transaction = {
                            amountDue: 0,
                            billingPeriod: {}
                        };
                        return cloudService.getTransactionReceipt(args.params.id, args.params.transactionId).then(function(response) {
                            if (response.data.transaction['billingDetails']['company']) {
                                response.data.transaction['transactionBillName'] = response.data.transaction['billingDetails']['company'];
                            } else {
                                response.data.transaction['transactionBillName'] = response.data.transaction['billingDetails']['firstName']+ ' '+response.data.transaction['billingDetails']['lastName'];
                            }
                            if (response.data.transaction['subscription']['billingPeriodStartDate']) {
                                service.load.transaction.billingPeriod.start = $filter('date')(new Date(response.data.transaction['subscription']['billingPeriodStartDate']['date']), 'MM/dd/yyyy', response.data.transaction['subscription']['billingPeriodStartDate']['timezone']);
                                service.load.transaction.billingPeriod.end = $filter('date')(new Date(response.data.transaction['subscription']['billingPeriodEndDate']['date']), 'MM/dd/yyyy', response.data.transaction['subscription']['billingPeriodEndDate']['timezone']);
                            }
                            response.data.transaction['createdAt']['formatted'] = $filter('date')(new Date(response.data.transaction['createdAt']['date']), 'MM/dd/yyyy', response.data.transaction['createdAt']['timezone']);
                            if (response.data.transaction['status'] != 'settled') {
                                service.load.transaction.amountDue = response.data.transaction['amount'];
                            }
                            service.load.transaction.product = response.data.transaction['planId'];

                            var caps = 0;
                            if (!_.isUndefined(response.data.transaction['addOns']) && response.data.transaction['addOns'].length > 0) {
                                angular.forEach(response.data.transaction['addOns'], function(val, key) {
                                    if (val.id == 'WURFL_CLOUD_CAPABILITY') {
                                        caps += val.quantity;
                                    }
                                });
                            }
                            if (caps) {
                                service.load.transaction.product += " + " + caps + " ";
                                if (caps > 1) {
                                    service.load.transaction.product += "CAPABILITIES";
                                } else {
                                    service.load.transaction.product += "CAPABILITY";
                                }
                            }
                            service.load.transaction.totalPaid = response.data.transaction['amount'] - service.load.transaction.amountDue;
                            service.load.transaction.transaction = response.data.transaction;
                            service.load.transaction.email = response.data.email;
                            return true;
                        }).catch(function(error) {
                            console.log(error);
                            service.error.status = error.status;
                            service.error.message = error.data.message;
                            service.error.data = error.data.data;
                            return false;
                        });
                    case 'cloud.home.billingcontacts':
                        service.load.billingcontacts = {};
                        return cloudService.getBillingContacts(args.params.id).then(function(response) {
                            service.load.billingcontacts.billingContacts = response.data;
                            return true;
                        }).catch(function(error) {
                            service.error.status = error.status;
                            switch (error.status) {
                                case 403:
                                    service.error.message = error.data.message
                                    break;
                                default:
                                    service.error.message = "An error occurred while gathering billing contacts";
                            }
                            service.error.data = error.data.data;
                            console.log(error.data);
                            return false;
                        });
                    case 'cloud.home.updatebilling':
                        service.load.updatebilling = {};
                        $ocLazyLoad.load(
                            'https://js.braintreegateway.com/web/dropin/1.9.3/js/dropin.min.js'
                        );
                        return cloudService.getBilling(args.params.id).then(function(response) {
                            service.load.updatebilling.form = response.data.form;
                            service.load.updatebilling.form.country = angular.copy(service.load.updatebilling.form.country_id);
                            if (_.isNull(service.load.updatebilling.form.email)) {
                                service.load.updatebilling.form.email = response.data.user.email;
                            }
                            return homeService.getCountries('country_id,name,code').then(function(response) {
                                service.load.updatebilling.countries = response.data;
                                return true;
                            }).catch(function(error) {
                                console.log(error.data);
                                service.error.status = error.status;
                                service.error.message = error.data.message;
                                service.error.data = error.data.data;
                                return false;
                            });
                        }).catch(function(error) {
                            service.error.status = error.status;
                            switch (error.status) {
                                case 403:
                                    service.error.message = error.data.message;
                                    break;
                                default:
                                    service.error.message = "An error occurred while gathering billing information";
                            }
                            service.error.data = error.data.data;
                            console.log(error);
                            return false;
                        });
                    case 'cloud.home.planinfo':
                        service.load.planinfo = {};
                        return cloudService.getPlanInfo(args.params.id).then(function(response) {
                            service.load.planinfo.changeDirection = response.data.change_direction;
                            service.load.planinfo.plans = response.data.plans;
                            service.load.planinfo.subscription = response.data.subscription;
                            return true;
                        }).catch(function(error) {
                            service.error.status = error.status;
                            service.error.message = "An error occurred while gathering the plan information";
                            service.error.data = error.data.data;
                            console.log(error.data);
                            return false;
                        });
                    case 'cloud.home.changeplan':
                        service.load.changeplan = {
                            form: {
                                first_name: null,
                                last_name: null,
                                address: null,
                                city: null,
                                state: null,
                                zip: null,
                                country: null,
                                nonce: null
                            }
                        };
                        $ocLazyLoad.load(
                            'https://js.braintreegateway.com/web/dropin/1.9.3/js/dropin.min.js'
                        );
                        return cloudService.getChangePlan(args.params.id, args.params.plan).then(function(response) {
                            service.load.changeplan.currentPlan = response.data.current_plan;
                            service.load.changeplan.differences = response.data.differences;
                            service.load.changeplan.isFree = response.data.is_free;
                            service.load.changeplan.isUpgrade = response.data.is_upgrade;
                            service.load.changeplan.newPlan = response.data.new_plan;
                            service.load.changeplan.user = response.data.user;
                            service.load.changeplan.form.first_name = service.load.changeplan.user.first_name;
                            service.load.changeplan.form.last_name = service.load.changeplan.user.last_name;
                            if (service.load.changeplan.isFree) {
                                return homeService.getCountries('country_id,name,code').then(function(response) {
                                    service.load.changeplan.countries = response.data;
                                    return true;
                                }).catch(function(error) {
                                    console.log(error.data);
                                    service.error.status = error.status;
                                    switch (error.status) {
                                        case 403:
                                            service.error.message = error.data.message;
                                            break;
                                        default:
                                            service.error.message = "An error occurred while attempting to change plans";
                                    }
                                    service.error.data = error.data.data;
                                    return false;
                                });
                            }
                            return true;
                        }).catch(function(error) {
                            console.log(error);
                            service.error.status = error.status;
                            switch (error.status) {
                                case 403:
                                    service.error.message = error.data.message;
                                    break;
                                default:
                                    service.error.message = "An error occurred while attempting to change plans";
                                    break;
                            }
                            service.error.data = error.data.data;
                            return false;
                        });
                    case 'cloud.home.capabilities':
                        service.load.capabilities = {};
                        service.load.capabilities.originalList = [];
                        return cloudService.getCapabilities(args.params.id).then(function(response) {
                            service.load.capabilities.accountCapabilities = _.sortBy(response.data.account_capabilities, 'sort_order');
                            service.load.capabilities.currentCapabilities = _.sortBy(angular.copy(response.data.account_capabilities), 'sort_order');
                            angular.forEach(service.load.capabilities.currentCapabilities, function(val, key) {
                                service.load.capabilities.originalList.push({
                                    capability_id: val.capability_id,
                                    sort_order: val.sort_order,
                                    active: val.active
                                });
                            });
                            service.load.capabilities.currentList = angular.copy(service.load.capabilities.originalList);

                            service.load.capabilities.addonsAllowed = response.data.addons_allowed;
                            service.load.capabilities.stats = response.data.stats;
                            service.load.capabilities.subscription = response.data.subscription;
                            service.load.capabilities.groupedCapabilities = response.data.grouped_capabilities;
                            var first = true;
                            angular.forEach(service.load.capabilities.groupedCapabilities, function(val, key) {
                                if (first) {
                                    service.load.capabilities.selectedGroup = val.id;
                                    first = false;
                                }
                            });
                            return true;
                        }).catch(function(error) {
                            console.log(error);
                            service.error.status = error.status;
                            service.error.message = "An error occurred while gathering capabilities";
                            service.error.data = error.data.data;
                            return false;
                        });
                    case 'cloud.signup':
                        service.load.signup = {
                            class: {
                                lite: 'plans-btn-word-width',
                                standard: 'plans-btn-word-width'
                            },
                            form: {
                                accountName: null,
                                address: null,
                                city: null,
                                country: null,
                                firstName: null,
                                lastName: null,
                                paymentPlan: null,
                                state: null,
                                zip: null
                            },
                            plans: {},
                            selectedPlan: null
                        };
                        $ocLazyLoad.load(
                            'https://js.braintreegateway.com/web/dropin/1.9.3/js/dropin.min.js'
                        );

                        return cloudService.getPlans().then(function(response) {
                            angular.forEach(response.data.plans, function(val, key) {
                                service.load.signup.plans[val.key] = val;
                            });
                            switch (args.params.plan) {
                                case 'lite':
                                    service.load.signup.selectedPlan = service.load.signup.plans.WURFL_CLOUD_FREE;
                                    service.load.signup.form.paymentPlan = 'WURFL_CLOUD_FREE';
                                    service.load.signup.class.lite = 'plans-btn-selected-word-width';
                                    service.load.signup.submitText = 'Create Account';
                                    break;
                                case 'standard':
                                default:
                                    service.load.signup.selectedPlan = service.load.signup.plans.WURFL_CLOUD_PREMIUM;
                                    service.load.signup.form.paymentPlan = 'WURFL_CLOUD_PREMIUM';
                                    service.load.signup.class.standard = 'plans-btn-selected-word-width';
                                    service.load.signup.submitText = 'Complete Order';
                                    break;
                            }

                            service.load.signup.form.paymentPlan = service.load.signup.selectedPlan.key;
                            return homeService.getCountries().then(function(response) {
                                service.load.signup.countries = response.data;
                                return true;
                            }).catch(function(error) {
                                console.log(error.data);
                                service.error.status = error.status;
                                service.error.message = "An error occurred while gathering countries";
                                service.error.data = error.data.data;
                                return false;
                            });
                        }).catch(function(error) {
                            console.log(error.data);
                            service.error.status = error.status;
                            service.error.message = "An error occurred while gathering WURFL Cloud plans";
                            service.error.data = error.data.data;
                            return false;
                        });
                    default:
                        return Promise.resolve(false);
                }
            }
        }
    }
})();
