(function() {
    'use strict';

    angular.module('customerVault.quwurfl')
        .controller('QuWURFLApiKeyController', QuWURFLApiKeyController);

    QuWURFLApiKeyController.$inject = ['$stateParams', 'bsLoadingOverlayService', 'quwurflResolve', 'quwurflService', 'Notification'];

    function QuWURFLApiKeyController($stateParams, bsLoadingOverlayService, quwurflResolve, quwurflService, Notification) {
        
        var vm = this;
        var resolveData = quwurflResolve.load;

        vm.apiError = null;
        vm.apiKeys = resolveData.apiKeys;
        vm.createKey = createKey;
        vm.defaults = {"api_keys": 10};
        vm.deleteKey = deleteKey;
        vm.keyName = null;

        function createKey() {
            vm.apiError = null;
            if (_.isNull(vm.keyName) || vm.keyName == '') {
                vm.apiError = 'error';
                return;
            }
            bsLoadingOverlayService.start({
                referenceId: 'quwurfl-api-keys'
            });

            quwurflService.addKey($stateParams.id, vm.keyName).then(function(response) {
                Notification.success("API Key Created");
                vm.apiKeys.push(response);
                vm.keyName = null;
                vm.keys = vm.apiKeys
            }).catch(function(error) {
                console.log(error);
                Notification.error(error.message);
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'quwurfl-api-keys'
                });
                $rootScope.$digest();
            })
        }

        function deleteKey(keyId) {
            bsLoadingOverlayService.start({
                referenceId: 'quwurfl-api-keys'
            });
            quwurflService.deleteKey($stateParams.id, keyId).then(function(response) {
                Notification.success("API Key removed");
                vm.apiKeys = _.reject(vm.apiKeys, function(key) {
                    return key.id == keyId;
                });
            }).catch(function(error) {
                console.log(error);
                Notification.error(error.message);
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'quwurfl-api-keys'
                });
                $rootScope.$digest();
            });
        }
    }
})();
