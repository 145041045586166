(function() {
    'use strict';

    angular.module('customerVault.home')
        .controller('OrdersController', OrdersController);

    OrdersController.$inject = ['$rootScope', '$state', '$uibModal', 'homeActions', 'homeService', 'moment', 'myaccountResolve', 'ordersService'];

    function OrdersController($rootScope, $state, $uibModal, homeActions, homeService, moment, myaccountResolve, ordersService) {
        var vm = this;
        var resolveData = myaccountResolve.load;
        var error = myaccountResolve.error;

        vm.closeOthers = closeOthers;
        vm.dueDateFilter = dueDateFilter;
        vm.error = false;
        vm.invoices = resolveData.invoices;
        vm.pageErrorView = 'view/errors.400';
        vm.status = {};
        vm.statusFilter = statusFilter;
        vm.view = view;

        if (_.isEmpty(vm.invoices)) {
            var task = $state.current.name == 'myaccount.orders' ? 'myaccountChangeTab' : 'accountChangeTab';
            $rootScope.$broadcast(task, {tab: 'licenses'});
        }

        angular.forEach(vm.invoices, function(val, key) {
            vm.status[val.id] = false;
        });

        if (!_.isEmpty(error)) {
            vm.error = true;
        }

        ordersService.reset();
        ordersService.variables.dateOptions = {
            minDate: angular.copy(resolveData.dateOptions.minDate),
            maxDate: angular.copy(resolveData.dateOptions.maxDate),
            floor: angular.copy(resolveData.dateOptions.minDate),
            ceil: angular.copy(resolveData.dateOptions.maxDate),
        };
        ordersService.variables.origInvoices = angular.copy(vm.invoices);
        ordersService.variables.selectedOrderStatus = angular.copy(homeService.selectedOrderStatus);

        function closeOthers(id) {
            angular.forEach(vm.status, function(val, key) {
                if (key != id) {
                    vm.status[key] = false;
                }
            });
        }

        function dueDateFilter() {
            homeActions.openDueDateFilter().then(function(response) {
                vm.invoices = response;
            });
        }

        function statusFilter() {
            homeActions.openStatusFilter().then(function(response) {
                vm.invoices = response;
            });
        }

        function view(invoice) {
            homeActions.openViewOrder(invoice, resolveData.origInvoices);
        }
    }
})();
