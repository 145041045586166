(function() {
    'use strict';

    angular.module('customerVault')
        .controller('GenericModalController', GenericModalController);

    GenericModalController.$inject = ['$uibModalInstance'];

    function GenericModalController($uibModalInstance) {
        var vm = this;

        vm.close = close;

        function close() {
            $uibModalInstance.dismiss();
        }
    }
})();
