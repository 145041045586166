(function() {
    'use strict';

    angular.module('customerVault.home')
        .controller('ForgotPasswordController', ForgotPasswordController);

    ForgotPasswordController.$inject = ['$state', '$uibModalInstance', 'bsLoadingOverlayService', 'homeService', 'Notification'];

    function ForgotPasswordController($state, $uibModalInstance, bsLoadingOverlayService, homeService, Notification) {
        var vm = this;

        vm.close = close;
        vm.email = null;
        vm.error = false;
        vm.errorMessage = null;
        vm.submit = submit;

        function close() {
            $uibModalInstance.close();
        }

        function submit() {
            vm.error = false;
            vm.errorMessage = null;
            if (_.isNull(vm.email) || vm.email == '') {
                vm.error = true;
                return;
            }

            bsLoadingOverlayService.start({
                referenceId: 'forgot-password-form'
            });
            homeService.postPasswordReset(vm.email).then(function(response) {
                $uibModalInstance.close();
                Notification.success(response.message);
            }).catch(function(error) {
                console.log(error);
                vm.errorMessage = error.data.message;
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'forgot-password-form'
                });
            });
        }
    }
})();
