(function() {
    'use strict';

    angular.module('customerVault')
        .directive('accountDetails', accountDetails);

    function accountDetails() {
        var directive = {
            templateUrl: 'view/components.account_details',
            restrict: 'AE',
            replace: true,
            scope: {
                text: '@',
                color: '@'
            },
            controller: controller
        };

        return directive;

        function controller($scope) {
            $scope.class = 'account-details';

            if ($scope.color == 'blue') {
                $scope.class = 'plan-details';
            }
        }
    }
})();
