(function() {
    'use strict';

    angular.module('customerVault')
        .directive('customSlideToggle', customSlideToggle);

    function customSlideToggle() {
        var directive = {
            restrict: 'A',
            scope: {
                isOpen: "@"
            },
            link: function(scope, element, attr) {
                var slideDuration = parseInt(attr.slideToggleDuration, 10) || 200;
                scope.$watch('isOpen', function(newVal,oldVal){
                    if(newVal !== oldVal){
                        element.slideToggle();
                    }
                });
            }
        };

        return directive;
    }
})();
