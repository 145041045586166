(function() {
    'use strict';

    angular.module('customerVault')
        .directive('breadcrumbs', breadcrumbs);

    function breadcrumbs() {
        var directive = {
            templateUrl: 'view/components.breadcrumbs',
            restrict: 'A',
            replace: true,
            scope: {
                steps: '='
            },
            controller: 'BreadcrumbsController',
            controllerAs: 'vm'
        };

        return directive;
    }
})();
