(function() {
    'use strict';

    angular.module('customerVault.home')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['$uibModalInstance', 'bsLoadingOverlayService', 'homeService', 'loginService', 'Notification'];

    function PasswordController($uibModalInstance, bsLoadingOverlayService, homeService, loginService, Notification) {
        var vm = this;

        vm.close = close;
        vm.empty = {
            password: false,
            repeat: false
        };
        vm.errors = {
            password: null,
            repeat: null
        };
        vm.form = {
            password: null,
            repeat: null
        };
        vm.proceed = proceed;

        function close() {
            $uibModalInstance.dismiss();
        }

        function proceed() {
            var error = false;
            if (_.isNull(vm.form.password) || vm.form.password == '') {
                vm.empty.password = true;
                error = true;
            }

            if (_.isNull(vm.form.repeat) || vm.form.repeat == '') {
                vm.empty.repeat = true;
                error = true;
            }

            if (error) {
                return;
            }
            vm.form.user_id = loginService.authInfo.user.id;
            bsLoadingOverlayService.start({
                referenceId: 'password-change-form'
            });
            homeService.postPassword(vm.form).then(function(response) {
                Notification.success('Your password has been updated!')
                $uibModalInstance.dismiss();
            }).catch(function(error) {
                vm.errors = error.data.data;
                console.log(error.data);
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'password-change-form'
                });
            });
        }
    }
})();
