(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .controller('CloudCancelController', CloudCancelController);

    CloudCancelController.$inject = ['$uibModalInstance', 'cloudActions'];

    function CloudCancelController($uibModalInstance, cloudActions) {
        var vm = this;

        vm.cancelReason = null;
        vm.close = close;
        vm.errorClass = null;
        vm.submit = submit;

        function close() {
            $uibModalInstance.dismiss();
        }

        function submit() {
            vm.errorClass = null;
            if (_.isNull(vm.cancelReason) || vm.cancelReason == '') {
                vm.errorClass = 'error';
                return;
            }
            $uibModalInstance.close();
            cloudActions.openCancelConfirm(vm.cancelReason);
        }
    }
})();
