(function() {
    'use strict';

    angular.module('customerVault.wurfljs')
        .controller('WurfljsBillingController', WurfljsBillingController);

    WurfljsBillingController.$inject = ['$ocLazyLoad', '$scope', '$state', '$stateParams', '$timeout', 'bsLoadingOverlayService', 'Notification', 'paymentService', 'wurfljsResolve', 'wurfljsService'];

    function WurfljsBillingController($ocLazyLoad, $scope, $state, $stateParams, $timeout, bsLoadingOverlayService, Notification, paymentService, wurfljsResolve, wurfljsService) {
        var vm = this;
        var error = wurfljsResolve.error;

        vm.countries = [];
        vm.creditcard = null;
        vm.error = false;
        vm.errors = {
            address: false,
            city: false,
            state: false,
            zip: false,
            country: false
        };
        vm.form = null;
        vm.pageErrorView = null;
        vm.pageErrorViewMessage = null;
        vm.saving = false;
        vm.submit = submit;
        vm.updateCard = false;

        runLoad();

        function runLoad() {
            var resolveData = wurfljsResolve.load.updatebilling;
            setError();
            vm.countries = resolveData.countries;
            vm.creditcard = true;
            vm.form = resolveData.form;
        }

        function setError() {
            if (!_.isEmpty(error)) {
                vm.error = error.status;
                switch (error.status) {
                    case 403:
                        vm.pageErrorViewMessage = error.message;
                        break;
                    default:
                        vm.pageErrorView = 'view/errors.400';
                }
                return;
            }
        }

        var loadedInstance = null;

        if (_.isEmpty(error)) {
            generateToken();
        }

        $scope.$on('tokenGenerated', function(event, args) {
            braintree.dropin.create({
                authorization: args.token,
                container: '#wjs-billing-payment-form',
                card: {
                    cardholderName: {
                        required: true
                    }
                }
            }, function(createErr, instance) {
                $timeout(function() {
                    var disablePay = false;
                }, 1);
                loadedInstance = instance;
            });
        });

        function generateToken() {
            return paymentService.getBraintreeAuthorization().then(function(response) {
                $scope.$broadcast('tokenGenerated', {token: response.data.authorization});
            });
        }

        function runSubmit() {
            bsLoadingOverlayService.start({
                referenceId: 'wjs-billing-form'
            });
            wurfljsService.postUpdateBilling($stateParams.id, vm.form).then(function(response) {
                Notification.success('Updated billing information!');
                if (!_.isNull(vm.form.nonce)) {
                    loadedInstance.teardown(function(err) {
                        if (!err) {
                            generateToken();
                            vm.creditcard = false;
                            wurfljsResolve.load.updatebilling = {};
                            wurfljsResolve.run({name: $state.current.name, params: $stateParams}).then(function(response) {
                                if (response) {
                                    runLoad();
                                } else {
                                    error = wurfljsResolve.error;
                                    setError();
                                }
                            });
                        }
                    });
                }
            }).catch(function(error) {
                console.log(error);
                Notification.error(error.data.message);
                if (!_.isNull(vm.form.nonce)) {
                    loadedInstance.teardown(function(err) {
                        if (!err) {
                            generateToken();
                            vm.creditcard = false;
                            wurfljsResolve.load.updatebilling = {};
                            wurfljsResolve.run({name: $state.current.name, params: $stateParams}).then(function(response) {
                                if (response) {
                                    runLoad();
                                } else {
                                    error = wurfljsResolve.error;
                                    setError();
                                }
                            });
                        }
                    });
                }
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'wjs-billing-form'
                });
            });
        }

        function submit() {
            vm.errors = {
                address: false,
                city: false,
                state: false,
                zip: false,
                country: false
            };
            vm.form.country_id = angular.copy(vm.form.country);
            if (vm.updateCard) {
                var errors = wurfljsService.validateBilling(vm.form, false);
                if (errors.length != 0) {
                    angular.forEach(errors, function(val) {
                        vm.errors[val] = true;
                    });
                    return;
                }
                loadedInstance.requestPaymentMethod(function(requestPaymentMethodErr, payload) {
                    if (!_.isUndefined(payload)) {
                        vm.form.nonce = payload.nonce;
                        vm.form.country_id = angular.copy(vm.form.country);
                        runSubmit();
                    }
                });
            } else {
                var errors = wurfljsService.validateBilling(vm.form, false);
                if (errors.length != 0) {
                    angular.forEach(errors, function(val) {
                        vm.errors[val] = true;
                    });
                    return;
                }
                runSubmit();
            }
        }
    }
})();
