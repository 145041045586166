(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .factory('cloudActions', cloudActions);

    cloudActions.$inject = ['$state', '$stateParams', '$uibModal', 'breadcrumbsService', 'bsLoadingOverlayService', 'cloudCapabilitiesService', 'cloudResolve', 'loaderService', 'loginService'];

    function cloudActions($state, $stateParams, $uibModal, breadcrumbsService, bsLoadingOverlayService, cloudCapabilitiesService, cloudResolve, loaderService, loginService) {
        var service = {
            addCapabilities: addCapabilities,
            changeTab: changeTab,
            getSteps: getSteps,
            getTab: getTab,
            openCancelAccount: openCancelAccount,
            openCancelConfirm: openCancelConfirm,
            openCapabilityDescription: openCapabilityDescription,
            openCapabilityReorder: openCapabilityReorder,
            openNotAvailable: openNotAvailable,
            openReceipt: openReceipt,
            openShowCapabilities: openShowCapabilities,
            updateReorderCapabilities: updateReorderCapabilities,
            usageInit: usageInit
        };

        var states = {
            accountApiKeys: 'apikeys',
            updateBilling: 'updatebilling',
            billingContacts: 'billingcontacts',
            billingHistory: 'billinghistory',
            home: '',
            planInfo: 'planinfo',
            accountUsageHistory: 'usage',
            changePlan: 'changeplan',
            accountCapabilities: 'capabilities'
        };

        return service;

        function addCapabilities(capability, add, currentCapabilities, max_capabilities) {
            if (add) {
                var last = !_.isUndefined(_.last(currentCapabilities)) ?
                    _.last(currentCapabilities).sort_order + 1 :
                    1;
                var active = last > max_capabilities ? 0 : 1;
                var cap = {
                    capability_id: capability.id,
                    subscription_id: parseInt($stateParams.id),
                    capability: capability,
                    sort_order:  last,
                    active: active
                };
                cloudCapabilitiesService.variables.currentList.push({
                    capability_id: capability.id,
                    sort_order:  last,
                    active: active
                });
                return cap;
            } else {
                var sortOrder = capability.sort_order;
                cloudCapabilitiesService.variables.currentList = [];
                angular.forEach(currentCapabilities, function(val, key) {
                    if (val.sort_order > sortOrder) {
                        val.sort_order -= 1;
                    }
                    val.active = val.sort_order > max_capabilities ? 0 : 1;
                    cloudCapabilitiesService.variables.currentList.push({
                        capability_id: val.capability_id,
                        sort_order: val.sort_order,
                        active: val.active
                    });
                });
                return currentCapabilities;
            }
        }

        function changeTab(tab, other) {
            loaderService.show = false;
            bsLoadingOverlayService.start({
                referenceId: 'cloud-tabs'
            });
            var state = 'cloud.home';
            var params = {id: $stateParams.id};
            if (tab !== 'home') {
                state = state + "." + states[tab];
            }
            if (!_.isUndefined(other)) {
                angular.forEach(other, function(val, key) {
                    params[key] = val;
                });
            }
            $state.go(state, params);
        }

        function getSteps(data) {
            var params = {};

            if (data.subscription.payment_type == 'TRIAL' || data.subscription.payment_type == 'FREE') {
                params.parent = {state: 'trials', title: 'Trials'};
            } else {
                params.parent = {state: 'products', title: 'Products'};
            }

            if (loginService.impersonate.mode) {
                if (loginService.impersonate.user.id != data.user.id) {
                    params.user = data.user;
                }
            } else {
                if (loginService.authInfo.user.id != data.user.id) {
                    params.user = data.user;
                }
            }
            return breadcrumbsService.get('cloud.home', params);
        }

        function getTab(name) {
            switch (name) {
                case 'cloud.home.apikeys':
                    return 'accountApiKeys';
                case 'cloud.home.usage':
                    return 'accountUsageHistory';
                case 'cloud.home.billinghistory':
                    return 'billingHistory';
                case 'cloud.home.billingcontacts':
                    return 'billingContacts';
                case 'cloud.home.updatebilling':
                    return 'updateBilling';
                case 'cloud.home.planinfo':
                    return 'planInfo';
                case 'cloud.home.changeplan':
                    return 'changePlan';
                case 'cloud.home.capabilities':
                    return 'accountCapabilities'
                default:
                    return 'home';
            }
        }

        function openCancelAccount() {
            $uibModal.open({
                templateUrl: 'view/cloud.partials.cancel',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                controller: 'CloudCancelController',
                controllerAs: 'vm',
                windowClass: 'zindex'
            });
        }

        function openCancelConfirm(cancelReason) {
            $uibModal.open({
                templateUrl: 'view/components.cancel_confirm',
                size: 'md',
                keyboard: false,
                backdrop: 'static',
                windowClass: 'zindex',
                controller: 'CloudCancelConfirmController',
                controllerAs: 'vm',
                resolve: {
                    cancelReason: function() {
                        return cancelReason;
                    }
                }
            });
        }

        function openCapabilityDescription(capability, groupedCapabilities) {
            $uibModal.open({
                templateUrl: 'view/components.capability_popup',
                size: 'md',
                keyboard: false,
                backdrop: 'static',
                controller: 'CloudCapabilityDescriptionController',
                controllerAs: 'vm',
                resolve: {
                    capability: function() {
                        return capability;
                    },
                    groupedCapabilities: function() {
                        return groupedCapabilities;
                    }
                }
            });
        }

        function openCapabilityReorder(capabilities, maxCapabilities, maxLength) {
            return $uibModal.open({
                templateUrl: 'view/cloud.partials.capability_reorder',
                controller: 'CapabilityReorderController',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                windowClass: 'zindex',
                resolve: {
                    capabilities: function() {
                        return capabilities;
                    },
                    maxCapabilities: function() {
                        return maxCapabilities;
                    },
                    maxLength: function() {
                        return maxLength;
                    }
                }
            });
        }

        function openNotAvailable(isPremium, subscription) {
            $uibModal.open({
                templateUrl: 'view/cloud.partials.not_available',
                size: 'md',
                keyboard: false,
                backdrop: 'static',
                controller: 'CloudNotAvailableController',
                controllerAs: 'vm',
                windowClass: 'zindex',
                resolve: {
                    isPremium: function() {
                        return isPremium;
                    },
                    subscription: function() {
                        return subscription;
                    }
                }
            });
        }

        function openReceipt(transactionId) {
            bsLoadingOverlayService.start({
                referenceId: 'cloud-billing-history'
            });
            cloudResolve.run({name: 'cloud.home.billinghistory.transaction', params: {id: $stateParams.id, transactionId: transactionId}}).then(function(response) {
                if (response) {
                    $uibModal.open({
                        templateUrl: 'view/cloud.partials.receipt',
                        size: 'lg',
                        keyboard: false,
                        backdrop: 'static',
                        controller: 'CloudReceiptController',
                        controllerAs: 'vm',
                        windowClass: 'zindex',
                        resolve: {
                            transactionId: function() {
                                return transactionId;
                            }
                        }
                    });
                } else {
                    var error = cloudResolve.error;
                    if (!_.isEmpty(error)) {
                        Notification.error(error.message);
                        return;
                    }
                }
                bsLoadingOverlayService.stop({
                    referenceId: 'cloud-billing-history'
                });
            });
        }

        function openShowCapabilities(activeCapabilities, capabilities, isFreeCloudAccount) {
            $uibModal.open({
                templateUrl: 'view/cloud.partials.capabilities-popup',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                controller: 'CloudCapabilitiesController',
                controllerAs: 'vm',
                windowClass: 'zindex',
                resolve: {
                    activeCapabilities: function() {
                        return activeCapabilities;
                    },
                    capabilities: function() {
                        return capabilities;
                    },
                    isFreeCloudAccount: function() {
                        return isFreeCloudAccount;
                    }
                }
            });
        }

        function updateReorderCapabilities(newVal, oldVal, capabilities, maxLength, maxCapabilities) {
            if (newVal.length === maxLength && !angular.equals(newVal, oldVal)) {
                var i = 1;
                angular.forEach(capabilities, function(val, key) {
                    val.sort_order = i;
                    if (i > maxCapabilities) {
                        val.active = 0;
                    } else {
                        val.active = 1;
                    }
                    i++;
                });
            }

            return capabilities;
        }

        function usageInit(detections, apiKeys) {
            var object = {
                type: "LineChart",
                displayed: true
            };
            var rows = [];

            angular.forEach(detections.data, function(val, key) {
                var timeDetections = _.filter(detections.data, function(obj) {
                    return obj.start_date == val.start_date;
                });
                var c = [{
                    v: val.start_date
                }];
                angular.forEach(apiKeys, function(aVal, aKey) {
                    var aTimeVal = _.find(timeDetections, function(obj) {
                        return obj.api_key_id == aVal.id;
                    });
                    if (!_.isUndefined(aTimeVal)) {
                        c.push({
                            v: aTimeVal.detections
                        });
                    } else {
                        c.push({
                            v: 0
                        });
                    }
                });
                var row = {c: c};
                rows.push(row);
            });

            var cols = [{
                id: "date",
                type: "string"
            }];
            angular.forEach(apiKeys, function(val, key) {
                cols.push({
                    id: "detections_"+val.id,
                    type: "number",
                    label: val.key_name
                });
            });

            object.data = {
                "cols": cols,
                "rows": rows
            };
            object.options = {
                'height': 400
            };

            return object;
        }
    }
})();
