(function() {
    'use strict';

    angular.module('customerVault')
        .directive('loader', loader);

    function loader() {
        var directive = {
            templateUrl: 'view/components.loading_overlay',
            restrict: 'AE',
            replace: true
        };

        return directive;
    }
})();
