(function() {
    'use strict';

    angular.module('customerVault')
        .controller('SigninController', SigninController);

    SigninController.$inject = ['$rootScope', '$state', '$timeout', '$uibModalInstance', 'authenticate', 'bsLoadingOverlayService', 'homeService', 'loginService'];

    function SigninController($rootScope, $state, $timeout, $uibModalInstance, authenticate, bsLoadingOverlayService, homeService, loginService) {
        var vm = this;

        vm.close = close;
        vm.authError = {
            message: null,
            status: null,
            type: null,
            class: 'alert-danger'
        };
        vm.errors = {
            username: {
                show: false,
                message: null
            },
            password: {
                show: false,
                message: null
            },
            invalid: {
                show: false
            }
        };
        vm.form = {
            username: null,
            password: null
        };
        vm.login = login;
        vm.remember = false;
        vm.rememberCheck = rememberCheck;
        vm.resend = resend;

        loginService.modalOpen = true;

        function close() {
            loginService.modalOpen = false;
            $uibModalInstance.dismiss();
        }

        function login() {
            vm.errors = {
                username: {
                    show: false,
                    message: null
                },
                password: {
                    show: false,
                    message: null
                },
                invalid: {
                    show: false
                }
            };
            var error = false;
            if (_.isNull(vm.form.username) || vm.form.username == '') {
                vm.errors.username.show = true;
                vm.errors.username.message = "Please enter your username or email";
                error = true;
            }
            if (_.isNull(vm.form.password) || vm.form.password == '') {
                vm.errors.password.show = true;
                vm.errors.password.message = "Please enter your password";
                error = true;
            }

            if (error) {
                return;
            }

            bsLoadingOverlayService.start({
                referenceId: 'login-modal-form'
            });
            authenticate.auth(vm.form).then(function(response) {
                if (!response) {
                    vm.form.password = null;
                    vm.remember = false;
                    vm.errors.invalid.show = true;

                    vm.authError.message = authenticate.error.message;
                    vm.authError.status = authenticate.error.status;
                    vm.authError.type = authenticate.error.type;
                } else {
                    loginService.authInfo.authenticated = true;
                    loginService.authInfo.user = response;
                    if (vm.remember) {
                        authenticate.setRemember(response.remember_token);
                    }
                    $rootScope.$broadcast('authenticated');
                    loginService.modalOpen = false;
                    if (!_.isUndefined(loginService.intended.name)) {
                        $state.go(loginService.intended.name, loginService.intended.params);
                    }
                    if ($state.current.name == 'verify') {
                        $state.go("myaccount");
                    }
                    $uibModalInstance.dismiss();
                }
                bsLoadingOverlayService.stop({
                    referenceId: 'login-modal-form'
                });
            });
        }

        function rememberCheck() {
            vm.remember = !vm.remember;
        }

        function resend() {
            bsLoadingOverlayService.start({
                referenceId: 'login-modal-form'
            });
            homeService.postSendVerification(vm.form.username).then(function(response) {
                vm.authError.class = "alert-success";
                vm.authError.message = response.message;
                vm.authError.status = null;
                vm.errors.invalid.show = true;
                $timeout(function() {
                    vm.authError.class = "alert-danger";
                    vm.authError.message = null;
                    vm.authError.status = null;
                    vm.errors.invalid.show = false;
                }, 5000)
            }).catch(function(error) {
                console.log(error);
                vm.authError.message = "Verification email could not be sent. Please contact support@scientiamobile.com for assistance.";
                vm.authError.status = null;
                vm.errors.invalid.show = true;
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'login-modal-form'
                });
            });
        }
    }
})();
