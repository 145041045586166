(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineDomainController', ImageEngineDomainController);

    ImageEngineDomainController.$inject = ['$q', '$stateParams', '$uibModalInstance', 'bsLoadingOverlayService', 'domainId', 'generalService', 'ieActions', 'ieResolve', 'ieService', 'isNew', 'loaderService', 'Notification', 'origins'];

    function ImageEngineDomainController($q, $stateParams, $uibModalInstance, bsLoadingOverlayService, domainId, generalService, ieActions, ieResolve, ieService, isNew, loaderService, Notification, origins) {
        var vm = this;
        var resolveData = ieResolve.load.summary;

        vm.close = close;
        vm.deleteDomain = deleteDomain;
        vm.errors = null;
        vm.form = {
            domainName: null,
            ssl: ieResolve.load.main.subscription.payment_plan === 'IMAGEENGINE_PRO' || ieResolve.load.main.subscription.payment_plan === 'IMAGEENGINE_STANDARD' ? true : false,
            name: null,
            origin: null,
            originProtocol: 'http',
            hostHeader: null,
            username: null,
            password: null,
            port: null,
            path: null,
            originId: null,
            bucketname: null,
            allowOriginPrefix: 1,
            whitelist: [null],
            corsEnabled: 1,
            corsAllowedOrigins: ['*'],
            corsAllowedHeaders: '*',
            corsAllowedMethods: 'GET',
            corsMaxAge: null
        };
        vm.generalError = false;
        vm.isNew = isNew;
        vm.openTab = openTab;
        vm.origins = origins;
        vm.confs = resolveData.confs;
        vm.originTab = null;
        vm.resetWhitelist = resetWhitelist;
        vm.selectedTab = 0;
        vm.showRestrictionExplanation = showRestrictionExplanation;
        vm.submit = submit;
        vm.subscription = ieResolve.load.main.subscription;
        vm.title = vm.title = isNew ? "Add new Engine" : "Edit Engine";
        vm.updateWhitelist = updateWhitelist;
        vm.resetCors = resetCors;
        vm.setSuggestedCorsValues = setSuggestedCorsValues;
        vm.removeAllowedOrigin = removeAllowedOrigin;
        vm.addAllowedOrigin = addAllowedOrigin;

        var accountResponse = null;

        var awsError = {
            error_type: []
        };
        var certificateError = false;
        var certificatePromises = [];
        var witCertificate = null;

        var origAllow = null;
        var origWhitelist = null;
        var origOrigin = null;

        var origCorsEnabled = null;
        var origCorsOrigins = null;
        var origCorsHeaders = null;
        var origCorsMethods = null;
        var origCorsMaxAge = null;

        var editFinish = {
            origin: false,
            whitelist: false,
            cors: false
        };
        var editError = {
            origin: false,
            whitelist: false,
            cors: false
        };

        setErrors();

        if (!isNew) {
            loaderService.confirm();
            ieService.getDomain($stateParams.id, domainId).then(function(response) {
                vm.form.domainName = response.data.conf.cname;
                vm.domain = response.data.conf;
                vm.form.ssl = response.data.ssl;
                vm.form.allowOriginPrefix = response.data.conf.allow_origin_prefix;
                origAllow = angular.copy(vm.form.allowOriginPrefix);
                origOrigin = angular.copy(response.data.origin);
                if (!_.isNull(response.data.origin)) {
                    vm.form.originId = response.data.origin.id.toString();
                    vm.selectedTab = 1;
                }
                if (!_.isEmpty(response.data.whitelist)) {
                    vm.form.whitelist = _.pluck(response.data.whitelist, 'origin');
                    origWhitelist = angular.copy(vm.form.whitelist);
                } else {
                    vm.form.whitelist = [null];
                }

                vm.form.corsEnabled = response.data.conf.cors_enabled;
                origCorsEnabled = angular.copy(vm.form.corsEnabled);
                vm.form.corsAllowedOrigins = _.pluck(response.data.cors_allowed_origins, 'origin');
                origCorsOrigins = angular.copy(vm.form.corsAllowedOrigins);
                origCorsHeaders = _.pluck(response.data.cors_allowed_headers, 'header');
                vm.form.corsAllowedHeaders = origCorsHeaders.join(',');
                vm.form.corsMaxAge = response.data.conf.cors_max_age;
                origCorsMaxAge = angular.copy(vm.form.corsMaxAge);
                vm.form.corsAllowedMethods = response.data.conf.cors_allowed_methods;
                origCorsMethods = angular.copy(vm.form.corsAllowedMethods);
            }).catch(function(error) {
                console.log(error);
            }).finally(function() {
                loaderService.close();
            });
        }

        function close() {
            $uibModalInstance.close(null);
        }

        function formatOrigins() {
            var o = {};
            angular.forEach(origins, function(val, key) {
                o[val.id] = val;
            });
            return o;
        }

        function setErrors() {
            vm.errors = {
                domainName: false,
                ssl: false,
                name: false,
                origin: false,
                originProtocol: false,
                hostHeader: false,
                username: false,
                password: false,
                port: false,
                path: false,
                originId: false,
                bucketname: false,
                corsAllowedOrigins: false
            };
        }

        function openTab(tab) {
            if (vm.originTab == tab) {
                vm.originTab = null;
            } else {
                vm.originTab = tab;
            }
        }

        function resetWhitelist(val) {
            if (val == 1) {
                vm.form.whitelist = [null];
            } else {
                vm.form.whitelist = null;
            }
        }

        function resetCors(val) {
            if (val == 1) {
                vm.form.corsAllowedOrigins = ['*'];
                vm.form.corsAllowedMethods = 'GET';
                vm.form.corsAllowedHeaders = '*';
            } else {
                vm.form.corsAllowedOrigins = [];
                vm.form.corsAllowedMethods = null;
                vm.form.corsAllowedHeaders = null;
                vm.form.corsMaxAge = null;
            }
        }

        function setSuggestedCorsValues() {
            vm.form.corsAllowedOrigins = ['*'];
            vm.form.corsAllowedMethods = 'GET,POST,OPTIONS';
            vm.form.corsAllowedHeaders = '*';
            vm.form.corsMaxAge = 7200;
        }

        function showRestrictionExplanation() {
            ieActions.openRestrictionExplanation();
        }

        function runCreateNew() {
            bsLoadingOverlayService.start({
                referenceId: 'ie-domain-form'
            });
            ieService.postCreateDomain($stateParams.id, vm.form).then(function(response) {
                accountResponse = response.data;
                ieService.getAwsRegionsByAccount($stateParams.id).then(function(response) {
                    var regions = response.data;
                    var records = [];

                    angular.forEach(regions, function(val, key) {
                        if (val.Deploy !== 'provisioning') {
                            var record = {
                                domain: vm.form.domainName.toLowerCase(),
                                region: val.RegionName,
                                type: 'A'
                            };
                        }
                        if (vm.form.ssl) {
                            certificatePromises.push(
                                ieService.postRequestCertificate($stateParams.id, vm.form.domainName.toLowerCase(), val.RegionName)
                                    .then(function(response) {
                                        accountResponse.conf.validation = {validation: response.data.validation};
                                        witCertificate = response.data.certificates;
                                        if (!_.isUndefined(response.data.validation.hash)) {
                                            accountResponse.conf.status.ssl = response.data.validation.ValidationStatus;
                                            accountResponse.conf.status.attached = response.data.validation.InUse;
                                            if (val.Deploy !== 'provisioning') {
                                                record['hash'] = response.data.validation.hash;
                                            }
                                        }
                                    }).catch(function(error) {
                                        awsError.type = "aws";
                                        awsError.error_type.push('certificate');
                                        $q.reject();
                                    }).finally(function() {
                                        if (val.Deploy !== 'provisioning') {
                                            records.push(record);
                                        }
                                    })
                            );
                        } else {
                            if (val.Deploy !== 'provisioning') {
                                records.push(record);
                            }
                        }
                    });

                    $q.all(certificatePromises).then(function() {
                        if (records.length !== 0) {
                            ieService.postResourceRecords($stateParams.id, records)
                                .then(function() {})
                                .catch(function(error) {
                                    console.log(error);
                                    awsError.type = "aws";
                                    awsError.error_type.push('resource record');
                                }).finally(function() {
                                    finish();
                                });
                        } else {
                            finish();
                        }
                    });
                });
            }).catch(function(error) {
                console.log(error.data);
                switch (error.data.data.type) {
                    case 'general':
                        vm.generalError = error.data.message;
                        break;
                    case 'form':
                        angular.forEach(error.data.data.errors, function(val, key) {
                            vm.errors[key] = val;
                        });
                        break;
                    case 'domain':
                        vm.generalError = "We are unable to create the domain name. Please contact support@scientiamobile.com for assistance.";
                        break;
                    default:
                        vm.generalError = "An unknown error occurred. Please contact support@scientiamobile.com for assistance.";
                }
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-domain-form'
                });
            });
        }

        function runUpdateDomain() {
            bsLoadingOverlayService.start({
                referenceId: 'ie-domain-form'
            });

            ieService.patchUpdateDomainOrigin($stateParams.id, domainId, vm.form)
                .then(function(response) {})
                .catch(function(error) {
                    console.log(error.data);
                    editError.origin = error.data;
                }).finally(function() {
                    editFinish.origin = true;
                    finishUpdate();
                    runUpdateAllowed();
                    runUpdateCors();
                });
        }

        function runUpdateAllowed() {
            if (vm.form.allowOriginPrefix != origAllow || ((!_.isNull(vm.form.whitelist) || !_.isNull(origWhitelist)) && !generalService.isEqual(vm.form.whitelist, origWhitelist))) {
                vm.form.whitelist = _.isNull(vm.form.whitelist) ? [] : vm.form.whitelist;
                ieService.postUpdatePrefixes(
                    $stateParams.id, domainId,
                    {allow: vm.form.allowOriginPrefix, whitelist: vm.form.whitelist}
                ).then(function(response) {
                    if (!_.isEmpty(response.data)) {
                        editError.whitelist = {
                            general: false,
                            errors: response.data
                        };
                    }
                }).catch(function(error) {
                    editError.whitelist = {
                        general: true,
                        errors: error.data.message
                    };
                }).finally(function() {
                    editFinish.whitelist = true;
                    finishUpdate();
                })
            } else {
                editFinish.whitelist = true;
                finishUpdate();
            }
        }

        function runUpdateCors() {
            if (vm.form.corsEnabled == origCorsEnabled &&
                generalService.isEqual(vm.form.corsAllowedOrigins, origCorsOrigins) &&
                vm.form.corsAllowedMethods == origCorsMethods &&
                generalService.isEqual(vm.form.corsAllowedHeaders.split(','), origCorsHeaders) &&
                vm.form.corsMaxAge == origCorsMaxAge) {
                editFinish.cors = true;
                finishUpdate();
                return;
            }

            var allowedOrigins = _.isNull(vm.form.corsAllowedOrigins) ? [] : vm.form.corsAllowedOrigins;

            // get the list of headers, trim the space from each item, and remove the empty items
            var allowedHeaders = vm.form.corsAllowedHeaders ? vm.form.corsAllowedHeaders.split(',') : [];
            for (var i = 0; i < allowedHeaders.length; i++) {
                allowedHeaders[i] = allowedHeaders[i].trim();
            }
            allowedHeaders = _.without(allowedHeaders, '');
            // set the form value to our trimmed version of the headers list
            if (allowedHeaders.length > 0) {
                vm.form.corsAllowedHeaders = allowedHeaders.join(',');
            }

            ieService.postUpdateCors(
                $stateParams.id,
                domainId,
                {
                    enabled: vm.form.corsEnabled,
                    allowed_origins: allowedOrigins,
                    allowed_methods: vm.form.corsAllowedMethods,
                    allowed_headers: allowedHeaders,
                    max_age: vm.form.corsMaxAge
                }
            ).then(function(response) {
                if (!_.isEmpty(response.data)) {
                    editError.cors = {
                        general: false,
                        errors: response.data
                    };
                }
            }).catch(function(error) {
                editError.cors = {
                    general: true,
                    errors: error.data.message
                };
            }).finally(function() {
                editFinish.cors = true;
                finishUpdate();
            });
        }

        function submit() {
            setErrors();

            var errors = false;
            if (_.isNull(vm.form.domainName)) {
                errors = true;
                vm.errors.domainName = true;
            }
            if (vm.selectedTab == 0) {
                vm.form.originId = null;
                if (_.isNull(vm.form.name)) {
                    errors = true;
                    vm.errors.name = true;
                }
                if (vm.form.originProtocol != 's3') {
                    if (_.isNull(vm.form.origin)) {
                        errors = true;
                        vm.errors.origin = true;
                    }
                } else {
                    if (_.isNull(vm.form.bucketname)) {
                        errors = true;
                        vm.errors.bucketname = true;
                    }
                }
            } else {
                if (_.isNull(vm.form.originId)) {
                    errors = true;
                    vm.errors.originId = true;
                }
            }

            vm.form.whitelist = _.without(vm.form.whitelist, null);
            if (vm.form.whitelist.length == 0) {
                vm.form.whitelist = null;
            }

            vm.form.corsAllowedOrigins = _.without(vm.form.corsAllowedOrigins, null, '');
            if (vm.form.corsAllowedOrigins.length === 0) {
                if (vm.form.corsEnabled == 1) {
                    vm.errors.corsAllowedOrigins = "At least one origin is required when CORS is enabled (use * for all origins)"
                    errors = true;
                    vm.form.corsAllowedOrigins = [''];
                } else {
                    vm.form.corsAllowedOrigins = null;
                }
            }

            if (!_.isNull(vm.form.whitelist)) {
                var distinctWhitelist = new Set(vm.form.whitelist);
                if (vm.form.whitelist.length !== distinctWhitelist.size) {
                    vm.generalError = "Duplicate allowed origins exist";
                    errors = true;
                }
            }
            if (!_.isNull(vm.form.corsAllowedOrigins)) {
                var distinctOrigins = new Set(vm.form.corsAllowedOrigins);
                if (vm.form.corsAllowedOrigins.length !== distinctOrigins.size) {
                    vm.generalError = "Duplicate cors origins exist";
                    errors = true;
                }
            }

            // if (vm.form.domainName == vm.form.origin) {
            //     vm.errors.domainName = true;
            //     vm.errors.origin = true;
            //     vm.generalError = "The hostname from where you want to serve your images (domain name) cannot be the same as the hostname of the origin.";
            //     errors = true;
            // }

            if (errors) {
                return;
            }

            if (
                !_.isNull(vm.form.whitelist) &&
                vm.form.whitelist.length == 1 &&
                (_.isNull(vm.form.whitelist[0]) || vm.form.whitelist[0] == '')
            ) {
                vm.form.whitelist = null;
            }

            vm.form.domainName = vm.form.domainName.toLowerCase();


            if (isNew) {
                var same = false;
                if (!_.isNull(vm.form.originId)) {
                    var selected = _.findWhere(vm.origins, {id: parseInt(vm.form.originId)});
                    if (selected.hostname == vm.form.domainName) {
                        same = true;
                    }
                } else if (vm.form.domainName == vm.form.origin) {
                    same = true;
                }
                if (same) {
                    ieActions.openCompareDomainOrigin().result.then(function() {
                        runCreateNew();
                    });
                } else {
                    runCreateNew();
                }
            } else {
                editFinish.whitelist = false;
                editFinish.origin = false;
                editFinish.cors = false;
                editError.whitelist = false;
                editError.origin = false;
                editError.cors = false;
                vm.generalError = false;

                if (_.isNull(origOrigin) || vm.form.originId != origOrigin.id) {
                    var same = false;
                    if (!_.isNull(vm.form.originId)) {
                        var selected = _.findWhere(vm.origins, {id: parseInt(vm.form.originId)});
                        if (selected.hostname == vm.form.domainName) {
                            same = true;
                        }
                    } else if (vm.form.domainName == vm.form.origin) {
                        same = true;
                    }

                    if (same) {
                        ieActions.openCompareDomainOrigin().result.then(function() {
                            runUpdateDomain();
                        });
                    } else {
                        runUpdateDomain();
                    }
                } else {
                    editFinish.origin = true;
                    finishUpdate();
                    bsLoadingOverlayService.start({
                        referenceId: 'ie-domain-form'
                    });

                    runUpdateAllowed();
                    runUpdateCors();
                }
            }
        }

        function finish() {
            if (_.indexOf(awsError.error_type, 'certificate') != -1 || !vm.form.ssl) {
                accountResponse.conf.status.ssl = null;
            } else {
                if (_.isUndefined(accountResponse.conf.validation.validation.hash)) {
                    accountResponse.conf.status.ssl = 'PENDING_USER_VALIDATION';
                }
                accountResponse.conf.wit_certificate = witCertificate;
                accountResponse.conf.validation.type = 'DNS';
            }

            if (!_.isEmpty(awsError.error_type)) {
                accountResponse.type = awsError.type;
                accountResponse.error_type = awsError.error_type;
                $uibModalInstance.dismiss(accountResponse);
            } else {
                $uibModalInstance.close(accountResponse);
            }

            bsLoadingOverlayService.stop({
                referenceId: 'ie-domain-form'
            });
        }

        function finishUpdate() {
            if (!(editFinish.whitelist && editFinish.origin && editFinish.cors)) {
                return;
            }
            
            var success = [];
            var error = {};
            if (editError.origin) {
                error.origin = [];
                if (!_.isUndefined(editError.origin.data.type) && editError.origin.data.type == 'form') {
                    angular.forEach(editError.origin.data.errors, function(val, key) {
                        error.origin.push(key+": "+val);
                    });
                } else {
                    error.origin.push(editError.origin);
                }
            } else {
                success.push('Origin configuration updated successfully');
            }

            if (editError.whitelist) {
                error.whitelist = [];
                if (editError.whitelist.general) {
                    error.whitelist.push(editError.whitelist.errors);
                } else {
                    success.push("Allowed prefix preference updated, however errors occurred with the specified origins");
                    angular.forEach(editError.whitelist.errors, function(val, key) {
                        error.whitelist.push(val.origin+": "+val.reason);
                    });
                }
            } else {
                success.push('Allowed prefix configuration updated successfully');
            }

            var message = '';

            if (!_.isEmpty(_.keys(error))) {
                message += success.join("<br />");
                message += "<br />";
                if (!_.isUndefined(error.origin)) {
                    message += "Errors occurred with the origin configuration:<br />";
                    message += error.origin.join("<br />");
                }
                if (!_.isUndefined(error.whitelist)) {
                    message += "Errors occurred with the allowed prefix configuration:<br />";
                    message += error.whitelist.join("<br />");
                }
                vm.generalError = message;
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-domain-form'
                });
                return;
            } else {
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-domain-form'
                });
                loaderService.confirm();
                ieService.getSummary($stateParams.id).then(function(response) {
                    $uibModalInstance.close(response.data);
                }).catch(function(error) {
                    console.log(error);
                }).finally(function() {
                    loaderService.close();
                });
            }
        }

        function updateWhitelist(id) {
            if (_.isUndefined(id)) {
                vm.form.whitelist.push(null);
            } else {
                vm.form.whitelist.splice(id, 1);
                if (vm.form.whitelist.length === 0) {
                    vm.form.whitelist = [null];
                }
            }
        }

        function removeAllowedOrigin(index) {
            vm.form.corsAllowedOrigins.splice(index, 1);
        }

        function addAllowedOrigin() {
            vm.form.corsAllowedOrigins.push('');
        }

        function deleteDomain(domain) {
            $uibModalInstance.close(null);
            var origin = _.findWhere(vm.origins, {origin_conf_id: domain.id});
            var confirmDeleteDomain = ieActions.confirmDeleteDomain(domain, origin);
            confirmDeleteDomain.result.then(function(response) {
                vm.confs = _.reject(vm.confs, function(obj) {
                    return obj.id == domain.id;
                });
                if (vm.confs.length == 0) {
                    $rootScope.$broadcast('enableCache', {status: false});
                }
                if (!_.isUndefined(response.origin)) {
                    _.map(vm.origins, function(obj) {
                        if (obj.id == response.origin.id) {
                            obj.origin_conf_id = response.origin.origin_conf_id;
                            obj.in_use = response.origin.in_use;
                        }

                        return obj;
                    });
                }
                var showOrigins = false;
                angular.forEach(vm.confs, function(val, key) {
                    if (val.url !== '') {
                        showOrigins = true;
                    }
                });
                vm.showOrigins = showOrigins;
                vm.allowPrefix = _.indexOf(_.pluck(vm.confs, 'allow_origin_prefix'), 1) !== -1;
            })
        }

    }
})();
