(function() {
    'use strict';

    angular.module('customerVault.home')
        .factory('registerResolve', registerResolve);

    registerResolve.$inject = ['homeService'];

    function registerResolve(homeService) {
        var service = {
            error: {},
            load: {},
            run: run
        };

        return service;

        function run(args) {
            service.error = {};
            service.load = {};

            if (_.isEmpty(service.load)) {
                service.load = {};
                return homeService.getCountries().then(function(response) {
                    service.load.countries = response.data;
                    return homeService.getRegistration().then(function(response) {
                        service.load.platforms = response.data.platforms;
                        service.load.description = response.data.description;
                        service.load.publicKey = response.data.public_key;
                        return true;
                    }).catch(function(error) {
                        console.log(error);
                        service.error.status = error.status;
                        service.error.message = error.data.message;
                        return false;
                    });
                }).catch(function(error) {
                    console.log(error);
                    service.error.status = error.status;
                    service.error.message = error.data.message;
                    return false;
                });
            }
        }
    }
})();
