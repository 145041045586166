(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .controller('CloudBillingContactsController', CloudBillingContactsController);

    CloudBillingContactsController.$inject = ['$stateParams', 'bsLoadingOverlayService', 'cloudResolve', 'cloudService', 'Notification'];

    function CloudBillingContactsController($stateParams, bsLoadingOverlayService, cloudResolve, cloudService, Notification) {
        var vm = this;
        var resolveData = cloudResolve.load.billingcontacts;
        var error = cloudResolve.error;

        vm.addContact = addContact;
        vm.billingContacts = resolveData.billingContacts;
        vm.error = {
            name: null,
            email: null
        };
        vm.form = {
            name: null,
            email: null
        };
        vm.pageError = false;
        vm.pageErrorView = 'view/errors.400';
        vm.pageErrorViewMessage = null;
        vm.removeContact = removeContact;

        if (!_.isEmpty(error)) {
            vm.pageError = error.status;
            if (error.status == 403) {
                vm.pageErrorView = 'view/errors.403';
                vm.pageErrorViewMessage = error.message;
            }
        }

        function addContact() {
            vm.error = {
                name: null,
                email: null
            };
            var error = false;
            if (_.isNull(vm.form.name) || vm.form.name == '') {
                error = true;
                vm.error.name = 'error';
            }
            if (_.isNull(vm.form.email) || vm.form.email == '') {
                error = true;
                vm.error.email = 'error';
            }
            if (error) {
                return;
            }

            bsLoadingOverlayService.start({
                referenceId: 'cloud-billing-contacts-form'
            });
            cloudService.postBillingContacts($stateParams.id, vm.form).then(function(response) {
                Notification.success("Saved!");
                vm.billingContacts.push(response.data);
                vm.form.name = null;
                vm.form.email = null;
            }).catch(function(error) {
                console.log(error);
                Notification.error(error.message);
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'cloud-billing-contacts-form'
                });
            });
        }

        function removeContact(contact) {
            bsLoadingOverlayService.start({
                referenceId: 'cloud-billing-contacts-form'
            });
            cloudService.deleteBillingContacts($stateParams.id, contact.id).then(function(response) {
                Notification.success('Deleted!');
                vm.billingContacts = _.reject(vm.billingContacts, function(val) {
                    return val.email == contact.email;
                });
            }).catch(function(error) {
                console.log(error);
                Notification.error(error.data.message);
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'cloud-billing-contacts-form'
                });
            });
        }
    }
})();
