(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineDownloadImageController', ImageEngineDownloadImageController);

    ImageEngineDownloadImageController.$inject = ['$scope', '$uibModalInstance', 'single', 'uri'];

    function ImageEngineDownloadImageController($scope, $uibModalInstance, single, uri) {
        var vm = this;

        vm.allUri = uri;
        vm.close = close;
        vm.selected = 'usagetrendsos';
        vm.single = single;
        vm.uri = single ? uri : uri[vm.selected];

        $scope.$watch('vm.selected', function(newVal, oldVal) {
            if (!vm.single) {
                vm.uri = uri[newVal].uri;
            }
        });

        function close() {
            $uibModalInstance.dismiss();
        }
    }
})();
