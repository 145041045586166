(function() {
    'use strict';

    angular.module('customerVault.home')
        .controller('VerifyController', VerifyController);

    VerifyController.$inject = ['homeActions', 'verifyResolve'];

    function VerifyController(homeActions, verifyResolve) {
        var vm = this;

        var resolveData = verifyResolve.load;
        var error = verifyResolve.error

        if (!_.isEmpty(error)) {
            vm.error = error.status;
            vm.pageErrorView = "view/errors.400";
            return;
        }

        vm.error = false;
        vm.login = login;
        vm.pageErrorView = null;
        vm.user = resolveData.user;

        function login() {
            homeActions.openSignIn();
        }
    }
})();
