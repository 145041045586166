(function() {
    'use strict';

    angular.module('customerVault')
        .controller('GeneralErrorController', GeneralErrorController);

    GeneralErrorController.$inject = ['$scope', 'loginService'];

    function GeneralErrorController($scope, loginService) {
        var vm = this;

        vm.btnText = loginService.authInfo.authenticated ?
            'Return to My Account' :
            'Return to Login';
        vm.showButton = !_.isUndefined($scope.showButton) ? $scope.showButton : false;
        vm.state = loginService.authInfo.authenticated ?
            "myaccount" : "login";
    }
})();
