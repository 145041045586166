(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .controller('CapabilityReorderController', CapabilityReorderController);

    CapabilityReorderController.$inject = ['$scope', '$uibModalInstance', 'capabilities', 'cloudActions', 'maxCapabilities', 'maxLength'];

    function CapabilityReorderController($scope, $uibModalInstance, capabilities, cloudActions, maxCapabilities, maxLength) {
        var vm = this;

        vm.capabilities = capabilities;
        vm.close = close;
        vm.proceed = proceed;

        $scope.$watch('vm.capabilities', function(newVal, oldVal) {
            vm.capabilities = cloudActions.updateReorderCapabilities(newVal, oldVal, vm.capabilities, maxLength, maxCapabilities);
        }, true);

        function close() {
            $uibModalInstance.dismiss();
        }

        function proceed() {
            $uibModalInstance.close({$value: vm.capabilities});
        }
    }
})();
