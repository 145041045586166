(function() {
    'use strict';

    angular.module('customerVault')
        .directive('unauthorized', unauthorized);

    function unauthorized() {
        var directive = {
            templateUrl: 'view/errors.401',
            restrict: 'AE',
            replace: true,
            scope: {
                buttonText: '@',
                message: '@',
                title: '@',
                showButton: '='
            },
            controller: controller
        };

        return directive;

        function controller($scope) {
            $scope.title = !_.isUndefined($scope.title) ? $scope.title : "Unauthorized";
            $scope.buttonText = !_.isUndefined($scope.buttonText) ? $scope.buttonText : "Return to My Account";
            $scope.showButton = !_.isUndefined($scope.showButton) ? $scope.showButton : false;
        }
    }
})();
