(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEnginePaymentController', ImageEnginePaymentController);

    ImageEnginePaymentController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', 'breadcrumbsService', 'bsLoadingOverlayService', 'ieResolve', 'ieService', 'loginService', 'Notification', 'paymentService'];

    function ImageEnginePaymentController($rootScope, $scope, $state, $stateParams, $timeout, breadcrumbsService, bsLoadingOverlayService, ieResolve, ieService, loginService, Notification, paymentService) {
        var vm = this;

        var loadedInstance = null;

        var resolveData = ieResolve.load.payment;
        var error = ieResolve.error;

        if (!_.isEmpty(error)) {
            vm.error = error.status;
            switch (error.status) {
                case 404:
                    vm.pageErrorView = 'view/errors.404';
                    break;
                case 403:
                    vm.pageErrorView = 'view/errors.403';
                    vm.pageErrorViewMessage = error.message;
                    break;
                default:
                    vm.pageErrorView = 'view/errors.400';
            }
        } else {
            vm.countries = resolveData.countries;
            vm.dateExpiration = resolveData.dateExpiration;
            vm.error = false;
            vm.errors = {
                first_name: false,
                last_name: false,
                address: false,
                city: false,
                state: false,
                zip: false,
                consent: false,
                country: false
            };
            vm.form = {
                first_name: resolveData.user.first_name,
                last_name: resolveData.user.last_name,
                address: null,
                city: null,
                state: null,
                zip: null,
                consent: false,
                country: 230
            };
            vm.pageErrorView = 'view/errors.400';

            if (loginService.impersonate.mode || resolveData.user.id == loginService.authInfo.user.id) {
                vm.steps = breadcrumbsService.get('ie.payment', {subscription: resolveData.subscription})
            } else {
                vm.steps = breadcrumbsService.get('ie.payment', {user: resolveData.user, subscription: resolveData.subscription});
            }

            vm.submit = submit;
            vm.subscription = resolveData.subscription;

            generateToken();
        }

        $scope.$on('tokenGenerated', function(event, args) {
            braintree.dropin.create({
                authorization: args.token,
                container: '#ie-payment-form',
                card: {
                    cardholderName: {
                        required: true
                    }
                }
            }, function(createErr, instance) {
                $timeout(function() {
                    var disablePay = false;
                }, 1);
                loadedInstance = instance;
            });
        });

        var updatePaymentBreadcrumbs = $rootScope.$on('updatePaymentBreadcrumbs', function() {
            vm.steps = null;
            $timeout(function() {
                if (loginService.impersonate.mode || resolveData.user.id == loginService.authInfo.user.id) {
                    vm.steps = breadcrumbsService.get('ie.payment', {subscription: resolveData.subscription})
                } else {
                    vm.steps = breadcrumbsService.get('ie.payment', {user: resolveData.user, subscription: resolveData.subscription});
                }
            }, 100);
            $scope.$on('$destroy', updatePaymentBreadcrumbs);
        });

        function generateToken() {
            return paymentService.getBraintreeAuthorization().then(function(response) {
                $scope.$broadcast('tokenGenerated', {token: response.data.authorization});
            });
        }

        function submit() {
            vm.errors = {
                first_name: false,
                last_name: false,
                address: false,
                city: false,
                state: false,
                zip: false,
                consent: false,
                country: false
            };
            if (!vm.form.consent) {
                vm.errors.consent = true;
                return;
            }
            var errors = ieService.validateBilling(vm.form, false);
            if (!_.isEmpty(errors)) {
                angular.forEach(errors, function(val, key) {
                    vm.errors[val] = true;
                });
                return;
            }

            bsLoadingOverlayService.start({
                referenceId: 'ie-payment'
            });
            loadedInstance.requestPaymentMethod(function(requestPaymentMethodErr, payload) {
                if (!_.isUndefined(payload)) {
                    vm.form.nonce = payload.nonce;
                    vm.form.country_id = angular.copy(vm.form.country);
                    ieService.postSetPayment(vm.subscription.id, vm.form).then(function(response) {
                        Notification.success("Successfully added payment information");
                        ieResolve.load.main = {};
                        $state.go('ie.home', {id: vm.subscription.id});
                    }).catch(function(error) {
                        Notification.error("Your payment information cannot be added at this time. Please contact support@scientiamobile.com");
                        console.log(error);
                        loadedInstance.teardown(function(err) {
                            if (!err) {
                                generateToken();
                            }
                        });
                    }).finally(function() {
                        bsLoadingOverlayService.stop({
                            referenceId: 'ie-payment'
                        });
                    });
                } else {
                    bsLoadingOverlayService.stop({
                        referenceId: 'ie-payment'
                    });
                    return;
                }
            })
        }
    }
})();
