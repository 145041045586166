(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .controller('CloudCapabilityDescriptionController', CloudCapabilityDescriptionController);

    CloudCapabilityDescriptionController.$inject = ['$rootScope', '$uibModalInstance', 'capability', 'groupedCapabilities'];

    function CloudCapabilityDescriptionController($rootScope, $uibModalInstance, capability, groupedCapabilities) {
        var vm = this;

        var group = _.find(groupedCapabilities, function(obj) {
            return obj.id == capability.group_id;
        });

        capability.group_name = group.group_name;

        vm.capability = capability;
        vm.close = close;

        function close() {
            $uibModalInstance.close();
        }
    }
})();
