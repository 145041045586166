(function() {
    'use strict';

    angular.module('customerVault.license')
        .controller('LicenseCapabilitiesModalController', LicenseCapabilitiesModalController);

    LicenseCapabilitiesModalController.$inject = ['$scope', 'capability', 'groups', 'licenseCapabilitiesService', '$uibModalInstance'];

    function LicenseCapabilitiesModalController($scope, capability, groups, licenseCapabilitiesService, $uibModalInstance) {
        $scope.capability = _.find(licenseCapabilitiesService.allCapabilities, function(obj) {
            return obj.name == capability;
        });
        $scope.close = close;
        $scope.group = !_.isUndefined($scope.capability) ? $scope.capability.group.group_name : null;
        $scope.view = !_.isUndefined($scope.capability) ? "view/components.detail_hover" : "view/components.details_unavailable";

        function close() {
            $uibModalInstance.close();
        }
    }
})();
