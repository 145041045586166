(function() {
    'use strict';

    angular.module('customerVault.wurfljs')
        .controller('WurfljsCertificateController', WurfljsCertificateController);

    WurfljsCertificateController.$inject = ['$rootScope', '$stateParams', '$uibModalInstance', 'action', 'bsLoadingOverlayService', 'cname', 'Notification', 'wurfljsService'];

    function WurfljsCertificateController($rootScope, $stateParams, $uibModalInstance, action, bsLoadingOverlayService, cname, Notification, wurfljsService) {
        var vm = this;

        vm.action = action;
        vm.close = close;
        vm.cname = cname;
        vm.errors = {
            certificate: false,
            key: false
        };
        vm.form = {
            certificate: null,
            key: null,
            ca: null
        };
        vm.proceed = proceed;
        vm.title = action == 'add' ? 'Add Certificate' : 'View Certificate';

        function addCertificate() {
            var error = false;
            vm.errors = {
                certificate: false,
                key: false
            };
            if (_.isNull(vm.form.certificate) || vm.form.certificate == '') {
                error = true;
                vm.errors.certificate = true;
            }
            if (_.isNull(vm.form.key) || vm.form.key == '') {
                error = true;
                vm.errors.key = true;
            }
            if (error) {
                return;
            }

            bsLoadingOverlayService.start({
                referenceId: 'wjs-cert-add-form'
            });
            wurfljsService.postCertificate($stateParams.id, cname.id, vm.form).then(function(response) {
                $rootScope.$broadcast('addedCertificate', {cert: response.data.cert, parse: response.data.parse, cnameId: cname.id});
                $uibModalInstance.dismiss();
            }).catch(function(error) {
                console.log(error);
                Notification.error("Unable to upload certificate");
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'wjs-cert-add-form'
                });
            });
        }

        function close() {
            $uibModalInstance.dismiss();
        }

        function deleteCertificate() {
            bsLoadingOverlayService.start({
                referenceId: 'wjs-cert-view-form'
            });
            wurfljsService.deleteCertificate($stateParams.id, cname.id).then(function(response) {
                $rootScope.$broadcast('removedCertificate', {cnameId: cname.id});
                $uibModalInstance.dismiss();
            }).catch(function(error) {
                console.log(error);
                Notification.error("Unable to delete certificate");
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'wjs-cert-view-form'
                });
            });
        }

        function proceed() {
            if (vm.action == 'add') {
                addCertificate();
            } else {
                deleteCertificate();
            }
        }
    }
})();
