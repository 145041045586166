(function() {
    'use strict';

    angular.module('customerVault.filex')
        .factory('filexService', filexService);

    filexService.$inject = ['$resource', '$rootScope', '$state', 'endSession', 'flashMessage', 'loaderService', 'loginService'];

    function filexService($resource, $rootScope, $state, endSession, flashMessage, loaderService, loginService) {
        var resource = new $resource('/api/vault/filex/:action', {}, {
            postCheckUser: {
                method: 'POST',
                params: {
                    action: 'login'
                }
            },
            postRemoveCookies: {
                method: 'POST',
                params: {
                    action: 'cookies'
                }
            }
        });

        var service = {
            checkAdmin: checkAdmin,
            postCheckUser: postCheckUser,
            postRemoveCookies: postRemoveCookies
        };

        return service;

        function checkAdmin(userId) {
            // True = go to admin
            // False = go to user
            if (userId != loginService.authInfo.user.id) {
                return true;
            }
            return false;
        }

        function postCheckUser(token) {
            resource.postCheckUser({}, {token: token}).$promise.then(function(response) {
                localStorage.setItem('auth_token', response.data.token);
                localStorage.setItem('access_time', Math.round((new Date()).getTime() / 1000));
                localStorage.setItem('refresh_time', Math.round((new Date()).getTime() / 1000));
                loaderService.close();
                if (loginService.impersonate.mode) {
                    $state.go('account', {id: response.data.user.id});
                } else {
                    $state.go('myaccount');
                }
            }).catch(function(error) {
                console.log(error.data);
                switch (error.status) {
                    case 403:
                        runError(error.data.message);
                        break;
                    default:
                        runError("An unknown error occurred. Please <a href='https://support.scientiamobile.com' target=\"_blank\">contact us</a> for assistance.");
                }
            });
        }

        function postRemoveCookies(cookies) {
            return resource.postRemoveCookies({}, {cookies: cookies}).$promise;
        }

        function runError(message) {
            // Log the user out
            endSession.end();
            loginService.intended = undefined;
            loginService.authInfo.authenticated = false;
            loginService.authInfo.user = null;
            $rootScope.$broadcast('authenticated');
            $rootScope.$broadcast('endImpersonation');

            flashMessage.message.message = message;
            flashMessage.message.show = true;

            $state.go('login');
        }
    }
})();
