(function() {
    'use strict';

    angular.module('customerVault.home')
        .factory('ordersService', ordersService);

    ordersService.$inject = [];

    function ordersService() {
        var start = {
            dateOptions: {
                minDate: null,
                maxDate: null,
                floor: null,
                ceil: null
            },
            origInvoices: null,
            selectedOrderStatus: null
        };

        var service = {
            variables: angular.copy(start),
            reset: reset
        };

        return service;

        function reset() {
            service.variables = angular.copy(start);
        }
    }
})();
