(function() {
    'use strict';

    angular.module('customerVault')
        .directive('authIssue', authIssue);

    function authIssue() {
        var directive = {
            restrict: 'EA',
            template: "<span>Your email address still requires verification. <a ng-click=\"vm.resend()\">Resend verification email</a>.</span>",
            replace: true
        };

        return directive;
    }
})();
