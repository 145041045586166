(function() {
    'use strict';

    angular.module('customerVault.wurfljs')
        .factory('wurfljsService', wurfljsService);

    wurfljsService.$inject = ['$resource', 'loginService'];

    function wurfljsService($resource, loginService) {
        var resource = new $resource('/api/vault/account/wurfljs/:id/:action/:action_id', {id: '@id', action_id: '@action_id'}, {
            deleteCertificate: {
                method: 'DELETE',
                params: {
                    action: 'certificate'
                }
            },
            deleteCname: {
                method: 'DELETE',
                params: {
                    action: 'cnames'
                }
            },
            deleteDomain: {
                method: 'DELETE',
                params: {
                    action: 'domains'
                }
            },
            getAccount: {
                method: 'GET',
                params: {
                    action: null
                }
            },
            getBilling: {
                method: 'GET',
                params: {
                    action: 'billing',
                    action_id: null
                }
            },
            getBillingHistory: {
                method: 'GET',
                params: {
                    action: 'billinghistory'
                }
            },
            getCapabilities: {
                method: 'GET',
                params: {
                    action: 'capabilities'
                }
            },
            getChangePlan: {
                method: 'GET',
                params: {
                    action: 'plan'
                }
            },
            getCnames: {
                method: 'GET',
                params: {
                    action: 'cnames'
                }
            },
            getDetections: {
                method: 'GET',
                params: {
                    action: 'detections',
                    start: '@start',
                    end: '@end'
                }
            },
            getDomains: {
                method: 'GET',
                params: {
                    action: 'domains'
                }
            },
            getIncreaseDetections: {
                method: 'GET',
                params: {
                    action: 'changedetections'
                }
            },
            getPlanInfo: {
                method: 'GET',
                params: {
                    action: 'plans'
                }
            },
            getPlans: {
                method: 'GET',
                params: {
                    action: 'plans'
                }
            },
            getTransactionReceipt: {
                method: 'GET',
                params: {
                    action: 'transaction'
                }
            },
            getUsage: {
                method: 'GET',
                params: {
                    action: 'usage'
                }
            },
            patchDomains: {
                method: 'PATCH',
                params: {
                    action: 'domains'
                }
            },
            postCancelAccount: {
                method: 'POST',
                params: {
                    action: 'cancel',
                    action_id: null
                }
            },
            postCertificate: {
                method: 'POST',
                params: {
                    action: 'certificate'
                }
            },
            postChangePlan: {
                method: 'POST',
                params: {
                    action: 'plan'
                }
            },
            postCnames: {
                method: 'POST',
                params: {
                    action: 'cnames'
                }
            },
            postCreate: {
                method: 'POST',
                params: {
                    action: 'create'
                }
            },
            postDomains: {
                method: 'POST',
                params: {
                    action: 'domains'
                }
            },
            postIncreaseDetections: {
                method: 'POST',
                params: {
                    action: 'changedetections'
                }
            },
            postSendTransactionReceipt: {
                method: 'POST',
                params: {
                    action: 'transaction'
                }
            },
            postUpdateBilling: {
                method: 'POST',
                params: {
                    action: 'billing',
                    action_id: null
                }
            }
        });

        var service = {
            deleteCertificate: deleteCertificate,
            deleteCname: deleteCname,
            deleteDomain: deleteDomain,
            getAccount: getAccount,
            getBilling: getBilling,
            getBillingHistory: getBillingHistory,
            getCapabilities: getCapabilities,
            getChangePlan: getChangePlan,
            getCnames: getCnames,
            getDetections: getDetections,
            getDomains: getDomains,
            getIncreaseDetections: getIncreaseDetections,
            getPlanInfo: getPlanInfo,
            getPlans: getPlans,
            getTransactionReceipt: getTransactionReceipt,
            getUsage: getUsage,
            patchDomains: patchDomains,
            postCancelAccount: postCancelAccount,
            postCertificate: postCertificate,
            postChangePlan: postChangePlan,
            postCnames: postCnames,
            postCreate: postCreate,
            postDomains: postDomains,
            postIncreaseDetections: postIncreaseDetections,
            postSendTransactionReceipt: postSendTransactionReceipt,
            postUpdateBilling: postUpdateBilling,
            validateBilling: validateBilling
        };

        return service;

        function deleteCertificate(id, cnameId) {
            return resource.deleteCertificate({id: id, action_id: cnameId}).$promise;
        }

        function deleteCname(id, cnameId) {
            return resource.deleteCname({id: id, action_id: cnameId}).$promise;
        }

        function deleteDomain(id, domainId) {
            return resource.deleteDomain({id: id, action_id: domainId}).$promise;
        }

        function getAccount(id) {
            return resource.getAccount({id: id}).$promise;
        }

        function getBilling(id) {
            return resource.getBilling({id: id}).$promise;
        }

        function getBillingHistory(id) {
            return resource.getBillingHistory({id: id}).$promise;
        }

        function getCapabilities() {
            return resource.getCapabilities({id: null}).$promise;
        }

        function getChangePlan(id, plan) {
            return resource.getChangePlan({id: id, action_id: plan}).$promise;
        }

        function getCnames(id) {
            return resource.getCnames({id: id}).$promise;
        }

        function getDetections(id, start, end) {
            return resource.getDetections({id: id, start: start, end: end}).$promise;
        }

        function getDomains(id) {
            return resource.getDomains({id: id}).$promise;
        }

        function getIncreaseDetections(id) {
            return resource.getIncreaseDetections({id: id}).$promise;
        }

        function getPlanInfo(id) {
            return resource.getPlanInfo({id: id}).$promise;
        }

        function getPlans() {
            return resource.getPlans({id: null}).$promise;
        }

        function getUsage(id) {
            return resource.getUsage({id: id}).$promise;
        }

        function getTransactionReceipt(id, transactionId) {
            return resource.getTransactionReceipt({id: id, action_id: transactionId}).$promise;
        }

        function patchDomains(id, domain_id) {
            return resource.patchDomains({id: id, action_id: domain_id}).$promise;
        }

        function postCancelAccount(id, reason) {
            return resource.postCancelAccount({id: id}, {reason: reason}).$promise;
        }

        function postCertificate(id, cnameId, certificate) {
            return resource.postCertificate({id: id, action_id: cnameId}, {certificate: certificate}).$promise;
        }

        function postChangePlan(id, to, plan) {
            return resource.postChangePlan({id: id, action_id: to}, {plan: plan}).$promise;
        }

        function postCnames(id, cname) {
            return resource.postCnames({id: id}, {cname: cname}).$promise;
        }

        function postCreate(form) {
            return resource.postCreate({id: null}, form).$promise;
        }

        function postDomains(id, domain) {
            return resource.postDomains({id: id}, {domain: domain}).$promise;
        }

        function postIncreaseDetections(id, detections) {
            return resource.postIncreaseDetections({id: id}, {allowedDetections: detections}).$promise;
        }

        function postSendTransactionReceipt(id, transactionId, email) {
            return resource.postSendTransactionReceipt({id: id, action_id: transactionId}, {email: email}).$promise;
        }

        function postUpdateBilling(id, form) {
            return resource.postUpdateBilling({id: id}, form).$promise;
        }

        function validateBilling(form, payment) {
            var fields = ['address', 'city', 'state', 'zip', 'country'];
            if (payment) {
                fields.push('nonce');
            }

            angular.forEach(fields, function(val, key) {
                if (!_.isNull(form[val]) && form[val] != '') {
                    fields = _.reject(fields, function(v) {
                        return v == val;
                    });
                }
            });

            return fields;
        }
    }
})();
