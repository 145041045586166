(function() {
    'use strict';

    angular.module('customerVault.ie')
        .factory('ieStats', ieStats);

    ieStats.$inject = ['$resource'];

    function ieStats($resource) {
        var resource = new $resource('/api/vault/account/imageengine/statistics/:action/:domain/:start/:end', {}, {
            postBandwidthSaved: {
                method: 'POST',
                params: {
                    action: 'bandwidthsaved'
                }
            },
            postCacheHitRatio: {
                method: 'POST',
                params: {
                    action: 'cachehitratio'
                }
            },
            postImageFormatSavings: {
                method: 'POST',
                params: {
                    action: 'imageformat'
                }
            },
            postImagePayloadReduction: {
                method: 'POST',
                params: {
                    action: 'payloadreduction'
                }
            },
            postRequests: {
                method: 'POST',
                params: {
                    action: 'requests'
                }
            },
            postTopCountries: {
                method: 'POST',
                params: {
                    action: 'topcountries'
                }
            },
            postTopMissedUri: {
                method: 'POST',
                params: {
                    action: 'topmissed'
                }
            },
            postTopRequestUri: {
                method: 'POST',
                params: {
                    action: 'toprequests'
                }
            },
            postUsageTrends: {
                method: 'POST',
                params: {
                    action: 'usagetrends'
                }
            }
        });

        var service = {
            postBandwidthSaved: postBandwidthSaved,
            postCacheHitRatio: postCacheHitRatio,
            postImageFormatSavings: postImageFormatSavings,
            postImagePayloadReduction: postImagePayloadReduction,
            postRequests: postRequests,
            postTopCountries: postTopCountries,
            postTopMissedUri: postTopMissedUri,
            postTopRequestUri: postTopRequestUri,
            postUsageTrends: postUsageTrends
        };

        return service;

        function postBandwidthSaved(type, value, start, end) {
            start = _.isUndefined(start) ? null : start;
            end = _.isUndefined(end) ? null : end;

            return resource.postBandwidthSaved(
                {start: start, end: end}, {type: type, value: value}
            ).$promise;
        }

        function postCacheHitRatio(type, value, start, end) {
            start = _.isUndefined(start) ? null : start;
            end = _.isUndefined(end) ? null : end;

            return resource.postCacheHitRatio(
                {start: start, end: end}, {type: type, value: value}
            ).$promise;
        }

        function postImageFormatSavings(type, value, start, end) {
            start = _.isUndefined(start) ? null : start;
            end = _.isUndefined(end) ? null : end;

            return resource.postImageFormatSavings(
                {start: start, end: end}, {type: type, value: value}
            ).$promise;
        }

        function postImagePayloadReduction(type, value, start, end) {
            start = _.isUndefined(start) ? null : start;
            end = _.isUndefined(end) ? null : end;

            return resource.postImagePayloadReduction(
                {start: start, end: end}, {type: type, value: value}
            ).$promise;
        }

        function postRequests(type, value, start, end) {
            start = _.isUndefined(start) ? null : start;
            end = _.isUndefined(end) ? null : end;

            return resource.postRequests(
                {start: start, end: end}, {type: type, value: value}
            ).$promise;
        }

        function postTopCountries(type, value, start, end) {
            start = _.isUndefined(start) ? null : start;
            end = _.isUndefined(end) ? null : end;

            return resource.postTopCountries(
                {start: start, end: end}, {type: type, value: value}
            ).$promise;
        }

        function postTopMissedUri(type, value, start, end) {
            start = _.isUndefined(start) ? null : start;
            end = _.isUndefined(end) ? null : end;

            return resource.postTopMissedUri(
                {start: start, end: end}, {type: type, value: value}
            ).$promise;
        }

        function postTopRequestUri(type, value, start, end) {
            start = _.isUndefined(start) ? null : start;
            end = _.isUndefined(end) ? null : end;

            return resource.postTopRequestUri(
                {start: start, end: end}, {type: type, value: value}
            ).$promise;
        }

        function postUsageTrends(type, value, start, end) {
            start = _.isUndefined(start) ? null : start;
            end = _.isUndefined(end) ? null : end;

            return resource.postUsageTrends(
                {start: start, end: end}, {type: type, value: value}
            ).$promise;
        }
    }
})();
