(function() {
    'use strict';

    angular.module('authApp')
        .factory('endSession', endSession);

    function endSession() {
        var service = {
            end: end
        };

        return service;

        function end() {
            localStorage.removeItem('auth_token');
            localStorage.removeItem('access_time');
            localStorage.removeItem('refresh_time');
            localStorage.removeItem('remember_token');
            localStorage.removeItem('remember_time');
        }
    }
})();
