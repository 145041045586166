(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineTrialController', ImageEngineTrialController);

    ImageEngineTrialController.$inject = ['$q', '$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$window', 'authenticate', 'breadcrumbsService', 'bsLoadingOverlayService', 'flashMessage', 'homeService', 'ieActions', 'ieResolve', 'ieService', 'loginService', 'signupService', 'vcRecaptchaService'];

    function ImageEngineTrialController($q, $rootScope, $scope, $state, $stateParams, $timeout, $window, authenticate, breadcrumbsService, bsLoadingOverlayService, flashMessage, homeService, ieActions, ieResolve, ieService, loginService, signupService, vcRecaptchaService) {
        var vm = this;

        var resolveData = ieResolve.load.signuptrial;
        var error = ieResolve.error;
        var widgetId = null;
        var certificateError = false;
        var certificatePromises = [];
        var errorMessages = [];
        var accountResponse = null;

        if (!_.isEmpty(error)) {
            switch (error.status) {
                case 404:
                    vm.redirect = false;
                    vm.error = error.status;
                    vm.pageErrorView = "view/errors.404";
                    break;
                case 303:
                    vm.redirect = true;
                    flashMessage.message.message = error.message;
                    flashMessage.message.show = true;
                    if (!error.data.id) {
                        $state.go(error.data.state);
                    } else {
                        if (
                            loginService.authInfo.authenticated &&
                            (loginService.authInfo.user.id == error.data.user_id || loginService.impersonate.user.id == error.data.user_id)
                        ) {
                            $state.go(error.data.state, {id: error.data.id});
                        } else {
                            $state.go('ie.signup');
                        }
                    }
                    break;
                default:
                    vm.redirect = false;
                    vm.pageErrorView = "view/errors.400";
                    vm.error = error.status;
            }
            return;
        }

        var accountError = {
            name: false,
            terms: false
        };
        var flash = {
            message: null,
            show: false,
            class: 'alert-danger'
        };
        var registrationErrors = {
            company_name: {
                message: null,
                show: false
            },
            passwordConfirm: {
                message: null,
                show: false
            },
            consent: {
                message: null,
                show: false
            },
            captcha: {
                show: false
            },
            country: {
                message: null,
                show: false
            },
            email: {
                message: null,
                show: false
            },
            first_name: {
                message: null,
                show: false
            },
            last_name: {
                message: null,
                show: false
            },
            password: {
                message: null,
                show: false
            },
            phone: {
                message: null,
                show: false
            },
            terms: {
                message: null,
                show: false
            },
            username: {
                message: null,
                show: false
            }
        };

        vm.accountError = angular.copy(accountError);
        vm.accountForm = {
            domainName: '',
            origin: '',
            hostHeader: '',
            name: null,
            terms: false,
            userId: null,
            link: $stateParams.link,
            ssl: true,
            originProtocol: 'http',
            allowOriginPrefix: 1,
            whitelist: [null]
        };
        vm.authenticated = loginService.authInfo.authenticated;
        vm.captchaSuccess = captchaSuccess;
        vm.check = check;
        vm.countries = resolveData.countries;
        vm.createAccount = createAccount;
        vm.error = false;
        vm.flash = angular.copy(flash);
        vm.onWidgetCreate = onWidgetCreate;
        vm.publicKey = resolveData.publicKey
        vm.redirect = false;
        vm.registrationForm = {
            company_name: null,
            confirmed: true,
            passwordConfirm: null,
            consent: false,
            country: null,
            email: resolveData.email,
            first_name: null,
            last_name: null,
            password: null,
            phone: null,
            terms: false,
            username: null
        };
        vm.register = register;
        vm.registrationErrors = angular.copy(registrationErrors);
        vm.showExplanation = showExplanation;
        vm.showRestrictionExplanation = showRestrictionExplanation;
        vm.signIn = signIn;
        vm.steps = loginService.authInfo.authenticated ? breadcrumbsService.get('ie.trial') : null;
        vm.termsAndConditions = true;
        vm.updateWhitelist = updateWhitelist;

        $rootScope.$on('authenticated', function() {
            vm.authenticated = true;
            vm.steps = breadcrumbsService.get('ie.trial');
        });

        $scope.$watch('vm.accountForm.allowOriginPrefix', function(newVal) {
            if (!_.isUndefined(newVal)) {
                if (newVal == 1) {
                    vm.accountForm.whitelist = [null];
                } else {
                    vm.accountForm.whitelist = null;
                }
            }
        }, true);

        function captchaSuccess() {
            vm.registrationErrors.captcha.show = false
        }

        function check(type, form) {
            if (form == 'registration') {
                if (type == 'terms') {
                    vm.registrationForm.terms = !vm.registrationForm.terms;
                    vm.registrationErrors.terms.show = false;
                } else {
                    vm.registrationForm.consent = !vm.registrationForm.consent;
                    vm.registrationErrors.consent.show = false;
                }
            } else {
                vm.accountForm.terms = !vm.accountForm.terms;
                vm.accountError.terms = false;
            }
        }

        function createAccount() {
            var fields = ieService.validateTrial(vm.accountForm);
            if (!_.isEmpty(fields)) {
                angular.forEach(fields, function(val, key) {
                    vm.accountError[val] = true;
                });
                if (vm.accountError['name'] || vm.accountError['domainName']) {
                    $window.scrollTo(0, 0);
                }
                return;
            }

            if (_.isNull(vm.accountForm.userId)) {
                if (loginService.impersonate.mode) {
                    vm.accountForm.userId = loginService.impersonate.user.id;
                } else if (loginService.authInfo.authenticated) {
                    vm.accountForm.userId = loginService.authInfo.user.id;
                } else {
                    vm.flash.message = "User could not be determined. Please contact support@scientiamobile.com for assistance";
                    vm.flash.show = true;
                    $window.scrollTo(0, 0);

                    $timeout(function() {
                        vm.flash = angular.copy(flash);
                    }, 10000);
                }
            }

            if (
                !_.isNull(vm.accountForm.whitelist) &&
                vm.accountForm.whitelist.length == 1 &&
                (_.isNull(vm.accountForm.whitelist[0]) || vm.accountForm.whitelist[0] == '')
            ) {
                vm.accountForm.whitelist = null;
            }

            // Check unique on whitelist
            if (!_.isNull(vm.accountForm.whitelist)) {
                var distinctWhitelist = new Set(vm.accountForm.whitelist);
                if (vm.accountForm.whitelist.length !== distinctWhitelist.size) {
                    vm.flash.message = "Duplicate allowed origins exist";
                    vm.flash.show = true;
                    $window.scrollTo(0, 0);

                    $timeout(function() {
                        vm.flash = angular.copy(flash);
                    }, 10000);
                    return;
                }
            }

            vm.accountForm.domainName = vm.accountForm.domainName.toLowerCase();

            if (vm.accountForm.domainName === vm.accountForm.origin) {
                // vm.flash.message = "The hostname from where you want to serve your images (domain name) cannot be the same as the hostname of the origin.";
                // vm.flash.show = true;
                //
                // vm.accountError.domainName = true;
                // vm.accountError.origin = true;
                //
                // $window.scrollTo(0, 0);
                //
                // $timeout(function() {
                //     vm.flash = angular.copy(flash);
                // }, 10000);
                // return;
                ieActions.openCompareDomainOrigin().result.then(function() {
                    bsLoadingOverlayService.start({
                        referenceId: 'ie-trial-account-form'
                    });
                    runCreateAccount();
                });
            } else {
                runCreateAccount();
            }
        }

        function runCreateAccount() {
            bsLoadingOverlayService.start({
                referenceId: 'ie-trial-account-form'
            });
            ieService.postCreateTrial(vm.accountForm).then(function(response) {
                accountResponse = response.data;
                ieService.transfer.subscription = response.data.subscription;
                // Trial account succeeded
                // Get the regions
                ieService.getAwsRegions().then(function(response) {
                    // Add DNS records
                    var regions = response.data;
                    var records = [];
                    angular.forEach(regions, function(val, key) {
                        if (val.Deploy !== 'provisioning') {
                            var record = {
                                domain: vm.accountForm.domainName.toLowerCase(),
                                region: val.RegionName,
                                type: 'A'
                            };
                        }

                        if (vm.accountForm.ssl) {
                            certificatePromises.push(
                                ieService.postRequestCertificate(accountResponse.subscription.id, vm.accountForm.domainName.toLowerCase(), val.RegionName)
                                    .then(function(response) {
                                        ieService.transfer.certificate = response.data.validation;
                                        if (val.Deploy !== 'provisioning') {
                                            if (!_.isUndefined(response.data.validation.hash)) {
                                                record['hash'] = response.data.validation.hash;
                                            }
                                        }
                                    }).catch(function(error) {
                                        certificateError = true;
                                        errorMessages.push("SSL/TLS certificate was not generated<br />");
                                        $q.reject();
                                    }).finally(function() {
                                        if (val.Deploy !== 'provisioning') {
                                            records.push(record);
                                        }
                                    })
                            );
                        } else {
                            if (val.Deploy !== 'provisioning') {
                                records.push(record);
                            }
                        }
                    });

                    $q.all(certificatePromises).then(function() {
                        if (records.length !== 0) {
                            ieService.postResourceRecords(accountResponse.subscription.id, records)
                                .then(function() {})
                                .catch(function(error) {
                                    errorMessages.push("Resource records were not configured<br />");
                                }).finally(function() {
                                    finish();
                                })
                        } else {
                            finish();
                        }
                    });
                }).catch(function(error) {
                    console.log(error);
                    bsLoadingOverlayService.stop({
                        referenceId: 'ie-trial-account-form'
                    });
                    ieService.transfer.subscription = accountResponse.subscription;
                    var message = "Your ImageEngine Trial account has been created, however the following issues occurred:<br />";
                    message += "Resource records were not configured<br />";
                    if (vm.accountForm.ssl) {
                        message += "SSL/TLS certificate was not generated<br />";
                    }
                    message += "Please contact support@scientiamobile.com for assistance.";
                    signupService.value = {
                        class: 'warning',
                        message: message,
                        show: true
                    };
                    $state.go('ie.trialcomplete', {id: accountResponse.subscription.id, fromTrial: true, errors: ['certificate']});
                });
            }).catch(function(error) {
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-trial-account-form'
                });

                switch (error.data.data.type) {
                    case 'link':
                    case 'user':
                        vm.flash.message = error.data.message;
                        vm.flash.show = true;
                        break;
                    case 'form':
                        angular.forEach(error.data.data.errors, function(val, key) {
                            vm.accountError[key] = val;
                        });
                        break;
                    case 'token':
                        vm.flash.message = "Your ImageEngine Trial account was created, however an issue occurred while gathering your usage token. Please contact support@scientiamobile.com for assistance.";
                        vm.flash.class = 'alert-warning';
                        vm.flash.show = true;
                        break;
                    case 'creation':
                        // Something happened after account creation
                        if (_.indexOf(error.data.data.errors, 'domain configuration') !== -1) {
                            signupService.value = {
                                class: 'warning',
                                message: 'Your ImageEngine Trial account has been created, however an issue occurred while creating your domain configuration. Please contact support@scientiamobile.com for assistance.',
                                show: true
                            };
                        } else {
                            var message = "Your ImageEngine Trial account has been created, however the following issues occurred:<br />";
                            if (_.indexOf(error.data.data.errors, 'origin') !== -1) {
                                message += "Origin host was not configured<br />";
                            }
                            if (_.indexOf(error.data.data.errors, 'resource record') !== -1) {
                                message += "Domain name record has not been configured<br />";
                            }
                            message += "Please contact support@scientiamobile.com for assistance.";
                            signupService.value = {
                                class: 'warning',
                                message: message,
                                show: true
                            };
                        }
                        ieService.transfer = error.data.data;
                        $state.go('ie.trialcomplete', {id: error.data.data.subscription.id, fromTrial: true, error: error.data.data.errors});
                        break;
                    default:
                        break;
                }
            });
        }

        function finish() {
            if (!_.isEmpty(errorMessages)) {
                var message = "Your ImageEngine Trial account has been created, however the following issues occurred:<br />";
                message += errorMessages.join();
                message += "Please contact support@scientiamobile.com for assistance.";
                signupService.value = {
                    class: 'warning',
                    message: message,
                    show: true
                };
            }
            var data = {
                id: accountResponse.subscription.id,
                fromTrial: true
            };

            if (certificateError) {
                data.error = ['certificate'];
            }
            bsLoadingOverlayService.stop({
                referenceId: 'ie-trial-account-form'
            });
            $state.go('ie.trialcomplete', data);
        }

        function onWidgetCreate(_widgetId) {
            widgetId = _widgetId;
        }

        function register() {
            vm.registrationErrors = angular.copy(registrationErrors);
            var captcha = vcRecaptchaService.getResponse();
            if (captcha === "") {
                vm.registrationErrors.captcha.show = true;
                return;
            }
            var fields = ieService.validateRegistration(vm.registrationForm)
            if (!_.isEmpty(fields)) {
                angular.forEach(fields, function(val, key) {
                    vm.registrationErrors[val].show = true;
                });
                return;
            }

            vm.registrationForm.captcha = captcha;
            vm.registrationForm.country_id = vm.registrationForm.country.country_id;
            vm.registrationForm.confirmed = true;

            bsLoadingOverlayService.start({
                referenceId: 'ie-trial-registration-form'
            });

            homeService.postRegistration(vm.registrationForm).then(function(response) {
                vm.accountForm.userId = response.data.user_id;
                authenticate.auth({
                    username: vm.registrationForm.username,
                    password: vm.registrationForm.password
                }).then(function(response) {
                    if (!response) {
                        vm.flash.message = "We are unable to log you in at this time. Please log in and try your trial link again.";
                        vm.flash.show = true;
                    } else {
                        loginService.authInfo.authenticated = true;
                        loginService.authInfo.user = response;
                        $rootScope.$broadcast('authenticated');
                        vm.authenticated = true;
                        vm.termsAndConditions = false;
                        vm.accountForm.terms = true;
                        $window.scrollTo(0, 0);
                    }
                }).catch(function(error) {
                    console.log(error);
                    vm.flash.message = "We are unable to log you in at this time. Please log in and try your trial link again.";
                    vm.flash.show = true;
                }).finally(function() {
                    bsLoadingOverlayService.stop({
                        referenceId: 'ie-trial-registration-form'
                    });
                });
            }).catch(function(error) {
                vcRecaptchaService.reload(widgetId);
                switch (error.status) {
                    case 412:
                        angular.forEach(error.data.data, function(val, key) {
                            if (!_.isUndefined(vm.registrationErrors[key].show)) {
                                vm.registrationErrors[key].show = true;
                                vm.registrationErrors[key].message = val.join("<br />");
                            } else {
                                vm.registrationErrors[key].show = true;
                            }
                        });
                        $window.scrollTo(0, 0);
                        break;
                    case '403':
                        Notification.error(error.data.message);
                        console.log(error);
                        break;
                    default:
                        Notification.error("We are unable to create your account at this time. Please contact support@scientiamobile.com for assistance.");
                        console.log(error);
                }
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-trial-registration-form'
                });
            });
        }

        function showExplanation() {
            ieActions.openTrialEmailExplanation();
        }

        function showRestrictionExplanation() {
            ieActions.openRestrictionExplanation();
        }

        function signIn() {
            signupService.openSignup();
        }

        function updateWhitelist(id) {
            if (_.isUndefined(id)) {
                vm.accountForm.whitelist.push(null);
            } else {
                vm.accountForm.whitelist.splice(id, 1);
                if (vm.accountForm.whitelist.length === 0) {
                    vm.accountForm.whitelist = [null];
                }
            }
        }
    }
})();
