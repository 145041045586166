(function() {
    'use strict';

    angular.module('customerVault.home')
        .controller('PasswordResetController', PasswordResetController);

    PasswordResetController.$inject = ['$state', 'authenticate', 'bsLoadingOverlayService', 'homeService', 'loginService', 'Notification', 'passwordResetResolve'];

    function PasswordResetController($state, authenticate, bsLoadingOverlayService, homeService, loginService, Notification, passwordResetResolve) {
        var vm = this;
        var resolveData = passwordResetResolve.load;
        var error = passwordResetResolve.error;

        vm.error = false;
        vm.errorMessage = null;
        vm.errorTitle = null;
        vm.errors = {
            password: false,
            passwordRepeat: false
        };
        vm.generalError = false;
        vm.pageErrorView = null;
        vm.password = null;
        vm.passwordRepeat = null;
        vm.redirecting = false;
        vm.submit = submit;

        if (loginService.authInfo.authenticated && loginService.authInfo.user.id == resolveData.reset.user_id) {
            vm.redirecting = true;
            vm.pageErrorView = 'view/components.303';
            $state.go('myaccount');
        } else {
            if (!_.isEmpty(error)) {
                vm.error = true;
                vm.errorMessage = error.message;
                switch (error.data.type) {
                    case 'invalid_url':
                        vm.errorTitle = "Invalid Password Reset URL";
                        break;
                    case 'expired_url':
                        vm.errorTitle = "Expired Password Reset URL";
                        break;
                }
            }
        }

        function submit() {
            vm.errors = {
                password: false,
                passwordRepeat: false
            };
            vm.generalError = false;
            var errors = homeService.validatePasswordReset(vm.password, vm.passwordRepeat);
            if (errors) {
                if (errors.generalError) {
                    vm.generalError = errors.generalErrorMessage;
                    return;
                }
                if (errors.fieldErrors) {
                    vm.errors = {
                        password: errors.fields.password,
                        passwordRepeat: errors.fields.passwordRepeat
                    };
                    return;
                }
            }

            bsLoadingOverlayService.start({
                referenceId: 'password-reset-form'
            });
            homeService.postPasswordExternal({
                user_id: resolveData.reset.user_id,
                password: vm.password,
                repeat: vm.passwordRepeat
            }).then(function(response) {
                console.log(response);
                Notification.success("Your password has been successfully changed!");
                loginService.intended = undefined;
                authenticate.auth({
                    username: resolveData.username,
                    password: vm.password
                }).then(function(response) {
                    if (!response) {
                        Notification.warning("Login failed. Please try again with your new password.");
                        $state.go('login', {});
                        bsLoadingOverlayService.stop({
                            referenceId: 'password-reset-form'
                        });
                    } else {
                        Notification.success('You have successfully logged in!');
                        homeService.deletePasswordReset(resolveData.reset.hash)
                        .then(function(response) {})
                        .catch(function(error) {
                            console.log(error);
                        }).finally(function() {
                            bsLoadingOverlayService.stop({
                                referenceId: 'password-reset-form'
                            });
                            $state.go('myaccount', {});
                        });
                    }
                });
            }).catch(function(error) {
                bsLoadingOverlayService.stop({
                    referenceId: 'password-reset-form'
                });
                console.log(error);
                Notification.error("Your password could not be reset. Please contact support@scientiamobile.com for assistance.");
            }).finally(function() {
                vm.password = null;
                vm.passwordRepeat = null;
            });
        }
    }
})();
