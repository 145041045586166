(function() {
    'use strict';

    angular.module('customerVault.home')
        .controller('DueDateFilterController', DueDateFilterController);

    DueDateFilterController.$inject = ['$uibModalInstance', 'homeActions', 'ordersService'];

    function DueDateFilterController($uibModalInstance, homeActions, ordersService) {
        var vm = this;

        vm.close = close;
        vm.filter = filter;
        vm.slider = null;

        init();

        function close() {
            $uibModalInstance.dismiss();
        }

        function filter() {
            var range = {
                minValue: vm.slider.minValue,
                maxValue: vm.slider.maxValue
            };
            $uibModalInstance.close(range);
        }

        function init() {
            var dates = homeActions.initDueDateFilter(ordersService.variables.dateOptions)

            vm.slider = {
                minValue: new Date(moment(angular.copy(ordersService.variables.dateOptions.minDate))),
                maxValue: new Date(moment(angular.copy(ordersService.variables.dateOptions.maxDate))),
                options: {
                    stepsArray: dates,
                    translate: function(date) {
                        if (date != null) {
                            return date.toDateString();
                        }
                        return '';
                    },
                    floor: new Date(moment(angular.copy(ordersService.variables.dateOptions.floor))),
                    ceil: new Date(moment(angular.copy(ordersService.variables.dateOptions.ceil)))
                }
            }
        }
    }
})();
