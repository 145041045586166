(function() {
    'use strict';

    angular.module('customerVault.site')
        .factory('siteService', siteService);

    siteService.$inject = ['$resource', 'loginService'];

    function siteService($resource, loginService) {
        var resource = new $resource('/api/vault/site/evaluation', {}, {
            create: {
                method: 'POST'
            },
            get: {
                method: 'GET'
            }
        });
        var service = {
            create: create,
            get: get
        };

        return service;

        function create(form) {
            if (loginService.impersonate.mode) {
                form.user_id = loginService.impersonate.user.id;
            } else {
                form.user_id = loginService.authInfo.user.id;
            }
            return resource.create(form).$promise;
        }

        function get() {
            return resource.get().$promise;
        }
    }
})();
