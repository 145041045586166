(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .controller('CloudApiKeyController', CloudApiKeyController);

    CloudApiKeyController.$inject = ['$stateParams', 'bsLoadingOverlayService', 'cloudResolve', 'cloudService', 'Notification'];

    function CloudApiKeyController($stateParams, bsLoadingOverlayService, cloudResolve, cloudService, Notification) {
        var vm = this;
        var resolveData = cloudResolve.load.main;
        var error = cloudResolve.mainError;

        vm.apiError = null;
        vm.apiKeys = resolveData.apiKeys;
        vm.createKey = createKey;
        vm.defaults = resolveData.defaults;
        vm.deleteKey = deleteKey;
        vm.keyName = null;

        function createKey() {
            vm.apiError = null;
            if (_.isNull(vm.keyName) || vm.keyName == '') {
                vm.apiError = 'error';
                return;
            }
            bsLoadingOverlayService.start({
                referenceId: 'cloud-api-keys'
            });
            cloudService.postCreateApikey($stateParams.id, vm.keyName).then(function(response) {
                Notification.success("Added API Key");
                vm.apiKeys.push(response.data.key);
                vm.keyName = null;
            }).catch(function(error) {
                console.log(error);
                Notification.error(error.message);
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'cloud-api-keys'
                });
            })
        }

        function deleteKey(id) {
            bsLoadingOverlayService.start({
                referenceId: 'cloud-api-keys'
            });
            cloudService.deleteApikey($stateParams.id, id).then(function(response) {
                Notification.success(response.message);
                vm.apiKeys = _.reject(vm.apiKeys, function(key) {
                    return key.id == id;
                });
            }).catch(function(error) {
                console.log(error);
                Notification.error(error.message);
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'cloud-api-keys'
                });
            });
        }
    }
})();
