(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineSignupController', ImageEngineSignupController);

    ImageEngineSignupController.$inject = ['$location', '$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$window', 'authenticate', 'breadcrumbsService', 'bsLoadingOverlayService', 'flashMessage', 'ieActions', 'ieResolve', 'ieService', 'loginService', 'myaccountResolve', 'Notification', 'signupService'];

    function ImageEngineSignupController($location, $rootScope, $scope, $state, $stateParams, $timeout, $window, authenticate, breadcrumbsService, bsLoadingOverlayService, flashMessage, ieActions, ieResolve, ieService, loginService, myaccountResolve, Notification, signupService) {
        var vm = this;
        var resolveData = ieResolve.load.signup;
        var error = ieResolve.error;

        vm.authorized = loginService.authInfo.authenticated;
        vm.changePlan = changePlan;
        vm.class = resolveData.class;
        vm.countries = resolveData.countries;
        vm.error = false;
        vm.errors = resetErrors;
        vm.flash = flashMessage.message;
        vm.form = resolveData.form;
        vm.pageErrorView = 'view/errors.400';
        vm.selectedPlan = resolveData.selectedPlan;
        vm.showRestrictionExplanation = showRestrictionExplanation;
        vm.signIn = signIn;
        vm.signupMessage = signupService.value;
        vm.steps = vm.authorized ? breadcrumbsService.get('ie.signup') : null;
        vm.submit = submit;
        vm.updateWhitelist = updateWhitelist;

        if (!_.isEmpty(error)) {
            vm.error = true;
            return;
        }

        if (!_.isNull($stateParams.plan)) {
            if (
                $stateParams.plan != 'basic' &&
                $stateParams.plan != 'standard' &&
                $stateParams.plan != 'custom'
            ) {
                $location.search('plan', 'basic');
            } else {
                vm.class[$stateParams.plan] = 'plans-btn-selected';
            }
        } else {
            vm.class['basic'] = 'plans-btn-selected';
        }

        $rootScope.$on('authenticated', function() {
            vm.authorized = loginService.authInfo.authenticated;
            if (vm.authorized) {
                vm.steps = breadcrumbsService.get('ie.signup');
            } else {
                vm.steps = null;
            }
        });

        var exitImpersonationIeSignup = $rootScope.$on('exitImpersonationIeSignup', function() {
            vm.form.firstName = loginService.authInfo.user.first_name;
            vm.form.lastName = loginService.authInfo.user.last_name;
            $scope.$on('$destroy', exitImpersonationIeSignup);
        });

        $timeout(function() {
            flashMessage.resetMessage();
            vm.flash = flashMessage.message;
        }, 10000);

        $scope.$watch('vm.form.allowOriginPrefix', function(newVal) {
            if (!_.isUndefined(newVal)) {
                if (newVal == 1) {
                    vm.form.whitelist = [null];
                } else {
                    vm.form.whitelist = null;
                }
            }
        }, true);

        function changePlan(plan) {
            vm.errors.paymentPlan = false;
            vm.selectedPlan = resolveData.plans[plan];
            angular.forEach(vm.class, function(val, key) {
                if (map(plan) == key) {
                    vm.class[key] = 'plans-btn-selected';
                } else {
                    vm.class[key] = 'plans-btn';
                }
            });
            vm.form.paymentPlan = plan;
        }

        function checkAccountErrors() {
            vm.errors = {
                accountName: false,
                paymentPlan: false,
                domainName: false,
                whitelist: false
            };

            angular.forEach(vm.errors, function(val, key) {
                if (_.isNull(vm.form[key])) {
                    vm.errors[key] = true;
                }
            });

            // errors.whitelist become true which there is an whitelist error detected
            // if form.whitelist is null which is the case of user select allow origin prefixing to disabled (no in UI)
            // if there is allowOriginPrefix in submitted form
            // and it is value is 0 
            if (vm.errors.whitelist && _.isNull(vm.form.whitelist) && _.has(vm.form, "allowOriginPrefix") && vm.form.allowOriginPrefix == "0") {
                vm.errors.whitelist = false
            }

            if (!_.isNull(vm.form.whitelist)) {
                var distinctWhitelist = new Set(vm.form.whitelist);
                if (vm.form.whitelist.length !== distinctWhitelist.size) {
                    vm.errors.whitelist = "Duplicate allowed origins exist";
                }
            }

            var errors = !_.every(vm.errors, function(val, key) {
                return !val;
            });


            if (errors) {
                if (vm.errors.paymentPlan) {
                    $window.scrollTo(0, 0);
                }
                return false;
            }

            return true;
        }

        function createAccount() {
            var form = angular.copy(vm.form);
            form.userId = loginService.impersonate.mode ?
                loginService.impersonate.user.id :
                loginService.authInfo.user.id;
            if (
                !_.isNull(vm.form.whitelist) &&
                vm.form.whitelist.length == 1 &&
                (_.isNull(vm.form.whitelist[0]) || vm.form.whitelist[0] == '')
            ) {
                vm.form.whitelist = null;
            }

            if (
                !_.isNull(form.whitelist) &&
                form.whitelist.length == 1 &&
                (_.isNull(form.whitelist[0]) || form.whitelist[0] == '')
            ) {
                form.whitelist = null;
            }

            form.domainName = form.domainName.toLowerCase();

            ieService.postCreate(form).then(function(response) {
                var accountResponse = response.data;
                myaccountResolve.load = {};
                ieService.transfer = {
                    subscription: response.data.subscription
                };
                ieService.getAwsRegionsByAccount(response.data.subscription.id).then(function(response) {
                    // Add DNS records
                    var regions = response.data;
                    var records = [];
                    angular.forEach(regions, function(val, key) {
                        if (val.Deploy !== 'provisioning') {
                            records.push({
                                domain: form.domainName.toLowerCase(),
                                region: val.RegionName,
                                type: 'A'
                            });
                        }
                    });
                    ieService.postResourceRecords(accountResponse.subscription.id, records)
                        .then(function() {})
                        .catch(function(error) {
                            console.log(error);
                            var message = "Your ImageEngine Trial account has been created, however the following issues occurred:<br />";
                            message += "Resource records were not configured<br />";
                            message += "Please contact support@scientiamobile.com for assistance.";
                            signupService.value = {
                                class: 'warning',
                                message: message,
                                show: true
                            };
                        }).finally(function() {
                            bsLoadingOverlayService.stop({
                                referenceId: 'ie-signup-form'
                            });
                            $timeout(function() {
                                signupService.reset();
                                vm.signupMessage = signupService.value;
                            }, 5000);
                            $state.go('ie.signupcomplete', {fromSignup: true});
                        });
                })
            }).catch(function(error) {
                console.log(error.data);
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-signup-form'
                });
                $timeout(function() {
                    signupService.reset();
                    vm.signupMessage = signupService.value;
                }, 5000);
                switch (error.status) {
                    case 401:
                    case 403:
                        signupService.value = {
                            class: 'danger',
                            message: error.data.message,
                            show: true
                        };
                        vm.signupMessage = signupService.value;
                        $window.scrollTo(0, 0);
                        break;
                    default:
                        if (_.isUndefined(error.data.data)) {
                            Notification.error("An unknown error occurred Please contact support@scientiamobile.com for assistance.");
                            break;
                        } else {
                            switch (error.data.data.type) {
                                case 'form':
                                    vm.errors = error.data.data.errors;
                                    break;
                                case 'general':
                                case 'user':
                                case 'account':
                                    Notification.error(error.data.message);
                                    break;
                                case 'creation':
                                    // Something happened after account creation
                                    if (_.indexOf(error.data.data.errors, 'domain configuration') !== -1) {
                                        signupService.value = {
                                            class: 'warning',
                                            message: 'Your ImageEngine Trial account has been created, however an issue occurred while creating your domain configuration. Please contact support@scientiamobile.com for assistance.',
                                            show: true
                                        };
                                    } else {
                                        var message = "Your ImageEngine Trial account has been created, however the following issues occurred:<br />";
                                        if (_.indexOf(error.data.data.errors, 'origin') !== -1) {
                                            message += "Origin host was not configured<br />";
                                        }
                                        if (_.indexOf(error.data.data.errors, 'resource record') !== -1) {
                                            message += "Domain name record has not been configured<br />";
                                        }
                                        if (_.indexOf(error.data.data.errors, 'allowed_prefixes') !== -1) {
                                            message += "Allowed prefixes were not stored<br />";
                                        }
                                        message += "Resource records were not configured<br />";
                                        message += "Please contact support@scientiamobile.com for assistance.";
                                        signupService.value = {
                                            class: 'warning',
                                            message: message,
                                            show: true
                                        };
                                    }
                                    ieService.transfer = error.data.data;
                                    $state.go('ie.signupcomplete', {fromSignup: true});
                                    break;
                            }
                        }
                }
            });
        }

        function map(plan) {
            switch (plan) {
                case 'IMAGEENGINE_BASIC':
                    return 'basic';
                    break;
                case 'IMAGEENGINE_STANDARD':
                    return 'standard';
                    break;
                case 'IMAGEENGINE_PRO':
                    return 'custom';
                    break;
            }
        }

        function resetErrors() {
            return {
                accountName: false,
                paymentPlan: false,
                domainName: false,
                origin: false,
                hostHeader: false,
                bucketname: false,
                whitelist: false
            };
        }

        function showRestrictionExplanation() {
            ieActions.openRestrictionExplanation();
        }

        function signIn() {
            signupService.openSignup();
        }

        function submit() {
            vm.errors = resetErrors();
            if (checkAccountErrors()) {
                if (vm.form.domainName == vm.form.origin) {
                    ieActions.openCompareDomainOrigin().result.then(function() {
                        bsLoadingOverlayService.start({
                            referenceId: 'ie-signup-form'
                        });
                        createAccount();
                    });
                    // vm.errors.domainName = "The hostname from where you want to serve your images (domain name) cannot be the same as the hostname of the origin.";
                    // vm.errors.origin = "The hostname from where you want to serve your images (domain name) cannot be the same as the hostname of the origin.";
                } else {
                    bsLoadingOverlayService.start({
                        referenceId: 'ie-signup-form'
                    });
                    createAccount();
                }
            } else {
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-signup-form'
                });
                $timeout(function() {
                    signupService.reset();
                    vm.signupMessage = signupService.value;
                }, 5000);
            }
        }

        function updateWhitelist(id) {
            if (_.isUndefined(id)) {
                vm.form.whitelist.push(null);
            } else {
                vm.form.whitelist.splice(id, 1);
                if (vm.form.whitelist.length === 0) {
                    vm.form.whitelist = [null];
                }
            }
        }
    }
})();
