(function() {
    'use strict';

    angular.module('customerVault.wurfljs')
        .controller('WurfljsReceiptController', WurfljsReceiptController);

    WurfljsReceiptController.$inject = ['$state', '$stateParams', '$timeout', '$uibModalInstance', 'bsLoadingOverlayService', 'transactionId', 'wurfljsResolve', 'wurfljsService'];

    function WurfljsReceiptController($state, $stateParams, $timeout, $uibModalInstance, bsLoadingOverlayService, transactionId, wurfljsResolve, wurfljsService) {
        var vm = this;
        var resolveData = wurfljsResolve.load.transaction;

        vm.alertClass = 'alert-success';
        vm.billingPeriod = resolveData.billingPeriod;
        vm.close = close;
        vm.email = resolveData.email;
        vm.emailError = null;
        vm.paymentDate = resolveData.paymentDate;
        vm.sendEmail = sendEmail;
        vm.sendMessage = null;
        vm.showMessage = false;
        vm.subscription = resolveData.subscription;
        vm.transaction = resolveData.transaction;

        function close() {
            $uibModalInstance.dismiss();
        }

        function sendEmail() {
            vm.emailError = null;
            if (_.isNull(vm.email) || vm.email == '') {
                vm.emailError = 'error';
                return;
            }
            bsLoadingOverlayService.start({
                referenceId: 'wjs-receipt'
            });
            wurfljsService.postSendTransactionReceipt($stateParams.id, vm.transaction.id, vm.email).then(function(response) {
                vm.sendMessage = response.message;
            }).catch(function(error) {
                console.log(error);
                vm.alertClass = 'alert-danger';
                vm.sendMessage = error.data.message;
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'wjs-receipt'
                });
                vm.showMessage = true;
                $timeout(function() {
                    vm.showMessage = false;
                }, 5000);
            });
        }
    }
})();
