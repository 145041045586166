(function() {
    'use strict';

    angular.module('customerVault.home')
        .factory('homeService', homeService);

    homeService.$inject = ['$filter', '$resource', 'moment'];

    function homeService($filter, $resource, moment) {
        var resource = new $resource('/api/vault/:action/:id/:action_id', {id: '@id', action_id: '@action_id'}, {
            deletePasswordReset: {
                method: 'DELETE',
                params: {
                    action: 'password'
                }
            },
            getAccount: {
                method: 'GET',
                params: {
                    action: 'myaccount'
                }
            },
            getAccountById: {
                method: 'GET',
                params: {
                    action: 'account'
                }
            },
            getCountries: {
                method: 'GET',
                params: {
                    action: 'countries'
                }
            },
            getOrders: {
                method: 'GET',
                params: {
                    action: 'orders'
                }
            },
            getPasswordReset: {
                method: 'GET',
                params: {
                    action: 'password'
                }
            },
            getRegistration: {
                method: 'GET',
                params: {
                    action: 'registration'
                }
            },
            postPassword: {
                method: 'POST',
                params: {
                    action: 'password'
                }
            },
            postPasswordExternal: {
                method: 'POST',
                params: {
                    action: 'password',
                    action_id: 'external'
                }
            },
            postPasswordReset: {
                method: 'POST',
                params: {
                    action: 'password',
                    action_id: 'reset'
                }
            },
            postRegistration: {
                method: 'POST',
                params: {
                    action: 'registration'
                }
            },
            postSendReceipt: {
                method: 'POST',
                params: {
                    action: 'orders'
                }
            },
            postSendVerification: {
                method: 'POST',
                params: {
                    action: 'confirm'
                }
            },
            postVerifyUser: {
                method: 'POST',
                params: {
                    action: 'verify'
                }
            }
        });

        var service = {
            deletePasswordReset: deletePasswordReset,
            getAccount: getAccount,
            getAccountById: getAccountById,
            getCountries: getCountries,
            extractBillingAddress: extractBillingAddress,
            extractExternalStatus: extractExternalStatus,
            extractInvoice: extractInvoice,
            extractPlan: extractPlan,
            extractReceipt: extractReceipt,
            getOrders: getOrders,
            getPasswordReset: getPasswordReset,
            getRegistration: getRegistration,
            postPassword: postPassword,
            postPasswordExternal: postPasswordExternal,
            postPasswordReset: postPasswordReset,
            postRegistration: postRegistration,
            postSendReceipt: postSendReceipt,
            postSendVerification: postSendVerification,
            postVerifyUser: postVerifyUser,
            registrationErrorsForm: registrationErrorsForm,
            validatePasswordReset: validatePasswordReset
        };

        service.selectedOrderStatus = [
            {
                title: 'Settled',
                show: true,
                type: 's'
            },
            {
                title: 'Pending',
                show: true,
                type: 'p'
            },
            {
                title: 'Paid',
                show: true,
                type: 's'
            },
            {
                title: 'Overdue',
                show: true,
                type: 'f'
            },
            {
                title: 'Paid',
                show: true,
                type: 'Successful'
            },
            {
                title: 'Voided',
                show: true,
                type: 's'
            },
            {
                title: 'Processor Declined',
                show: true,
                type: 'f',
                value: 'processor declined'
            },
            {
                title: 'Failed',
                show: true,
                type: 'f',
                value: 'failed'
            },
            {
                title: 'Gateway Rejected',
                show: true,
                type: 'f',
                value: 'gateway rejected'
            },
            {
                title: 'Settlement Declined',
                show: true,
                type: 'f',
                value: 'settlement declined'
            },
            {
                title: 'Settling',
                show: true,
                type: 'p',
                value: 'settling'
            },
            {
                title: 'Settlement Pending',
                show: true,
                type: 'p',
                value: 'settlement pending'
            }
        ];
        return service;

        function deletePasswordReset(hash) {
            return resource.deletePasswordReset({id: null, action_id: hash}).$promise;
        }

        function getAccount(id) {
            return resource.getAccount({id: id}).$promise;
        }

        function getAccountById(id) {
            return resource.getAccountById({id: id}).$promise;
        }

        function getCountries(id) {
            var id = id === undefined ? 'country_id,name' : id;
            return resource.getCountries({id: id}).$promise;
        }

        function extractBillingAddress(invoice, type) {
            var ret = [];
            if (type == 'external') {
                angular.forEach(invoice.BillAddr, function(val, key) {
                    if (key != 'Id') {
                        ret.push(val);
                    }
                });
            } else {
                if (!_.isNull(invoice.billingDetails.firstName) && !_.isNull(invoice.billingDetails.lastName)) {
                    ret.push(invoice.billingDetails.firstName+" "+invoice.billingDetails.lastName);
                }
                if (!_.isNull(invoice.billingDetails.streetAddress)) {
                    ret.push(invoice.billingDetails.streetAddress);
                }
                if (!_.isNull(invoice.billingDetails.extendedAddress)) {
                    ret.push(invoice.billingDetails.extendedAddress);
                }
                if (!_.isNull(invoice.billingDetails.locality) && !_.isNull(invoice.billingDetails.region)) {
                    ret.push(invoice.billingDetails.locality+", "+invoice.billingDetails.region);
                }
                if (!_.isNull(invoice.billingDetails.countryName)) {
                    ret.push(invoice.billingDetails.countryName);
                }

                if (_.isEmpty(ret)) {
                    if (!_.isNull(invoice.customer.firstName) && !_.isNull(invoice.customer.lastName)) {
                        ret.push(invoice.customer.firstName+" "+invoice.customer.lastName);
                    }
                    if (!_.isNull(invoice.customer.company)) {
                        ret.push(invoice.customer.company);
                    }
                    if (!_.isNull(invoice.customer.email)) {
                        ret.push(invoice.customer.email);
                    }
                }
                ret.push(invoice.creditCardDetails.cardType+" "+invoice.creditCardDetails.maskedNumber);
            }

            return ret.join("<br />");
        }

        function extractExternalStatus(invoice) {
            if (invoice.Balance == 0) {
                return {
                    status: 'Paid',
                    statusClass: 'success'
                };
            } else if (invoice.Balance < invoice.TotalAmt) {
                var obj = {
                    status: 'Partially Paid'
                };
                if (moment().isAfter(moment(invoice.DueDate))) {
                    obj.statusClass = 'warning';
                } else {
                    obj.statusClass = 'success';
                }
                return obj;
            } else if (invoice.Balance == invoice.TotalAmt) {
                if (moment().isAfter(moment(invoice.DueDate))) {
                    return {
                        status: 'Overdue',
                        statusClass: 'danger'
                    };
                } else {
                    return {
                        status: 'Pending',
                        statusClass: 'success'
                    };
                }
            }
        }

        function extractInvoice(invoices, subscriptions) {
            var data = [];
            angular.forEach(invoices.external, function(invoice, key) {
                var obj = {
                    type: 'external',
                    startDate: moment(invoice.MetaData.CreateTime).format('YYYY-MM-DD'),
                    endDate: invoice.DueDate,
                    amount: $filter('currency')(invoice.TotalAmt, '$', 2),
                    product: invoice.Line[0].SalesItemLineDetail.ItemRef.name,
                    balance: invoice.Balance,
                    createdAt: moment(invoice.MetaData.CreateTime).format('YYYY-MM-DD'),
                    id: invoice.Id,
                    sortDate: invoice.DueDate,
                };
                if (invoice.Balance == 0) {
                    obj.status = 'Settled';
                    obj.statusClass = 'success';
                } else if (moment(invoice.DueDate).isAfter(moment())) {
                    obj.status = 'Pending';
                    obj.statusClass = 'warning';
                } else if (invoice.Balance < invoice.TotalAmt) {
                    obj.status = 'Paid';
                    obj.statusClass = 'warning';
                } else if (invoice.Balance == invoice.TotalAmt) {
                    obj.status = 'Overdue';
                    obj.statusClass = 'danger';
                }
                data.push(obj);
            });

            angular.forEach(invoices.standard, function(invoice, key) {
                var obj = {
                    type: 'standard',
                    startDate: moment(invoice.subscription.billingPeriodStartDate.date).format('YYYY-MM-DD'),
                    endDate: moment(invoice.subscription.billingPeriodEndDate.date).format('YYYY-MM-DD'),
                    amount: $filter('currency')(invoice.amount, '$', 2),
                    product: service.extractPlan(invoice, subscriptions),//sub.plan.name,
                    status: invoice.status.replace(/^\w/, function (chr) {
                        return chr.toUpperCase();
                    }),
                    createdAt: moment(invoice.createdAt.date).format('YYYY-MM-DD'),
                    payment: invoice.creditCardDetails.maskedNumber,
                    paymentImage: invoice.creditCardDetails.imageUrl,
                    id: invoice.id,
                    sortDate: moment(invoice.subscription.billingPeriodStartDate.date).format('YYYY-MM-DD')
                };

                switch (invoice.status) {
                    case 'settled':
                    case 'voided':
                        obj.statusClass = 'success';
                        break;
                    case 'processor declined':
                    case 'gateway rejected':
                    case 'failed':
                    case 'settlement declined':
                        obj.statusClass = 'danger';
                        break;
                    case 'settling':
                    case 'settlement pending':
                        obj.statusClass = 'warning';
                        break;

                }
                data.push(obj);
            });

            return _.sortBy(data, 'sortDate').reverse();
        }

        function extractPlan(invoice, subscriptions) {
            var subArray = _.flatten(_.toArray(subscriptions));
            var sub = _.findWhere(subArray, {payment_plan: invoice.planId});
            if (!_.isUndefined(sub)) {
                return sub.plan.name;
            }
            return null;
        }

        function extractReceipt(invoice, type, subscriptions) {
            var obj = {};

            obj.plan = type == 'external' ?
                invoice.Line[0].SalesItemLineDetail.ItemRef.name :
                service.extractPlan(invoice, subscriptions);

            obj.description = type == 'external' ?
                invoice.Line[0].Description : null;

            obj.quantity = type == 'external' ?
                invoice.Line[0].SalesItemLineDetail.Qty : 1;

            obj.price = type == 'external' ?
                $filter('currency')(invoice.Line[0].SalesItemLineDetail.UnitPrice, '$', 2) :
                $filter('currency')(invoice.amount, '$', 2);

            obj.amount = type == 'external' ?
                $filter('currency')(invoice.Line[0].Amount, '$', 2) :
                $filter('currency')(invoice.amount, '$', 2);

            var statusClass = null;
            if (type == 'external') {
                obj.balance = $filter('currency')(invoice.Balance, '$', 2);
            } else {
                if (invoice.status != 'settled') {
                    obj.balance = $filter('currency')(invoice.amount, '$', 2);
                    statusClass = 'danger';
                } else {
                    obj.balance = $filter('currency')(0, '$', 2);
                    statusClass = 'success';
                }
            }

            obj.status = type == 'external' ?
                service.extractExternalStatus(invoice) :
                {status: $filter('capitalize')(invoice.status.split("_").join(" ")), statusClass: statusClass};
            obj.billingAddr = service.extractBillingAddress(invoice, type);
            obj.dates = type == 'external' ?
                [
                    {
                        name: 'Invoice Date',
                        value: $filter('date')(invoice.MetaData.CreateTime, 'mediumDate')
                    },
                    {
                        name: 'Shipping Date',
                        value: $filter('date')(invoice.ShipDate, 'mediumDate')
                    },
                    {
                        name: 'Due Date',
                        value: $filter('date')(invoice.DueDate, 'mediumDate')
                    }
                ] :
                [
                    {
                        name: 'Billing Period',
                        value: moment(invoice.subscription.billingPeriodStartDate.date).format('MMM DD, YYYY')+" - "+moment(invoice.subscription.billingPeriodEndDate.date).format('MMM DD, YYYY')
                    },
                    {
                        name: 'Due Date',
                        value: moment(invoice.subscription.billingPeriodStartDate.date).format('MMM DD, YYYY')
                    },
                    {
                        name: 'Payment Date',
                        value: moment(invoice.createdAt.date).format('MMM DD, YYYY')
                    }
                ];

            return obj;
        }

        function getOrders(id) {
            return resource.getOrders({id: id}).$promise;
        }

        function getPasswordReset(hash) {
            return resource.getPasswordReset({id: null, action_id: hash}).$promise;
        }

        function getRegistration() {
            return resource.getRegistration({id: null}).$promise;
        }

        function postPassword(form) {
            return resource.postPassword({id: null}, form).$promise;
        }

        function postPasswordExternal(form) {
            return resource.postPasswordExternal({id: null}, form).$promise;
        }

        function postPasswordReset(email) {
            return resource.postPasswordReset({id: null}, {email: email}).$promise;
        }

        function postRegistration(form) {
            return resource.postRegistration({id: null}, form).$promise;
        }

        function postSendReceipt(email, receipt, userId, receiptId) {
            return resource.postSendReceipt({id: userId, action_id: receiptId}, {email: email, receipt: receipt}).$promise;
        }

        function postVerifyUser(link) {
            return resource.postVerifyUser({id: link}).$promise;
        }

        function postSendVerification(username) {
            return resource.postSendVerification({id: null}, {username: username}).$promise;
        }

        function registrationErrorsForm() {
            return {
                company_name: {
                    show: false,
                    message: null
                },
                consent: false,
                country: false,
                email: {
                    show: false,
                    message: null
                },
                first_name: {
                    show: false,
                    message: null
                },
                last_name: {
                    show: false,
                    message: null
                },
                password: {
                    show: false,
                    message: null
                },
                passwordConfirm: {
                    show: false,
                    message: null
                },
                terms: false,
                username: {
                    show: false,
                    message: null
                }
            };
        }

        function validatePasswordReset(password, repeat) {
            var errors = {
                fields: {
                    password: false,
                    passwordRepeat: false,
                },
                fieldErrors: false,
                generalError: false,
                generalErrorMessage: null
            };
            if (_.isNull(password) || password == '') {
                errors.fieldErrors = true;
                errors.fields.password = true;
            }
            if (_.isNull(repeat) || repeat == '') {
                errors.fieldErrors = true;
                errors.fields.passwordRepeat = true;
            }

            if (errors.fieldErrors) {
                return errors;
            }

            if (password != repeat) {
                errors.generalError = true;
                errors.generalErrorMessage = "Passwords do not match";
                return errors;
            }

            if (password.length > 50 || password.length < 6) {
                errors.generalError = true;
                errors.generalErrorMessage = "Password must be between 6 and 50 characters";
                return errors;
            }

            return false;
        }
    }
})();
