(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .controller('CloudCancelConfirmController', CloudCancelConfirmController);

    CloudCancelConfirmController.$inject = ['$rootScope', '$stateParams', '$uibModalInstance', 'bsLoadingOverlayService', 'cancelReason', 'cloudService', 'Notification'];

    function CloudCancelConfirmController($rootScope, $stateParams, $uibModalInstance, bsLoadingOverlayService, cancelReason, cloudService, Notification) {
        var vm = this;

        vm.close = close;
        vm.proceed = proceed;

        function close() {
            $uibModalInstance.close();
        }

        function proceed() {
            bsLoadingOverlayService.start({
                referenceId: 'confirm-cancel-account'
            });
            cloudService.postCancelAccount($stateParams.id, cancelReason).then(function(response) {
                Notification.success(response.message);
                $uibModalInstance.dismiss();
                $rootScope.$broadcast('cloudCanceledAccount');
            }).catch(function(error) {
                console.log(error.data);
                Notification.error(error.data.message);
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'confirm-cancel-account'
                });
            });
        }
    }
})();
