(function() {
    'use strict';

    angular.module('customerVault.wurfljs')
        .controller('WurfljsPlanInfoController', WurfljsPlanInfoController);

    WurfljsPlanInfoController.$inject = ['$rootScope', '$stateParams', 'Notification', 'wurfljsResolve', 'wurfljsService'];

    function WurfljsPlanInfoController($rootScope, $stateParams, Notification, wurfljsResolve, wurfljsService) {
        var vm = this;
        var resolveData = wurfljsResolve.load.planinfo;
        var error = wurfljsResolve.error;

        vm.changeDirection = resolveData.changeDirection;
        vm.changeTab = changeTab;
        vm.error = false;
        vm.id = $stateParams.id;
        vm.pageErrorView = 'view/errors.400';
        vm.plans = resolveData.plans;
        vm.subscription = resolveData.subscription;

        if (!_.isEmpty(error)) {
            vm.error = true;
        }

        function changeTab(tab, plan) {
            $rootScope.$broadcast('wjsChangeTab', {tab: tab, plan: plan});
        }
    }
})();
