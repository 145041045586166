(function() {
    'use strict';

    angular.module('customerVault.ie')
        .factory('ieService', ieService);

    ieService.$inject = ['$q', '$resource', '$stateParams', 'loginService'];

    function ieService($q, $resource, $stateParams, loginService) {
        var resource = new $resource('/api/vault/account/imageengine/:id/:action/:action_id/:other', {id: '@id', action_id: '@action_id', other: '@other'}, {
            deleteCertificate: {
                method: 'DELETE',
                params: {
                    action: 'certificate'
                }
            },
            deleteCertificateByDomain: {
                method: 'DELETE',
                params: {
                    action: 'certificate',
                    action_id: 'domain'
                }
            },
            deleteDomain: {
                method: 'DELETE',
                params: {
                    action: 'domain'
                }
            },
            deleteOrigin: {
                method: 'DELETE',
                params: {
                    action: 'origin'
                }
            },
            deleteSetting: {
                method: 'DELETE',
                params: {
                    action: 'settings'
                }
            },
            deleteUnstoredCertificate: {
                method: 'DELETE',
                params: {
                    action: 'certificate',
                    action_id: 'unstored'
                }
            },
            getAccount: {
                method: 'GET',
                params: {
                    action: null
                }
            },
            getAwsRegions: {
                method: 'GET',
                params: {
                    action: 'regions'
                }
            },
            getAwsRegionsByAccount: {
                method: 'GET',
                params: {
                    action: 'regions'
                }
            },
            getBillingHistory: {
                method: 'GET',
                params: {
                    action: 'billinghistory'
                }
            },
            getBillingInfo: {
                method: 'GET',
                params: {
                    action: 'billing'
                }
            },
            getCachePurging: {
                method: 'GET',
                params: {
                    action: 'cache'
                }
            },
            getCertificateStatus: {
                method: 'GET',
                params: {
                    action: 'certificate'
                }
            },
            getCertificateValidationDetails: {
                method: 'GET',
                params: {
                    action: 'validation'
                }
            },
            getDomain: {
                method: 'GET',
                params: {
                    action: 'domain'
                }
            },
            getOrigin: {
                method: 'GET',
                params: {
                    action: 'origin'
                }
            },
            getPaymentLink: {
                method: 'GET',
                params: {
                    action: null
                }
            },
            getPlans: {
                method: 'GET',
                params: {
                    action: 'plans'
                }
            },
            getPlanUpgrade: {
                method: 'GET',
                params: {
                    action: 'upgrade'
                }
            },
            getSettings: {
                method: 'GET',
                params: {
                    action: 'settings'
                }
            },
            getStats: {
                method: 'GET',
                params: {
                    action: 'stats'
                }
            },
            getStatistics: {
                method: 'GET',
                params: {
                    action: 'statistics'
                }
            },
            getStatisticsByIdentifier: {
                method: 'GET',
                params: {
                    action: 'statistics',
                    show: '@show'
                }
            },
            getSummary: {
                method: 'GET',
                params: {
                    action: 'summary'
                }
            },
            getTrialLink: {
                method: 'GET',
                params: {
                    action: 'trial'
                }
            },
            getUpgradeAccount: {
                method: 'GET',
                params: {
                    action: 'upgrade'
                }
            },
            patchEditOrigin: {
                method: 'PATCH',
                params: {
                    action: 'origin'
                }
            },
            patchEditSettings: {
                method: 'PATCH',
                params: {
                    action: 'settings'
                }
            },
            patchUpdateBilling: {
                method: 'PATCH',
                params: {
                    action: 'billing'
                }
            },
            patchUpdateDomainOrigin: {
                method: 'PATCH',
                params: {
                    action: 'domain'
                }
            },
            patchUpdateShardingForWildcard: {
                method: 'PATCH',
                params: {
                    action: 'sharding'
                }
            },
            postAddNewOrigin: {
                method: 'POST',
                params: {
                    action: 'origin'
                }
            },
            postAddPurgeRequest: {
                method: 'POST',
                params: {
                    action: 'purge'
                }
            },
            postCancelAccount: {
                method: 'POST',
                params: {
                    action: 'cancel'
                }
            },
            postCreate: {
                method: 'POST',
                params: {
                    action: 'create'
                }
            },
            postCreateDomain: {
                method: 'POST',
                params: {
                    action: 'domain'
                }
            },
            postCreateSettings: {
                method: 'POST',
                params: {
                    action: 'settings'
                }
            },
            postCreateTrial: {
                method: 'POST',
                params: {
                    action: 'trial'
                }
            },
            postCycleUsage: {
                method: 'POST',
                params: {
                    action: 'usage'
                }
            },
            postDeleteResourceRecords: {
                method: 'POST',
                params: {
                    action: 'dns'
                }
            },
            postDetachFromShard: {
                method: 'POST',
                params: {
                    action: 'detach'
                }
            },
            postPlanUpgrade: {
                method: 'POST',
                params: {
                    action: 'upgrade'
                }
            },
            postRequestCertificate: {
                method: 'POST',
                params: {
                    action: 'certificate'
                }
            },
            postRequestTestCertificate:{
                method: 'POST',
                params: {
                    action: 'certificate',
                    action_id: 'test'
                }
            },
            postResourceRecords: {
                method: 'POST',
                params: {
                    action: 'dns'
                }
            },
            postSendReceipt: {
                method: 'POST',
                params: {
                    action: 'receipt'
                }
            },
            postSetPayment: {
                method: 'POST',
                params: {
                    action: 'payment'
                }
            },
            postUpdatePrefixes: {
                method: 'POST',
                params: {
                    action: 'prefix'
                }
            },
            postValidatePath: {
                method: 'POST',
                params: {
                    action: 'validatepath'
                }
            },
            postUpdateCors: {
                method: 'POST',
                params: {
                    action: 'cors'
                }
            }
        });

        var service = {
            calculateMatchings: calculateMatchings,
            defaultFields: defaultFields,
            deleteCertificate: deleteCertificate,
            deleteCertificateByDomain: deleteCertificateByDomain,
            deleteDomain: deleteDomain,
            deleteOrigin: deleteOrigin,
            deleteSetting: deleteSetting,
            deleteUnstoredCertificate: deleteUnstoredCertificate,
            getAccount: getAccount,
            getAwsRegions: getAwsRegions,
            getAwsRegionsByAccount: getAwsRegionsByAccount,
            getBillingHistory: getBillingHistory,
            getBillingInfo: getBillingInfo,
            getCachePurging: getCachePurging,
            getCertificateStatus: getCertificateStatus,
            getCertificateValidationDetails: getCertificateValidationDetails,
            getDomain: getDomain,
            getOrigin: getOrigin,
            getPaymentLink: getPaymentLink,
            getPlans: getPlans,
            getPlanUpgrade: getPlanUpgrade,
            getSettings: getSettings,
            getStats: getStats,
            getStatistics: getStatistics,
            getStatisticsByIdentifier: getStatisticsByIdentifier,
            getSummary: getSummary,
            getTrialLink: getTrialLink,
            getUpgradeAccount: getUpgradeAccount,
            loadCertificateStatuses: loadCertificateStatuses,
            patchEditOrigin: patchEditOrigin,
            patchEditSettings: patchEditSettings,
            patchUpdateBilling: patchUpdateBilling,
            patchUpdateDomainOrigin: patchUpdateDomainOrigin,
            patchUpdateShardingForWildcard: patchUpdateShardingForWildcard,
            postAddNewOrigin: postAddNewOrigin,
            postAddPurgeRequest: postAddPurgeRequest,
            postCancelAccount: postCancelAccount,
            postCreate: postCreate,
            postCreateDomain: postCreateDomain,
            postCreateSettings: postCreateSettings,
            postCreateTrial: postCreateTrial,
            postCycleUsage: postCycleUsage,
            postDeleteResourceRecords: postDeleteResourceRecords,
            postDetachFromShard: postDetachFromShard,
            postPlanUpgrade: postPlanUpgrade,
            postRequestCertificate: postRequestCertificate,
            postRequestTestCertificate: postRequestTestCertificate,
            postResourceRecords: postResourceRecords,
            postSendReceipt: postSendReceipt,
            postSetPayment: postSetPayment,
            postUpdatePrefixes: postUpdatePrefixes,
            postUpdateCors: postUpdateCors,
            postValidatePath: postValidatePath,
            restructureConfs: restructureConfs,
            validateBilling: validateBilling,
            validateRegistration: validateRegistration,
            validateTrial: validateTrial
        };

        service.settings = null;
        service.transfer = {};

        return service;

        function calculateMatchings(rawConfigurations) {
            var partitions = _.partition(rawConfigurations, function(conf) {
                return conf.cname == "DEFAULT";
            });

            var results = [];
            var defaultConfs = partitions[0];

            if (defaultConfs.length != 1) {
                return [];
            }

            var defaultConf = defaultConfs[0];

            defaultConf.pointsTo = true;
            defaultConf.isCnamed = false;

            if (defaultConf.url == '') {
                defaultConf.pointsTo = false;
            }

            defaultConf.pointingUrl = defaultConf.url;

            var cnamesConfs = partitions[1];

            _.map(cnamesConfs, function(cn) {
                cn.isCnamed = true;
                cn.pointsTo = false;

                if (cn.origin_conf_id != 0) {
                    cn.pointingUrl = defaultConf.url;
                } else {
                    cn.pointingUrl = cn.url;
                }

                if (cn.pointingUrl != '') {
                    cn.pointsTo = true;
                }
            });

            results = _.union(defaultConfs, cnamesConfs);
            return results;
        }

        function defaultFields(type) {
            switch(type) {
                case 'http':
                    return {
                        "is_secure": false,
                        "hostname": "",
                        "username": "",
                        "password": "",
                        "port": "",
                        "path": "",
                    };
                case 's3':
                    return {
                        "bucketname": "",
                        "path": "",
                    };
                default:
                    return false;
            }
        }

        function deleteCertificate(id, certificateId)
        {
            return resource.deleteCertificate({id: id, action_id: certificateId}).$promise;
        }

        function deleteCertificateByDomain(id, domainId) {
            return resource.deleteCertificateByDomain({id: id, other: domainId}).$promise;
        }

        function deleteDomain(id, domainId) {
            return resource.deleteDomain({id: id, action_id: domainId}).$promise;
        }

        function deleteOrigin(id, originId) {
            return resource.deleteOrigin({id: id, action_id: originId}).$promise;
        }

        function deleteSetting(id, settingsId) {
            return resource.deleteSetting({id: id, action_id: settingsId}).$promise;
        }

        function deleteUnstoredCertificate(id, domainId) {
            return resource.deleteUnstoredCertificate({id: id, other: domainId}).$promise;
        }

        function getAccount(id) {
            return resource.getAccount({id: id}).$promise;
        }

        function getAwsRegions() {
            return resource.getAwsRegions({id: null}).$promise;
        }

        function getAwsRegionsByAccount(id) {
            return resource.getAwsRegionsByAccount({id: null, action_id: id}).$promise;
        }

        function getBillingHistory(id) {
            return resource.getBillingHistory({id: id}).$promise;
        }

        function getBillingInfo(id) {
            return resource.getBillingInfo({id: id}).$promise;
        }

        function getCachePurging(id) {
            return resource.getCachePurging({id: id}).$promise;
        }

        function getCertificateStatus(id, domain) {
            return resource.getCertificateStatus({id: id, action_id: domain}).$promise;
        }

        function getCertificateValidationDetails(id, certificateId) {
            return resource.getCertificateValidationDetails({id: id, action_id: certificateId}).$promise;
        }

        function getDomain(id, domainId) {
            return resource.getDomain({id: id, action_id: domainId}).$promise;
        }

        function getOrigin(id, originId) {
            return resource.getOrigin({id: id, action_id: originId}).$promise;
        }

        function getPaymentLink(link) {
            return resource.getPaymentLink({id: 'payment', action_id: link}).$promise;
        }

        function getPlans() {
            return resource.getPlans({id: null}).$promise;
        }

        function getPlanUpgrade(id, plan) {
            return resource.getPlanUpgrade({id: id, action_id: plan}).$promise;
        }

        function getSettings() {
            return resource.getSettings({id: null}).$promise;
        }

        function getStats(id) {
            return resource.getStats({id: id}).$promise;
        }

        function getStatistics(id) {
            return resource.getStatistics({id: id}).$promise;
        }

        function getStatisticsByIdentifier(params)
        {
            return resource.getStatisticsByIdentifier(params).$promise;
        }

        function getSummary(id) {
            return resource.getSummary({id: id}).$promise;
        }

        function getTrialLink(link) {
            return resource.getTrialLink({id: null, action_id: link}).$promise;
        }

        function getUpgradeAccount(id) {
            return resource.getUpgradeAccount({id: id}).$promise;
        }

        function loadCertificateStatuses(confs) {
            var loadingPromises = [];
            var certificateError = false;
            var testsExist = false;
            angular.forEach(confs, function(conf, key) {
                if (conf.status.ssl || conf.ie_only_flag || conf.custom_wildcard_flag) {
                    loadingPromises.push(
                        service.getCertificateStatus($stateParams.id, conf.cname)
                            .then(function(response) {
                                if (!conf.ie_only_flag && !conf.custom_wildcard_flag) {
                                    conf.status.ssl = response.data.status.status;
                                    conf.invalid_record = response.data.status.invalid_record;
                                    conf.certificates = response.data.status.certificates;
                                    conf.validation = response.data.status.domain_validation;
                                    conf.test_certificates = response.data.status.test_certificates;
                                    conf.test_enabled = response.data.status.test_enabled;
                                    conf.status.test_ssl = response.data.status.test_status;
                                    conf.status.attached = response.data.status.attached;
                                    if (response.data.status.test_enabled) {
                                        testsExist = true;
                                    }
                                } else if (conf.ie_only_flag) {
                                    conf.status.ssl = response.data.status.status;
                                    conf.invalid_record = response.data.status.invalid_record;
                                    conf.status.attached = response.data.status.attached;
                                } else if (conf.custom_wildcard_flag) {
                                    conf.invalid_record = response.data.status.invalid_record;
                                    conf.status.ssl = response.data.status.status;
                                    conf.certificates = response.data.status.certificates;
                                    conf.validation = response.data.status.domain_validation;
                                    conf.status.attached = response.data.status.attached;
                                    conf.customWc = true;
                                }

                            }).catch(function(error) {
                                conf.status.ssl = 'ERROR';
                                conf.certificates = null;
                                conf.validation = null;
                                conf.test_certificates = null;
                                conf.test_enabled = null;
                                conf.status.test_ssl = null;
                                $q.reject();
                            })
                    );
                }
            });
            return $q.all(loadingPromises).then(function() {
                return {
                    confs: confs,
                    testsExist: testsExist
                };
            });
        }

        function patchEditOrigin(id, originId, form) {
            return resource.patchEditOrigin({id: id, action_id: originId}, form).$promise;
        }

        function patchEditSettings(id, settingId, form) {
            return resource.patchEditSettings({id: id, action_id: settingId}, form).$promise;
        }

        function patchUpdateBilling(id, form) {
            return resource.patchUpdateBilling({id: id}, form).$promise;
        }

        function patchUpdateDomainOrigin(id, domainId, form) {
            return resource.patchUpdateDomainOrigin({id: id, action_id: domainId}, form).$promise;
        }

        function patchUpdateShardingForWildcard(id, domainId, hash) {
            return resource.patchUpdateShardingForWildcard({id: id, action_id: domainId}, {hash: hash}).$promise;
        }

        function postAddNewOrigin(id, origin) {
            return resource.postAddNewOrigin({id: id}, origin).$promise;
        }

        function postAddPurgeRequest(id, request) {
            return resource.postAddPurgeRequest({id: id}, request).$promise;
        }

        function postCancelAccount(id, message) {
            return resource.postCancelAccount({id: id}, {message: message}).$promise;
        }

        function postCreate(form) {
            return resource.postCreate({id: null}, form).$promise;
        }

        function postCreateDomain(id, form) {
            return resource.postCreateDomain({id: id}, form).$promise;
        }

        function postCreateSettings(id, domainId, form) {
            return resource.postCreateSettings({id: id, action_id: domainId}, form).$promise;
        }

        function postCreateTrial(form) {
            return resource.postCreateTrial({id: null}, form).$promise;
        }

        function postCycleUsage(id, cycle) {
            return resource.postCycleUsage({id: id}, cycle).$promise;
        }

        function postDeleteResourceRecords(id, domainId, store) {
            store = !_.isUndefined(store) ? store : true;
            return resource.postDeleteResourceRecords({id: id, action_id: domainId}, {store: store}).$promise;
        }

        function postDetachFromShard(id, form) {
            return resource.postDetachFromShard({id: id}, form).$promise;
        }

        function postPlanUpgrade(id, newPlan) {
            return resource.postPlanUpgrade({id: id}, {new_plan: newPlan}).$promise;
        }

        function postRequestCertificate(id, domainName, region, deploy, dbStore) {
            deploy = _.isUndefined(deploy) ? 'ALL' : deploy;
            dbStore = _.isUndefined(dbStore) ? true : dbStore;
            return resource.postRequestCertificate({id: id}, {domain: domainName, region: region, deploy: deploy, db_store: dbStore}).$promise;
        }

        function postRequestTestCertificate(id, domainName, region, deploy) {
            deploy = _.isUndefined(deploy) ? 'ALL' : deploy;
            return resource.postRequestTestCertificate({id: id}, {domain: domainName, region: region, deploy: deploy}).$promise;
        }

        function postResourceRecords(id, records) {
            return resource.postResourceRecords({id: id}, {action: 'CREATE', records: records}).$promise;
        }

        function postSendReceipt(id, transactionId, email) {
            return resource.postSendReceipt({id: id, action_id: transactionId}, {email: email}).$promise;
        }

        function postSetPayment(id, form) {
            return resource.postSetPayment({id: id}, form).$promise;
        }

        function postUpdatePrefixes(id, domainId, form) {
            return resource.postUpdatePrefixes({id: id, action_id: domainId}, form).$promise;
        }

        function postValidatePath(pattern, paths) {
            return resource.postValidatePath({id: null, action_id: null}, {pattern: pattern, paths: paths}).$promise;
        }

        function postUpdateCors(id, domainId, form) {
            return resource.postUpdateCors({id: id, action_id: domainId}, form).$promise;
        }

        function restructureConfs(confs) {
            var restructured = {};
            angular.forEach(confs, function(val, key) {
                if (val.cname == 'DEFAULT') {
                    restructured.default = val;
                } else {
                    restructured[val.id] = val;
                }
            });

            return restructured;
        }

        function validateBilling(form, payment) {
            var fields = ['address', 'city', 'state', 'zip', 'country'];
            if (payment) {
                fields.push('nonce');
            }

            angular.forEach(fields, function(val, key) {
                if (!_.isNull(form[val]) && form[val] != '') {
                    fields = _.reject(fields, function(v) {
                        return v == val;
                    });
                }
            });

            return fields;
        }

        function validateRegistration(form) {
            var fields = ['company_name', 'passwordConfirm', 'country', 'email', 'first_name', 'last_name', 'password', 'username'];
            angular.forEach(fields, function(val, key) {
                if (!_.isNull(form[val]) && form[val] != '') {
                    fields = _.reject(fields, function(v) {
                        return v == val.trim();
                    });
                }
            });

            if (!form.consent) {
                fields.push('consent');
            }

            if (!form.terms) {
                fields.push('terms');
            }

            return fields;
        }

        function validateTrial(form) {
            var fields = [];
            if (_.isNull(form.name) || form.name == '') {
                fields.push('name');
            }

            if (_.isNull(form.domainName) || form.domainName == '') {
                fields.push('domainName');
            }

            if (!form.terms) {
                fields.push('terms');
            }

            return fields;
        }
    }
})();
