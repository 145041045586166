(function() {
    'use strict';

    angular.module('customerVault.wurfljs')
        .factory('wurfljsActions', wurfljsActions);

    wurfljsActions.$inject = ['$state', '$stateParams', '$uibModal', 'breadcrumbsService', 'bsLoadingOverlayService', 'loginService', 'Notification', 'wurfljsResolve'];

    function wurfljsActions($state, $stateParams, $uibModal, breadcrumbsService, bsLoadingOverlayService, loginService, Notification, wurfljsResolve) {
        var service = {
            getSteps: getSteps,
            getTab: getTab,
            map: map,
            openCancelAccount: openCancelAccount,
            openCancelConfirm: openCancelConfirm,
            openCertificate: openCertificate,
            openDeleteCname: openDeleteCname,
            openDeleteDomain: openDeleteDomain,
            openIncreaseDetections: openIncreaseDetections,
            openReceipt: openReceipt
        };

        return service;

        function getSteps(data) {
            var params = {};

            if (data.subscription.payment_type == 'TRIAL' || data.subscription.payment_type == 'FREE') {
                params.parent = {state: 'trials', title: 'Trials'};
            } else {
                params.parent = {state: 'products', title: 'Products'};
            }

            if (loginService.impersonate.mode) {
                if (loginService.impersonate.user.id != data.user.id) {
                    params.user = data.user;
                }
            } else {
                if (loginService.authInfo.user.id != data.user.id) {
                    params.user = data.user;
                }
            }
            return breadcrumbsService.get('wurfljs.home', params);
        }

        function getTab() {
            switch ($state.current.name) {
                case 'wurfljs.home':
                    return;
                case 'wurfljs.home.gettingstarted':
                    return 'gettingstarted';
                case 'wurfljs.home.summary':
                    return 'summary';
                case 'wurfljs.home.capabilities':
                    return 'capabilities';
                case 'wurfljs.home.domains':
                    return 'domains';
                case 'wurfljs.home.cnames':
                    return 'cnames';
                case 'wurfljs.home.billinghistory':
                    return 'billinghistory';
                case 'wurfljs.home.updatebilling':
                    return 'updatebilling';
                case 'wurfljs.home.planinfo':
                    return 'planinfo';
                case 'wurfljs.home.changeplan':
                    return 'changeplan';
            }
        }

        function map(plan) {
            switch (plan) {
                case 'WURFL_JS_BUSINESS_SMALL':
                    return 'basic';
                case 'WURFL_JS_BUSINESS_MEDIUM':
                    return 'standard';
                case 'WURFL_JS_BUSINESS_LARGE':
                    return 'pro';
            }
        }

        function openCancelAccount() {
            $uibModal.open({
                templateUrl: 'view/wurfljs.partials.cancel',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                controller: 'WurfljsCancelController',
                controllerAs: 'vm',
                windowClass: 'zindex'
            });
        }

        function openCancelConfirm(cancelReason) {
            $uibModal.open({
                templateUrl: 'view/components.cancel_confirm',
                size: 'md',
                keyboard: false,
                backdrop: 'static',
                windowClass: 'zindex',
                controller: 'WurfljsCancelConfirmController',
                controllerAs: 'vm',
                resolve: {
                    cancelReason: function() {
                        return cancelReason;
                    }
                }
            });
        }

        function openCertificate(cname, action) {
            $uibModal.open({
                templateUrl: 'view/wurfljs.partials.certificate',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                controller: 'WurfljsCertificateController',
                controllerAs: 'vm',
                windowClass: 'zindex',
                resolve: {
                    cname: function() {
                        return cname;
                    },
                    action: function() {
                        return action;
                    }
                }
            });
        }

        function openDeleteCname(cname) {
            return $uibModal.open({
                templateUrl: "view/wurfljs.partials.delete_confirm",
                size: 'md',
                keyboard: false,
                windowClass: 'zindex',
                backdrop: 'static',
                controller: 'WurfljsDeleteCertificateController',
                controllerAs: 'vm',
                resolve: {
                    cname: function() {
                        return cname;
                    }
                }
            });
        }

        function openDeleteDomain(domain) {
            return $uibModal.open({
                templateUrl: "view/wurfljs.partials.delete_confirm",
                size: "md",
                keyboard: false,
                windowClass: 'zindex',
                backdrop: 'static',
                controller: 'WurfljsDeleteDomainController',
                controllerAs: 'vm',
                resolve: {
                    domain: function() {
                        return domain;
                    }
                }
            });
        }

        function openIncreaseDetections(defaults) {
            return $uibModal.open({
                templateUrl: 'view/wurfljs.partials.increasedetections',
                size: 'md',
                keyboard: false,
                backdrop: 'static',
                controller: 'WurfljsIncreaseDetectionsController',
                controllerAs: 'vm',
                windowClass: 'zindex',
                resolve: {
                    detections: function() {
                        return defaults.detections;
                    },
                    currentState: function() {
                        return $state.current.name;
                    }
                }
            });
        }

        function openReceipt(transactionId) {
            wurfljsResolve.run({name: 'wurfljs.home.billinghistory.transaction', params: {id: $stateParams.id, transactionId: transactionId}}).then(function(response) {
                if (response) {
                    var modal = $uibModal.open({
                        templateUrl: 'view/wurfljs.partials.receipt',
                        size: 'lg',
                        keyboard: false,
                        backdrop: 'static',
                        controller: 'WurfljsReceiptController',
                        controllerAs: 'vm',
                        windowClass: 'zindex',
                        resolve: {
                            transactionId: function() {
                                return transactionId;
                            }
                        }
                    });
                    modal.result.then(function() {
                        bsLoadingOverlayService.stop({
                            referenceId: 'wurfljs-billing-history'
                        });
                    });
                } else {
                    var error = wurfljsResolve.error;
                    if (!_.isEmpty(error)) {
                        Notification.error(error.message);
                        return;
                    }
                }
                bsLoadingOverlayService.stop({
                    referenceId: 'wurfljs-billing-history'
                });
            });
        }
    }
})();
