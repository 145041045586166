(function() {
    'use strict';

    angular.module('customerVault')
        .directive('generalError', generalError);

    function generalError() {
        var directive = {
            templateUrl: 'view/errors.400',
            restrict: 'AE',
            replace: true,
            scope: {
                showButton: '='
            },
            controller: 'GeneralErrorController',
            controllerAs: 'vm'
        };

        return directive;
    }
})();
