(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .controller('CloudController', CloudController);

    CloudController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$window', 'breadcrumbsService', 'bsLoadingOverlayService', 'cloudActions', 'cloudResolve', 'cloudService', 'loaderService', 'loginService', 'myaccountResolve', 'Notification'];

    function CloudController($rootScope, $scope, $state, $stateParams, $timeout, $window, breadcrumbsService, bsLoadingOverlayService, cloudActions, cloudResolve, cloudService, loaderService, loginService, myaccountResolve, Notification) {
        var vm = this;
        var resolveData = null;
        var error = null;

        vm.accounts = null;
        vm.activeCapabilities = null;
        vm.addonsAllowed = null;
        vm.cancelAccount = cancelAccount;
        vm.capabilities = null;
        vm.changeTab = changeTab;
        vm.currentDetections = null;
        vm.homeTab = 'view/cloud.subpages.home';
        vm.inactiveCapabilities = null;
        vm.isFreeCloudAccount = null;
        vm.isLegacy = null;
        vm.isPremium = null;
        vm.maxCapabilities = null;
        vm.maxDetections = null;
        vm.notAvailable = notAvailable;
        vm.onlinePurchaseAllowed = null;
        vm.pageError = false;
        vm.pageErrorView = 'view/errors.400';
        vm.pageNotFound = false;
        vm.pageNotFoundView = 'view/errors.404'
        vm.percentClass = null;
        vm.percentDetections = null;
        vm.percentStyle = null;
        vm.selectedAccount = undefined;
        vm.showCapabilities = showCapabilities;
        vm.statusWrapper = false;
        vm.steps = null;
        vm.subscription = {};
        vm.switchAccounts = switchAccounts;
        vm.title = 'WURFL Cloud Account Settings';
        vm.upgradePremium = upgradePremium;
        vm.user = null;
        vm.viewTab = 'home';

        var notAvailable = null;

        vm.viewTab = cloudActions.getTab($state.current.name);

        run();

        function run() {
            resolveData = cloudResolve.load.main;
            error = cloudResolve.mainError;

            if (!_.isEmpty(error) && (_.isEmpty(error.data) || error.data.status != 'invalid')) {
                vm.error = error.status;
                if (vm.error == 403) {
                    vm.pageErrorViewMessage = error.message;
                } else if (vm.error == 404) {
                    $rootScope.$broadcast('setTitle', {title: 'Page Not Found'});
                }

                return;
            }

            if (!_.isUndefined(resolveData.statusWrapper)) {
                vm.statusWrapper = resolveData.statusWrapper
                vm.title = resolveData.title;
            } else {
                vm.accounts = resolveData.accounts;
                vm.activeCapabilities = resolveData.activeCapabilities;
                vm.addonsAllowed = resolveData.addonsAllowed;
                vm.capabilities = resolveData.capabilities;
                vm.currentDetections = resolveData.currentDetections;
                vm.error = false;
                vm.inactiveCapabilities = resolveData.inactiveCapabilities;
                vm.isFreeCloudAccount = resolveData.isFreeCloudAccount;
                vm.isLegacy = resolveData.isLegacy;
                vm.isPremium = resolveData.isPremium;
                vm.maxCapabilities = resolveData.maxCapabilities;
                vm.maxDetections = resolveData.maxDetections;
                vm.onlinePurchaseAllowed = resolveData.onlinePurchaseAllowed;
                vm.percentDetections = resolveData.percentDetections;
                vm.percentClass = resolveData.percentClass;
                vm.percentStyle = resolveData.percentStyle;
                vm.steps = cloudActions.getSteps(resolveData);
                vm.subscription = resolveData.subscription;
            }
            vm.user = resolveData.user;
        }

        var cloudCanceledAccount = $rootScope.$on('cloudCanceledAccount', function() {
            vm.statusWrapper = 'view/subscriptions.status_cancelled';
            vm.title = 'Account Status';
            myaccountResolve.load = {};
            $window.scrollTo(0, 0);
            $scope.$on('$destroy', cloudCanceledAccount);
        });

        var cloudChangeTab = $rootScope.$on('cloudChangeTab', function(event, args) {
            if (!_.isUndefined(args.other)) {
                changeTab(args.tab, args.other);
            } else {
                changeTab(args.tab);
            }
            $scope.$on('$destroy', cloudChangeTab);
        });

        var cloudUpdateActiveCapabilities = $rootScope.$on('cloudUpdateActiveCapabilities', function(event, args) {
            vm.activeCapabilities = args.active;
            vm.inactiveCapabilities = args.inactive;
            $scope.$on('$destroy', cloudUpdateActiveCapabilities);
        });

        var reloadCloudAccount = $rootScope.$on('reloadCloudAccount', function() {
            bsLoadingOverlayService.start({
                referenceId: 'cloud-top'
            });
            bsLoadingOverlayService.start({
                referenceId: 'cloud-tabs'
            });
            myaccountResolve.load = {};
            vm.steps = null;
            cloudResolve.load.main = {}
            cloudResolve.run({name: 'cloud.home', params: $stateParams}).then(function(response) {
                if (response) {
                    run();
                    changeTab('home');
                } else {
                    vm.pageError = true;
                }
                bsLoadingOverlayService.stop({
                    referenceId: 'cloud-top'
                });
                bsLoadingOverlayService.stop({
                    referenceId: 'cloud-tabs'
                });
                $scope.$on('$destroy', reloadCloudAccount);
            });
        });

        var reloadCloudBreadcrumbs = $rootScope.$on('reloadCloudBreadcrumbs', function() {
            if (!_.isEmpty(error)) {
                cloudResolve.mainError = {};
                run();
            } else {
                vm.steps = null;
                $timeout(function() {
                    var params = {};
                    if (vm.subscription.payment_type == 'TRIAL' || vm.subscription.payment_type == 'FREE') {
                        params.parent = {state: 'trials', title: 'Trials'};
                    } else {
                        params.parent = {state: 'products', title: 'Products'};
                    }

                    if (loginService.impersonate.mode || vm.user.id == loginService.authInfo.user.id) {
                        vm.steps = breadcrumbsService.get('cloud.home', params);
                    } else {
                        params.user = vm.user;
                        vm.steps = breadcrumbsService.get('cloud.home', params);
                    }
                }, 100);
            }
            $scope.$on('$destroy', reloadCloudBreadcrumbs);
        });

        $rootScope.$on('$stateChangeSuccess', function() {
            bsLoadingOverlayService.stop({
                referenceId: 'cloud-tabs'
            });
        });

        function cancelAccount() {
            cloudActions.openCancelAccount();
        }

        function changeTab(tab, other) {
            if (!_.isNull(notAvailable)) {
                notAvailable.close();
            }
            if (vm.viewTab != tab) {
                vm.viewTab = tab;
                cloudActions.changeTab(tab, other);
            }
        }

        function notAvailable() {
            cloudActions.openNotAvailable(vm.isPremium, vm.subscription);
        }

        function showCapabilities() {
            cloudActions.openShowCapabilities(vm.activeCapabilities, vm.capabilities, vm.isFreeCloudAccount);
        }

        function switchAccounts() {
            $state.go('cloud.home', {id: vm.selectedAccount.id});
        }

        function upgradePremium() {
            changeTab('changePlan', {plan: 'wurfl-cloud'});
        }
    }
})();
