(function() {
    'use strict';

    angular.module('customerVault.impersonate')
        .factory('impersonateService', impersonateService);

    impersonateService.$inject = ['$resource', '$rootScope', '$state', 'endSession', 'flashMessage', 'loaderService', 'loginService'];

    function impersonateService($resource, $rootScope, $state, endSession, flashMessage, loaderService, loginService) {
        var resource = new $resource('/api/vault/impersonate/:id/:userId/:authId', {auth_id: '@authId'}, {
            getBasicIsAdmin: {
                method: 'GET',
                params: {
                    id: 'basic',
                    authId: null
                }
            },
            getCheckImpersonateIsAdmin: {
                method: 'GET',
                params: {
                    id: 'check',
                    authId: null
                }
            },
            getLoginViaImpersonate: {
                method: 'GET',
                params: {
                    id: 'login'
                }
            },
            getVerifyImpersonateAccess: {
                method: 'GET',
                params: {
                    authId: null
                }
            },
            postLoginFromAdmin: {
                method: 'POST',
                params: {
                    id: 'admin',
                    authId: null,
                    userId: null
                }
            }
        });

        var service = {
            getBasicIsAdmin: getBasicIsAdmin,
            getCheckImpersonateIsAdmin: getCheckImpersonateIsAdmin,
            getLoginViaImpersonate: getLoginViaImpersonate,
            getVerifyImpersonateAccess: getVerifyImpersonateAccess,
            flash: {
                message: null,
                show: false,
                admin: false,
                impersonateUser: null
            },
            postLoginFromAdmin: postLoginFromAdmin,
            removePersist: removePersist,
            resetFlash: resetFlash,
            setPersist: setPersist
        };

        return service;

        function getBasicIsAdmin(id) {
            return resource.getBasicIsAdmin({userId: id}).$promise;
        }

        function getCheckImpersonateIsAdmin(id, type, accountId) {
            return resource.getCheckImpersonateIsAdmin({userId: id, type: type, accountId: accountId}).$promise;
        }

        function getLoginViaImpersonate(token, authToken) {
            var params = {userId: token};
            if (!_.isNull(authToken)) {
                params.authId = authToken;
            }

            resource.getLoginViaImpersonate(params).$promise.then(function(response) {
                localStorage.setItem('auth_token', response.data.token);
                localStorage.setItem('access_time', Math.round((new Date()).getTime() / 1000));
                localStorage.setItem('refresh_time', Math.round((new Date()).getTime() / 1000));
                $rootScope.$broadcast('startImpersonate', {user: response.data.imp_user});
                loaderService.close();
                $state.go('myaccount');
            }).catch(function(error) {
                console.log(error.data);
                switch (error.status) {
                    case 404:
                    case 403:
                        runError(error.data.message, error.data.data);
                        break;
                    default:
                        runError("An unknown error occurred. Please <a href='https://support.scientiamobile.com' target=\"_blank\">contact us</a> for assistance.", error.data.data);
                }
            });
        }

        function getVerifyImpersonateAccess(id, userId) {
            return resource.getVerifyImpersonateAccess({id: id, userId: userId}).$promise;
        }

        function postLoginFromAdmin(session, sm_auth_token) {
            var data = {
                session: session,
                authenticated: false
            };
            if (loginService.authInfo.authenticated) {
                data.authenticated = true;
                data.user_id = loginService.authInfo.user.id;
            }

            resource.postLoginFromAdmin({}, data).$promise.then(function(response) {
                loaderService.close();
                localStorage.setItem('auth_token', response.data.token);
                localStorage.setItem('access_time', Math.round((new Date()).getTime() / 1000));
                localStorage.setItem('refresh_time', Math.round((new Date()).getTime() / 1000));
                localStorage.setItem('sm_auth_token', sm_auth_token);
                
                loginService.authInfo.authenticated = true;
                loginService.authInfo.user = response.data.user;
                $rootScope.$broadcast('startImpersonate', {user: response.data.impersonate, stop: true, home: true});
            }).catch(function(error) {
                console.log(error.data);
                switch (error.status) {
                    case 403:
                        runLogoutError(error.data.message);
                        break;
                    case 409:
                        $rootScope.$broadcast('endImpersonation');
                        flashMessage.message.message = error.data.message;
                        flashMessage.message.show = true;
                        loaderService.show = false;
                        $state.go('myaccount');
                        break;
                    default:
                        runLogoutError("An unknown error occurred. Please <a href='https://support.scientiamobile.com' target=\"_blank\">contact us</a> for assistance.");
                }
            });
        }

        function removePersist() {
            localStorage.removeItem('impersonate');
        }

        function resetFlash() {
            service.flash = {
                message: null,
                show: false,
                admin: false,
                impersonateUser: null
            };
        }

        function runError(message, data) {
            var state = 'myaccount';
            if (!data.authenticated) {
                endSession.end();
                loginService.intended = undefined;
                state = 'login';
            }

            flashMessage.message.message = message;
            flashMessage.message.show = true;
            flashMessage.message.admin = data.admin;
            flashMessage.message.impersonateUser = data.impersonate_user;
            $state.go(state);
        }

        function runLogoutError(message) {
            endSession.end();
            loginService.intended = undefined;
            loginService.authInfo.authenticated = false;
            loginService.authInfo.user = null;
            $rootScope.$broadcast('authenticated');
            $rootScope.$broadcast('endImpersonation');

            flashMessage.message.message = message;
            flashMessage.message.show = true;

            $state.go('login');
        }

        function setPersist(user) {
            localStorage.setItem('impersonate', JSON.stringify(user));
        }
    }
})();
