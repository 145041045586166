(function() {
    'use strict';

    angular.module('customerVault.wurfljs')
        .controller('WurfljsIncreaseDetectionsController', WurfljsIncreaseDetectionsController);

    WurfljsIncreaseDetectionsController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$uibModalInstance', 'bsLoadingOverlayService', 'detections', 'loaderService', 'Notification', 'wurfljsResolve', 'wurfljsService'];

    function WurfljsIncreaseDetectionsController($rootScope, $scope, $state, $stateParams, $uibModalInstance, bsLoadingOverlayService, detections, loaderService, Notification, wurfljsResolve, wurfljsService) {
        var vm = this;
        var resolveData = wurfljsResolve.load.increasedetections;

        vm.buttonText = null;
        vm.close = close;
        vm.detections = detections;
        vm.newCost = null;
        vm.newNumber = resolveData.newNumber;
        vm.nextBilling = resolveData.nextBilling;
        vm.spinnerOptions = resolveData.spinnerOptions
        vm.stats = resolveData.stats;
        vm.submit = submit;

        bsLoadingOverlayService.stop({
            referenceId: 'wurfljs-tabs'
        });

        $scope.$watch('vm.newNumber', function(newVal) {
            if (!_.isNull(newVal) && newVal != (vm.stats.current / 1000000)) {
                var newAddons = newVal - (vm.stats.current / 1000000);
                vm.newCost = vm.stats.current_total_cost + (vm.stats.addon_base_cost * newAddons);
                if (vm.newNumber > (vm.stats.current / 1000000)) {
                    vm.buttonText = "Purchase Upgrade";
                } else if (vm.newNumber < (vm.stats.current / 1000000)) {
                    vm.buttonText = "Save Changes";
                }
            }
        });

        function close() {
            $uibModalInstance.dismiss();
        }

        function submit() {
            bsLoadingOverlayService.start({
                referenceId: 'wjs-increase-detections-form'
            });
            wurfljsService.postIncreaseDetections($stateParams.id, vm.newNumber).then(function(response) {
                Notification.success(response.message);
                $uibModalInstance.close(vm.newNumber);
            }).catch(function(error) {
                console.log(error);
                Notification.error(error.data.message);
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'wjs-increase-detections-form'
                });
            });
        }
    }
})();
