(function() {
    'use strict';

    angular.module('customerVault.wurfljs')
        .controller('WurfljsCnamesController', WurfljsCnamesController);

    WurfljsCnamesController.$inject = ['$rootScope', '$stateParams', '$uibModal', 'bsLoadingOverlayService', 'Notification', 'wurfljsActions', 'wurfljsResolve', 'wurfljsService'];

    function WurfljsCnamesController($rootScope, $stateParams, $uibModal, bsLoadingOverlayService, Notification, wurfljsActions, wurfljsResolve, wurfljsService) {
        var vm = this;
        var resolveData = wurfljsResolve.load.cnames;
        var error = wurfljsResolve.error;

        vm.addCertificate = addCertificate;
        vm.addCname = addCname;
        vm.addingClass = null;
        vm.cnames = resolveData.cnames;
        vm.defaults = resolveData.defaults;
        vm.deleteCertificate = deleteCertificate;
        vm.deleteCname = deleteCname;
        vm.error = false;
        vm.newCname = null;
        vm.pageErrorView = 'view/errors.400';
        vm.plan = resolveData.plan;

        if (!_.isEmpty(error)) {
            vm.error = true;
        }

        $rootScope.$on('addedCertificate', function(event, args) {
            angular.forEach(vm.cnames, function(val, key) {
                if (val.id == args.cnameId) {
                    val.certificate = args.cert;
                    val.certificate_parse = args.parse;
                }
            });
            Notification.success('Successfully added SSL certificate');
        });

        $rootScope.$on('removedCertificate', function(event, args) {
            angular.forEach(vm.cnames, function(val, key) {
                if (val.id == args.cnameId) {
                    val.certificate = null;
                    val.certificate_parse = false;
                }
            });
            Notification.success('Successfully removed SSL certificate');
        })

        function addCertificate(cname) {
            wurfljsActions.openCertificate(cname, 'add');
        }

        function addCname() {
            vm.addingClass = null;
            if (_.isNull(vm.newCname) || vm.newCname == '') {
                vm.addingClass = 'error';
                return;
            }
            bsLoadingOverlayService.start({
                referenceId: 'wjs-cname-form'
            });
            wurfljsService.postCnames($stateParams.id, vm.newCname).then(function(response) {
                vm.cnames.push(response.data);
                vm.newCname = null;
            }).catch(function(error) {
                switch (error.status) {
                    case 403:
                        Notification.error(error.data.message);
                        break;
                    default:
                        Notification.error("Unable to create new cname");
                }
                console.log(error);
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'wjs-cname-form'
                });
            });
        }

        function deleteCertificate(cname) {
            wurfljsActions.openCertificate(cname, 'view');
        }

        function deleteCname(cname) {
            var deleteModal = wurfljsActions.openDeleteCname(cname);

            deleteModal.result.then(function() {
                vm.cnames = _.reject(vm.cnames, function(obj) {
                    return obj.id == cname.id
                });
            });
        }
    }
})();
