(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .controller('CloudSignupController', CloudSignupController);

    CloudSignupController.$inject = ['$rootScope', '$scope', '$state', '$timeout', '$window', 'authenticate', 'breadcrumbsService', 'bsLoadingOverlayService', 'cloudActions', 'cloudResolve', 'cloudService', 'loginService', 'myaccountResolve', 'paymentService', 'signupService'];

    function CloudSignupController($rootScope, $scope, $state, $timeout, $window, authenticate, breadcrumbsService, bsLoadingOverlayService, cloudActions, cloudResolve, cloudService, loginService, myaccountResolve, paymentService, signupService) {
        var vm = this;
        var resolveData = cloudResolve.load.signup;
        var error = cloudResolve.error;

        vm.authorized = null;
        vm.changePlan = changePlan;
        vm.class = resolveData.class;
        vm.countries = resolveData.countries;
        vm.error = false;
        vm.errors = {
            accountName: false,
            address: false,
            city: false,
            country: false,
            firstName: false,
            lastName: false,
            paymentPlan: false,
            state: false,
            zip: false
        };
        vm.form = resolveData.form;
        vm.pageViewError = 'view/errors.400';
        vm.selectedPlan = resolveData.selectedPlan;
        vm.signIn = signIn;
        vm.signupMessage = signupService.value;
        vm.steps = vm.authorized ? breadcrumbsService.get('cloud.signup') : null;
        vm.submit = submit;
        vm.submitText = resolveData.submitText;

        if (!loginService.authInfo.authenticated) {
            vm.authorized = false;
        } else {
            if (loginService.impersonate.mode) {
                vm.form.firstName = loginService.impersonate.user.first_name;
                vm.form.lastName = loginService.impersonate.user.last_name;
            } else {
                vm.form.firstName = loginService.authInfo.user.first_name;
                vm.form.lastName = loginService.authInfo.user.last_name;
            }
            vm.authorized = true;
        }

        var loadedInstance = null;

        if (_.isEmpty(error)) {
            generateToken();
        } else {
            vm.error = true;
        }

        $scope.$on('tokenGenerated', function(event, args) {
            braintree.dropin.create({
                authorization: args.token,
                container: '#cloud-signup-payment-form',
                card: {
                    cardholderName: {
                        required: true
                    }
                }
            }, function(createErr, instance) {
                $timeout(function() {
                    var disablePay = false;
                }, 1);
                loadedInstance = instance;
            });
        });

        $rootScope.$on('authenticated', function() {
            vm.authorized = loginService.authInfo.authenticated;
            if (vm.authorized) {
                vm.form.firstName = loginService.authInfo.user.first_name;
                vm.form.lastName = loginService.authInfo.user.last_name;
                vm.steps = breadcrumbsService.get('cloud.signup');
                generateToken();
            } else {
                vm.steps = null;
            }
        });

        var exitImpersonationCloudSignup = $rootScope.$on('exitImpersonationCloudSignup', function() {
            vm.form.firstName = loginService.authInfo.user.first_name;
            vm.form.lastName = loginService.authInfo.user.last_name;
            $scope.$on('$destroy', exitImpersonationCloudSignup);
        });

        function changePlan(plan) {
            vm.errors.paymentPlan = false;
            vm.selectedPlan = resolveData.plans[plan];

            angular.forEach(vm.class, function(val, key) {
                if (map(plan) == key) {
                    vm.class[key] = 'plans-btn-selected-word-width';
                } else {
                    vm.class[key] = 'plans-btn-word-width';
                }
            });
            vm.form.paymentPlan = plan;
        }

        function checkAccountErrors() {
            vm.errors = {
                accountName: false,
                address: false,
                city: false,
                country: false,
                firstName: false,
                lastName: false,
                paymentPlan: false,
                state: false,
                zip: false
            };

            if (vm.selectedPlan.key == 'WURFL_CLOUD_PREMIUM') {
                angular.forEach(vm.form, function(val, key) {
                    if (_.isNull(val)) {
                        vm.errors[key] = true;
                    }
                });
            } else {
                if (_.isNull(vm.form.accountName)) {
                    vm.errors.accountName = true;
                }
            }

            var errors = !_.every(vm.errors, function(val, key) {
                return !val;
            });

            if (errors) {
                if (vm.errors.paymentPlan) {
                    $window.scrollTo(0, 0);
                }
                return false;
            }
            return true;
        }

        function createAccount() {
            vm.form.user_id = loginService.impersonate.mode ?
                loginService.impersonate.user.id :
                loginService.authInfo.user.id;

            loadedInstance.requestPaymentMethod(function(requestPaymentMethodErr, payload) {
                if (
                    (payload !== undefined && vm.selectedPlan.key !== 'WURFL_CLOUD_FREE') ||
                    vm.selectedPlan.key == 'WURFL_CLOUD_FREE'
                ) {
                    vm.form.nonce = !_.isUndefined(payload) ? payload.nonce : null;
                    cloudService.postCreate(vm.form).then(function(response) {
                        signupService.value = {
                            class: 'success',
                            message: 'Your WURFL Cloud account has been created!',
                            show: true
                        };
                        myaccountResolve.load = {};
                        $state.go('subscription.signupComplete', {type: 'cloud'});
                    }).catch(function(error) {
                        switch (error.status) {
                            case 401:
                            case 403:
                                signupService.value = {
                                    class: 'danger',
                                    message: error.data.message,
                                    show: true
                                };
                                vm.signupMessage = signupService.value;
                                $window.scrollTo(0, 0);
                                break;
                            default:
                                if (!error.data.data.account) {
                                    signupService.value = {
                                        class: 'danger',
                                        message: "WURFL Cloud account could not be created",
                                        show: true
                                    };
                                    vm.signupMessage = signupService.value;
                                    $window.scrollTo(0, 0);
                                } else if (!error.data.data.tier) {
                                    if (vm.selectedPlan.key !== 'WURFL_CLOUD_FREE') {
                                        signupService.value = {
                                            class: 'warning',
                                            message: "Your WURFL Cloud account has been created, but an issue occurred while setting your support level. Please contact support@scientiamobile.com for assistance.",
                                            show: true
                                        };
                                    } else {
                                        signupService.value = {
                                            class: 'success',
                                            message: 'Your WURFL Cloud account has been created!',
                                            show: true
                                        };
                                    }
                                    myaccountResolve.load = {};
                                    $state.go('subscription.signupComplete', {type: 'cloud'});
                                }
                                break;
                        }
                        console.log(error.data);
                    }).finally(function() {
                        bsLoadingOverlayService.stop({
                            referenceId: 'cloud-signup-form'
                        });
                        $timeout(function() {
                            signupService.reset();
                            vm.signupMessage = signupService.value;
                        }, 5000);
                    });
                } else {
                    bsLoadingOverlayService.stop({
                        referenceId: 'cloud-signup-form'
                    });
                    $timeout(function() {
                        signupService.reset();
                        vm.signupMessage = signupService.value;
                    }, 5000);
                }
            });
        }

        function generateToken() {
            return paymentService.getBraintreeAuthorization().then(function(response) {
                $scope.$broadcast('tokenGenerated', {token: response.data.authorization});
            });
        }

        function map(plan) {
            switch (plan) {
                case 'WURFL_CLOUD_FREE':
                    vm.submitText = 'Create Account';
                    return 'lite';
                    break;
                case 'WURFL_CLOUD_PREMIUM':
                    vm.submitText = 'Complete Order';
                    return 'standard';
                    break;
            }
        }

        function signIn() {
            signupService.openSignup();
        }

        function submit() {
            if (!_.isNull(vm.form.country)) {
                vm.form.countryName = _.findWhere(vm.countries, {country_id: vm.form.country}).name;
            }

            if (checkAccountErrors()) {
                bsLoadingOverlayService.start({
                    referenceId: 'cloud-signup-form'
                });
                createAccount();
            }
        }
    }
})();
