(function() {
    'use strict';

    angular.module('customerVault.zendesk')
        .controller('ZendeskController', ZendeskController);

    ZendeskController.$inject = ['$state', '$stateParams', '$window', 'loaderService', 'loginService', 'zendeskService'];

    function ZendeskController($state, $stateParams, $window, loaderService, loginService, zendeskService) {
        var vm = this;

        vm.error = false;

        if (!_.isNull($stateParams.return_to)) {
            zendeskService.return_to = $stateParams.return_to;
        }

        if (!loginService.authInfo.authenticated) {
            loginService.intended = {name: $state.current.name, params: $stateParams};
            $state.go('login');
            return;
        }

        loaderService.confirm();
        zendeskService.postAuth(loginService.authInfo.user.id).then(function(response) {
            var redirect = response.data.redirect;
            $window.location.href = redirect;
        }).catch(function(error) {
            console.log(error);
            vm.error = true;
        }).finally(function() {
            loaderService.close();
            zendeskService.return_to = null;
        });
    }
})();
