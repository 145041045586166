(function() {
    'use strict';

    angular.module('customerVault.license')
        .factory('licenseCapabilitiesService', licenseCapabilitiesService);

    licenseCapabilitiesService.$inject = [];

    function licenseCapabilitiesService() {
        var service = {
            allCapabilities: null,
            chunk: chunk,
            chunks: null,
            currentCapabilities: null,
            currentChunkedCapabilities: null,
            runFilter: runFilter,
            set: set
        };

        return service;

        function chunk() {
            var arr = [];
            var arrChunk = [];
            angular.forEach(service.currentCapabilities, function(val, key) {
                if (arrChunk.length < 10) {
                    arrChunk.push(val);
                } else {
                    arr.push(arrChunk);
                    arrChunk = [];
                    arrChunk.push(val);
                }
            });
            arr.push(arrChunk);
            return arr;
        }

        function runFilter(checkedGroups, checkedValueTypes, search) {
            service.chunks = 1;
            var filteredCategoryCapabilities = [];

            // Category search
            if (_.every(checkedGroups, function(obj) {
                return obj == false;
            })) {
                filteredCategoryCapabilities = angular.copy(service.allCapabilities)
            } else {
                angular.forEach(service.allCapabilities, function(val, key) {
                    if (checkedGroups[val.group.group_name]) {
                        filteredCategoryCapabilities.push(val);
                    }
                });
            }

            var filteredValueCapabilities = [];
            // Value type search
            if (!_.every(checkedValueTypes, function(obj) {
                return obj == false;
            })) {
                angular.forEach(filteredCategoryCapabilities, function(val, key) {
                    if (checkedValueTypes[val.return_type]) {
                        filteredValueCapabilities.push(val);
                    }
                })
            } else {
                filteredValueCapabilities = angular.copy(filteredCategoryCapabilities);
            }

            if (!_.isNull(search) && search != '' && !_.isUndefined(search)) {
                service.currentCapabilities = _.filter(filteredValueCapabilities, function(obj) {
                    return obj.name.indexOf(search) != -1;
                });
            } else {
                service.currentCapabilities = angular.copy(filteredValueCapabilities);
            }

            service.currentChunkedCapabilities = service.chunk();
        }

        function set(data) {
            service.allCapabilities = data.allCapabilities; // All the capabilities that exist (flattened). Static
            service.currentCapabilities = data.currentCapabilities; // All capabilities that match filters (flattened). Dynamic
            service.currentChunkedCapabilities = data.currentChunkedCapabilities;
            service.chunks = data.chunks;
        }
    }
})();
