(function() {
    'use strict';

    angular.module('customerVault.license')
        .controller('WurflArchiveController', WurflArchiveController);

    WurflArchiveController.$inject = ['$uibModalInstance', 'downloadKey', 'licenseAction', 'snapshotGenerator'];

    function WurflArchiveController($uibModalInstance, downloadKey, licenseAction, snapshotGenerator) {
        var vm = this;

        vm.close = close;
        vm.wurflReleases = licenseAction.getWurflArchive(downloadKey, snapshotGenerator);

        function close() {
            $uibModalInstance.dismiss();
        }
    }
})();
