(function() {
    'use strict';

    angular.module('customerVault.license')
        .factory('licenseService', licenseService);

    licenseService.$inject = ['$resource', 'loginService'];

    function licenseService($resource, loginService) {
        var resource = new $resource('/api/vault/account/license/:id/:action', {id: '@id'}, {
            getAccount: {
                method: 'GET',
                params: {
                    action: null
                }
            },
            getCapabilities: {
                method: 'GET',
                params: {
                    action: 'capabilities'
                }
            },
            getCreateEvaluationLicense: {
                method: 'GET',
                params: {
                    action: 'eval'
                }
            },
            getCreateFile: {
                method: 'GET',
                params: {
                    action: 'file'
                }
            },
            getGuide: {
                method: 'GET',
                params: {
                    action: 'home'
                }
            },
            getLicenseText: {
                method: 'GET',
                params: {
                    action: 'text'
                }
            },
            postCreateDownloadLink: {
                method: 'POST',
                params: {
                    action: 'download-link'
                }
            }
        });

        var service = {
            getAccount: getAccount,
            getCapabilities: getCapabilities,
            getCreateEvaluationLicense: getCreateEvaluationLicense,
            getCreateFile: getCreateFile,
            getGuide: getGuide,
            getLicenseText: getLicenseText,
            postCreateDownloadLink: postCreateDownloadLink,
        };

        return service;

        function getAccount(id) {
            return resource.getAccount({id: id}).$promise;
        }

        function getCapabilities(id) {
            return resource.getCapabilities({id: id}).$promise;
        }

        function getCreateEvaluationLicense() {
            return resource.getCreateEvaluationLicense({id: null}).$promise;
        }

        function getCreateFile(id) {
            return resource.getCreateFile({id: id}).$promise;
        }

        function getGuide(id) {
            return resource.getGuide({id: id}).$promise;
        }

        function getLicenseText(id) {
            return resource.getLicenseText({id: id}).$promise;
        }

        function postCreateDownloadLink(id) {
            return resource.postCreateDownloadLink({id: id}).$promise;
        }
    }
})();
