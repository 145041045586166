(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineEditSettingsController', ImageEngineEditSettingsController);

    ImageEngineEditSettingsController.$inject = ['$timeout', '$uibModalInstance', 'bsLoadingOverlayService', 'domain', 'ieActions', 'ieService'];

    function ImageEngineEditSettingsController($timeout, $uibModalInstance, bsLoadingOverlayService, domain, ieActions, ieService) {
        var vm = this;

        vm.addSetting = addSetting;
        vm.close = close;
        vm.deleteSetting = deleteSetting;
        vm.domain = domain;
        vm.editSetting = editSetting;
        vm.error = {
            show: false,
            message: null
        };

        function addSetting() {
            settingsModal(true, null);
        }

        function close() {
            $uibModalInstance.dismiss();
        }

        function deleteSetting(setting) {
            var deleteSetting = ieActions.confirmDeleteSetting(setting);

            deleteSetting.result.then(function(response) {
                if (!response) {
                    vm.error.message = "We are unable to delete your settings at this time. Please contact support@scientiamobile.com for assistance.";
                    vm.error.class = "alert-danger";
                    vm.error.show = true;
                } else {
                    vm.error.message = "Successfully deleted setting";
                    vm.error.class = "alert-success";
                    vm.error.show = true;

                    vm.domain.wit_domain_setting = _.reject(vm.domain.wit_domain_setting, function(obj) {
                        return obj.id == setting.id;
                    });
                }

                $timeout(function() {
                    vm.error.show = false;
                }, 10000);
            });
        }

        function editSetting(setting) {
            settingsModal(false, setting);
        }

        function settingsModal(isNew, setting) {
            var newSetting = ieActions.openAddSetting(vm.domain, isNew, setting);
            newSetting.result.then(function(response) {
                vm.domain.wit_domain_setting = response;
            });
        }
    }
})();
