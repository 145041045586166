(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineStatisticsController', ImageEngineStatisticsController);

    ImageEngineStatisticsController.$inject = ['$stateParams', '$uibModal', 'bsLoadingOverlayService', 'ieResolve', 'ieService', 'ieStats'];

    function ImageEngineStatisticsController($stateParams, $uibModal, bsLoadingOverlayService, ieResolve, ieService, ieStats) {
        var vm = this;
        var resolveData = ieResolve.load.statistics;
        var error = ieResolve.error;

        var charts = ['cachehitratio', 'payloadreduction', 'bandwidthsaved', 'imageformat', 'toprequests', 'requests', 'usagetrends', 'topmissed', 'topcountries'];

        vm.charts = {};
        vm.chartAccordion = setChartAccordion();
        vm.chartError = setChartError();
        vm.data = angular.copy(resolveData);
        vm.downloadPng = downloadPng;
        vm.error = false;
        vm.getClass = getClass;
        vm.open = setOpen();
        vm.pageErrorView = 'view/errors.400';
        vm.readyHandler = readyHandler;
        vm.refreshBandwidthSaved = refreshBandwidthSaved;
        vm.refreshCacheHitRatio = refreshCacheHitRatio;
        vm.refreshImageFormat = refreshImageFormat;
        vm.refreshPayloadReduction = refreshPayloadReduction;
        vm.refreshRequests = refreshRequests;
        vm.refreshTopCountries = refreshTopCountries;
        vm.refreshTopMissed = refreshTopMissed;
        vm.refreshTopRequests = refreshTopRequests;
        vm.refreshUsageTrends = refreshUsageTrends;
        vm.running = false;
        vm.selectedRange = setRange();
        vm.show = !_.every(_.pluck(resolveData, 'show'), function(obj) {
            return obj === false;
        }) && !_.every(_.pluck(resolveData, 'show7'), function(obj) {
            return obj === false;
        });
        vm.sortTopRequests = sortTopRequests;
        vm.toggleAccordion = toggleAccordion;
        vm.topMissed = {};
        vm.topRequests = {};
        vm.updateTopRequests = updateTopRequests;

        var download = setChartDownload();

        if (!_.isEmpty(error)) {
            vm.error = true;
        }

        function downloadPng(domain, chart) {
            if (chart == 'usagetrends') {
                var uri = {
                    usagetrendsos: {
                        uri: download['usagetrendsos'][domain],
                        title: 'OS'
                    },
                    usagetrendsform: {
                        uri: download['usagetrendsform'][domain],
                        title: 'Form Factors'
                    },
                    usagetrendsphone: {
                        uri: download['usagetrendsphone'][domain],
                        title: 'Smart Phones'
                    }
                };
                var single = false;
            } else {
                var uri = download[chart][domain];
                var single = true;
            }
            $uibModal.open({
                templateUrl: 'view/imageengine.partials.download_image',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                controller: 'ImageEngineDownloadImageController',
                controllerAs: 'vm',
                windowClass: 'zindex',
                resolve: {
                    single: function () {
                        return single;
                    },
                    uri: function() {
                        return uri;
                    }
                }
            });
        }

        function getClass(domain, type, chart) {
            if (vm.selectedRange[chart][domain] === type) {
                return 'plans-btn-selected';
            } else {
                return 'plans-btn'
            }
        }

        function readyHandler(domain, chart, chartWrapper) {
            var uri = chartWrapper.getChart().getImageURI();
            download[chart][domain] = uri;
        }

        function refreshBandwidthSaved(domain) {
            bsLoadingOverlayService.start({
                referenceId: 'ie-bandwidthsaved-'+domain
            });
            var start = null;
            var end = null;
            if (vm.selectedRange.bandwidthsaved[domain] === 'd') {
                start = moment().subtract(7, 'days').format("YYYY-MM-DD");
                end = moment().format("YYYY-MM-DD");
            }

            vm.chartError.bandwidthsaved[domain] = false;
            vm.charts[domain].bandwidthsaved.data.rows = [];
            ieStats.postBandwidthSaved(vm.data[domain].type, domain, start, end).then(function(response) {
                vm.charts[domain].bandwidthsaved.data.rows = [];
                angular.forEach(response.data, function(val, key) {
                    vm.charts[domain].bandwidthsaved.data.rows.push({
                        c: [
                            {v: val.date},
                            {v: val.bandwidth_orig},
                            {v: val.bandwidth}
                        ]
                    });
                });
            }).catch(function(error) {
                console.log(error);
                vm.chartError.bandwidthsaved[domain] = true;
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-bandwidthsaved-'+domain
                });
            });
        }

        function refreshCacheHitRatio(domain) {
            bsLoadingOverlayService.start({
                referenceId: 'ie-cachehitratio-'+domain
            });
            var start = null;
            var end = null;
            if (vm.selectedRange.cachehitratio[domain] === 'd') {
                start = moment().subtract(7, 'days').format("YYYY-MM-DD");
                end = moment().format("YYYY-MM-DD");
            }

            vm.chartError.cachehitratio[domain] = false;
            vm.charts[domain].cachehitratio.data.rows = [];
            ieStats.postCacheHitRatio(vm.data[domain].type, domain, start, end).then(function(response) {
                vm.charts[domain].cachehitratio.data.rows.push({
                    c: [
                        {v: "Cache hit"},
                        {v: response.data.hits},
                        {v: "#1b4886"},
                        {v: response.data.hits_percentage+"%"}
                    ]
                });
                vm.charts[domain].cachehitratio.data.rows.push({
                    c: [
                        {v: "Cache miss"},
                        {v: response.data.miss},
                        {v: "#7baa31"},
                        {v: response.data.miss_percentage+"%"}
                    ]
                });
            }).catch(function(error) {
                console.log(error);
                vm.chartError.cachehitratio[domain] = true;
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-cachehitratio-'+domain
                });
            });
        }

        function refreshImageFormat(domain) {
            bsLoadingOverlayService.start({
                referenceId: 'ie-imageformat-'+domain
            });
            var start = null;
            var end = null;
            if (vm.selectedRange.imageformat[domain] === 'd') {
                start = moment().subtract(7, 'days').format("YYYY-MM-DD");
                end = moment().format("YYYY-MM-DD");
            }

            vm.chartError.imageformat[domain] = false;
            vm.charts[domain].imageformat.data.rows = [];
            ieStats.postImageFormatSavings(vm.data[domain].type, domain, start, end).then(function(response) {
                angular.forEach(response.data, function(val, key) {
                    vm.charts[domain].imageformat.data.rows.push({
                        c: [
                            {v: val.format},
                            {v: val.original},
                            {v: val.ie},
                            {v: val.savings+"% savings"}
                        ]
                    });
                });
            }).catch(function(error) {
                console.log(error.data);
                vm.chartError.imageformat[domain] = true;
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-imageformat-'+domain
                });
            });
        }

        function refreshPayloadReduction(domain) {
            bsLoadingOverlayService.start({
                referenceId: 'ie-payloadreduction-'+domain
            });
            var start = null;
            var end = null;
            if (vm.selectedRange.payloadreduction[domain] === 'd') {
                start = moment().subtract(7, 'days').format("YYYY-MM-DD");
                end = moment().format("YYYY-MM-DD");
            }

            vm.chartError.payloadreduction[domain] = false;
            vm.charts[domain].payloadreduction.data.rows = [];
            ieStats.postImagePayloadReduction(vm.data[domain].type, domain, start, end).then(function(response) {
                vm.charts[domain].payloadreduction.data.rows.push({
                    c: [
                        {v: "Original"},
                        {v: response.data.without_ie},
                        {v: "#1b4886"},
                        {v: response.data.without_ie+" GB"}
                    ]
                });
                vm.charts[domain].payloadreduction.data.rows.push({
                    c: [
                        {v: 'ImageEngine'},
                        {v: response.data.with_ie},
                        {v: "#7baa31"},
                        {v: response.data.with_ie+" GB"},
                        {v: response.data.savings+"% savings"}
                    ]
                });
            }).catch(function(error) {
                console.log(error.data);
                vm.chartError.payloadreduction[domain] = true;
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-payloadreduction-'+domain
                });
            });
        }

        function refreshRequests(domain) {
            bsLoadingOverlayService.start({
                referenceId: 'ie-requests-'+domain
            });
            var start = null;
            var end = null;
            if (vm.selectedRange.requests[domain] === 'd') {
                start = moment().subtract(7, 'days').format("YYYY-MM-DD");
                end = moment().format("YYYY-MM-DD");
            }

            vm.chartError.requests[domain] = false;
            vm.charts[domain].requests.data.rows = [];
            ieStats.postRequests(vm.data[domain].type, domain, start, end).then(function(response) {
                vm.charts[domain].requests.data.rows = [];
                angular.forEach(response.data, function(val, key) {
                    vm.charts[domain].requests.data.rows.push({
                        c: [
                            {v: val.date},
                            {v: val.count}
                        ]
                    });
                });
            }).catch(function(error) {
                console.log(error);
                vm.chartError.requests[domain] = true;
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-requests-'+domain
                });
            });
        }

        function refreshTopCountries(domain) {
            bsLoadingOverlayService.start({
                referenceId: 'ie-topcountries-'+domain
            });
            var start = null;
            var end = null;
            if (vm.selectedRange.topcountries[domain] === 'd') {
                start = moment().subtract(7, 'days').format("YYYY-MM-DD");
                end = moment().format("YYYY-MM-DD");
            }

            vm.chartError.topcountries[domain] = false;
            vm.charts[domain].topcountries.data.rows = [];
            ieStats.postTopCountries(vm.data[domain].type, domain, start, end).then(function(response) {
                angular.forEach(response.data, function(val, key) {
                    vm.charts[domain].topcountries.data.rows.push({
                        c: [
                            {v: val.country},
                            {v: val.count}
                        ]
                    });
                });
            }).catch(function(error) {
                console.log(error.data);
                vm.chartError.topcountries[domain] = true;
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-topcountries-'+domain
                });
            });
        }

        function refreshTopMissed(domain) {
            bsLoadingOverlayService.start({
                referenceId: 'ie-topmissed-'+domain
            });
            var start = null;
            var end = null;
            if (vm.selectedRange.topmissed[domain] === 'd') {
                start = moment().subtract(7, 'days').format("YYYY-MM-DD");
                end = moment().format("YYYY-MM-DD");
            }

            vm.chartError.topmissed[domain] = false;
            vm.topMissed[domain] = [];
            ieStats.postTopMissedUri(vm.data[domain].type, domain, start, end).then(function(response) {
                vm.topMissed[domain] = _.sortBy(response.data, 'doc_count').reverse();
            }).catch(function(error) {
                console.log(error.data);
                vm.chartError.topmissed[domain] = true;
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-topmissed-'+domain
                });
            });
        }

        function refreshTopRequests(domain) {
            bsLoadingOverlayService.start({
                referenceId: 'ie-toprequests-'+domain
            });
            var start = null;
            var end = null;
            if (vm.selectedRange.toprequests[domain] === 'd') {
                start = moment().subtract(7, 'days').format("YYYY-MM-DD");
                end = moment().format("YYYY-MM-DD");
            }

            vm.chartError.toprequests[domain] = false;
            vm.topRequests[domain].chunks = [];
            vm.topRequests[domain].selected = 0;
            vm.topRequests[domain].range = [1, 2, 3, 4];
            vm.topRequests[domain].direction = 'desc';
            ieStats.postTopRequestUri(vm.data[domain].type, domain, start, end).then(function(response) {
                vm.topRequests[domain].chunks = _.chunk(_.sortBy(response.data, 'count').reverse(), 10);
            }).catch(function(error) {
                console.log(error.data);
                vm.chartError.toprequests[domain] = true;
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-toprequests-'+domain
                });
            });
        }

        function refreshUsageTrends(domain) {
            bsLoadingOverlayService.start({
                referenceId: 'ie-usagetrends-'+domain
            });
            var start = null;
            var end = null;
            if (vm.selectedRange.usagetrends[domain] === 'd') {
                start = moment().subtract(7, 'days').format("YYYY-MM-DD");
                end = moment().format("YYYY-MM-DD");
            }

            vm.chartError.usagetrends[domain] = false;
            vm.charts[domain].usagetrendsos.data.rows = [];
            vm.charts[domain].usagetrendsform.data.rows = [];
            vm.charts[domain].usagetrendsphone.data.rows = [];
            ieStats.postUsageTrends(vm.data[domain].type, domain, start, end).then(function(response) {
                angular.forEach(response.data.os, function(val, key) {
                    vm.charts[domain].usagetrendsos.data.rows.push({
                        c: [
                            {v: key},
                            {v: val}
                        ]
                    });
                });
                angular.forEach(response.data.form_factor, function(val, key) {
                    vm.charts[domain].usagetrendsform.data.rows.push({
                        c: [
                            {v: key},
                            {v: val}
                        ]
                    });
                });
                angular.forEach(response.data.smartphone, function(val, key) {
                    vm.charts[domain].usagetrendsphone.data.rows.push({
                        c: [
                            {v: key},
                            {v: val}
                        ]
                    });
                });
            }).catch(function(error) {
                console.log(error);
                vm.chartError.usagetrends[domain] = true;
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-usagetrends-'+domain
                });
            });
        }

        function setCharts(domain, val) {
            var charts = {};

            if (val.cachehitratio.total !== 0) {
                var cacheHitRatio = {};
                cacheHitRatio.type = 'BarChart';
                cacheHitRatio.data = {
                    "cols": [
                        {id: "t", label: "Cache", type: "string"},
                        {id: "s", type: "number"},
                        {role: "style", type: "string"},
                        {role: 'annotation', type: "string"}
                    ],
                    "rows": [
                        {
                            c: [
                                {v: "Cache hit"},
                                {v: val.cachehitratio.hits},
                                {v: "#1b4886"},
                                {v: val.cachehitratio.hits_percentage+"%"}
                            ]
                        },
                        {
                            c: [
                                {v: "Cache miss"},
                                {v: val.cachehitratio.miss},
                                {v: "#7baa31"},
                                {v: val.cachehitratio.miss_percentage+"%"}
                            ]
                        }
                    ]
                };
                cacheHitRatio.options = {
                    legend: {position: 'none'},
                    height: 600,
                    hAxis: {
                        title: 'Counts'
                    }
                };
                charts.cachehitratio = cacheHitRatio;
            } else {
                charts.cachehitratio = null;
            }

            if (val.payloadreduction.without_ie !== 0) {
                var payloadReduction = {};
                payloadReduction.type = 'BarChart';
                payloadReduction.data = {
                    "cols": [
                        {id: "t", label: "Image Payload", type: "string"},
                        {id: "s", type: "number"},
                        {role: "style", type: "string"},
                        {role: 'annotation', type: "string"},
                        {role: 'annotation', type: "string"}
                    ],
                    "rows": [
                        {
                            c: [
                                {v: "Original"},
                                {v: val.payloadreduction.without_ie},
                                {v: "#1b4886"},
                                {v: val.payloadreduction.without_ie+" GB"}
                            ]
                        },
                        {
                            c: [
                                {v: 'ImageEngine'},
                                {v: val.payloadreduction.with_ie},
                                {v: "#7baa31"},
                                {v: val.payloadreduction.with_ie+" GB"},
                                {v: val.payloadreduction.savings+"% savings"}
                            ]
                        }
                    ]
                };
                payloadReduction.options = {
                    legend: {position: 'none'},
                    height: 600,
                    hAxis: {
                        title: 'Image Payload (GB)'
                    }
                };
                charts.payloadreduction = payloadReduction;
            } else {
                charts.payloadreduction = null;
            }

            if (!_.every(_.pluck(val.bandwidthsaved, 'bandwidth_orig'), function(val) {
                return val === 0;
            })) {
                var bytesSaved = {};
                bytesSaved.type = 'LineChart';
                bytesSaved.data = {
                    cols: [
                        {
                            id: "date",
                            label: "Date",
                            type: "string"
                        },
                        {
                            id: "bandwidth_orig",
                            label: "Original",
                            type: "number"
                        },
                        {
                            id: "bandwidth",
                            label: "ImageEngine",
                            type: "number"
                        }
                    ],
                    rows: []
                };

                angular.forEach(val.bandwidthsaved, function(band, key) {
                    bytesSaved.data.rows.push({
                        c: [
                            {v: band.date},
                            {v: band.bandwidth_orig},
                            {v: band.bandwidth}
                        ]
                    });
                });
                bytesSaved.options = {
                    height: 600,
                    vAxis: {
                        title: 'Bandwidth (GB)'
                    },
                    series: {
                        0: { color: "#1b4886" },
                        1: { color: "#7baa31" }
                    }
                };
                charts.bandwidthsaved = bytesSaved;
            } else {
                charts.bandwidthsaved = null;
            }

            if (!_.every(_.pluck(val.imageformat, 'original'), function(val) {
                return val === 0;
            })) {
                var imageFormats = {};
                imageFormats.type = 'BarChart';
                imageFormats.data = {
                    cols: [
                        {id: "format", label: "Image Format", type: "string"},
                        {id: "orig", type: "number", label: "Original"},
                        {id: "ie", type: "number", label: "ImageEngine"},
                        {role: 'annotation', type: "string"}
                    ],
                    rows: []
                };
                angular.forEach(val.imageformat, function(form, key) {
                    imageFormats.data.rows.push({
                        c: [
                            {v: form.format},
                            {v: form.original},
                            {v: form.ie},
                            {v: form.savings+"% savings"}
                        ]
                    });
                });
                imageFormats.options = {
                    height: 600,
                    hAxis: {
                        title: 'Image Payload (MB)'
                    },
                    vAxis: {
                        title: 'Image Format'
                    },
                    series: {
                        0: { color: "#1b4886" },
                        1: { color: "#7baa31" }
                    }
                };
                charts.imageformat = imageFormats;
            } else {
                charts.imageformat = null;
            }

            if (!_.every(_.pluck(val.requests, 'count'), function(val) {
                return val === 0;
            })) {
                var requests = {};
                requests.type = 'LineChart';
                requests.data = {
                    cols: [
                        {
                            id: "date",
                            label: "Date",
                            type: "string"
                        },
                        {
                            id: "count",
                            label: "Requests",
                            type: "number"
                        }
                    ],
                    rows: []
                };

                angular.forEach(val.requests, function(request, key) {
                    requests.data.rows.push({
                        c: [
                            {v: request.date},
                            {v: request.count}
                        ]
                    });
                });
                requests.options = {
                    height: 600,
                    series: {
                        0: { color: "#7baa31" }
                    }
                };
                charts.requests = requests;
            } else {
                charts.requests = null;
            }

            if (!_.isEmpty(val.usagetrends.os)) {
                var usageTrendsOs = {};
                usageTrendsOs.type = 'PieChart';
                usageTrendsOs.data = {
                    cols: [
                        {id: 'os', label: 'OS', type: 'string'},
                        {id: 'val', label: 'Counts', type: 'number'}
                    ],
                    rows: []
                };
                angular.forEach(val.usagetrends.os, function(val, key) {
                    usageTrendsOs.data.rows.push({
                        c: [
                            { v: key },
                            { v: val }
                        ]
                    });
                });

                usageTrendsOs.options = {
                    title: 'Top 10 OS',
                    height: 600
                };

                charts.usagetrendsos = usageTrendsOs;
            } else {
                charts.usagetrendsos = null;
            }

            if (!_.isEmpty(val.usagetrends.form_factor)) {
                var usageTrendsForm = {};
                usageTrendsForm.type = 'PieChart';
                usageTrendsForm.data = {
                    cols: [
                        {id: 'os', label: 'OS', type: 'string'},
                        {id: 'val', label: 'Counts', type: 'number'}
                    ],
                    rows: []
                };
                angular.forEach(val.usagetrends.form_factor, function(val, key) {
                    usageTrendsForm.data.rows.push({
                        c: [
                            { v: key },
                            { v: val }
                        ]
                    });
                });

                usageTrendsForm.options = {
                    title: 'Top Form Factors',
                    height: 600
                };

                charts.usagetrendsform = usageTrendsForm;
            } else {
                charts.usagetrendform = null;
            }

            if (!_.isEmpty(val.usagetrends.smartphone)) {
                var usageTrendsPhone = {};
                usageTrendsPhone.type = 'PieChart';
                usageTrendsPhone.data = {
                    cols: [
                        {id: 'os', label: 'OS', type: 'string'},
                        {id: 'val', label: 'Counts', type: 'number'}
                    ],
                    rows: []
                };
                angular.forEach(val.usagetrends.smartphone, function(val, key) {
                    usageTrendsPhone.data.rows.push({
                        c: [
                            { v: key },
                            { v: val }
                        ]
                    });
                });

                usageTrendsPhone.options = {
                    title: 'Top 10 Smart Phones',
                    height: 600
                };

                charts.usagetrendsphone = usageTrendsPhone;
            } else {
                charts.usagetrendphone = null;
            }

            if (!_.isNull(val.topcountries) && !_.every(_.pluck(val.topcountries, 'count'), function(val) {
                return val === 0;
            })) {
                var topCountries = {};
                topCountries.type = 'GeoChart';
                topCountries.data = {
                    cols: [
                        {id: "c", label: "Country", type: "string"},
                        {id: "n", label: "Requests", type: "number"}
                    ],
                    rows: []
                };

                angular.forEach(val.topcountries, function(country, key) {
                    topCountries.data.rows.push({
                        c: [
                            {v: country.country},
                            {v: country.count}
                        ]
                    });
                });
                topCountries.options = {
                    title: 'Top 10 End User Countries',
                    height: 600
                };
                charts.topcountries = topCountries;
            } else {
                charts.topcountries = null;
            }
            vm.charts[domain] = charts;
        }

        function setChartAccordion() {
            var accordion = {};
            angular.forEach(charts, function(chart) {
                accordion[chart] = {};
                angular.forEach(_.keys(resolveData), function(val) {
                    accordion[chart][val] = true;
                });
            });

            return accordion;
        }

        function setChartError() {
            var error = {};
            angular.forEach(charts, function(chart) {
                error[chart] = {};
                angular.forEach(_.keys(resolveData), function(val) {
                    error[chart][val] = false;
                });
            });

            return error;
        }

        function setChartDownload() {
            var download = {};
            var c = ['cachehitratio', 'payloadreduction', 'bandwidthsaved', 'imageformat', 'requests', 'usagetrendsos', 'usagetrendsform', 'usagetrendsphone', 'topcountries'];

            angular.forEach(c, function(chart) {
                download[chart] = {};
                angular.forEach(_.keys(vm.data), function(val) {
                    download[chart][val] = null;
                });
            });

            return download;
        }

        function setRange() {
            var range = {};
            angular.forEach(charts, function(chart) {
                range[chart] = {};
                angular.forEach(_.keys(vm.data), function(val) {
                    range[chart][val] = 'h';
                });
            });

            return range;
        }

        function setOpen() {
            var open = {};
            angular.forEach(_.keys(vm.data), function(val) {
                open[val] = false;
            });

            return open;
        }

        function setSelectedTopRequests() {
            var requests = {};
            angular.forEach(vm.data, function(data, domain) {
                requests[domain] = 0;
            });

            return requests;
        }

        function setTopMissed() {
            angular.forEach(vm.data, function(data, domain) {
                vm.topMissed[domain] = _.sortBy(data.topmissed, 'doc_count').reverse();
            });
        }

        function setTopRequests() {
            angular.forEach(vm.data, function(data, domain) {
                vm.topRequests[domain] = {};
                vm.topRequests[domain].chunks = _.chunk(_.sortBy(data.topuri, 'count').reverse(), 10);
                vm.topRequests[domain].selected = 0;
                vm.topRequests[domain].range = [1, 2, 3, 4];
                vm.topRequests[domain].direction = 'desc';
            });
        }

        function sortTopRequests(domain) {
            switch (vm.topRequests[domain].direction) {
                case 'asc':
                default:
                    vm.topRequests[domain].direction = 'desc';
                    break;
                case 'desc':
                    vm.topRequests[domain].direction = 'asc';
                    break;
            }
            var data = _.sortBy(vm.data[domain].topuri, 'count');
            if (vm.topRequests[domain].direction == 'desc') {
                data.reverse();
            }

            vm.topRequests[domain].chunks = _.chunk(data, 10);
        }

        function toggleAccordion(domain) {
            if (!vm.running) {
                vm.open[domain] = !vm.open[domain];

                if (vm.open[domain]) {
                    if (_.isEmpty(vm.charts) || _.indexOf(_.keys(vm.charts), domain) === -1) {
                        vm.charts[domain] = {};
                        var params = {
                            id: $stateParams.id,
                            action_id: domain,
                            other: vm.data[domain].type
                        };
                        if (!vm.data[domain].show && vm.data[domain].show7) {
                            params.show = 'week';
                            angular.forEach(vm.selectedRange, function(val, key) {
                                vm.selectedRange[key][domain] = 'd';
                            });
                        }
                        vm.running = true;
                        bsLoadingOverlayService.start({
                            referenceId: 'ie-stats-'+domain
                        });
                        ieService.getStatisticsByIdentifier(params)
                            .then(function(response) {
                                setCharts(domain, response.data);
                                vm.topMissed[domain] = _.sortBy(response.data.topmissed, 'doc_count').reverse();
                                vm.topRequests[domain] = {};
                                vm.topRequests[domain].chunks = _.chunk(_.sortBy(response.data.topuri, 'count').reverse(), 10);
                                vm.topRequests[domain].selected = 0;
                                vm.topRequests[domain].range = [1, 2, 3, 4];
                                vm.topRequests[domain].direction = 'desc';
                            }).catch(function(error) {
                                console.log(error);
                            }).finally(function() {
                                vm.running = false;
                                bsLoadingOverlayService.stop({
                                    referenceId: 'ie-stats-'+domain
                                });
                            });
                    }
                }
            }
        }

        function updateTopRequests(domain, index) {
            if (index < 1) {
                index = 1;
            } else if (index > 10) {
                index = 10;
            }

            var range = null;
            if (index <= 2) {
                range = [1, 2, 3, 4];
            } else if (index >= 8) {
                range = [7, 8, 9, 10]
            } else {
                range = [];
                for (var i = index - 1; i <= index + 2; i++) {
                    range.push(i);
                }
            }

            vm.topRequests[domain].selected = index - 1;
            vm.topRequests[domain].range = range;
        }
    }
})();
