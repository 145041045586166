(function() {
    'use strict';

    angular.module('customerVault.capabilities')
        .factory('wurflCapabilitiesService', wurflCapabilitiesService);

    wurflCapabilitiesService.$inject = ['$resource'];

    function wurflCapabilitiesService($resource) {
        var resource = new $resource('/api/vault/capabilities/:action', {}, {
            getCapabilities: {
                method: 'GET',
                params: {
                    action: null
                }
            },
            postDownloadList: {
                method: 'POST',
                params: {
                    action: 'download'
                }
            }
        })
        var service = {
            getCapabilities: getCapabilities,
            postDownloadList: postDownloadList
        };

        return service;

        function getCapabilities() {
            return resource.getCapabilities().$promise;
        }

        function postDownloadList(type) {
            return resource.postDownloadList({type: type}).$promise;
        }
    }
})();
