(function() {
    angular.module('authApp')
        .factory('pendingRequestsService', pendingRequestsService);

    function pendingRequestsService() {
        var service = {
            add: add,
            cancelAll: cancelAll,
            get: get,
            remove: remove
        };

        var pending = [];

        return service;

        function add(request) {
            pending.push(request);
        }

        function cancelAll() {
            angular.forEach(pending, function(p) {
                p.canceller.resolve();
            });
            pending.length = 0;
        }

        function get() {
            return pending;
        }

        function remove(request) {
            pending = _.filter(pending, function(p) {
                return p.url !== request;
            });
        }
    }
})();
