(function() {
    'use strict';

    angular.module('customerVault.wurfljs')
        .controller('WurfljsCapabilitiesController', WurfljsCapabilitiesController);

    WurfljsCapabilitiesController.$inject = ['Notification', 'wurfljsResolve', 'wurfljsService'];

    function WurfljsCapabilitiesController(Notification, wurfljsResolve, wurfljsService) {
        var vm = this;
        var resolveData = wurfljsResolve.load.capabilities;
        var error = wurfljsResolve.error;

        vm.capabilities = resolveData.capabilities;
        vm.error = false;
        vm.pageErrorView = 'view/errors.400';

        if (!_.isEmpty(error)) {
            vm.error = true;
        }
    }
})();
