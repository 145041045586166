(function() {
    'use strict';

    angular.module('customerVault.zendesk')
        .factory('zendeskService', zendeskService);

    zendeskService.$inject = ['$resource'];

    function zendeskService($resource) {
        var resource = new $resource('/api/vault/zendesk/:action', {}, {
            postAuth: {
                method: 'POST',
                params: {
                    action: 'auth'
                }
            }
        });
        var service = {
            postAuth: postAuth
        };

        service.return_to = null;

        return service;

        function postAuth(userId) {
            return resource.postAuth({user_id: userId, return_to: service.return_to}).$promise;
        }
    }
})();
