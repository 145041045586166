(function() {
    'use strict';

    angular.module('customerVault.capabilities')
        .controller('WurflCapabilitiesController', WurflCapabilitiesController);

    WurflCapabilitiesController.$inject = ['$scope', '$timeout', '$window', 'bsLoadingOverlayService', 'capabilitiesResolve', 'wurflCapabilitiesService'];

    function WurflCapabilitiesController($scope, $timeout, $window, bsLoadingOverlayService, capabilitiesResolve, wurflCapabilitiesService) {
        var vm = this;

        var resolveData = capabilitiesResolve.load;
        var error = capabilitiesResolve.error;

        vm.download = download;
        vm.error = false;
        vm.static = angular.copy(resolveData.static);
        vm.staticFiltered = _.flatten(_.toArray(vm.static)).length;
        vm.staticSearch = null;
        vm.staticTotal = _.flatten(_.toArray(resolveData.static)).length
        vm.virtual = angular.copy(resolveData.virtual);
        vm.virtualFiltered = vm.virtual.length;
        vm.virtualSearch = null;
        vm.virtualTotal = resolveData.virtual.length;

        if (!_.isEmpty(error)) {
            vm.error = true;
            return;
        }

        $scope.$watch('vm.virtualSearch', function(newVal) {
            if (!_.isNull(newVal)) {
                if (newVal === '') {
                    vm.virtual = angular.copy(resolveData.virtual);
                } else {
                    $timeout(function() {
                        vm.virtual = _.filter(resolveData.virtual, function(obj) {
                            return obj.name.indexOf(newVal) != -1 || obj.description.indexOf(newVal) != -1;
                        });
                        vm.virtualFiltered = vm.virtual.length;
                    }, 500);
                }
            }
        });

        $scope.$watch('vm.staticSearch', function(newVal) {
            if (!_.isNull(newVal)) {
                if (newVal === '') {
                    vm.static = angular.copy(resolveData.static);
                } else {
                    $timeout(function() {
                        var groups = _.keys(vm.static);
                        var filteredGroups = _.filter(groups, function(val) {
                            return val.toLowerCase().indexOf(newVal.toLowerCase()) != -1;
                        });
                        var filtered = {};
                        angular.forEach(filteredGroups, function(val, key) {
                            filtered[val] = (resolveData.static[val]);
                        });
                        angular.forEach(resolveData.static, function(val, key) {
                            if (_.indexOf(filteredGroups, key) == -1) {
                                var filteredValues = _.filter(val, function(obj) {
                                    return obj.name.toLowerCase().indexOf(newVal.toLowerCase()) != -1 || obj.kind_info.toLowerCase().indexOf(newVal.toLowerCase()) != -1 || obj.description.toLowerCase().indexOf(newVal.toLowerCase()) != -1;
                                });
                                if (!_.isEmpty(filteredValues)) {
                                    filtered[key] = filteredValues; // TODO Keep all in group if any match?
                                }
                            }
                        });
                        vm.static = filtered;
                        vm.staticFiltered = _.flatten(_.toArray(vm.static)).length;
                    }, 500);
                }
            }
        });

        function download(type) {
            bsLoadingOverlayService.start({
                referenceId: 'wurfl-capabilities-download'
            });
            wurflCapabilitiesService.postDownloadList(type).then(function(response) {
                if (type == 'virtual' || type == 'all') {
                    var blob = new Blob([response.data.virtual], {type: 'text/csv'}),
                        url = $window.URL || $window.webkitURL;
                    var a = $("<a style='display: none;'/>");
                    var url = url.createObjectURL(blob);
                    a.attr("href", url);
                    a.attr("download", "virtual.csv");
                    $("body").append(a);
                    a[0].click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                }

                if (type == 'static' || type == 'all') {
                    var blob = new Blob([response.data.static], {type: 'text/csv'}),
                        url = $window.URL || $window.webkitURL;
                    var a = $("<a style='display: none;'/>");
                    var url = url.createObjectURL(blob);
                    a.attr("href", url);
                    a.attr("download", "static.csv");
                    $("body").append(a);
                    a[0].click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                }
            }).catch(function(error) {
                console.log(error);
                Notification.error("Unable to download WURFL capabilities at this time. Please contact support@scientiamobile.com for assistance.");
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'wurfl-capabilities-download'
                });
            });
        }
    }
})();
