(function() {
    'use strict';

    angular.module('customerVault.home')
        .controller('RegistrationController', RegistrationController);

    RegistrationController.$inject = ['$state', '$stateParams', '$uibModal', '$window', 'authenticate', 'bsLoadingOverlayService', 'flashMessage', 'homeActions', 'homeService', 'loaderService', 'loginService', 'Notification', 'registerResolve', 'vcRecaptchaService'];

    function RegistrationController($state, $stateParams, $uibModal, $window, authenticate, bsLoadingOverlayService, flashMessage, homeActions, homeService, loaderService, loginService, Notification, registerResolve, vcRecaptchaService) {
        var vm = this;
        var resolveData = registerResolve.load;
        var error = registerResolve.error;
        var widgetId = null;

        loaderService.close();

        vm.check = check;
        vm.countries = resolveData.countries;
        vm.description = resolveData.description;
        vm.error = false;
        vm.errors = homeService.registrationErrorsForm();
        vm.evaluation = $stateParams.evaluation;
        vm.form = {
            captcha: null,
            company_name: null,
            consent: false,
            country: _.findWhere(vm.countries, {country_id: 230}),
            email: null,
            first_name: null,
            last_name: null,
            password: null,
            passwordConfirm: null,
            terms: false,
            username: null,
            country_id: null
        };
        vm.onWidgetCreate = onWidgetCreate;
        vm.pageErrorView = 'view/errors.400';
        vm.publicKey = resolveData.publicKey;
        vm.redirect = false;
        vm.register = register;
        vm.signIn = signIn;
        vm.terms = false;
        vm.title = 'Register';

        if (!_.isEmpty(error)) {
            vm.error = true;
        }

        if (loginService.authInfo.authenticated) {
            vm.redirect = true;
            vm.pageErrorView = 'view/components.303';
            $state.go('myaccount');
            return;
        }

        var forapi = null;
        if ($stateParams.evaluation) {
            forapi = loginService.intended.params.forapi;
            vm.title = "First You Need To Register To Our Site";
        }

        function check(type) {
            if (type == 'terms') {
                vm.form.terms = !vm.form.terms;
                vm.errors.terms = false;
            } else {
                vm.form.consent = !vm.form.consent;
                vm.errors.consent = false;
            }
        }

        function onWidgetCreate(_widgetId) {
            widgetId = _widgetId;
        }

        function register() {
            var captcha = vcRecaptchaService.getResponse();
            if (captcha === "") {
                Notification.error("Please resolve the captcha before submitting");
                return;
            }

            vm.form.captcha = captcha;
            vm.errors = homeService.registrationErrorsForm()

            vm.form.country_id = vm.form.country.country_id;
            bsLoadingOverlayService.start({
                referenceId: 'registration-form'
            });
            homeService.postRegistration(vm.form).then(function(response) {
                loginService.intended = undefined;
                flashMessage.message.message = "<h4>Thank you for registering with ScientiaMobile!</h4><br /><h6>Please verify your email address before logging in.</h6>";
                flashMessage.message.show = true;
                flashMessage.message.class = 'alert-success';
                flashMessage.message.dismissible = false;
                $state.go('login');
            }).catch(function(error) {
                vcRecaptchaService.reload(widgetId);
                switch (error.status) {
                    case 412:
                        angular.forEach(error.data.data, function(val, key) {
                            if (!_.isUndefined(vm.errors[key].show)) {
                                vm.errors[key].show = true;
                                vm.errors[key].message = val.join("<br />");
                            } else {
                                vm.errors[key] = true;
                            }
                        });
                        $window.scrollTo(0, 0);
                        break;
                    case '403':
                        Notification.error(error.data.message);
                        console.log(error);
                        break;
                    default:
                        if (error.data.data.verify) {
                            flashMessage.message.message = "<h4>Thank you for registering with ScientiaMobile!</h4><br /><h6>An error occurred while sending your verification email. Please contact support@scientiamobile.com for assistance before logging in.</h6>";
                            flashMessage.message.show = true;
                            flashMessage.message.class = 'alert-warning';
                            flashMessage.message.dismissible = false;
                            $state.go('login');
                        } else {
                            Notification.error("We are unable to create your account at this time. Please contact support@scientiamobile.com for assistance.");
                        }
                        console.log(error);
                }
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'registration-form'
                });
            });
        }

        function signIn() {
            homeActions.openSignIn();
        }
    }
})();
