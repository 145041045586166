(function() {
    'use strict';

    angular.module('customerVault')
        .factory('reloadService', reloadService);

    reloadService.$inject = ['$rootScope', '$state'];

    function reloadService($rootScope, $state) {
        var service = {
            reload: reload
        };

        return service;

        function reload() {
            var state = $state.current.name.split('.');
            switch (state[0]) {
                case 'cloud':
                    switch (state[1]) {
                        case 'signup':
                            $rootScope.$broadcast('exitImpersonationCloudSignup');
                            break;
                        default:
                            $rootScope.$broadcast('reloadCloudBreadcrumbs');
                    }
                    break;
                case 'ie':
                    switch (state[1]) {
                        case 'payment':
                            $rootScope.$broadcast('updatePaymentBreadcrumbs');
                            break;
                        case 'signup':
                            $rootScope.$broadcast('exitImpersonationIeSignup');
                            break;
                        default:
                            $rootScope.$broadcast('reloadIeBreadcrumbs');
                    }
                    break;
                case 'insight':
                    $rootScope.$broadcast('reloadInsightBreadcrumbs');
                    break;
                case 'license':
                    $rootScope.$broadcast('reloadLicenseBreadcrumbs');
                    break;
                case 'wurfljs':
                    switch (state[1]) {
                        case 'signup':
                            $rootScope.$broadcast('exitImpersonationWurfljsSignup');
                            break;
                        default:
                            $rootScope.$broadcast('reloadWurfljsBreadcrumbs');
                    }
                    break;
                case 'account':
                case 'myaccount':
                    $rootScope.$broadcast('reloadAccountBreadcrumbs');
                    break;
                default:
                    return;
            }
        }
    }
})();
