(function() {
    'use strict';

    angular.module('customerVault')
        .factory('filterManager', filterManager);

    filterManager.$inject = [];

    function filterManager() {
        var service = {
            exportFilter: exportFilter
        };

        service.range = {};
        service.rangeManager = '';
        service.region = null;

        return service;

        function exportFilter() {
            return {
                region: service.region,
                range: service.range,
                rangeMessage: service.rangeMessage
            };
        }
    }
})();
