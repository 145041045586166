(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .controller('CloudCapabilitiesTabController', CloudCapabilitiesTabController);

    CloudCapabilitiesTabController.$inject = ['$rootScope', '$scope', '$stateParams', 'bsLoadingOverlayService', 'cloudActions', 'cloudCapabilitiesService', 'cloudResolve', 'cloudService', 'Notification'];

    function CloudCapabilitiesTabController($rootScope, $scope, $stateParams, bsLoadingOverlayService, cloudActions, cloudCapabilitiesService, cloudResolve, cloudService, Notification) {
        var vm = this;
        var resolveData = cloudResolve.load.capabilities;
        var error = cloudResolve.error;

        vm.accountCapabilities = resolveData.accountCapabilities;
        vm.addonsAllowed = resolveData.addonsAllowed;
        vm.addToList = addToList;
        vm.allowAdd = allowAdd;
        vm.capabilityCount = null;
        vm.changeExists = false;
        vm.currentCapabilities = resolveData.currentCapabilities;
        vm.error = false;
        vm.filter = filter;
        vm.filteredCapabilities = [];
        vm.groupedCapabilities = resolveData.groupedCapabilities;
        vm.pageErrorView = 'view/errors.400';
        vm.reorder = reorder;
        vm.reset = reset;
        vm.save = save;
        vm.search = null;
        vm.selectedGroup = resolveData.selectedGroup;
        vm.showDescription = showDescription;
        vm.stats = resolveData.stats;
        vm.subscription = resolveData.subscription;
        vm.upgrade = upgrade;

        cloudCapabilitiesService.reset();
        cloudCapabilitiesService.variables.originalList = resolveData.originalList;
        cloudCapabilitiesService.variables.currentList = resolveData.currentList;

        if (!_.isEmpty(error)) {
            vm.error = true;
        } else {
            filterList(false);
        }

        $scope.$watch('vm.search', function(newVal) {
            if (!cloudCapabilitiesService.variables.bypass) {
                if (!_.isNull(newVal) && newVal != '') {
                    filterListSearch();
                    vm.selectedGroup = 'all';
                } else {
                    filterList(true);
                }
            } else {
                cloudCapabilitiesService.variables.bypass = false;
            }
        });

        function addToList(capability, add) {
            if (add) {
                vm.currentCapabilities.push(cloudActions.addCapabilities(capability, add, vm.currentCapabilities, vm.stats.max_capabilities));
                compare();
            } else {
                vm.currentCapabilities = _.reject(vm.currentCapabilities, function(obj) {
                    return obj.capability_id == capability.capability_id;
                });
                vm.currentCapabilities = cloudActions.addCapabilities(capability, add, vm.currentCapabilities, vm.stats.max_capabilities);
                compare();
            }
        }

        function allowAdd(capability) {
            var inAdding = _.find(vm.currentCapabilities, function(obj) {
                return obj.capability_id == capability.id;
            });
            return _.isUndefined(inAdding);
        }

        function compare() {
            vm.changeExists = !angular.equals(cloudCapabilitiesService.variables.originalList, cloudCapabilitiesService.variables.currentList);
        }

        function filterList(all) {
            var filtered = [];
            angular.forEach(vm.groupedCapabilities, function(val, key) {
                if (all || vm.selectedGroup == val.id) {
                    filtered.push(_.toArray(val.wurfl_capabilities));
                }
            });
            filtered = _.flatten(filtered);
            vm.filteredCapabilities = filtered;
            vm.capabilityCount = vm.filteredCapabilities.length;
        }

        function filterListSearch() {
            var filtered = [];
            angular.forEach(vm.groupedCapabilities, function(val, key) {
                filtered.push(_.filter(val.wurfl_capabilities, function(obj) {
                    return obj.capability.indexOf(vm.search) != -1;
                }));
            });
            vm.filteredCapabilities = _.flatten(filtered);
            vm.capabilityCount = vm.filteredCapabilities.length;
        }

        function filter() {
            cloudCapabilitiesService.variables.bypass = true;
            vm.search = null;
            if (_.isNull(vm.selectedGroup)) {
                filterList(true);
            } else {
                filterList(false);
            }
        }

        function reorder() {
            var modalInstance = cloudActions.openCapabilityReorder(angular.copy(vm.currentCapabilities), vm.stats.max_capabilities, vm.currentCapabilities.length);
            modalInstance.result.then(function(response) {
                vm.currentCapabilities = response.$value;
                cloudCapabilitiesService.variables.currentList = angular.copy(response.$value);
                compare();
            });
        }

        function reset() {
            vm.currentCapabilities = angular.copy(vm.accountCapabilities);
            cloudCapabilitiesService.variables.currentList = angular.copy(cloudCapabilitiesService.variables.originalList);
            vm.changeExists = false;
        }

        function save() {
            angular.forEach(vm.currentCapabilities, function(val, key) {
                if (_.isUndefined(val.id)) {
                    val.id = false;
                }
            });
            bsLoadingOverlayService.start({
                referenceId: 'cloud-your-capabilities'
            });
            cloudService.postUpdateCapabilities($stateParams.id, vm.currentCapabilities).then(function(response) {
                Notification.success("Successfully updated capabilities");
                if (response.data.added.length) {
                    var addMessage = "Successfully added " + response.data.added.length + " capabilities!";
                    Notification.success(addMessage);
                }
                if (response.data.removed.length) {
                    var removeMessage = "Successfully removed " + response.data.removed.length + " capabilities!";
                    Notification.success(removeMessage);
                }
            }).catch(function(error) {
                console.log(error.data);
                Notification.error(error.data.message);
            }).finally(function() {
                vm.changeExists = false;
                bsLoadingOverlayService.stop({
                    referenceId: 'cloud-your-capabilities'
                });
                var active = _.where(vm.currentCapabilities, {active: 1}).length;
                var inactive = _.where(vm.currentCapabilities, {active: 0}).length;
                $rootScope.$broadcast('cloudUpdateActiveCapabilities', {active: active, inactive: inactive});
                cloudCapabilitiesService.variables.originalList = angular.copy(vm.currentCapabilities);
                cloudCapabilitiesService.variables.currentList = angular.copy(vm.currentCapabilities);
                vm.accountCapabilities = angular.copy(vm.currentCapabilities);
            });
        }

        function showDescription(capability) {
            cloudActions.openCapabilityDescription(capability, vm.groupedCapabilities);
        }

        function upgrade(type) {
            if (type) {
                $rootScope.$broadcast('cloudChangeTab', {tab: 'changePlan', other: {plan: 'wurfl-cloud'}});
            } else {
                $rootScope.$broadcast('cloudChangeTab', {tab: 'planInfo'});
            }
        }
    }
})();
