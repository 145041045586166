(function() {
    'use strict';

    angular.module('customerVault.capabilities')
        .factory('capabilitiesResolve', capabilitiesResolve);

    capabilitiesResolve.$inject = ['wurflCapabilitiesService'];

    function capabilitiesResolve(wurflCapabilitiesService) {
        var service = {
            error: {},
            load: {},
            run: run
        };

        return service;

        function run(args) {
            service.error = {};
            service.load = {};

            if (_.isEmpty(service.load)) {
                service.load = {};

                return wurflCapabilitiesService.getCapabilities().then(function(response) {
                    service.load.virtual = response.data.virtual;
                    service.load.static = response.data.static;
                    return true;
                }).catch(function(error) {
                    console.log(error);
                    service.error.status = error.status;
                    service.error.message = error.data.message;
                    return false;
                });
            }
        }
    }
})();
