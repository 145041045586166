(function() {
    'use strict';

    angular.module('customerVault.site')
        .controller('EvaluationController', EvaluationController);

    EvaluationController.$inject = ['$rootScope', '$state', '$stateParams', '$timeout', '$window', 'breadcrumbsService', 'bsLoadingOverlayService', 'loginService', 'myaccountResolve', 'Notification', 'signupService', 'siteResolve', 'siteService'];

    function EvaluationController($rootScope, $state, $stateParams, $timeout, $window, breadcrumbsService, bsLoadingOverlayService, loginService, myaccountResolve, Notification, signupService, siteResolve, siteService) {
        var vm = this;
        var resolveData = siteResolve.load;
        var error = siteResolve.error;

        vm.accept_eval = false;
        vm.api = null;
        vm.apiOptions = resolveData.apiOptions;
        vm.authenticated = loginService.authInfo.authenticated;
        vm.check = check;
        vm.description = resolveData.description;
        vm.error = false;
        vm.errorClass = {
            check: '',
            select: ''
        };
        vm.signIn = signIn;
        vm.steps = vm.authenticated ? breadcrumbsService.get('site.evaluation') : null;
        vm.submit = submit;

        $window.scrollTo(0, angular.element('api-select').offsetTop);

        $rootScope.$on('authenticated', function() {
            vm.authenticated = loginService.authInfo.authenticated;
            if (vm.authenticated) {
                vm.steps = breadcrumbsService.get('site.evaluation');
            } else {
                vm.steps = null;
            }
        });

        if (!_.isNull($stateParams.forapi)) {
            var selected = _.findWhere(vm.apiOptions, {id: $stateParams.forapi});
            if (!_.isNull(selected)) {
                vm.api = $stateParams.forapi;
            }
        }

        if (!_.isEmpty(error)) {
            vm.error = true;
        }

        function check() {
            vm.accept_eval = !vm.accept_eval;
            vm.errorClass.check = false;
        }

        function signIn() {
            signupService.openSignup();
        }

        function submit() {
            vm.errorClass = {
                check: '',
                select: ''
            };
            if (_.isNull(vm.api)) {
                vm.errorClass.select = 'error';
                $window.scrollTo(0, angular.element('api-select').offsetTop);
                return;
            }

            if (!vm.accept_eval) {
                vm.errorClass.check = 'error';
                return;
            }
            bsLoadingOverlayService.start({
                referenceId: 'evaluation-form'
            });
            siteService.create({api: vm.api, accept_eval: vm.accept_eval}).then(function(response) {
                Notification.success(response.message);
                myaccountResolve.load = {};
                $timeout(function() {
                    $state.go('license.home', {id: response.data.id});
                }, 2000);
            }).catch(function(error) {
                Notification.error(error.data.message);
                console.log(error);
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'evaluation-form'
                });
            });
        }
    }
})();
