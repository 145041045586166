(function() {
    'use strict';

    angular.module('customerVault.layout')
        .controller('LayoutController', LayoutController);

    LayoutController.$inject = ['$rootScope', '$scope', '$state', 'filexService', 'ieResolve', 'impersonateService', 'loaderService', 'loginService', 'myaccountResolve', 'reloadService'];

    function LayoutController($rootScope, $scope, $state, filexService, ieResolve, impersonateService, loaderService, loginService, myaccountResolve, reloadService) {
        var vm = this;

        vm.clearMain = clearMain;
        vm.endImpersonation = endImpersonation;
        vm.expanded = false;
        vm.isImpersonate = loginService.impersonate.mode;
        vm.plan = null;
        vm.showPayment = false;
        vm.showTrial = false;
        vm.showRedirectBanner = false;
        vm.subscription = null;
        vm.trialEnds = null;
        vm.trialOver = false;
        vm.user = loginService.impersonate.user;

        $rootScope.$on('header-expand', function(event, args) {
            vm.expanded = args.expanded;
        });


        $rootScope.$on('showRedirectBanner', function(event, args) {
            vm.showRedirectBanner = args;
        });

        $rootScope.$on('checkPaymentState', function() {
            var state = $state.current.name.split(".");
            if (state[0] !== 'ie' || _.isUndefined(state[1]) || state[1] !== 'home') {
                vm.showPayment = false;
                vm.showTrial = false;
            } else if (
                !_.isUndefined(ieResolve.load.main.subscription) &&
                ieResolve.load.main.subscription.payment_type == 'TRIAL' &&
                ieResolve.load.main.subscription.payment_plan !== 'IMAGEENGINE_PRO' &&
                ieResolve.load.main.subscription.status !== 'CANCELED'
            ) {
                vm.showTrial = true;
                if (ieResolve.load.main.tabs.payment) {
                    vm.showPayment = true;
                }
                vm.subscription = ieResolve.load.main.subscription;
                vm.trialEnds = ieResolve.load.main.trialEnds;
                vm.trialOver = ieResolve.load.main.trialOver;
                vm.plan = 'subscription';
            } else if (
                !_.isUndefined(ieResolve.load.main.subscription) &&
                ieResolve.load.main.subscription.payment_type == 'TRIAL' &&
                ieResolve.load.main.subscription.payment_plan === 'IMAGEENGINE_PRO' &&
                ieResolve.load.main.subscription.status !== 'CANCELED'
            ) {
                vm.showTrial = true;
                vm.plan = 'pro';
                var trialEnds = moment(ieResolve.load.main.subscription.date_started).add(ieResolve.load.main.subscription.plan.trial_days - 1, 'days');
                vm.trialEnds = trialEnds.format("MMM Do, YYYY");
                vm.trialOver = trialEnds < moment();
            } else {
                vm.showTrial = false;
                vm.showPayment = false;
            }
        });

        var startImpersonate = $rootScope.$on('startImpersonate', function(event, args) {
            loaderService.close();
            loaderService.confirm();
            impersonateService.getBasicIsAdmin(args.user.id, 'account', args.user.id).then(function(response) {
                loginService.impersonate.mode = true;
                vm.isImpersonate = true;
                loginService.impersonate.user = args.user;
                loginService.impersonate.admin = response.data.admin;
                vm.user = loginService.impersonate.user;
                impersonateService.setPersist(loginService.impersonate.user);
                if (args.reload) {
                    $rootScope.$broadcast('reloadMyAccount');
                }
                reloadService.reload();
                if (args.home) {
                    loaderService.close();
                    $state.go('myaccount');
                }
                $rootScope.$broadcast('startImpersonateHeader');
            }).catch(function(error) {
                console.log(error);
            }).finally(function() {
                if (args.stop) {
                    loaderService.close();
                }
            });

            $scope.$on('$destroy', startImpersonate);
        });

        $rootScope.$on('endImpersonation', function() {
            loginService.impersonate.mode = false;
            vm.isImpersonate = false;
            loginService.impersonate.user = null;
            loginService.impersonate.admin = false;
            vm.user = loginService.impersonate.user;
            impersonateService.removePersist();
            $rootScope.$broadcast('endImpersonateHeader');
        });

        function clearMain() {
            ieResolve.load.main = {};
        }

        function endImpersonation() {
            var impersonateUser = angular.copy(loginService.impersonate.user);
            loaderService.close();
            loginService.impersonate.mode = false;
            vm.isImpersonate = false;
            loginService.impersonate.user = null;
            loginService.impersonate.admin = false;
            impersonateService.removePersist();
            reloadService.reload();
            $rootScope.$broadcast('endImpersonateHeader');
        }
    }
})();
