(function() {
    'use strict';

    angular.module('customerVault.home')
        .factory('passwordResetResolve', passwordResetResolve);

    passwordResetResolve.$inject = ['homeService'];

    function passwordResetResolve(homeService) {
        var service = {
            error: {},
            load: {},
            run: run
        };

        return service;

        function run(args) {
            service.error = {};

            if (_.isEmpty(service.load) || service.load.hash != args.params.hash) {
                service.load = {};
                return homeService.getPasswordReset(args.params.hash).then(function(response) {
                    service.load.reset = response.data.reset;
                    service.load.username = response.data.username;
                    return true;
                }).catch(function(error) {
                    service.error.status = error.status;
                    service.error.message = error.data.message;
                    service.error.data = error.data.data;
                    console.log(error);
                    return false;
                });
            } else {
                return Promise.resolve(true);
            }
        }
    }
})();
