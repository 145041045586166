(function() {
    'use strict';

    angular.module('customerVault')
        .directive('capabilityInfo', capabilityInfo);

    function capabilityInfo() {
        var directive = {
            restrict: 'EA',
            templateUrl: 'view/components.capability_info',
            replace: true,
            scope: {
                capability: '=',
                group: '@',
                showTitle: '@'
            },
            controller: controller
        };

        return directive;

        function controller($scope) {
            if (_.isUndefined($scope.capability.value_type)) {
                $scope.capability.value_type = $scope.capability.return_type;
            }
        }
    }
})();
