(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineSignupCompleteController', ImageEngineSignupCompleteController);

    ImageEngineSignupCompleteController.$inject = ['$state', '$stateParams', 'ieService', 'signupService'];

    function ImageEngineSignupCompleteController($state, $stateParams, ieService, signupService) {
        var vm = this;

        if (!$stateParams.fromSignup) {
            $state.go('myaccount');
            return;
        }

        Date.prototype.addDays = function(days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }

        var trialEnds = new Date(moment(ieService.transfer.subscription.date_started));

        vm.errors = ieService.transfer.errors;
        vm.message = signupService.value;
        vm.show = {
            domain: false,
            origin: false
        };
        vm.subscription = ieService.transfer.subscription;
        vm.trialEnds = trialEnds.addDays(vm.subscription.plan.trial_days);

        if (!vm.errors) {
            vm.show.domain = true;
            vm.show.origin = true;
        } else {
            if (_.indexOf(vm.errors, 'domain configuration') === -1) {
                vm.show.domain = true;
            }
            if (_.indexOf(vm.errors, 'origin') === -1) {
                vm.show.origin = true;
            }
        }
    }
})();
