(function() {
    'use strict';

    angular.module('customerVault.ie')
        .factory('ieChartService', ieChartService);

    ieChartService.$inject = ['$resource', 'filterManager', 'loginService', 'moment'];

    function ieChartService($resource, filterManager, loginService, moment) {
        var apiResource = new $resource('/api/vault/account/imageengine/:user_id/:id/:action', {id: '@id'}, {
            postBandwidthDetections: {
                method: 'POST',
                params: {
                    action: 'bandwidthdetections'
                }
            },
            postBytesSaved: {
                method: 'POST',
                params: {
                    action: 'bytessaved'
                }
            },
            postImageDetections: {
                method: 'POST',
                params: {
                    action: 'imagedetections'
                }
            }
        })

        var service = {
            fetchTimeData: fetchTimeData,
            prettyDate: prettyDate,
            prettyRangeDate: prettyRangeDate
        };

        service.isFree = null;
        service.periods = null;
        service.subscription = null;

        return service;

        function fetchTimeData(aspect, overrides, id) {
            var urlParams = {};

            var appFilter = _.defaults(overrides, filterManager.exportFilter());

            var reportFrom = appFilter.range.from;
            var reportTo = appFilter.range.to;

            if (!_.isNull(reportFrom) || !_.isUndefined(reportFrom)) {
                urlParams['startdate'] = moment.unix(reportFrom).format('YYYY-MM-DD 00:00:00');
            }

            if (!_.isNull(reportTo) || !_.isUndefined(reportTo)) {
                urlParams['enddate'] = moment.unix(reportTo).format('YYYY-MM-DD 00:00:00');
            }

            switch (aspect) {
                case "bytes_saved":
                    return apiResource.postBytesSaved({user_id: loginService.authInfo.user.id, id: id}, urlParams).$promise;
            }
        }

        function prettyDate(value) {
            return moment(value, 'X').format('MMMM Do YYYY');
        }

        function prettyRangeDate(range) {
            return service.prettyDate(range.from) + " - " + service.prettyDate(range.to);
        }
    }
})();
