(function() {
    'use strict';

    angular.module('customerVault')
        .factory('breadcrumbsService', breadcrumbsService);

    breadcrumbsService.$inject = ['$state', 'loginService']
    function breadcrumbsService($state, loginService) {
        var service = {
            authUser: null,
            checkImpersonate: checkImpersonate,
            get: get,
            home: {
                link: 'myaccount',
                label: 'My Account'
            }
        };

        var home = {
            link: 'myaccount',
            label: 'My Account'
        };

        var steps = {};

        return service;

        function checkImpersonate() {
            if (service.authUser && service.authUser.id == loginService.authInfo.user.id) {
                return false;
            }

            if (service.authUser && !loginService.impersonate.mode) {
                return true;
            }

            if (loginService.impersonate.admin && $state.current.name.indexOf('account') != -1) {
                return true;
            }

            if (loginService.impersonate.admin && service.authUser) {
                return true;
            }

            if (!service.authUser) {
                return false;
            }

            return false;
        }

        function get(state, other) {
            other = typeof other !== 'undefined' ? other : {};
            switch (state) {
                case 'account':
                    steps = {
                        home: service.home,
                        current: {
                            label: other.user.username
                        }
                    };
                    service.authUser = other.user;
                    break;
                case 'license.home':
                    if (!_.isUndefined(other.user)) {
                        var parents = [
                            {
                                link: 'account({id: '+other.user.id+'})',
                                label: other.user.username
                            },
                            {
                                link: 'account.'+other.parent.state+'({id: '+other.user.id+'})',
                                label: other.parent.title
                            }
                        ];
                        service.authUser = other.user;
                    } else {
                        var parents = [
                            {
                                link: 'myaccount.'+other.parent.state,
                                label: other.parent.title
                            }
                        ];
                        service.authUser = false;
                    }
                    steps = {
                        home: service.home,
                        parents: parents,
                        current: {
                            label: other.title
                        }
                    };
                    break;
                case 'insight.home':
                    if (!_.isUndefined(other.user)) {
                        var parents = [
                            {
                                link: 'account({id: '+other.user.id+'})',
                                label: other.user.username
                            },
                            {
                                link: 'account.'+other.parent.state+'({id: '+other.user.id+'})',
                                label: other.parent.title
                            }
                        ];
                        service.authUser = other.user;
                    } else {
                        var parents = [
                            {
                                link: 'myaccount.'+other.parent.state,
                                label: other.parent.title
                            }
                        ];
                        service.authUser = false;
                    }
                    steps = {
                        home: service.home,
                        parents: parents,
                        current: {
                            label: other.title
                        }
                    };
                    break;
                case 'errors':
                    steps = {
                        home: home,
                        current: {
                            label: other.error
                        }
                    };
                    service.authUser = false;
                    break;
                case 'ie.signup':
                    steps = {
                        home: home,
                        current: {
                            label: 'ImageEngine Account Signup'
                        }
                    };
                    break;
                case 'ie.trial':
                    steps = {
                        home: home,
                        current: {
                            label: 'ImageEngine Trial Account Registration'
                        }
                    };
                    break;
                case 'ie.trialcomplete':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'myaccount.trials',
                                label: 'Trials'
                            }
                        ],
                        current: {
                            label: 'ImageEngine Trial Account Registration Complete'
                        }
                    };
                    break;
                case 'cloud.signup':
                    steps = {
                        home: home,
                        current: {
                            label: 'WURFL Cloud Signup'
                        }
                    };
                    break;
                case 'wurfljs.signup':
                    steps = {
                        home: home,
                        current: {
                            label: 'WURFL.js Signup'
                        }
                    };
                    break;
                case 'signupComplete.cloud':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'cloud.signup',
                                label: 'WURFL Cloud Signup'
                            }
                        ],
                        current: {
                            label: 'WURFL Cloud Signup Complete'
                        }
                    };
                    break;
                case 'signupComplete.imageengine':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'ie.signup',
                                label: 'ImageEngine Account Signup'
                            }
                        ],
                        current: {
                            label: 'ImageEngine Account Signup Complete'
                        }
                    };
                    break;
                case 'signupComplete.wurfljs':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'wurfljs.signup',
                                label: 'WURFL.js Account Signup'
                            }
                        ],
                        current: {
                            label: 'WURFL.js Signup Complete'
                        }
                    };
                    break;
                case 'cloud.home':
                    if (!_.isUndefined(other.user)) {
                        var parents = [
                            {
                                link: 'account({id: '+other.user.id+'})',
                                label: other.user.username
                            },
                            {
                                link: 'account.'+other.parent.state+'({id: '+other.user.id+'})',
                                label: other.parent.title
                            }
                        ];
                        service.authUser = other.user;
                    } else {
                        var parents = [
                            {
                                link: 'myaccount.'+other.parent.state,
                                label: other.parent.title
                            }
                        ];
                        service.authUser = false;
                    }
                    steps = {
                        home: service.home,
                        parents: parents,
                        current: {
                            label: "WURFL Cloud Account"
                        }
                    };
                    break;
                case 'wurfljs.home':
                    if (!_.isUndefined(other.user)) {
                        var parents = [
                            {
                                link: 'account({id: '+other.user.id+'})',
                                label: other.user.username
                            },
                            {
                                link: 'account.'+other.parent.state+'({id: '+other.user.id+'})',
                                label: other.parent.title
                            }
                        ];
                        service.authUser = other.user;
                    } else {
                        var parents = [
                            {
                                link: 'myaccount.'+other.parent.state,
                                label: other.parent.title
                            }
                        ];
                        service.authUser = false;
                    }
                    steps = {
                        home: service.home,
                        parents: parents,
                        current: {
                            label: 'WURFL.js Account'
                        }
                    };
                    break;
                case 'ie.home':
                    if (!_.isUndefined(other.user)) {
                        var parents = [
                            {
                                link: 'account({id: '+other.user.id+'})',
                                label: other.user.username
                            },
                            {
                                link: 'account.'+other.parent.state+'({id: '+other.user.id+'})',
                                label: other.parent.title
                            }
                        ];
                        service.authUser = other.user;
                    } else {
                        var parents = [
                            {
                                link: 'myaccount.'+other.parent.state,
                                label: other.parent.title
                            }
                        ];
                        service.authUser = false;
                    }
                    steps = {
                        home: service.home,
                        parents: parents,
                        current: {
                            label: 'ImageEngine Account'
                        }
                    };
                    break;
                case 'ie.payment':
                    if (!_.isUndefined(other.user)) {
                        var parents = [
                            {
                                link: 'account({id: '+other.user.id+'})',
                                label: other.user.username
                            },
                            {
                                link: 'account.trials({id: '+other.user.id+'})',
                                label: 'Trials'
                            }
                        ];
                        service.authUser = other.user;
                    } else {
                        var parents = [
                            {
                                link: 'myaccount.trials',
                                label: 'Trials'
                            }
                        ];
                        service.authUser = false;
                    }
                    parents.push({
                        link: 'ie.home.summary({id: '+other.subscription.id+'})',
                        label: 'ImageEngine Account'
                    });
                    steps = {
                        home: service.home,
                        parents: parents,
                        current: {
                            label: 'Provide Payment Information'
                        }
                    };
                    break;
                case 'site.evaluation':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'myaccount.trials',
                                label: 'Trials'
                            }
                        ],
                        current: {
                            label: 'Create Evaluation License'
                        }
                    };
                    break;
                case 'quwurfl.home':
                    if (!_.isUndefined(other.user)) {
                        var parents = [
                            {
                                link: 'account({id: '+other.user.id+'})',
                                label: other.user.username
                            },
                            {
                                link: 'account.'+other.parent.state+'({id: '+other.user.id+'})',
                                label: other.parent.title
                            }
                        ];
                        service.authUser = other.user;
                    } else {
                        var parents = [
                            {
                                link: 'myaccount.'+other.parent.state,
                                label: other.parent.title
                            }
                        ];
                        service.authUser = false;
                    }
                    steps = {
                        home: service.home,
                        parents: parents,
                        current: {
                            label: other.title
                        }
                    };
                    break;
            }

            return steps;
        }
    }
})();
