(function() {
    'use strict';

    angular.module('customerVault.quwurfl')
        .factory('quwurflResolve', quwurflResolve);

    quwurflResolve.$inject = ['impersonateService', 'quwurflService', 'licenseService', 'loginService', 'menuService'];

    function quwurflResolve(impersonateService, quwurflService, licenseService, loginService, menuService) {
        var service = {
            error: {},
            load: {},
            run: run
        };

        return service;

        function run(args) {
            service.error = {};
            service.load = {};
            service.load.id = args.params.id;
            args.params.impersonateId = null;
            return runIndex(args);       
        }

        function runIndex(args) {
            return quwurflService.getIndex(args.params.id, args.params.userId).then(function(response) {
                service.load.capabilities = response.capabilities;
                service.load.domains = response.domains;
                service.load.apiKeys = response.api_keys;
                service.load.user = response.user;
                service.load.defaults = {
                    'api_keys': 10,
                    'domains': 10,
                };
            }).catch(function(error) {
                service.error.status = error.status;
                service.error.message = error.data.message;
                return false;
            });
        }
    }
})();
