(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineBillingInfoController', ImageEngineBillingInfoController);

    ImageEngineBillingInfoController.$inject = ['$scope', '$state', '$stateParams', '$timeout', 'bsLoadingOverlayService', 'homeService', 'ieResolve', 'ieService', 'Notification', 'paymentService'];

    function ImageEngineBillingInfoController($scope, $state, $stateParams, $timeout, bsLoadingOverlayService, homeService, ieResolve, ieService, Notification, paymentService) {
        var vm = this;
        var error = ieResolve.error;

        vm.error = false;
        vm.errors = {
            address: false,
            city: false,
            state: false,
            zip: false,
            country: false
        };
        vm.form = null;
        vm.submit = submit;
        vm.updateCard = false;
        vm.user = null;

        runLoad();

        function runLoad() {
            var resolveData = ieResolve.load.billinginfo;
            setError();
            vm.form = resolveData.form;
            vm.user = resolveData.user;
            vm.countries = resolveData.countries;
            bsLoadingOverlayService.stop({
                referenceId: 'ie-update-billing-info-form'
            });
        }

        function setError() {
            if (!_.isEmpty(error)) {
                vm.error = error.status;
                switch (error.status) {
                    case 403:
                        vm.pageErrorViewMessage = error.message;
                        vm.pageErrorView = 'view/errors.403';
                        break;
                    default:
                        vm.pageErrorView = 'view/errors.400';
                }
                return;
            }
        }


        var loadedInstance = null;

        if (_.isEmpty(error)) {
            generateToken();
        }

        $scope.$on('tokenGenerated', function(event, args) {
            braintree.dropin.create({
                authorization: args.token,
                container: '#ie-update-card-payment-form',
                card: {
                    cardholderName: {
                        required: true
                    }
                }
            }, function(createErr, instance) {
                $timeout(function() {
                    var disablePay = false;
                }, 1);
                loadedInstance = instance;
            });
        });

        function generateToken() {
            return paymentService.getBraintreeAuthorization().then(function(response) {
                $scope.$broadcast('tokenGenerated', {token: response.data.authorization});
            });
        }

        function runSubmit() {
            vm.form.country_id = vm.form.country;
            bsLoadingOverlayService.start({
                referenceId: 'ie-update-billing-info-form'
            });
            ieService.patchUpdateBilling($stateParams.id, vm.form).then(function(response) {
                Notification.success('Updated billing information!');
                if (!_.isNull(vm.form.nonce)) {
                    loadedInstance.teardown(function(err) {
                        ieResolve.load.billinginfo = {};
                        ieResolve.run({name: $state.current.name, params: $stateParams}).then(function(response) {
                            if (response) {
                                runLoad();
                            } else {
                                error = ieResolve.error;
                                setError();
                            }
                        });
                        if (!err) {
                            generateToken();
                            runLoad();
                        }
                    });
                }
            }).catch(function(error) {
                console.log(error);
                Notification.error(error.data.message);
                if (!_.isNull(vm.form.nonce)) {
                    loadedInstance.teardown(function(err) {
                        if (!err) {
                            generateToken();
                            ieResolve.run({name: $state.current.name, params: $stateParams}).then(function(response) {
                                if (response) {
                                    runLoad();
                                } else {
                                    error = ieResolve.error;
                                    setError();
                                }
                            });
                        }
                    });
                }
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-update-billing-info-form'
                });
            });
        }

        function submit() {
            vm.errors = {
                address: false,
                city: false,
                state: false,
                zip: false,
                country: false
            };

            if (vm.updateCard) {
                var errors = ieService.validateBilling(vm.form, false);
                if (errors.length != 0) {
                    angular.forEach(errors, function(val) {
                        vm.errors[val] = true;
                    });
                    return;
                }
                loadedInstance.requestPaymentMethod(function(requestPaymentMethodErr, payload) {
                    if (!_.isUndefined(payload)) {
                        vm.form.nonce = payload.nonce;
                        vm.form.country_id = angular.copy(vm.form.country);
                        runSubmit();
                    }
                });
            } else {
                var errors = ieService.validateBilling(vm.form, false);
                if (errors.length != 0) {
                    angular.forEach(errors, function(val) {
                        vm.errors[val] = true;
                    });
                    return;
                }
                runSubmit();
            }
        }
    }
})();
