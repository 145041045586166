(function() {
    'use strict';

    angular.module('authApp')
        .factory('httpRequestInterceptor', httpRequestInterceptor);

    httpRequestInterceptor.$inject = ['$q', '$injector', 'endSession', 'loginService', 'pendingRequestsService'];

    function httpRequestInterceptor($q, $injector, endSession, loginService, pendingRequestsService) {
        var httpService = {
            request: request,
            responseError: responseError
        };
        return httpService;

        function request(config) {
            
            if (
                localStorage.getItem('auth_token') !== null &&
                localStorage.getItem('auth_token') !== 'null'
            ) {
                if (config.headers["x-smauth"]) {
                    config.headers['Authorization'] = 'Bearer '+localStorage.getItem('sm_auth_token');
                } else {
                    config.headers['Authorization'] = 'Bearer '+localStorage.getItem('auth_token');
                }
            }
            if (config.method === 'GET') {
                // Add routes not triggered by $http
                if (config.timeout === undefined && config.url !== 'api/login/destroy') {
                    var canceller = $q.defer();
                    pendingRequestsService.add({
                        url: config.url,
                        canceller: canceller
                    });
                    config.timeout = canceller.promise;
                }
            }

            return config;
        }

        function responseError(response) {
            if (response.status === 401) {
                endSession.end();
                if (!loginService.modalOpen && !loginService.notFound) {
                    $injector.get('$state').transitionTo('login');
                }
            }
            return $q.reject(response);
        }
    }
})();
