(function() {
    'use strict';

    angular.module('customerVault.insight')
        .factory('insightService', insightService);

    insightService.$inject = ['$resource'];

    function insightService($resource) {
        var resource = new $resource('/api/vault/account/license/insight/:user_id/:id/:action/:action_id', {id: '@id', action_id: '@action_id'}, {
            getIndex: {
                method: 'GET',
                params: {
                    action: 'index'
                }
            }
        });
        var service = {
            getIndex: getIndex
        };

        return service;

        function getIndex(id, userId, impersonateId) {
            return resource.getIndex({user_id: userId, id: id, impersonateId: impersonateId}).$promise;
        }
    }
})();
