(function() {
    'use strict';

    angular.module('customerVault')
        .controller('BreadcrumbsController', BreadcrumbsController);

    BreadcrumbsController.$inject = ['$rootScope', '$scope', 'breadcrumbsService', 'loginService', 'reloadService'];

    function BreadcrumbsController($rootScope, $scope, breadcrumbsService, loginService, reloadService) {
        var vm = this;

        vm.impersonate = breadcrumbsService.checkImpersonate();
        vm.steps = $scope.steps;
        vm.triggerImpersonate = triggerImpersonate;

        function triggerImpersonate() {
            $rootScope.$broadcast('startImpersonate', {user: breadcrumbsService.authUser, stop: true});
        }
    }
})();
