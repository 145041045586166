(function() {
    'use strict';

    angular.module('authApp')
        .controller('AuthController', AuthController);

    AuthController.$inject = ['$state', '$timeout', '$uibModal', 'authenticate', 'bsLoadingOverlayService', 'endSession', 'flashMessage', 'homeService', 'loginService', 'pendingRequestsService'];

    function AuthController($state, $timeout, $uibModal, authenticate, bsLoadingOverlayService, endSession, flashMessage, homeService, loginService, pendingRequestsService) {
        var vm = this;

        vm.check = check;
        vm.checking = false;
        vm.errorClass = "alert-danger";
        vm.errorMessage = null;
        vm.errorStatus = null;
        vm.errors = {
            password: false,
            show: false,
            username: false
        };
        vm.flash = flashMessage.message;
        vm.forgotPassword = forgotPassword;
        vm.loginSubmit = loginSubmit;
        vm.logout = logout;
        vm.password = null;
        vm.resend = resend;
        vm.username = null;
        vm.remember = false;

        $timeout(function() {
            flashMessage.resetMessage();
            vm.flash = flashMessage.message;
        }, 15000);

        function check() {
            vm.remember = !vm.remember;
        }

        function forgotPassword() {
            $uibModal.open({
                templateUrl: 'view/home.partials.forgotpassword',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                controller: 'ForgotPasswordController',
                controllerAs: 'vm',
                windowClass: 'zindex'
            });
        }

        function loginSubmit() {
            var credentials = {
                username: vm.username,
                password: vm.password
            };
            vm.errors.show = false;
            vm.errors.username = false;
            vm.errors.password = false;

            var error = false;
            if (_.isNull(vm.username) || vm.username == '') {
                vm.errors.username = true;
                error = true;
            }

            if (_.isNull(vm.password) || vm.password == '') {
                vm.errors.password = true;
                error = true;
            }

            if (error) {
                return;
            }
            bsLoadingOverlayService.start({
                referenceId: 'login-form'
            });

            authenticate.auth(credentials).then(function(response) {
                if (!response) {
                    vm.password = null;
                    vm.remember = false;
                    vm.errors.show = true;
                    vm.errorMessage = authenticate.error.message;
                    vm.errorStatus = authenticate.error.status;
                    vm.errorType = authenticate.error.type;

                    authenticate.error.message = null;
                    authenticate.error.status = null;
                    authenticate.error.type = null;
                } else {
                    if (vm.remember) {
                        authenticate.setRemember(response.remember_token);
                    }
                    if (loginService.intended !== undefined) {
                        var state = loginService.intended;
                        $state.go(state.name, state.params);
                    } else {
                        $state.go('myaccount', {});
                    }
                }
                bsLoadingOverlayService.stop({
                    referenceId: 'login-form'
                });
            });
        }

        function logout() {
            loginService.intended = undefined;
            pendingRequestsService.cancelAll();
            authenticate.logout().then(function(response) {
                if (response) {
                    loginService.authInfo.authenticated = false;
                    loginService.authInfo.user = null;
                    endSession.end();
                    $state.go('login');
                } else {
                    // Something indicating that logout failed
                }
            });
        }

        function resend() {
            bsLoadingOverlayService.start({
                referenceId: 'login-form'
            });
            homeService.postSendVerification(vm.username).then(function(response) {
                vm.errorClass = "alert-success";
                vm.errorMessage = response.message;
                vm.errorStatus = null;
                vm.errors.show = true;
                $timeout(function() {
                    vm.errorClass = "alert-danger";
                    vm.errorMessage = null;
                    vm.errorStatus = null;
                    vm.errors.show = false;
                }, 5000)
            }).catch(function(error) {
                console.log(error);
                vm.errorMessage = "Verification email could not be sent. Please contact support@scientiamobile.com for assistance.";
                vm.errorStatus = null;
                vm.errors.show = true;
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'login-form'
                });
            });
        }
    }
})();
