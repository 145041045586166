(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .controller('CloudChangePlanController', CloudChangePlanController);

    CloudChangePlanController.$inject = ['$ocLazyLoad', '$rootScope', '$scope', '$stateParams', '$timeout', 'bsLoadingOverlayService', 'cloudActions', 'cloudResolve', 'cloudService', 'Notification', 'paymentService'];

    function CloudChangePlanController($ocLazyLoad, $rootScope, $scope, $stateParams, $timeout, bsLoadingOverlayService, cloudActions, cloudResolve, cloudService, Notification, paymentService) {
        var vm = this;
        var resolveData = cloudResolve.load.changeplan;
        var error = cloudResolve.error;

        vm.changePlan = changePlan;
        vm.changeTab = changeTab;
        vm.countries = resolveData.countries;
        vm.currentPlan = resolveData.currentPlan;
        vm.differences = resolveData.differences;
        vm.error = false;
        vm.errors = {
            first_name: false,
            last_name: false,
            address: false,
            city: false,
            state: false,
            zip: false,
            country: false
        };
        vm.form = resolveData.form;
        vm.isFree = resolveData.isFree;
        vm.isUpgrade = resolveData.isUpgrade;
        vm.newPlan = resolveData.newPlan;
        vm.notAvailable = notAvailable;
        vm.pageErrorView = 'view/errors.400';
        vm.pageErrorViewMessage = null;
        vm.user = resolveData.user;

        var loadedInstance = null;
        var notAvailable = null;

        if (!_.isEmpty(error)) {
            vm.error = error.status;
            if (error.status == 403) {
                vm.pageErrorView = 'view/errors.403';
                vm.pageErrorViewMessage = error.message;
            }
        } else if (vm.isFree) {
            loadBilling();
        }

        $scope.$on('closeModal', function() {
            notAvailable.close();
        });

        $scope.$on('tokenGenerated', function(event, args) {
            braintree.dropin.create({
                authorization: args.token,
                container: '#cloud-upgrade-payment-form',
                card: {
                    cardholderName: {
                        required: true
                    }
                }
            }, function(createErr, instance) {
                $timeout(function() {
                    vm.disablePay = false;
                }, 1);
                loadedInstance = instance;
            });
        });

        function changeTab(tab) {
            $rootScope.$broadcast('cloudChangeTab', {tab: tab});
        }

        function changePlan() {
            if (vm.isFree) {
                vm.errors = {
                    first_name: false,
                    last_name: false,
                    address: false,
                    city: false,
                    state: false,
                    zip: false,
                    country: false
                };
                var errors = cloudService.validateBilling(vm.form, false);
                if (errors.length != 0) {
                    angular.forEach(errors, function(val) {
                        vm.errors[val] = true;
                    });
                    console.log(vm.errors);
                    return;
                }
                loadedInstance.requestPaymentMethod(function(requestPaymentMethodErr, payload) {
                    if (!_.isUndefined(payload)) {
                        vm.form.nonce = payload.nonce;
                        vm.form.country_id = angular.copy(vm.form.country);
                        runSubmit(vm.newPlan.key, vm.newPlan.num_addons, vm.form);
                    }
                });
            } else {
                runSubmit(vm.newPlan.key, vm.newPlan.num_addons, null);
            }
        }

        function loadBilling() {
            generateToken();
        }

        function generateToken() {
            return paymentService.getBraintreeAuthorization().then(function(response) {
                $scope.$broadcast('tokenGenerated', {token: response.data.authorization});
            });
        }

        function notAvailable() {
            cloudActions.openNotAvailable(vm.isPremium, vm.subscription);
        }

        function runSubmit(plan, addons, billingInfo) {
            bsLoadingOverlayService.start({
                referenceId: 'cloud-upgrade-form'
            });

            cloudService.postChangePlan($stateParams.id, $stateParams.plan, plan, addons, billingInfo).then(function(response) {
                Notification.success('Your plan changed successfully!');
                $rootScope.$broadcast('reloadCloudAccount');
            }).catch(function(error) {
                console.log(error.data);
                if (error.data.data.tier) {
                    Notification.warning("Your plan changed successfully, however your support permissions were not updated. Please contact support@scientiamobile.com for assistance");
                    $rootScope.$broadcast('reloadCloudAccount');
                } else {
                    Notification.error(error.data.message);
                    if (!_.isNull(vm.form.nonce)) {
                        loadedInstance.teardown(function(err) {
                            if (!err) {
                                generateToken();
                            }
                        });
                    }
                }
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'cloud-upgrade-form'
                });
            });
        }
    }
})();
