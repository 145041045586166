(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineReceiptController', ImageEngineReceiptController);

    ImageEngineReceiptController.$inject = ['$stateParams', '$timeout', '$uibModalInstance', 'bsLoadingOverlayService', 'ieService', 'subscription', 'transaction', 'user'];

    function ImageEngineReceiptController($stateParams, $timeout, $uibModalInstance, bsLoadingOverlayService, ieService, subscription, transaction, user) {
        var vm = this;

        vm.alertClass = 'alert-success';
        vm.close = close;
        vm.email = user.email;
        vm.emailError = null;
        vm.sendEmail = sendEmail;
        vm.sendMessage = null;
        vm.showMessage = false;
        vm.subscription = subscription;
        vm.transaction = transaction;

        function close() {
            $uibModalInstance.dismiss();
        }

        function sendEmail() {
            vm.emailError = null;
            if (_.isNull(vm.email) || vm.email == '') {
                vm.emailError = 'error';
                return;
            }

            bsLoadingOverlayService.start({
                referenceId: 'ie-receipt'
            });
            ieService.postSendReceipt($stateParams.id, vm.transaction.id, vm.email).then(function(response) {
                vm.alertClass = 'alert-success';
                vm.sendMessage = response.message;
            }).catch(function(error) {
                vm.alertClass = 'alert-danger';
                console.log(error);
                vm.sendMessage = error.data.message;
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-receipt'
                });
                vm.showMessage = true;
                $timeout(function() {
                    vm.showMessage = false;
                }, 5000);
            });
        }
    }
})();
