(function() {
    'use strict';

    angular.module('customerVault.home')
        .controller('OrderReceiptController', OrderReceiptController);

    OrderReceiptController.$inject = ['$timeout', '$uibModalInstance', 'bsLoadingOverlayService', 'homeService', 'invoice', 'myaccountResolve', 'type'];

    function OrderReceiptController($timeout, $uibModalInstance, bsLoadingOverlayService, homeService, invoice, myaccountResolve, type) {
        var vm = this;

        vm.alertClass = 'alert-success';
        vm.close = close;
        vm.email = myaccountResolve.load.user.email;
        vm.emailError = null;
        vm.id = !_.isUndefined(invoice.Id) ? invoice.Id : invoice.id;
        vm.receipt = homeService.extractReceipt(invoice, type, myaccountResolve.load.subscriptions);
        vm.sendEmail = sendEmail;
        vm.sendMessage = null;
        vm.showMessage = false;

        function close() {
            $uibModalInstance.close();
        }

        function sendEmail() {
            vm.alertClass = 'alert-success';
            vm.sendMessage = 'Sending...';
            vm.emailError = null;
            if (_.isNull(vm.email) || vm.email == '') {
                vm.emailError = 'error';
                return;
            }
            bsLoadingOverlayService.start({
                referenceId: 'order-receipt'
            });
            homeService.postSendReceipt(vm.email, vm.receipt, myaccountResolve.load.user.id, vm.id).then(function(response) {
                vm.sendMessage = response.message;
                vm.alertClass = 'alert-success';
            }).catch(function(error) {
                console.log(error);
                vm.alertClass = 'alert-danger';
                vm.sendMessage = error.data.message;
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'order-receipt'
                });
                vm.showMessage = true;
                $timeout(function() {
                    vm.showMessage = false;
                }, 5000);
            });
        }
    }
})();
