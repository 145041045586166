(function() {
    'use strict';

    angular.module('customerVault.home')
        .factory('myaccountResolve', myaccountResolve);

    myaccountResolve.$inject = ['$rootScope', '$state', 'filexService', 'homeService', 'impersonateService', 'loginService', 'quwurflService'];

    function myaccountResolve($rootScope, $state, filexService, homeService, impersonateService, loginService, quwurflService) {
        var service = {
            error: {},
            load: {},
            redirect: false,
            run: run
        };

        return service;

        function run(args) {
            service.error = {};

            if (args.params.id == '') {
                service.error.status = 404;
                service.error.message = "Unable to determine account"
                return Promise.resolve(false);
            }

            if (loginService.impersonate.mode) {
                // Check if impersonate user is admin
                var userId = !_.isUndefined(args.params.id) ? args.params.id : loginService.impersonate.user.id;
                return impersonateService.getCheckImpersonateIsAdmin(loginService.impersonate.user.id, 'account', userId).then(function() {
                    return runIndex(args);
                }).catch(function(error) {
                    if (error.status != 303) {
                        service.error.status = error.status;
                        service.error.message = error.data.message;
                    } else if (!_.isUndefined(args.params.id)) {
                        service.redirect = true;
                    }
                    return runIndex(args);
                });
            }

            return runIndex(args);
        }

        function runIndex(args) {
            if (_.isEmpty(service.load) || service.load.id != loginService.authInfo.user.id) {
                service.load = {};

                var userId = null;
                if (args.name.indexOf('myaccount') != -1) {
                    if (loginService.impersonate.mode) {
                        userId = loginService.impersonate.user.id;
                    } else {
                        userId = loginService.authInfo.user.id;
                    }
                } else {
                    userId = args.params.id;
                }

                return homeService.getAccount(userId).then(function(response) {
                    service.load.id = userId;
                    angular.forEach(response.data.licenses, function(val, key) {
                        val.created_at = angular.copy(val.license_date);
                        val.license_date = new Date(val.license_date);
                        val.license_expire = angular.copy(val.license_expire)
                    });
                    service.load.licenses = _.sortBy(response.data.licenses, function(obj) {
                        return obj.product.display_order;
                    });

                    service.load.subscriptions = response.data.subscriptions;

                    var products = [];
                    var trials = [];
                    angular.forEach(service.load.licenses, function(val, key) {
                        val.name = val.product.product_name;
                        val.level = val.license_type.license_type_name;
                        val.created = moment(val.created_at).format('YYYY-MM-DD');
                        val.expires = moment(val.license_expire).format('YYYY-MM-DD');
                        if (_.isNull(val.expires)) {
                            val.accountStatus = 'Current';
                        } else if (val.expires < new Date()) {
                            val.accountStatus = 'Expired';
                            val.class = 'expired';
                        } else {
                            var daysRemaining = moment(moment(val.expires)).diff(moment(new Date()), 'days');
                            if (daysRemaining < 0) {
                                val.accountStatus = 'Expired';
                                val.class = 'expired';
                            } else if (daysRemaining > 5) {
                                val.accountStatus = 'Current';
                            } else {
                                val.accountStatus = 'Trial ends in '+daysRemaining+' days'
                                val.class = 'expiring';
                            }
                        }
                        // Trial or evaluation
                        if (val.license_type_id == 1 || val.license_type_id == 5) {
                            if (val.license_type_id == 5) {
                                val.name += " - Evaluation";
                            }
                            trials.push(val);
                        } else {
                            if (val.class == 'expiring') {
                                val.accountStatus = 'Account expires in '+daysRemaining+' days';
                            }
                            products.push(val);
                        }
                    });

                    angular.forEach(service.load.subscriptions, function(val, key) {
                        angular.forEach(val, function(ival, ikey) {
                            ival.created_at = ival.date_started;
                            ival.name = ival.account_name;
                            ival.level = ival.plan.name;
                            ival.created = moment(angular.copy(ival.date_started)).format('YYYY-MM-DD');
                            ival.expires = !_.isNull(ival.date_expiration) && ival.date_expiration !== '0000-00-00 00:00:00' ?
                                moment(angular.copy(ival.date_expiration)).format('YYYY-MM-DD') : null;
                            switch (ival.type) {
                                case 'cloud':
                                    ival.state = 'cloud';
                                    break;
                                case 'wjs':
                                    ival.state = 'wurfljs';
                                    break;
                                case 'imgeng':
                                    ival.state = 'ie';
                                    break;
                            }
                            if (_.isNull(ival.expires)) {
                                ival.accountStatus = 'Current';
                            } else if (moment(ival.expires) < new Date()) {
                                ival.accountStatus = 'Expired';
                                ival.class = 'expired';
                            } else {
                                var daysRemaining = moment(moment(ival.expires)).diff(moment(new Date()), 'days');
                                if (daysRemaining > 5) {
                                    ival.accountStatus = 'Current';
                                } else {
                                    ival.accountStatus = 'Trial ends in '+daysRemaining+' days'
                                    ival.class = 'expiring';
                                }
                            }
                            if (ival.payment_type == 'TRIAL' || ival.payment_type == 'FREE') {
                                ival.name += " - "+ival.level;
                                if (ival.type == "imgeng") {
                                    ival.trialExpires = new Date(moment(ival.date_started).add(ival.plan.trial_days - 1, 'days'));
                                    if (ival.trialExpires < new Date()) {
                                        if (ival.payment_plan === 'IMAGEENGINE_PRO') {
                                            ival.accountStatus = 'Suspended';
                                        } else {
                                            ival.accountStatus = 'Canceled';
                                        }
                                        ival.class = 'expired';
                                    } else {
                                        var daysRemaining = moment(moment(ival.trialExpires)).diff(moment(new Date()), 'days');
                                        if (daysRemaining < 7) {
                                            ival.accountStatus = 'Trial ends in '+daysRemaining+' days'
                                            ival.class = 'expiring';
                                        }
                                    }
                                }
                                trials.push(ival);

                            } else {
                                if (ival.class == 'expiring') {
                                    ival.accountStatus = 'Account expires in '+daysRemaining+' days';
                                }
                                products.push(ival);
                            }
                        });
                    });

                    function statuscmp(a, b) {
                        if ((a.indexOf('Trial ends') != -1 || a.indexOf('Account expires') != -1) && (b.indexOf('Trial ends') == -1 || b.indexOf('Account expires') == -1)) {
                            return -1;
                        } else if (a.indexOf('Current') != -1 && b.indexOf('Current') == -1) {
                            return -1;
                        } else if ((a.indexOf('Trial ends') == -1 || a.indexOf('Account expires') == -1) && (b.indexOf('Trial ends') != -1 || b.indexOf('Account expires') != -1)) {
                            return 1;
                        } else if (a.indexOf('Current') == -1 && b.indexOf('Current') != -1) {
                            return 1;
                        }
                        return 0;
                    }

                    function sortTrials(a, b) {
                        if (a == "expiring" && b !== "expiring") {
                            return -1;
                        } else if (b == "expiring" && a !== "expiring") {
                            return 1;
                        } else if (a == "expired" && b !== "expired") {
                            return 1;
                        } else if (b == "expired" && a !== "expired") {
                            return -1;
                        }
                        return 0;
                    }


                    products = products.sort(function(a, b) {
                        return statuscmp(a.accountStatus, b.accountStatus);
                    });
                    trials = trials.sort(function(a, b) {
                        return sortTrials(a.class, b.class);
                    });

                    // Fetches the QuWURFL subscriptions and adds to the products list
                    quwurflService.userSubscriptions(userId).then(function (result) {
                        angular.forEach(result, function(subscription, idx) {
                            if (subscription.type != "quwurfl") {
                                return;
                            }
                            var accountStatus = 'Current';
                            if (moment(subscription.date_expiration) < new Date()) {
                                accountStatus = 'Expired';
                            }
                            service.load.products.push({
                                "type": "quwurfl",
                                "state": "quwurfl",
                                "id": subscription.id,
                                "user_id": subscription.user_id,
                                "name": subscription.account_name,
                                "level": "QuWURFL BE",
                                "created": moment(angular.copy(subscription.date_started)).format('YYYY-MM-DD'),
                                "expires": !_.isNull(subscription.date_expiration) && subscription.date_expiration !== '0000-00-00 00:00:00' ?
                                moment(angular.copy(subscription.date_expiration)).format('YYYY-MM-DD') : null,
                                "accountStatus": accountStatus,
                            });
                        });
                    });

                    service.load.products = products;
                    service.load.trials = trials;
                    service.load.user = response.data.user;
                    service.load.filex = response.data.filex;

                    service.load.fileManagementAdmin = filexService.checkAdmin(userId);

                    return true;

                    // return homeService.getOrders(userId).then(function(response) {
                    //     service.load.showOrders = false;
                    //     if (!_.isEmpty(response.data.external) || !_.isEmpty(response.data.standard)) {
                    //         service.load.showOrders = true;
                    //     }
                    //     service.load.origInvoices = response.data;
                    //     service.load.invoices = homeService.extractInvoice(response.data, service.load.subscriptions);
                    //     var dueDates = _.pluck(service.load.invoices, 'sortDate');
                    //     service.load.dateOptions = {
                    //         maxDate: dueDates[0],
                    //         minDate: dueDates.reverse()[0]
                    //     };
                    //     return true;
                    // }).catch(function(error) {
                    //     service.error.status = error.status;
                    //     service.error.message = error.data.message;
                    //     console.log(error);
                    //     return false;
                    // });
                }).catch(function(error) {
                    console.log(error);
                    service.error.status = error.status;
                    service.error.message = "An error occurred while loading your accounts";
                    return false;
                });
            } else {
                return Promise.resolve(true);
            }
        }
    }
})();
