(function() {
    'use strict';

    angular.module('customerVault.wurfljs')
        .controller('WurfljsDomainsController', WurfljsDomainsController);

    WurfljsDomainsController.$inject = ['$stateParams', '$uibModal', 'bsLoadingOverlayService', 'Notification', 'wurfljsActions', 'wurfljsResolve', 'wurfljsService'];

    function WurfljsDomainsController($stateParams, $uibModal, bsLoadingOverlayService, Notification, wurfljsActions, wurfljsResolve, wurfljsService) {
        var vm = this;
        var resolveData = wurfljsResolve.load.domains;
        var error = wurfljsResolve.error;

        vm.addDomain = addDomain;
        vm.addingClass = resolveData.addingClass;
        vm.defaults = resolveData.defaults;
        vm.deleteDomain = deleteDomain;
        vm.domains = resolveData.domains;
        vm.newDomain = null;
        vm.notTrashed = resolveData.notTrashed;
        vm.pageErrorView = 'view/errors.400';
        vm.plan = resolveData.plan;
        vm.restoreDomain = restoreDomain;
        vm.trashed = resolveData.trashed;
        vm.whitelist = resolveData.whitelist;

        if (!_.isEmpty(error)) {
            vm.error = true;
        }

        function addDomain() {
            vm.addingClass = null;
            if (_.isNull(vm.newDomain)) {
                vm.addingClass = 'error';
                return;
            }
            bsLoadingOverlayService.start({
                referenceId: 'wjs-hostname-form'
            });
            wurfljsService.postDomains($stateParams.id, vm.newDomain).then(function(response) {
                vm.notTrashed.push(response.data);
                Notification.success("Successfully added hostname");
                vm.newDomain = null;
            }).catch(function(error) {
                switch (error.status) {
                    case 403:
                        Notification.error(error.data.message);
                        break;
                    default:
                        Notification.error("Unable to create new hostname");
                }
                console.log(error);
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'wjs-hostname-form'
                });
            });
        }

        function deleteDomain(domain) {
            var deleteModal = wurfljsActions.openDeleteDomain(domain);

            deleteModal.result.then(function(response) {
                vm.domains = response.domains;
                vm.notTrashed = response.not_trashed;
                vm.trashed = response.trashed;
            });
        }

        function restoreDomain(domain) {
            bsLoadingOverlayService.start({
                referenceId: 'wjs-hostname-form'
            });
            wurfljsService.patchDomains($stateParams.id, domain.id).then(function(response) {
                vm.domains = response.data.domains;
                vm.notTrashed = response.data.not_trashed;
                vm.trashed = response.data.trashed;
                Notification.success("Successfully restored hostname");
            }).catch(function(error) {
                console.log(error);
                Notification.error("Unable to restore hostname");
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'wjs-hostname-form'
                });
            });
        }
    }
})();
