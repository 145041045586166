(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineUpgradeConfirmController', ImageEngineUpgradeConfirmController);

    ImageEngineUpgradeConfirmController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'bsLoadingOverlayService', 'ieService', 'Notification', 'obj', 'paymentService'];

    function ImageEngineUpgradeConfirmController($scope, $stateParams, $uibModalInstance, bsLoadingOverlayService, ieService, Notification, obj, paymentService) {
        var vm = this;

        vm.close = close;
        vm.costs = obj.costs;
        vm.currentPlan = obj.currentPlan;
        vm.daysLeft = obj.daysLeft;
        vm.form = obj.form;
        vm.newPlan = obj.newPlan;
        vm.nextCycle = obj.nextCycle;
        vm.subscription = obj.subscription;
        vm.upgrade = upgrade;

        function close() {
            $uibModalInstance.close('cancel');
        }

        function generateToken() {
            return paymentService.getBraintreeAuthorization().then(function(response) {
                $scope.$broadcast('tokenGenerated', {token: response.data.authorization});
            });
        }

        function runUpgrade() {
            bsLoadingOverlayService.start({
                referenceId: 'ie-upgrade-account'
            });
            ieService.postPlanUpgrade($stateParams.id, vm.newPlan.payment_plan).then(function(response) {
                $uibModalInstance.close('reload');
            }).catch(function(error) {
                console.log(error);
                $uibModalInstance.dismiss(error);
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-upgrade-account'
                });
            });
        }

        function upgrade() {
            if (vm.currentPlan.payment_plan == 'IMAGEENGINE_TRIAL') {
                vm.form.payment_plan = vm.newPlan.payment_plan;
                bsLoadingOverlayService.start({
                    referenceId: 'ie-upgrade-account'
                });
                ieService.patchUpdateBilling(vm.subscription.id, vm.form).then(function(response) {
                    runUpgrade();
                }).catch(function(error) {
                    Notification.error("Your payment information cannot be added at this time. Please contact support@scientiamobile.com");
                    $uibModalInstance.dismiss();
                });
            } else {
                runUpgrade();
            }
        }
    }
})();
