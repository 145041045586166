(function() {
    'use strict';

    angular.module('customerVault.insight')
        .factory('insightResolve', insightResolve);

    insightResolve.$inject = ['impersonateService', 'insightService', 'licenseService', 'loginService', 'menuService'];

    function insightResolve(impersonateService, insightService, licenseService, loginService, menuService) {
        var service = {
            error: {},
            load: {},
            run: run
        };

        return service;

        function run(args) {
            service.error = {};
            if (
                (_.isEmpty(service.load) || service.load.id != args.params.id) &&
                !_.isUndefined(args.params.id)
            ) {
                service.load = {};
                service.load.id = args.params.id;
                args.params.impersonateId = null;
                if (loginService.impersonate.mode) {
                    // Check if impersonate user is admin
                    return impersonateService.getCheckImpersonateIsAdmin(loginService.impersonate.user.id, 'license', args.params.id).then(function() {
                        args.params.impersonateId = loginService.impersonate.user.id;
                        return runIndex(args);
                    }).catch(function(error) {
                        service.error.status = error.status;
                        service.error.message = error.data.message;
                        return false;
                    });
                }

                return runIndex(args);
            }
        }

        function runIndex(args) {
            return insightService.getIndex(args.params.id, args.params.userId, args.params.impersonateId).then(function(response) {
                service.load.capabilities = response.data.capabilities;
                service.load.files = response.data.insight.files;
                service.load.insight = response.data.insight;
                service.load.insightKeys = response.data.insight_keys;
                service.load.isAdmin = response.data.has_admin_access && !loginService.impersonate.mode; // Don't show admin link in impersonation mode
                service.load.serverAddress = response.data.server_address;
                service.load.user = response.data.user;

                return licenseService.getLicenseText(service.load.insight.license_id).then(function(response) {
                    service.load.licenseText = response.data.text;
                    if (service.load.isAdmin) {
                        return menuService.getAdmin(loginService.authInfo.user.id).then(function(response) {
                            service.load.adminLink = response.data.url;
                            service.load.session = response.data.session;
                            return true;
                        }).catch(function(error) {
                            console.log(error);
                            service.error.status = error.status;
                            service.error.message = error.data.message;
                            return false;
                        });
                    } else {
                        return true;
                    }
                }).catch(function(error) {
                    service.error.status = error.status;
                    service.error.message = error.data.message;
                    return false;
                });
            }).catch(function(error) {
                service.error.status = error.status;
                service.error.message = error.data.message;
                return false;
            });
        }
    }
})();
