(function() {
    'use strict';

    angular.module('customerVault.site')
        .factory('siteResolve', siteResolve);

    siteResolve.$inject = ['$window'];

    function siteResolve($window) {
        var service = {
            run: run
        };

        return service;

        function run(args) {
            $window.location.href = 'https://www.scientiamobile.com/trial-request/';
        }
    }
})();
