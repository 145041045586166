(function() {
    'use strict';

    angular.module('customerVault')
        .factory('resolveService', resolveService);

    resolveService.$inject = ['$filter', '$state', 'capabilitiesResolve', 'cloudResolve', 'ieResolve', 'insightResolve', 'licenseResolve', 'myaccountResolve', 'passwordResetResolve', 'registerResolve', 'siteResolve', 'verifyResolve', 'wurfljsResolve', 'wurfljsService', 'quwurflResolve'];

    function resolveService($filter, $state, capabilitiesResolve, cloudResolve, ieResolve, insightResolve, licenseResolve, myaccountResolve, passwordResetResolve, registerResolve, siteResolve, verifyResolve, wurfljsResolve, wurfljsService, quwurflResolve) {
        var service = {
            capabilities: capabilities,
            cloud: cloud,
            error: {},
            filex: filex,
            ie: ie,
            impersonate: impersonate,
            insight: insight,
            license: license,
            login: login,
            myaccount: myaccount,
            passwordreset: passwordreset,
            register: register,
            quwurfl: quwurfl,
            site: site,
            subscription: subscription,
            verify: verify,
            wurfljs: wurfljs,
            zendesk: zendesk
        };

        service.wurfljsLoad = {
            main: {}
        };

        return service;

        function capabilities(args) {
            service.error = {};

            return capabilitiesResolve.run(args).then(function(response) {
                if (response) {
                    return true;
                } else {
                    service.error = capabilitiesResolve.error;
                    return false;
                }
            });
        }

        function cloud(args) {
            service.error = {};
            return cloudResolve.run(args).then(function(response) {
                if (response) {
                    return true;
                } else {
                    service.error = cloudResolve.error;
                    return false;
                }
            });
        }

        function filex(args) {
            return Promise.resolve(true);
        }

        function ie(args) {
            service.error = {};
            return ieResolve.run(args).then(function(response) {
                if (response) {
                    return true;
                } else {
                    service.error = ieResolve.error;
                    return false;
                }
            });
        }

        function impersonate(args) {
            return Promise.resolve(true);
        }

        function insight(args) {
            service.error = {};
            return insightResolve.run(args).then(function(response) {
                if (response) {
                    return true;
                } else {
                    service.error = insightResolve.error;
                    return false;
                }
            });
        }

        function quwurfl(args) {
            service.error = {};
            return quwurflResolve.run(args).then(function(response) {
                if (response) {
                    return true;
                } else {
                    service.error = quwurflResolve.error;
                    return false;
                }
            });
        }

        function license(args) {
            service.error = {};
            return licenseResolve.run(args).then(function(response) {
                if (response) {
                    return true;
                } else {
                    service.error = licenseResolve.error;
                    return false;
                }
            });
        }

        function login(args) {
            return Promise.resolve(true);
        }

        function myaccount(args) {
            service.error = {};
            return myaccountResolve.run(args).then(function(response) {
                if (response) {
                    return true;
                } else {
                    service.error = myaccountResolve.error;
                    return false;
                }
            });
        }

        function passwordreset(args) {
            service.error = {};
            return passwordResetResolve.run(args).then(function(response) {
                if (response) {
                    return true;
                } else {
                    service.error = passwordResetResolve.error;
                    return false;
                }
            });
        }

        function register(args) {
            service.error = {};
            return registerResolve.run(args).then(function(response) {
                if (response) {
                    return true;
                } else {
                    service.error = registerResolve.error;
                    return false;
                }
            });
        }

        function site(args) {
            service.error = {};
            return siteResolve.run(args).then(function(response) {
                if (response) {
                    return true;
                } else {
                    service.error = siteResolve.error;
                    return false;
                }
            });
        }

        function subscription(args) {
            return Promise.resolve(true);
        }

        function verify(args) {
            service.error = {};
            return verifyResolve.run(args).then(function(response) {
                if (response) {
                    return true;
                } else {
                    service.error = verifyResolve.error;
                    return false;
                }
            });
        }

        function wurfljs(args) {
            service.error = {};
            return wurfljsResolve.run(args).then(function(response) {
                if (response) {
                    return true;
                } else {
                    service.error = wurfljsResolve.error;
                    return false;
                }
            });
        }

        function zendesk(args) {
            return Promise.resolve(true);
        }
    }
})();
