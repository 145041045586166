(function() {
    'use strict';

    angular.module('customerVault.subscription')
        .controller('SubscriptionSignupCompleteController', SubscriptionSignupCompleteController);

    SubscriptionSignupCompleteController.$inject = ['$state', '$stateParams', '$window', 'breadcrumbsService', 'signupService'];

    function SubscriptionSignupCompleteController($state, $stateParams, $window, breadcrumbsService, signupService) {
        var vm = this;

        vm.message = signupService.value;
        vm.steps = breadcrumbsService.get('signupComplete.'+$stateParams.type);
        vm.type = null;

        switch ($stateParams.type) {
            case 'cloud':
                vm.type = 'WURFL Cloud';
                break;
            case 'imageengine':
                vm.type = 'ImageEngine';
                break;
            case 'wurfljs':
                vm.type = 'WURFL.js';
                break;
            default:
                $state.go('myaccount');
                break;
        }

        $window.scrollTo(0, 0);
    }
})();
