(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineController', ImageEngineController);

    ImageEngineController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$uibModal', '$window', 'breadcrumbsService', 'bsLoadingOverlayService', 'flashMessage', 'ieActions', 'ieResolve', 'loaderService', 'loginService', 'myaccountResolve', 'Notification'];

    function ImageEngineController($rootScope, $scope, $state, $stateParams, $timeout, $uibModal, $window, breadcrumbsService, bsLoadingOverlayService, flashMessage, ieActions, ieResolve, loaderService, loginService, myaccountResolve, Notification) {
        var vm = this;
        var resolveData = null;
        var error = null;

        run();

        function run() {
            window.location.href='https://control.imageengine.io?referrer=my.scientiamobile.com';
            resolveData = ieResolve.load.main;
            error = ieResolve.mainError;

            $rootScope.$broadcast('showRedirectBanner', true);
            $scope.$on('$destroy', () => {
                $rootScope.$broadcast('showRedirectBanner', false);
            });

            if (!_.isEmpty(error)) {
                vm.error = error.status;
                switch (error.status) {
                    case 404:
                        vm.pageErrorView = 'view/errors.404';
                        $rootScope.$broadcast('setTitle', {title: 'Page Not Found'});
                        break;
                    case 403:
                        vm.pageErrorViewMessage = error.message;
                        break;
                    default:
                        vm.pageErrorView = 'view/errors.400';

                }
            } else {
                if (!_.isUndefined(resolveData.statusWrapper)) {
                    vm.statusWrapper = resolveData.statusWrapper;
                    vm.title = resolveData.title;
                } else {
                    vm.accounts = resolveData.accounts;
                    vm.cancelAccount = cancelAccount;
                    vm.cancelAllowed = resolveData.cancelAllowed;
                    vm.changeTab = changeTab;
                    vm.clearMain = clearMain;
                    vm.costs = resolveData.costs;
                    vm.docBasePath = resolveData.docBasePath;
                    vm.docIEBasePath = resolveData.docIEBasePath;
                    vm.error = false;
                    vm.flash = flashMessage.message;
                    vm.id = $stateParams.id;
                    vm.isFree = resolveData.isFree;
                    vm.onlinePurchaseAllowed = resolveData.onlinePurchaseAllowed;
                    vm.paymentNeeded = resolveData.paymentNeeded;
                    vm.percentDetections = resolveData.percentDetections;
                    vm.percentClass = resolveData.percentClass;
                    vm.percentStyle = resolveData.percentStyle;
                    vm.selectedAccount = null;
                    vm.showTab = null;
                    vm.statusWrapper = false;
                    vm.steps = ieActions.getSteps(resolveData);
                    vm.subscription = resolveData.subscription;
                    vm.switchAccounts = switchAccounts;
                    vm.tabs = resolveData.tabs;
                    vm.title = "ImageEngine - "+vm.subscription.account_name;
                    vm.totalBandwidth = resolveData.totalBandwidth;
                    vm.viewTab = null;

                    ieResolve.load.main.confs = vm.subscription.wit_domain_conf;
                }
                if ($state.current.name == 'ie.home') {
                    $state.go('ie.home.summary', $stateParams);
                }
            }
            vm.viewTab = ieActions.getTab();
        }

        $rootScope.$on('ieChangeCacheTab', function(event, args) {
            if (!vm.isFree) {
                vm.tabs.cache = args.show;
            }
        });

        $rootScope.$on('ieChangeTab', function(event, args) {
            $stateParams.plan = args.plan;
            changeTab(args.tab);
        });

        var enableCache = $rootScope.$on('enableCache', function(event, args) {
            vm.tabs.cache = args.status;
            $scope.$on('$destroy', enableCache);
        });

        var reloadIeAccount = $rootScope.$on('reloadIeAccount', function() {
            bsLoadingOverlayService.start({
                referenceId: 'ie-top'
            });
            bsLoadingOverlayService.start({
                referenceId: 'ie-tabs'
            });
            vm.steps = null;
            myaccountResolve.load = {};
            ieResolve.load.main = {}
            ieResolve.run({name: 'ie.home', params: $stateParams}).then(function(response) {
                if (response) {
                    run();
                    vm.viewTab = 'summary';
                } else {
                    vm.error = 400;
                    vm.pageErrorView = 'view/errors.400';
                }
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-top'
                });
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-tabs'
                });
                $scope.$on('$destroy', reloadIeAccount);
            });
        });

        var reloadIeBreadcrumbs = $rootScope.$on('reloadIeBreadcrumbs', function() {
            if (!_.isEmpty(error)) {
                ieResolve.mainError = {};
                run();
            } else {
                vm.steps = null;
                $timeout(function() {
                    var params = {};
                    if (vm.subscription.payment_type == 'TRIAL' || vm.subscription.payment_type == 'FREE') {
                        params.parent = {state: 'trials', title: 'Trials'};
                    } else {
                        params.parent = {state: 'products', title: 'Products'};
                    }
                    if (loginService.impersonate.mode || resolveData.user.id == loginService.authInfo.user.id) {
                        vm.steps = breadcrumbsService.get('ie.home', params)
                    } else {
                        params.user = resolveData.user;
                        vm.steps = breadcrumbsService.get('ie.home', params);
                    }
                }, 100);
            }
            $scope.$on('$destroy', reloadIeBreadcrumbs);
        });

        $rootScope.$on('$stateChangeSuccess', function() {
            bsLoadingOverlayService.stop({
                referenceId: 'ie-tabs'
            });
        });

        $timeout(function() {
            flashMessage.resetMessage();
            vm.flash = flashMessage.message;
        }, 10000);

        function cancelAccount() {
            vm.showTab = angular.copy(vm.viewTab);
            vm.viewTab = 'cancelaccount';

            var modalInstance = ieActions.openCancelAccount();

            modalInstance.result.then(function(response) {
                vm.viewTab = angular.copy(vm.showTab);
                vm.showTab = null;
                if (!_.isUndefined(response.status_wrapper)) {
                    vm.title = 'Account Status';
                    vm.statusWrapper = response.status_wrapper;
                    Notification.success("Your account has been cancelled");
                    myaccountResolve.load = {};
                    $window.scrollTo(0, 0);
                }
            }).catch(function(error) {
                Notification.error("Something unexpected occurred");
            });
        }

        function changeTab(tab) {
            if (vm.viewTab != tab) {
                loaderService.show = false;
                bsLoadingOverlayService.start({
                    referenceId: 'ie-tabs'
                });
                vm.viewTab = tab;
                $state.go('ie.home.'+tab, $stateParams);
            }
        }

        function clearMain() {
            ieResolve.load.main = {};
        }

        function switchAccounts() {
            $state.go('ie.home', {id: vm.selectedAccount.id});
        }
    }
})();
