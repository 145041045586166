(function() {
    'use strict';

    angular.module('customerVault.quwurfl')
        .controller('QuWURFLDomainController', QuWURFLDomainController);

    QuWURFLDomainController.$inject = ['$stateParams', 'bsLoadingOverlayService', 'quwurflResolve', 'quwurflService', 'Notification'];

    function QuWURFLDomainController($stateParams, bsLoadingOverlayService, quwurflResolve, quwurflService, Notification) {
        
        var vm = this;
        var resolveData = quwurflResolve.load;

        vm.apiError = null;
        vm.domains = resolveData.domains;
        vm.createDomain = createDomain;
        vm.defaults = {"domains": 10};
        vm.deleteDomain = deleteDomain;
        vm.domainName = null;

        function createDomain() {
            vm.apiError = null;
            if (_.isNull(vm.domainName) || vm.domainName == '') {
                vm.apiError = 'error';
                return;
            }
            bsLoadingOverlayService.start({
                referenceId: 'quwurfl-domains'
            });

            quwurflService.addDomain($stateParams.id, vm.domainName).then(function(response) {
                Notification.success("Domain added");
                vm.domains.push(response[0]);
                vm.domainName = null;
            }).catch(function(error) {
                console.log(error);
                Notification.error(error.message);
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'quwurfl-domains'
                });
                $rootScope.$digest();
            })
        }

        function deleteDomain(domainId) {
            bsLoadingOverlayService.start({
                referenceId: 'quwurfl-domains'
            });
            quwurflService.deleteDomain($stateParams.id, domainId).then(function(response) {
                Notification.success("Domain removed");
                vm.domains = _.reject(vm.domains, function(domain) {
                    return domain.id == domainId;
                });
                console.log(vm.domains);
            }).catch(function(error) {
                console.log(error);
                Notification.error(error.message);
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'quwurfl-domains'
                });
                $rootScope.$digest();
            });
        }
    }
})();
