(function() {
    'use strict';

    angular.module('customerVault.home')
        .controller('MyAccountController', MyAccountController);

    MyAccountController.$inject = ['$location', '$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$uibModal', '$window', 'breadcrumbsService', 'bsLoadingOverlayService', 'flashMessage', 'homeActions', 'homeService', 'loaderService', 'loginService', 'myaccountResolve', 'Notification'];

    function MyAccountController($location, $rootScope, $scope, $state, $stateParams, $timeout, $uibModal, $window, breadcrumbsService, bsLoadingOverlayService, flashMessage, homeActions, homeService, loaderService, loginService, myaccountResolve, Notification) {
        var vm = this;
        var resolveData = null;
        var error = null;

        run();

        function run() {
            resolveData = myaccountResolve.load;
            error = myaccountResolve.error;

            if (!_.isEmpty(error)) {
                vm.error = error.status;
                switch (error.status) {
                    case 404:
                        vm.pageErrorView = 'view/errors.404';
                        break;
                    case 403:
                        vm.pageErrorViewMessage = error.message;
                        break;
                    default:

                        // If there is no account name found, display a login button.
                        if ($state.current.name.indexOf('myaccount') != -1) {
                            vm.showButton = false;
                        } else {
                            vm.showButton = true;
                        }
                        break;
                }
                return;
            }

            vm.changePassword = changePassword;
            vm.changeTab = changeTab;
            vm.error = false;
            vm.filex = resolveData.filex;
            vm.flash = {
                show: flashMessage.message.show,
                message: flashMessage.message.message,
                admin: flashMessage.message.admin,
                impersonateUser: flashMessage.message.impersonateUser
            };
            vm.goToEval = goToEval;
            vm.fileManagementAdmin = resolveData.fileManagementAdmin;
            vm.impersonate = homeActions.getImpersonate(resolveData);
            vm.impersonateMode = loginService.impersonate.mode;
            vm.include = homeActions.getInclude(_.last($state.current.name.split(".")));
            vm.licenses = resolveData.licenses;
            vm.pageErrorView = 'view/errors.400';
            vm.pageErrorViewMessage = null;
            vm.prefix = myaccountResolve.redirect || $state.current.name.indexOf('myaccount') != -1 ?
                'myaccount' : 'account';
            vm.products = resolveData.products;
            // vm.showOrders = resolveData.showOrders;
            vm.showButton = false;
            vm.steps = vm.prefix == 'myaccount' ?
                null :
                breadcrumbsService.get('account', {user: resolveData.user});
            vm.subscriptions = resolveData.subscriptions;
            vm.subscriptionsAvailable = subscriptionsAvailable;
            vm.support = support;
            vm.title = vm.prefix == 'myaccount' ?
                "My Account" :
                resolveData.user.username;
            vm.trials = resolveData.trials;
            vm.triggerImpersonate = triggerImpersonate;
            vm.user = resolveData.user;
            vm.viewTab = null;

            vm.viewTab = vm.prefix == 'myaccount' ?
                homeActions.getTab(true) :
                homeActions.getTab(false);

            if (vm.prefix == 'myaccount') {
                breadcrumbsService.authUser = false;
            }

            // If the user has exactly one product, which is ImageEngine,
            // redirect them to that product summary
            // if (!vm.trials.length && vm.products.length == 1 && vm.products[0].type == 'imgeng') {
            //     return $state.go('ie.home.summary', {id: vm.products[0].id});
            // }

            // If the user has exactly one trial, which is ImageEngine,
            // redirect them to that product summary
            // if (!vm.products.length && vm.trials.length == 1 && vm.trials[0].type == 'imgeng') {
            //     return $state.go('ie.home.summary', {id: vm.trials[0].id});
            // }

            var state = $state.current.name;
            switch (state) {
                case 'myaccount.licenses':
                case 'myaccount.subscriptions':
                    if (vm.viewTab == 'trials') {
                        $state.transitionTo('myaccount.trials', null, {reload: false, notify: false});
                    } else {
                        $state.transitionTo('myaccount.products', null, {reload: false, notify: false});
                    }
                    break;

                //============================================
                // Account pages are for admin viewing of user
                //=============================================
                case 'account.licenses':
                case 'account.subscriptions':
                    if (vm.viewTab == 'trials') {
                        $state.transitionTo('account.trials', $stateParams, {reload: false, notify: false});
                    } else {
                        $state.transitionTo('account.products', $stateParams, {reload: false, notify: false});
                    }
                    break;
            }

            $timeout(function() {
                flashMessage.resetMessage();
                vm.flash = flashMessage.message;
            }, 9000);

            if (myaccountResolve.redirect) {
                switch (homeActions.getState()) {
                    case 'ie.home':
                        vm.viewTab = 'summary';
                    case 'myaccount.products':
                        vm.viewTab = 'products';
                        break;
                    case 'myaccount.trials':
                        vm.viewTab = 'trials';
                        break;
                    default:
                        vm.viewTab = 'products';

                }
                $state.go(homeActions.getState(), {}, {notify: false});
            }
        }

        $rootScope.$on('myaccountChangeTab', function(event, args) {
            changeTab(args.tab);
        });

        var reloadAccountBreadcrumbs = $rootScope.$on('reloadAccountBreadcrumbs', function() {
            if (!_.isEmpty(error)) {
                myaccountResolve.error = {};
                myaccountResolve.redirect = false;
                run();
            } else {
                vm.steps = null;
                $timeout(function() {
                    vm.impersonateMode = loginService.impersonate.mode;
                    if (!loginService.impersonate.mode && resolveData.user.id != loginService.authInfo.user.id) {
                        vm.steps = breadcrumbsService.get('account', {user: resolveData.user});
                        vm.title = resolveData.user.username;
                        vm.impersonate = false;
                        vm.prefix = 'account';
                        vm.fileManagementAdmin = true;
                        $state.go(homeActions.getState(), {id: resolveData.user.id}, {notify: false});
                    } else {
                        vm.title = "My Account";
                        if (loginService.impersonate.mode) {
                            vm.impersonate = true;
                            vm.prefix = 'myaccount';
                            vm.fileManagementAdmin = true;
                            $state.go(homeActions.getState(), {}, {notify: false});
                        } else {
                            vm.impersonate = false;
                            vm.prefix = 'account';
                            vm.fileManagementAdmin = false;
                        }
                    }
                }, 100);
                $scope.$on('$destroy', reloadAccountBreadcrumbs);
            }
        });

        $rootScope.$on('$stateChangeSuccess', function() {
            bsLoadingOverlayService.stop({
                referenceId: 'myaccount-tabs'
            });
        });

        $rootScope.$on('reloadMyAccount', function() {
            loaderService.close();
            loaderService.confirm();
            myaccountResolve.load = {};
            myaccountResolve.run().then(function() {
                run();
                loaderService.close();
            });
        });

        function changePassword() {
            homeActions.openChangePassword();
        }

        function changeTab(tab) {
            loaderService.show = false;
            vm.viewTab = tab;
            if (vm.viewTab == 'summary') {
                vm.prefix =  'ie.home';
                $stateParams = vm.subscriptions.imgeng.id;
            };
            vm.include = homeActions.getInclude(tab);
            $state.go(vm.prefix+'.'+tab, $stateParams, {notify: false});
        }

        function goToEval() {
            $state.go('site.evaluation');
        }

        function subscriptionsAvailable() {
            return homeActions.subscriptionsAvailable(vm.subscriptions);
        }

        function support() {
            $window.open('https://support.scientiamobile.com', '_blank');
        }

        function triggerImpersonate() {
            vm.flash.show = false;
            flashMessage.resetMessage();
            $rootScope.$broadcast('startImpersonate', {user: vm.flash.impersonateUser, reload: true});
        }
    }
})();
