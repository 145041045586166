(function() {
    'use strict';

    angular.module('customerVault.layout')
        .controller('TitleController', TitleController);

    TitleController.$inject = ['$rootScope', '$scope'];

    function TitleController($rootScope, $scope) {
        var vm = this;

        vm.title = 'ScientiaMobile - Account';

        var setTitle = $rootScope.$on('setTitle', function(event, args) {
            vm.title = args.title;
            $scope.$on('$destroy', setTitle);
        });
    }
})();
