(function() {
    'use strict';

    angular.module('customerVault.license')
        .controller('LicenseTextController', LicenseTextController);

    LicenseTextController.$inject = ['$stateParams', '$window', 'bsLoadingOverlayService', 'licenseAction', 'licenseResolve', 'licenseService', 'Notification'];

    function LicenseTextController($stateParams, $window, bsLoadingOverlayService, licenseAction, licenseResolve, licenseService, Notification) {
        var vm = this;
        var resolveData = licenseResolve.load.text;
        var error = licenseResolve.error;

        vm.downloadLicense = downloadLicense;
        vm.error = false;
        vm.fileManagementAdmin = licenseResolve.load.main.fileManagementAdmin;
        vm.filexCustomerUrl = resolveData.filexCustomerUrl;
        vm.filexToken = resolveData.filexToken;
        vm.isInsightModule = resolveData.isInsightModule;
        vm.isOnSite = resolveData.isOnSite;
        vm.licenseText = resolveData.licenseText;
        vm.pageErrorView = 'view/errors.400';
        vm.product = resolveData.product;
        vm.user = licenseResolve.load.main.user;

        if (!_.isEmpty(error)) {
            vm.error = true;
        }

        function downloadLicense() {
            bsLoadingOverlayService.start({
                referenceId: 'license-insight-download'
            });
            licenseService.getCreateFile($stateParams.id).then(function(response) {
                licenseAction.createLicense(response);
            }).catch(function(error) {
                console.log(error);
                Notification.error('Unable to download license');
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'license-insight-download'
                });
            });
        }
    }
})();
