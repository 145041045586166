(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineViewDnsController', ImageEngineViewDnsController);

    ImageEngineViewDnsController.$inject = ['$uibModalInstance', 'conf'];

    function ImageEngineViewDnsController($uibModalInstance, conf) {
        var vm = this;

        vm.close = close;
        vm.conf = conf;
        vm.status = conf.status.dns ? 'DNS configured successfully' : 'Waiting for DNS configuration';

        function close() {
            $uibModalInstance.dismiss();
        }
    }
})();
