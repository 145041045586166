(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineStandardCompleteController', ImageEngineStandardCompleteController);

    ImageEngineStandardCompleteController.$inject = ['$state', '$uibModalInstance'];

    function ImageEngineStandardCompleteController($state, $uibModalInstance) {
        var vm = this;

        vm.close = close;

        function close() {
            $state.go('myaccount');
            $uibModalInstance.close();
        }
    }
})();
