(function() {
    'use strict';

    angular.module('customerVault.license')
        .controller('LicenseGuideController', LicenseGuideController);

    LicenseGuideController.$inject = ['$stateParams', 'licenseResolve'];

    function LicenseGuideController($stateParams, licenseResolve) {
        var vm = this;
        var resolveData = licenseResolve.load.guide;
        var error = licenseResolve.error;
        var mainResolveData = licenseResolve.load.main;

        vm.config = mainResolveData.config;
        vm.documentationUrl = mainResolveData.documentationUrl;
        vm.error = false;
        vm.fileManagementAdmin = mainResolveData.fileManagementAdmin;
        vm.filex = resolveData.filex;
        vm.guide = resolveData.guide;
        vm.home = resolveData.home;
        vm.id = mainResolveData.id;
        vm.isInsightModule = resolveData.isInsightModule;
        vm.license = mainResolveData.license;
        vm.pageErrorView = 'view/errors.400';
        vm.product = resolveData.product;
        vm.user = licenseResolve.load.main.user

        if (!_.isEmpty(error)) {
            vm.error = true;
        }
    }
})();
