(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineSettingsController', ImageEngineSettingsController);

    ImageEngineSettingsController.$inject = ['$stateParams', '$uibModalInstance', 'bsLoadingOverlayService', 'domain', 'ieActions', 'ieService', 'isNew', 'Notification', 'setting', 'settingsService'];

    function ImageEngineSettingsController($stateParams, $uibModalInstance, bsLoadingOverlayService, domain, ieActions, ieService, isNew, Notification, setting, settingsService) {
        var vm = this;

        vm.checkCheckboxStatus = checkCheckboxStatus;
        vm.checkCheckboxStatusCmprdpicl = checkCheckboxStatusCmprdpicl;
        vm.checkFitMethod = checkFitMethod;
        vm.checkQualityOverride = checkQualityOverride;
        vm.checkQualityRadios = checkQualityRadios;
        vm.checkStaticImage = checkStaticImage;
        vm.close = close;
        vm.display = {
            message: null,
            class: null,
            show: false
        };
        vm.domain = domain;
        vm.errors = {
            setting_name: false,
            path: false
        };
        vm.form = {
            setting_name: _.isNull(setting) ? null : setting.setting_name,
            path: _.isNull(setting) ? null : setting.path
        };
        vm.formatOptions = ["jpg","gif","png","bmp","webp","jp2"];
        vm.formatOptionsAuto = ["webp", "mp4", "jp2"];
        vm.openPathValidation = openPathValidation;
        vm.openPatternExplanation = openPatternExplanation;
        vm.openSettingsExplanation = openSettingsExplanation;
        vm.qualityOverride = null;
        vm.radio = {
            format: 'force',
            size: 'none',
            quality: 'none',
            origin_returnable_behavior: 'default'
        }
        vm.selected = {
            cache_ttl: 'default',
            browser_cache_ttl: 'default',
            image_info: 'default',
            meta: 'default',
            pass: 'default',
            s: 'default',
            static: 'default',
            cors: 'default',
            origin_timeout: 'default',
            x_wit_sync: 'default',
            format: 'default',
            origin_returnable: 'default',
            response_headers: 'default'
        };
        vm.patherror = false;
        vm.submit = submit;
        vm.title = isNew ? "Add" : "Edit";
        vm.updateCheckbox = updateCheckbox;
        vm.updateCorsOrigin = updateCorsOrigin;
        vm.updateFitMethod = updateFitMethod;
        vm.updateImageFormat = updateImageFormat;
        vm.updateImageSize = updateImageSize;
        vm.updateImageQuality = updateImageQuality;
        vm.updateQuality = updateQuality;
        vm.updateQualityOverride = updateQualityOverride;
        vm.removeCustomResponseHeader = removeCustomResponseHeader;
        vm.addCustomResponseHeader = addCustomResponseHeader;
        vm.updateSelected = updateSelected;

        if (!_.isNull(setting)) {
            angular.forEach(setting.wit_domain_path_setting, function(obj) {
                if (!obj.wit_setting.active) {
                    return;
                }

                var fnc = settingsService.formatSetting(obj);
                var response = settingsService[fnc](obj);
                vm.form[obj.wit_setting.directive] = response.value || response.map_value;
                vm.selected[response.selected.field] = response.selected.value;
                if (!_.isUndefined(response.radio)) {
                    angular.forEach(response.radio, function(o) {
                        vm.radio[o.field] = o.value;
                    });
                }
                if (!_.isUndefined(response.form)) {
                    angular.forEach(response.form, function(o) {
                        vm.form[o.field] = o.value;
                    });
                }
            });
            if (vm.radio.quality == 'auto' && _.isUndefined(vm.form.ppi) && _.isUndefined(vm.form.w_auto)) {
                vm.radio.size = 'auto';
            }
            if (vm.radio.size == 'auto' && _.isUndefined(vm.form.ppi)) {
                vm.form.ppi = 'true';
            }
        }

        function checkCheckboxStatus(val) {
            if (_.indexOf(vm.form.naf, val) !== -1) {
                return true;
            }
            return false;
        }

        function checkCheckboxStatusCmprdpicl(val) {
            if (_.indexOf(_.keys(vm.form.cmprdpicl), val) !== -1) {
                return true;
            }
            return false;
        }

        function checkFitMethod() {
            if (vm.selected.image_info == 'default' || vm.radio.size !== 'force') {
                return true;
            }

            if (vm.form.w == null || vm.form.h == null) {
                return true;
            }

            return false;
        }

        function checkStaticImage() {
            if (_.isNull(vm.form.w) || _.isNull(vm.form.h)) {
                vm.form.m = null;
            } else if (!_.isNull(vm.form.w) && !_.isNull(vm.form.h) && _.isNull(vm.form.m)) {
                vm.form.m = {
                    selected: "stretch"
                };
            }
        }

        function checkQualityOverride(option) {
            return _.indexOf(_.keys(vm.form.cmprdpicl), option) !== -1;
        }

        function checkQualityRadios() {
            if (vm.radio.size !== 'auto') {
                return false;
            }

            if (vm.form.ppi !== 'true') {
                return false;
            }

            return true;
        }

        function close() {
            $uibModalInstance.dismiss();
        }

        function openPathValidation() {
            if (_.isNull(vm.form.path) || vm.form.path === '') {
                vm.patherror = true;
                return;
            }
            var path = angular.copy(vm.form.path);
            if (!vm.form.path.match(/^\//)) {
                path = "/**/"+path;
            }

            var result = ieActions.openPathValidation(vm.form.path, path);
            result.result.then(function(response) {
                vm.form.path = response;
            });
        }

        function openPatternExplanation() {
            ieActions.openPatternExplanation();
        }

        function openSettingsExplanation(type) {
            switch (type) {
                case 'ppi':
                    var title = "Honor High PPI Screens";
                    var message = "<p>Indicates whether to use \"display pixels\" or physical pixels when deciding the optimal pixel size of the image. Default is physical pixels. If display pixels (sometimes called CSS pixels) is used, images will be smaller in byte size but may have lower visual quality.</p>"
                    break;
                case 'fit':
                    var title = "Fit Methods";
                    var message = `
                        <p>
                            Decide how to fit your image.
                        </p>
                        <ul style='list-style-type: none'>
                            <li>
                                <code>box</code> scales the image to fit within the specified dimensions. Note that the image that is returned will not necessarily be the same size as the requested dimensions, but it will fit inside a box of those dimensions.
                            </li>
                            <li>
                                <code>cropbox</code> method will resample the image as small as possible while showing no canvas. This will result in some cropping if the source and output aspect ratios differ.
                            </li>
                            <li>
                                <code>letterbox</code> is the same as box, except the returned image is the exact specified size. Bars of white are placed around the image if necessary, but the image will not be scaled up. To use a color other than white, you can specify the HEX color code. The canvas opacity can also be specified for images that support transparency.
                            </li>
                            <li>
                                <code>stretch</code> will change the aspect ratio of the image to fill the new canvas defined by the specified image height and width.
                            </li>
                        </ul>
                    `;
                default:

            }
            ieActions.openSettingsExplanation(title, message);
        }

        function submit() {
            angular.forEach(vm.selected, function(val, key) {
                if (val === 'default') {
                    switch (key) {
                        case 'image_info':
                            vm.form.image_width = null;
                            vm.form.ppi = null;
                            vm.form.cmprdpicl = null;
                            vm.form.m = null;
                            vm.form.w = null;
                            vm.form.h = null;
                            vm.form.cmpr = null;
                            vm.qualityOverride = null;
                            vm.radio.size = 'none';
                            vm.radio.quality = 'none';
                            break;
                        case 'format':
                            vm.form.naf = null;
                            vm.form.f = null;
                            vm.radio.format = 'force';
                            break;
                        default:
                            vm.form[key] = null;
                    }
                }
            });

            vm.errors = _.mapObject(vm.errors, function(val, key) {
                return false;
            });
            vm.display.show = false;

            if (_.isNull(vm.form.setting_name) || vm.form.setting_name == '') {
                vm.errors.setting_name = true;
            }

            if (_.isNull(vm.form.path) || vm.form.path == '') {
                vm.errors.path = true;
            }

            if (vm.errors.path || vm.errors.setting_name) {
                vm.display.message = "Please provide both a path and a setting name";
                vm.display.class = "alert-danger";
                vm.display.show = true;
                return;
            }

            var others = _.without(_.reject(vm.form, function(obj, key) {
                return key == 'setting_name' || key == 'path';
            }), null);

            if (others.length == 0) {
                vm.display.message = "Please provide a minimum of one custom setting";
                vm.display.class = "alert-danger";
                vm.display.show = true;
                return;
            }

            if (vm.radio.size == 'auto' && (_.isUndefined(vm.form.w_auto) || _.isNull(vm.form.w_auto))) {
                vm.errors.w_auto = true;
                vm.display.message = "Please provide a fallback maximum image size";
                vm.display.class = "alert-danger";
                vm.display.show = true;
                return;
            }

            if (!_.every(vm.errors, function(obj) {
                return obj === false;
            })) {
                return;
            }

            var form = _.omit(vm.form, function(val, key) {
                return _.isNull(val);
            });

            if (!_.isUndefined(form.naf)) {
                form.naf = form.naf.join(",");
            }

            if (!_.isUndefined(form.m)) {
                if (!_.isUndefined(form.m.hex) && !_.isNull(form.m.hex)) {
                    form.m.hex = "#" + form.m.hex;
                }
                form.m = JSON.stringify(form.m);
            }

            if (!_.isUndefined(form.cmprdpicl)) {
                if (!_.isEmpty(form.cmprdpicl)) {
                    form.cmprdpicl = JSON.stringify(form.cmprdpicl);
                } else {
                    delete form.cmprdpicl;
                }
            }

            if (!_.isNull(form.cors)) {
                var cors = [];
                var errors = [];
                angular.forEach(form.cors, function(val, key) {
                    try {
                        if (val.protocol === 's3') {
                            var origin = 'http://' + val.hostname;
                        } else {
                            var origin = val.protocol + "://" + val.hostname;
                        }

                        if (!_.isNull(val.port)) {
                            origin += ":" + val.port;
                        }
                        if (_.isNull(val.protocol) || _.isNull(val.hostname)) {
                            errors.push({
                                value: origin,
                                message: "Protocol and hostname are required for all CORS origins",
                                class: 'danger'
                            });
                            return;
                        }

                        var url = new URL('', origin);

                        if (
                            url.username !== "" ||
                            url.password !== "" ||
                            url.hash !== "" ||
                            url.search !== "" ||
                            !_.isEmpty(url.searchParams)
                        ) {
                            errors.push({
                                value: origin,
                                message: "The provided origin is incorrectly formatted. Please only include the origin scheme and path, with an optional port between 1 and 65535.<br />Suggested: "+url.origin,
                                class: 'warning'
                            });
                            return;
                        } else if (
                            !_.isNull(val.port) &&
                            (val.port < 1 || val.port > 65535)
                        ) {
                            errors.push({
                                value: origin,
                                message: "Invalid origin provided for CORS Header Support. Please be sure to enclude the origin scheme and path, with an optional port between 1 and 65535.",
                                class: 'danger'
                            });
                            return;
                        }
                        if (val.protocol === 's3') {
                            origin = origin.replace("http", "s3");
                        }
                        cors.push(origin);
                    } catch (err) {
                        errors.push({
                            value: origin,
                            message: "Invalid origin provided for CORS Header Support. Please be sure to enclude the origin scheme and path, with an optional port between 1 and 65535.",
                            class: 'danger'
                        });
                        return;
                    }
                });

                if (errors.length > 0) {
                    var message = '';
                    var errorClass = null;
                    angular.forEach(errors, function(val) {
                        message += "<strong>" + val.value + "</strong><br />";
                        message += val.message + "<br />";
                        if (_.isNull(errorClass)) {
                            errorClass = val.class;
                        } else if (errorClass !== 'danger' && val.class === 'danger') {
                            errorClass = val.class;
                        }
                    });
                    vm.display.message = message;
                    vm.display.class = 'alert-'+errorClass;
                    vm.display.show = true;

                    return;
                } else {
                    if (cors.length !== 0) {
                        form.cors = cors.join(",");
                    }
                }
            }

            if (!_.isUndefined(form.origin_returnable) && !_.isNull(form.origin_returnable)) {
                form.origin_returnable = vm.radio.origin_returnable_behavior;
                if (!_.isUndefined(form.origin_returnable_ttl) && !_.isNull(form.origin_returnable_ttl) && !_.isNaN(form.origin_returnable_ttl)) {
                    form.origin_returnable += '; ttl=' + form.origin_returnable_ttl;
                }
            }
            delete form.origin_returnable_ttl;

            if (!_.isUndefined(form.response_headers) && !_.isNull(form.response_headers)) {
                form.response_headers = _.filter(form.response_headers, function(obj) {
                    return obj.key.trim() !== '' || obj.value.trim() !== '';
                });

                if (form.response_headers.length === 0) {
                    vm.display.message = 'At least one header is required.';
                    vm.display.class = 'alert-danger';
                    vm.display.show = true;
                    return;
                }

                var foundError = false;
                angular.forEach(form.response_headers, function(obj) {
                    if (obj.key.trim() === '') {
                        vm.display.message = 'Please provide a name for all custom headers.';
                        vm.display.class = 'alert-danger';
                        vm.display.show = true;
                        foundError = true;
                        return false;
                    }

                    if (obj.value.trim() === '') {
                        vm.display.message = 'Please provide a value for all custom headers.';
                        vm.display.class = 'alert-danger';
                        vm.display.show = true;
                        foundError = true;
                        return false;
                    }
                });

                if (foundError) {
                    return;
                }

                var uniqueKeys = new Set(_.map(form.response_headers, function(obj) { return obj.key; }));
                if (form.response_headers.length !== uniqueKeys.size) {
                    vm.display.message = 'A duplicate header key was found.';
                    vm.display.class = 'alert-danger';
                    vm.display.show = true;
                    return;
                }
            }

            if (form.path === '/**/*' || form.path === '**/*') {
                var confirm = ieActions.confirmAllSettings("<h3>Are you really sure you want to apply these settings to all images?</h3><p>Moving forward, all supplied settings will be applied to all images. If you're certain, press \"proceed\" to continue, or press \"close\" to make changes.</p>");
                confirm.result
                    .then(function() {
                        runSubmit(form)
                    }).catch(function() {});
            } else if (!form.path.match(/^\//)) {
                // Does not start with slash
                var message = "<p>The supplied settings will be applied to all paths ending in <code>"+form.path+"</code>. If this is not the desired behavior, please press \"close\" to make changes.</p><p>Adding a proceeding <code>/</code> will limit the settings to paths matching the entire pattern only.</p>";
                var confirm = ieActions.confirmAllSettings(message);
                confirm.result
                    .then(function() {
                        form.path = "/**/"+form.path;
                        runSubmit(form);
                    });
            } else {
                runSubmit(form);
            }
        }

        function runSubmit(form) {
            bsLoadingOverlayService.start({
                referenceId: 'ie-settings'
            });

            if (isNew) {
                ieService.postCreateSettings($stateParams.id, vm.domain.id, form)
                    .then(function(response) {
                        $uibModalInstance.close(response.data);
                    }).catch(function(error) {
                        console.log(error);
                        vm.display.message = error.data.message;
                        vm.display.class="alert-danger";
                        vm.display.show = true;
                    }).finally(function() {
                        bsLoadingOverlayService.stop({
                            referenceId: 'ie-settings'
                        });
                    });
            } else {
                ieService.patchEditSettings($stateParams.id, setting.id, form)
                    .then(function(response) {
                        $uibModalInstance.close(response.data);
                    }).catch(function(error) {
                        console.log(error);
                        vm.display.message = error.data.message;
                        vm.display.class="alert-danger";
                        vm.display.show = true;
                    }).finally(function() {
                        bsLoadingOverlayService.stop({
                            referenceId: 'ie-settings'
                        });
                    });
            }
        }

        function updateCheckbox(option) {
            if (_.indexOf(vm.form.naf, option) === -1) {
                vm.form.naf.push(option);
            } else {
                var indx = _.indexOf(vm.form.naf, option);
                vm.form.naf.splice(indx, 1);
            }
        }

        function updateCorsOrigin(key) {
            if (_.isNull(vm.form.cors)) {
                vm.form.cors = [];
            }
            if (_.isUndefined(key)) {
                vm.form.cors.push({
                    protocol: 'http',
                    hostname: null,
                    port: null
                });
            } else {
                vm.form.cors.splice(key, 1);
                if (vm.form.cors.length === 0) {
                    vm.form.cors = [{
                        protocol: 'http',
                        hostname: null,
                        port: null
                    }];
                }
            }
        }

        function updateFitMethod() {
            if (vm.form.m.selected == 'letterbox') {
                vm.form.m.hex = null;
                vm.form.m.opacity = null;
            } else {
                vm.form.m = {
                    selected: vm.form.m.selected
                };
            }
        }

        function updateImageFormat(value) {
            if (value == 'force') {
                vm.form.naf = null
            } else {
                vm.form.f = null;
                vm.form.naf = [];
            }
        }

        function updateImageSize(value) {
            switch (value) {
                case 'none':
                case 'force':
                    vm.form.w = null;
                    vm.form.h = null;
                    vm.form.m = null;
                    vm.form.w_auto = null;
                    vm.form.ppi = null;
                    if (vm.radio.quality == 'auto') {
                        vm.qualityOverride = null;
                        vm.radio.quality = 'default';
                        vm.form.cmpr = 1;
                    }
                    vm.form.cmprdpicl = null;
                    break;
                case 'auto':
                    vm.form.w_auto = 2560;
                    vm.form.w = null;
                    vm.form.h = null;
                    vm.form.m = null;
                    vm.form.ppi = 'true';
                    vm.form.cmprdpicl = null;
                    break;
            }
        }

        function updateImageQuality(value) {
            if (value == 'specify') {
                vm.form.cmpr = 1;
            } else {
                vm.form.cmpr = null;
            }

            if (!_.isNull(vm.form.ppi)) {
                vm.form.cmprdpicl = {};
            }
            vm.qualityOverride = null;
        }

        function updateQuality() {
            if (vm.form.ppi == 'false') {
                if (vm.radio.quality === 'auto') {
                    vm.radio.quality = 'default';
                }
                vm.form.cmprdpicl = null;
                vm.qualityOverride = null;
            } else {
                vm.form.cmprdpicl = {};
            }
        }

        function updateQualityOverride(val) {
            if (_.indexOf(_.keys(vm.form.cmprdpicl), val) === -1) {
                vm.form.cmprdpicl[val] = 1;
            } else {
                vm.form.cmprdpicl = _.omit(vm.form.cmprdpicl, function(value, key, obj) {
                    return key === val;
                });
            }
        }

        function removeCustomResponseHeader(i) {
            vm.form.response_headers.splice(i, 1);
        }

        function addCustomResponseHeader() {
            vm.form.response_headers.push({key: '', value: ''});
        }

        function updateSelected(directive, value) {
            switch (directive) {
                case 'format':
                    if (value == 'custom') {
                        if (vm.radio.format == 'force') {
                            vm.form.naf = null;
                        } else {
                            vm.form.f = null;
                            vm.form.naf = [];
                        }
                    }
                    break;
                case 'cors':
                    if (
                        value == 'custom' &&
                        (_.isNull(vm.form.cors) || _.isUndefined(vm.form.cors))
                    ) {
                        vm.form.cors = [{
                            protocol: 'http',
                            hostname: null,
                            port: null
                        }];
                    }
                    break;
                case 'meta':
                case 'pass':
                case 'x_wit_sync':
                    if (value == 'custom') {
                        vm.form[directive] = true;
                    }
                    break;
                case 's':
                    if (value == 'custom' && _.isUndefined(vm.form.s)) {
                        vm.form.s = 0;
                    }
                    break;
                case 'origin_returnable':
                    if (value == 'default') {
                        vm.form.origin_returnable = null;
                    } else {
                        vm.form.origin_returnable = vm.radio.origin_returnable_behavior;
                    }
                    break;
                case 'response_headers':
                    if (value == 'default') {
                        vm.form.response_headers = null;
                    } else {
                        vm.form.response_headers = [{key: '', value: ''}];
                    }
            }
            vm.selected[directive] = value;
        }
    }
})();
