(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .controller('CloudBillingController', CloudBillingController);

    CloudBillingController.$inject = ['$scope', '$stateParams', '$timeout', 'bsLoadingOverlayService', 'cloudResolve', 'cloudService', 'Notification', 'paymentService'];

    function CloudBillingController($scope, $stateParams, $timeout, bsLoadingOverlayService, cloudResolve, cloudService, Notification, paymentService) {
        var vm = this;
        var error = cloudResolve.error;

        vm.countries = [];
        vm.error = false;
        vm.errors = {
            address: false,
            city: false,
            state: false,
            zip: false,
            country: false
        };
        vm.form = null;
        vm.pageErrorView = null;
        vm.pageErrorViewMessage = null;
        vm.submit = submit;
        vm.updateCard = false;

        runLoad();

        function runLoad() {
            var resolveData = cloudResolve.load.updatebilling;
            setError();
            vm.form = resolveData.form;
            vm.countries = resolveData.countries;
        }

        function setError() {
            if (!_.isEmpty(error)) {
                vm.error = error.status;
                switch (error.status) {
                    case 403:
                        vm.pageErrorViewMessage = error.message;
                        break;
                    default:
                        vm.pageErrorView = 'view/errors.400';
                }
                return;
            }
        }

        var loadedInstance = null;

        if (_.isEmpty(error)) {
            generateToken();
        }

        $scope.$on('tokenGenerated', function(event, args) {
            braintree.dropin.create({
                authorization: args.token,
                container: '#cloud-billing-payment-form',
                card: {
                    cardholderName: {
                        required: true
                    }
                }
            }, function(createErr, instance) {
                $timeout(function() {
                    vm.disablePay = false;
                }, 1);
                loadedInstance = instance;
            });
        });

        function generateToken() {
            return paymentService.getBraintreeAuthorization().then(function(response) {
                $scope.$broadcast('tokenGenerated', {token: response.data.authorization});
            });
        }

        function runSubmit() {
            bsLoadingOverlayService.start({
                referenceId: 'cloud-update-billing-form'
            });
            cloudService.postBilling($stateParams.id, vm.form).then(function(response) {
                Notification.success('Updated billing information!');
                if (!_.isNull(vm.form.nonce)) {
                    loadedInstance.teardown(function(err) {
                        cloudResolve.load.updatebilling = {};
                        cloudResolve.run({name: $state.current.name, params: $stateParams}).then(function(response) {
                            if (response) {
                                runLoad();
                            } else {
                                error = cloudResolve.error;
                                setError();
                            }
                        });
                        if (!err) {
                            generateToken();
                            runLoad();
                        }
                    });
                }
            }).catch(function(error) {
                console.log(error);
                Notification.error(error.data.message);
                if (!_.isNull(vm.form.nonce)) {
                    loadedInstance.teardown(function(err) {
                        if (!err) {
                            generateToken();
                            cloudResolve.run({name: $state.current.name, params: $stateParams}).then(function(response) {
                                if (response) {
                                    runLoad();
                                } else {
                                    error = cloudResolve.error;
                                    setError();
                                }
                            });
                        }
                    });
                }
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'cloud-update-billing-form'
                });
            });
        }

        function submit() {
            vm.errors = {
                address: false,
                city: false,
                state: false,
                zip: false,
                country: false
            };

            if (vm.updateCard) {
                var errors = cloudService.validateBilling(vm.form, false);
                if (errors.length != 0) {
                    angular.forEach(errors, function(val) {
                        vm.errors[val] = true;
                    });
                    return;
                }
                loadedInstance.requestPaymentMethod(function(requestPaymentMethodErr, payload) {
                    if (!_.isUndefined(payload)) {
                        vm.form.nonce = payload.nonce;
                        vm.form.country_id = angular.copy(vm.form.country);
                        runSubmit();
                    }
                });
            } else {
                var errors = cloudService.validateBilling(vm.form, false);
                if (errors.length != 0) {
                    angular.forEach(errors, function(val) {
                        vm.errors[val] = true;
                    });
                    return;
                }
                vm.form.country_id = angular.copy(vm.form.country);
                runSubmit();
            }
        }
    }
})();
