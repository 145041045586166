(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineBillingHistoryController', ImageEngineBillingHistoryController);

    ImageEngineBillingHistoryController.$inject = ['$uibModal', 'ieActions', 'ieResolve'];

    function ImageEngineBillingHistoryController($uibModal, ieActions, ieResolve) {
        var vm = this;
        var resolveData = ieResolve.load.billinghistory;
        var error = ieResolve.error;

        vm.error = false;
        vm.pageErrorView = 'view/errors.400';
        vm.pageErrorViewMessage = null;
        vm.transactions = resolveData.transactions;
        vm.viewReceipt = viewReceipt;

        if (!_.isEmpty(error)) {
            vm.error = error.status;
            switch (error.status) {
                case 403:
                    vm.pageErrorView = 'view/errors.403';
                    vm.pageErrorViewMessage = error.message;
                    break;
                default:
                    break;
            }
        }

        function viewReceipt(transaction) {
            ieActions.openViewReceipt(resolveData.subscription, transaction, resolveData.user)
        }
    }
})();
