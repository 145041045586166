(function() {
    'use strict';

    angular.module('authApp')
        .factory('loginService', loginService);

    function loginService() {
        var service = {};

        service.authInfo = {
            authenticated: false,
            user: null
        };
        service.impersonate = {
            mode: false,
            user: null,
            admin: false
        };
        service.intended = undefined;
        service.modalOpen = false;
        service.notFound = false;

        return service;
    }
})();
