(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .controller('CloudCapabilitiesController', CloudCapabilitiesController);

    CloudCapabilitiesController.$inject = ['$rootScope', '$uibModalInstance', 'activeCapabilities', 'capabilities', 'isFreeCloudAccount'];

    function CloudCapabilitiesController($rootScope, $uibModalInstance, activeCapabilities, capabilities, isFreeCloudAccount) {
        var vm = this;

        vm.active = activeCapabilities;
        vm.capabilities = capabilities;
        vm.changePlan = changePlan;
        vm.close = close;
        vm.isFreeCloudAccount = isFreeCloudAccount;

        function changePlan() {
            $rootScope.$broadcast('cloudChangeTab', {tab: 'planInfo'});
            $uibModalInstance.close();
        }

        function close() {
            $uibModalInstance.dismiss();
        }
    }
})();
