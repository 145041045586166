(function() {
    'use strict';

    angular.module('customerVault.wurfljs')
        .controller('WurfljsCancelController', WurfljsCancelController);

    WurfljsCancelController.$inject = ['$uibModalInstance', 'wurfljsActions'];

    function WurfljsCancelController($uibModalInstance, wurfljsActions) {
        var vm = this;

        vm.cancelReason = null;
        vm.close = close;
        vm.errorClass = null;
        vm.submit = submit;

        function close() {
            $uibModalInstance.dismiss();
        }

        function submit() {
            vm.errorClass = null;
            if (_.isNull(vm.cancelReason) || vm.cancelReason == '') {
                vm.errorClass = 'error';
                return;
            }
            $uibModalInstance.close();
            wurfljsActions.openCancelConfirm(vm.cancelReason);
        }
    }
})();
