(function() {
    'use strict';

    angular.module('customerVault')
        .factory('smAuthService', smAuthService);

        smAuthService.$inject = ['$resource'];

    function smAuthService($resource) {
        var service = {
            login: login,
            token: token,
        };

        var resource = new $resource("/api/smauth/:action", {}, {
            login: {
                method:'POST',
                params: {
                    action: 'login',
                },
            },
            token: {
                method:'POST',
                params: {
                    action: 'token',
                },
            },
        });

        return service;

        function login(username, password) {
            return resource.login({'username':username, 'password': password}).$promise;
        }

        function token(refresh_token) {
            return resource.token({'refresh_token': refresh_token}).$promise;
        }
    }
})();
