(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .factory('cloudService', cloudService);

    cloudService.$inject = ['$resource', 'loginService'];

    function cloudService($resource, loginService) {
        var resource = new $resource('/api/vault/account/cloud/:id/:action/:action_id', {id: '@id', action_id: '@action_id'}, {
            deleteApikey: {
                method: 'DELETE',
                params: {
                    action: 'apikey'
                }
            },
            deleteBillingContacts: {
                method: 'DELETE',
                params: {
                    action: 'billingcontacts',
                    action_id: null
                }
            },
            getAccount: {
                method: 'GET',
                params: {
                    action: null,
                    action_id: null
                }
            },
            getBilling: {
                method: 'GET',
                params: {
                    action: 'billing',
                    action_id: null
                }
            },
            getBillingContacts: {
                method: 'GET',
                params: {
                    action: 'billingcontacts',
                    action_id: null
                }
            },
            getBillingHistory: {
                method: 'GET',
                params: {
                    action: 'billinghistory',
                    action_id: null
                }
            },
            getCapabilities: {
                method: 'GET',
                params: {
                    action: 'capabilities',
                    action_id: null
                }
            },
            getChangePlan: {
                method: 'GET',
                params: {
                    action: 'plan'
                }
            },
            getDetectionPeriods: {
                method: 'GET',
                params: {
                    action: 'periods'
                }
            },
            getDetections: {
                method: 'GET',
                params: {
                    action: 'detections'
                }
            },
            getPlanInfo: {
                method: 'GET',
                params: {
                    action: 'plans',
                    action_id: null
                }
            },
            getPlans: {
                method: 'GET',
                params: {
                    action: 'plans',
                    action_id: null
                }
            },
            getTransactionReceipt: {
                method: 'GET',
                params: {
                    action: 'transaction'
                }
            },
            postBilling: {
                method: 'POST',
                params: {
                    action: 'billing',
                    action_id: null
                }
            },
            postBillingContacts: {
                method: 'POST',
                params: {
                    action: 'billingcontacts',
                    action_id: null
                }
            },
            postCancelAccount: {
                method: 'POST',
                params: {
                    action: 'cancel',
                    action_id: null
                }
            },
            postChangePlan: {
                method: 'POST',
                params: {
                    action: 'plan'
                }
            },
            postCreate: {
                method: 'POST',
                params: {
                    action: 'create',
                    action_id: null
                }
            },
            postCreateApikey: {
                method: 'POST',
                params: {
                    action: 'apikey',
                    action_id: null
                }
            },
            postSendTransactionReceipt: {
                method: 'POST',
                params: {
                    action: 'transaction'
                }
            },
            postUpdateCapabilities: {
                method: 'POST',
                params: {
                    action: 'capabilities'
                }
            }
        });

        var service = {
            deleteApikey: deleteApikey,
            deleteBillingContacts: deleteBillingContacts,
            getAccount: getAccount,
            getBilling: getBilling,
            getBillingContacts: getBillingContacts,
            getBillingHistory: getBillingHistory,
            getCapabilities: getCapabilities,
            getChangePlan: getChangePlan,
            getDetectionPeriods: getDetectionPeriods,
            getDetections: getDetections,
            getPlanInfo: getPlanInfo,
            getPlans: getPlans,
            getTransactionReceipt: getTransactionReceipt,
            postBilling: postBilling,
            postBillingContacts: postBillingContacts,
            postCancelAccount: postCancelAccount,
            postChangePlan: postChangePlan,
            postCreate: postCreate,
            postCreateApikey: postCreateApikey,
            postSendTransactionReceipt: postSendTransactionReceipt,
            postUpdateCapabilities: postUpdateCapabilities,
            validateBilling: validateBilling
        };

        return service;

        function deleteApikey(id, apikeyId) {
            return resource.deleteApikey({id: id, action_id: apikeyId}).$promise;
        }

        function deleteBillingContacts(id, contactId) {
            return resource.deleteBillingContacts({id: id, action_id: contactId}).$promise;
        }

        function getAccount(id) {
            return resource.getAccount({id: id}).$promise;
        }

        function getBilling(id) {
            return resource.getBilling({id: id}).$promise;
        }

        function getBillingContacts(id) {
            return resource.getBillingContacts({id: id}).$promise;
        }

        function getBillingHistory(id) {
            return resource.getBillingHistory({id: id}).$promise;
        }

        function getCapabilities(id) {
            return resource.getCapabilities({id: id}).$promise;
        }

        function getChangePlan(id, to) {
            return resource.getChangePlan({id: id, action_id: to}).$promise;
        }

        function getDetectionPeriods(id) {
            return resource.getDetectionPeriods({id: id}).$promise;
        }

        function getDetections(id, start, end) {
            var range = start + "/" + end;
            return resource.getDetections({id: id, action_id: range}).$promise;
        }

        function getPlanInfo(id) {
            return resource.getPlanInfo({id: id}).$promise;
        }

        function getPlans() {
            return resource.getPlans({id: null}).$promise;
        }

        function getTransactionReceipt(id, transactionId) {
            return resource.getTransactionReceipt({id: id, action_id: transactionId}).$promise;
        }

        function postBilling(id, form) {
            return resource.postBilling({id: id}, form).$promise;
        }

        function postBillingContacts(id, form) {
            return resource.postBillingContacts({id: id}, form).$promise;
        }

        function postCancelAccount(id, reason) {
            return resource.postCancelAccount({id: id}, {reason: reason}).$promise;
        }

        function postChangePlan(id, to, plan, addons, billingInfo) {
            return resource.postChangePlan({id: id, action_id: to}, {plan: plan, addons: addons, billing_info: billingInfo}).$promise;
        }

        function postCreate(form) {
            return resource.postCreate({id: null}, form).$promise;
        }

        function postCreateApikey(id, keyName) {
            return resource.postCreateApikey({id: id}, {key_name: keyName}).$promise;
        }

        function postSendTransactionReceipt(id, transactionId, email) {
            return resource.postSendTransactionReceipt({id: id, action_id: transactionId}, {email: email}).$promise;
        }

        function postUpdateCapabilities(id, capabilities) {
            return resource.postUpdateCapabilities({id: id}, {capabilities: capabilities}).$promise;
        }

        function validateBilling(form, payment) {
            var fields = ['address', 'city', 'state', 'zip', 'country'];
            if (payment) {
                fields.push('nonce');
            }

            angular.forEach(fields, function(val, key) {
                if (!_.isNull(form[val]) && form[val] != '') {
                    fields = _.reject(fields, function(v) {
                        return v == val;
                    });
                }
            });

            return fields;
        }
    }
})();
