(function() {
    'use strict';

    angular.module('customerVault')
        .directive('serverError', serverError);

    function serverError() {
        var directive = {
            templateUrl: 'view/errors.500',
            restrict: 'AE',
            replace: true,
            scope: {
                message: '@',
                showHomeButton: '='
            },
            controller: 'ServerErrorController',
            controllerAs: 'vm'
        };

        return directive;
    }
})();
