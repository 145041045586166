(function() {
    'use strict';

    angular.module('customerVault.home')
        .factory('homeActions', homeActions);

    homeActions.$inject = ['$state', '$stateParams', '$uibModal', 'loginService', 'myaccountResolve', 'ordersService'];

    function homeActions($state, $stateParams, $uibModal, loginService, myaccountResolve, ordersService) {
        var service = {
            getDates: getDates,
            getImpersonate: getImpersonate,
            getInclude: getInclude,
            getState: getState,
            getTab: getTab,
            initDueDateFilter: initDueDateFilter,
            openChangePassword: openChangePassword,
            openDueDateFilter: openDueDateFilter,
            openSignIn: openSignIn,
            openStatusFilter: openStatusFilter,
            openViewOrder: openViewOrder,
            subscriptionsAvailable: subscriptionsAvailable
        };

        return service;

        function getDates(startDate, stopDate) {
            var dateArray = new Array();
            var currentDate = startDate;
            while (currentDate <= stopDate) {
                dateArray.push(new Date (currentDate));
                currentDate = currentDate.addDays(1);
            }
            return dateArray;
        }

        function getImpersonate(data) {
            if (myaccountResolve.redirect) {
                return true;
            }

            if ($state.current.name.indexOf('myaccount') != -1) {
                return true;
            }

            if (loginService.impersonate.mode && loginService.impersonate.user.id == data.user.id) {
                return true;
            }

            return false;
        }

        function getInclude(name) {
            switch (name) {
                case 'products':
                    return "view/home.tabs.products";
                    break;
                case 'trials':
                    return "view/home.tabs.trials";
                    break;
                case 'orders':
                    return "view/home.tabs.orders";
                    break;
                default:
                    // if (myaccountResolve.load.products.length == 1 && myaccountResolve.load.products[0] == 'ImageEgnine') {

                    // }
                    if (myaccountResolve.load.products.length) {
                        return "view/home.tabs.products";
                    } else {
                        return "view/home.tabs.trials";
                    }
            }
        }

        function getState() {
            var redirect = null;
            //============================================
            // Account pages are for admin viewing of user
            //=============================================
            if ($state.current.name.indexOf('myaccount') != -1) {
                switch ($state.current.name) {
                    case 'myaccount.products':
                        redirect = 'account.products';
                        break;
                    case 'myaccount.trials':
                        redirect = 'account.trials';
                        break;
                    // case 'myaccount.orders':
                    //     redirect = 'account.orders';
                    //     break;
                    default:
                        if (myaccountResolve.load.products.length) {
                            return "account.products";
                        } else {
                            return "account.trials";
                        }
                }
            } else {
                switch ($state.current.name) {
                    case 'account.products':
                        redirect = 'myaccount.products';
                        break;
                    case 'account.trials':
                        redirect = 'myaccount.trials';
                        break;
                    case 'account.orders':
                        redirect = 'myaccount.orders';
                        break;
                    default:
                        if (myaccountResolve.load.products.length) {
                            return "myaccount.products";
                        } else {
                            return "myaccount.trials";
                        }
                }
            }
            return redirect;
        }

        function getTab(my) {
            var prefix = my ? 'myaccount' : 'account';
            if ($state.current.name === prefix) {
                if (myaccountResolve.load.products.length) {
                    $state.go(prefix+'.products', {id: $stateParams.id}, {notify: false});
                    return 'products';
                } else {
                    $state.go(prefix+'.trials', {id: $stateParams.id}, {notify: false});
                    return 'trials';
                }
            }

            switch ($state.current.name) {
                case prefix+'.products':
                    return 'products';
                case prefix+'.trials':
                    return 'trials';
                case prefix+'.orders':
                    return 'orders';
                default:
                    // if products exist go to products
                    if (myaccountResolve.load.products.length) {
                        return "products";
                        // else go to trials
                    } else {
                        return "trials";
                    }
            }
        }

        function initDueDateFilter(dateOptions) {
            Date.prototype.addDays = function(days) {
                var date = new Date(this.valueOf());
                date.setDate(date.getDate() + days);
                return date;
            }

            return service.getDates(new Date(moment(angular.copy(dateOptions.floor))), new Date(moment(angular.copy(dateOptions.ceil))));
        }

        function openChangePassword() {
            $uibModal.open({
                templateUrl: 'view/home.partials.password',
                controller: 'PasswordController',
                controllerAs: 'vm',
                size: 'md',
                keyboard: false,
                windowClass: 'zindex',
                backdrop: 'static'
            });
        }

        function openDueDateFilter() {
            var dateFilter = $uibModal.open({
                templateUrl: "view/home.partials.date_filter",
                controller: "DueDateFilterController",
                controllerAs: "vm",
                size: "md",
                keyboard: false,
                windowClass: 'zindex',
                backdrop: 'static'
            });

            return dateFilter.result.then(function(response) {
                ordersService.variables.dateOptions.minDate = moment(response.minValue).format('YYYY-MM-DD');
                ordersService.variables.dateOptions.maxDate = moment(response.maxValue).format('YYYY-MM-DD');
                return _.filter(ordersService.variables.origInvoices, function(obj) {
                    var date = new Date(moment(obj.sortDate));
                    return date >= response.minValue && date <= response.maxValue;
                });
            });
        }

        function openSignIn() {
            $uibModal.open({
                templateUrl: 'view/components.signin',
                controller: 'SigninController',
                controllerAs: 'vm',
                size: 'md',
                keyboard: false,
                windowClass: 'zindex',
                backdrop: 'static'
            });
        }

        function openStatusFilter() {
            var statusFilter = $uibModal.open({
                templateUrl: "view/home.partials.status_filter",
                controller: "StatusFilterController",
                controllerAs: "vm",
                size: 'Md',
                keyboard: false,
                windowClass: 'zindex',
                backdrop: 'static'
            });

            return statusFilter.result.then(function(response) {
                ordersService.variables.selectedOrderStatus = response.selectedOrderStatus;
                return _.filter(ordersService.variables.origInvoices, function(obj) {
                    return !_.isUndefined(_.find(response.arr, function(val) {
                        return val == obj.status;
                    }));
                });
            });
        }

        function openViewOrder(invoice, origInvoices) {
            var selectedInvoice = null;
            if (invoice.type == 'external') {
                selectedInvoice = _.findWhere(origInvoices.external, {Id: invoice.id});
            } else {
                selectedInvoice = _.findWhere(origInvoices.standard, {id: invoice.id});
            }
            $uibModal.open({
                templateUrl: 'view/home.partials.receipt',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                controller: 'OrderReceiptController',
                controllerAs: 'vm',
                windowClass: 'zindex',
                resolve: {
                    invoice: function() {
                        return selectedInvoice;
                    },
                    type: function() {
                        return invoice.type;
                    }
                },
                windowClass: 'zindex'
            });
        }

        function subscriptionsAvailable(subscriptions) {
            if (_.isEmpty(subscriptions)) {
                return false;
            }
            if (
                !_.isUndefined(subscriptions.cloud) &&
                !subscriptions.cloud.length &&
                !_.isUndefined(subscriptions.imgeng) &&
                !subscriptions.imgeng.length &&
                !_.isUndefined(subscriptions.wjs) &&
                !subscriptions.wjs.length
            ) {
                return false;
            }

            return true;
        }
    }
})();
