(function() {
    'use strict';

    angular.module('customerVault.home')
        .controller('StatusFilterController', StatusFilterController);

    StatusFilterController.$inject = ['$scope', '$uibModalInstance', 'ordersService'];

    function StatusFilterController($scope, $uibModalInstance, ordersService) {
        var vm = this;

        vm.all = true;
        vm.close = close;
        vm.filter = filter;
        vm.selectAll = selectAll;
        vm.statuses = ordersService.variables.selectedOrderStatus;

        angular.forEach(vm.statuses, function(val, key) {
            if (!val.show) {
                vm.all = false;
            }
        });

        $scope.$watch('vm.statuses', function(newVal) {
            vm.all = _.every(newVal, function(obj) {
                return obj.show;
            });
        }, true);

        function close() {
            $uibModalInstance.dismiss();
        }

        function filter() {
            var statuses = [];
            angular.forEach(vm.statuses, function(val, key) {
                if (val.show) {
                    var add = !_.isUndefined(val.value) ? val.value : val.title;
                    statuses.push(add);
                }
            });

            $uibModalInstance.close({arr: statuses, selectedOrderStatus: vm.statuses});
        }

        function selectAll() {
            vm.all = !vm.all;
            angular.forEach(vm.statuses, function(val, key) {
                val.show = vm.all;
            });
        }
    }
})();
