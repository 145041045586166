(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEnginePlanInfoController', ImageEnginePlanInfoController);

    ImageEnginePlanInfoController.$inject = ['$rootScope', '$state', '$stateParams', '$uibModal', 'ieActions', 'ieResolve'];

    function ImageEnginePlanInfoController($rootScope, $state, $stateParams, $uibModal, ieActions, ieResolve) {
        var vm = this;
        var resolveData = ieResolve.load.planinfo;
        var error = ieResolve.error;
        vm.currentPlan = resolveData.plan;
        vm.direction = resolveData.direction;
        vm.error = false;
        vm.pageErrorView = 'view/errors.400';
        vm.pageErrorViewMessage = null;
        vm.plans = resolveData.plans;
        vm.upgrade = upgrade;

        if (!_.isEmpty(error)) {
            vm.error = error.status;
            switch (error.status) {
                case 403:
                    vm.pageErrorView = 'view/errors.403';
                    vm.pageErrorViewMessage = error.message;
                    break;
                default:
                    break;
            }
        }

        function upgrade(plan) {
            ieActions.openUpgrade(plan);
        }
    }
})();
