(function() {
    'use strict';

    angular.module('customerVault.ie')
        .factory('ieResolve', ieResolve);

    ieResolve.$inject = ['$ocLazyLoad', '$q', 'homeService', 'ieChartService', 'ieService', 'impersonateService', 'loginService', 'moment', 'statsToolService'];

    function ieResolve($ocLazyLoad, $q, homeService, ieChartService, ieService, impersonateService, loginService, moment, statsToolService) {
        var service = {
            error: {},
            load: {
                main: {}
            },
            mainError: {},
            run: run
        };

        return service;

        function run(args) {
            service.error = {};
            service.mainError = {};

            if (loginService.impersonate.mode) {
                // Check if impersonate user is admin
                return impersonateService.getCheckImpersonateIsAdmin(loginService.impersonate.user.id, 'subscription', args.params.id).then(function() {
                    return runIndex(args);
                }).catch(function(error) {
                    service.mainError.status = error.status;
                    service.mainError.message = error.data.message;
                    return runIndex(args);
                });
            }

            return runIndex(args);
        }

        function runIndex(args) {
            if (
                (_.isEmpty(service.load.main) || service.load.main.id != args.params.id) &&
                !_.isUndefined(args.params.id)
            ) {
                service.load.main = {};
                service.load.main.id = args.params.id;
                return ieService.getAccount(args.params.id).then(function(response) {
                    if (!_.isUndefined(response.data.statusWrapper)) {
                        service.load.main.statusWrapper = 'view/subscriptions.'+response.data.statusWrapper;
                        service.load.main.title = 'Account Status';
                    } else {
                        service.load.main.subscription = response.data.subscription;
                        service.load.main.totalBandwidth = response.data.total_bandwidth;
                        service.load.main.costs = response.data.costs;
                        service.load.main.percentDetections = Math.round((service.load.main.totalBandwidth / response.data.defaults.value) * 100);
                        if (service.load.main.percentDetections <= 60) {
                            service.load.main.percentClass = "progress-bar-success";
                        } else if (service.load.main.percentDetections <= 85) {
                            service.load.main.percentClass = "progress-bar-warning";
                        } else {
                            service.load.main.percentClass = "progress-bar-danger";
                            if (service.load.main.percentDetections >= 100) {
                                service.load.main.percentDetections = 100;
                            }
                        }
                        service.load.main.percentStyle = "width: "+service.load.main.percentDetections+"%;";
                        service.load.main.accounts = response.data.user_accounts;
                        service.load.main.docBasePath = response.data.doc_base_path;
                        service.load.main.docIEBasePath = response.data.doc_ie_base_path;
                        service.load.main.isFree = response.data.is_free;
                        service.load.main.onlinePurchaseAllowed = response.data.online_purchase_allowed;
                        service.load.main.tabs = response.data.tabs;
                        service.load.main.trialEnds = response.data.trial_ends;
                        service.load.main.trialOver = response.data.trial_over;
                        service.load.main.user = response.data.user;
                    }
                    return true;
                }).catch(function(error) {
                    service.mainError.status = error.status;
                    service.mainError.message = error.data.message;
                    return false;
                });
            } else if (args.main && !_.isUndefined(args.params.id)) {
                return Promise.resolve(true);
            } else {
                switch (args.name) {
                    case 'ie.signupcomplete':
                        return Promise.resolve(true);
                    case 'ie.signup':
                        service.load.signup = {
                            class: {
                                basic: 'plans-btn',
                                standard: 'plans-btn',
                                custom: 'plans-btn'
                            },
                            form: {
                                accountName: null,
                                paymentPlan: null,
                                domainName: null,
                                origin: null,
                                hostHeader: null,
                                originProtocol: 'http',
                                bucketname: null,
                                allowOriginPrefix: 1,
                                whitelist: [null]
                            },
                            plans: {}
                        };
                        return ieService.getPlans().then(function(response) {
                            service.load.signup.details = response.data.details;
                            angular.forEach(response.data.plans, function(val, key) {
                                service.load.signup.plans[val.payment_plan] = val;
                            });
                            service.load.signup.selectedPlan = service.load.signup.plans.IMAGEENGINE_BASIC;
                            service.load.signup.form.paymentPlan = "IMAGEENGINE_BASIC";
                            if (!_.isNull(args.params.plan)) {
                                switch (args.params.plan) {
                                    case 'basic':
                                        service.load.signup.selectedPlan = service.load.signup.plans.IMAGEENGINE_BASIC;
                                        service.load.signup.form.paymentPlan = "IMAGEENGINE_BASIC";
                                        break;
                                    case 'standard':
                                        service.load.signup.selectedPlan = service.load.signup.plans.IMAGEENGINE_STANDARD;
                                        service.load.signup.form.paymentPlan = "IMAGEENGINE_STANDARD";
                                        break;
                                    case 'custom':
                                        service.load.signup.selectedPlan = service.load.signup.plans.IMAGEENGINE_PRO;
                                        break;
                                }
                            }
                            return true;
                        }).catch(function(error) {
                            service.error.status = error.status;
                            service.error.message = error.data.message;
                            console.log(error.data);
                            return false;
                        });
                    case 'ie.trial':
                        service.load.signuptrial = {};
                        return ieService.getTrialLink(args.params.link).then(function(response) {
                            service.load.signuptrial.publicKey = response.data.public_key;
                            service.load.signuptrial.email = response.data.email;
                            return homeService.getCountries().then(function(response) {
                                service.load.signuptrial.countries = response.data;
                                return true;
                            }).catch(function(error) {
                                console.log(error.data);
                                service.error.status = error.status;
                                service.error.message = error.data.message;
                                return false;
                            });
                        }).catch(function(error) {
                            service.error.status = error.status;
                            service.error.message = error.data.message;
                            service.error.data = error.data.data;
                            console.log(error);
                            return false;
                        });
                    case 'ie.payment':
                        service.load.payment = {};
                        $ocLazyLoad.load(
                            'https://js.braintreegateway.com/web/dropin/1.9.3/js/dropin.min.js'
                        );
                        return ieService.getPaymentLink(args.params.link).then(function(response) {
                            service.load.payment.subscription = response.data.subscription;
                            service.load.payment.dateExpiration = response.data.date_expiration;
                            service.load.payment.user = response.data.user;
                            return homeService.getCountries().then(function(response) {
                                service.load.payment.countries = response.data;
                                return true;
                            }).catch(function(error) {
                                console.log(error.data);
                                service.error.status = error.status;
                                service.error.message = error.data.message;
                                return false;
                            });
                        }).catch(function(error) {
                            console.log(error.data);
                            service.error.status = error.status;
                            service.error.message = error.data.message;
                            return false;
                        });
                    case 'ie.home.summary':
                        service.load.summary = {};
                        return ieService.getSummary(args.params.id).then(function(response) {
                            service.load.summary.isFree = response.data.is_free;
                            // service.load.summary.wasUpgraded = response.data.was_upgraded;
                            var showOrigins = false;
                            angular.forEach(response.data.confs, function(val, key) {
                                if (val.url !== '') {
                                    showOrigins = true;
                                }
                            });
                            service.load.summary.showOrigins = showOrigins;
                            service.load.summary.token = response.data.token;
                            service.load.summary.origins = response.data.origins;

                            service.load.summary.ieOnly = !_.isUndefined(_.findWhere(response.data.confs, {ie_only_flag: 1}));

                            angular.forEach(response.data.confs, function(conf, key) {
                                if (conf.status.ssl || conf.ie_only_flag || conf.custom_wildcard_flag) {
                                    conf.status.ssl = "loading";
                                }

                                if (conf.origin_conf_id !== 0 && conf.url === "") {
                                    var originMatch = _.findWhere(response.data.origins, {origin_conf_id: conf.origin_conf_id});
                                    if (!_.isUndefined(originMatch)) {
                                        conf.url = originMatch.url;
                                    }
                                }
                            });

                            service.load.summary.confs = response.data.confs;

                            return true;

                            // var promises = [];
                            // var certificateError = false;
                            // var testsExist = false;
                            // angular.forEach(response.data.confs, function(conf, key) {
                            //     if (conf.origin_conf_id !== 0 && conf.url === "") {
                            //         var originMatch = _.findWhere(response.data.origins, {origin_conf_id: conf.origin_conf_id});
                            //         conf.url = originMatch.url;
                            //     }
                            //     if (conf.status.ssl || conf.ie_only_flag || conf.custom_wildcard_flag) {
                            //         promises.push(
                            //             ieService.getCertificateStatus(args.params.id, conf.cname)
                            //                 .then(function(response) {
                            //                     if (!conf.ie_only_flag && !conf.custom_wildcard_flag) {
                            //                         conf.status.ssl = response.data.status.status;
                            //                         conf.certificates = response.data.status.certificates;
                            //                         conf.validation = response.data.status.domain_validation;
                            //                         conf.test_certificates = response.data.status.test_certificates;
                            //                         conf.test_enabled = response.data.status.test_enabled;
                            //                         conf.status.test_ssl = response.data.status.test_status;
                            //                         conf.status.attached = response.data.status.attached;
                            //                         if (response.data.status.test_enabled) {
                            //                             testsExist = true;
                            //                         }
                            //                     } else if (conf.ie_only_flag) {
                            //                         service.load.summary.ieOnly = true;
                            //                         conf.status.ssl = response.data.status.status;
                            //                     } else if (conf.custom_wildcard_flag) {
                            //                         conf.status.ssl = response.data.status.status;
                            //                         conf.certificates = response.data.status.certificates;
                            //                         conf.validation = response.data.status.domain_validation;
                            //                         conf.status.attached = response.data.status.attached;
                            //                         conf.customWc = true;
                            //                     }
                            //                 }).catch(function(error) {
                            //                     certificateError = error;
                            //                     conf.status.ssl = 'ERROR';
                            //                     conf.certificates = null;
                            //                     conf.validation = null;
                            //                     conf.test_certificates = null;
                            //                     conf.test_enabled = null;
                            //                     conf.status.test_ssl = null;
                            //                     $q.reject();
                            //                 })
                            //         );
                            //     }
                            // });
                            // return $q.all(promises).then(function() {
                            //     service.load.summary.confs = response.data.confs;
                            //     service.load.summary.testsExist = testsExist;
                            //     return true;
                            // });
                        }).catch(function(error) {
                            service.error.status = error.status;
                            service.error.message = error.data.message;
                            console.log(error.data);
                            return false;
                        });
                    case 'ie.home.cache':
                        service.load.cache = {};
                        return ieService.getCachePurging(args.params.id).then(function(response) {
                            service.load.cache.origins = response.data.origins;
                            service.load.cache.purges = response.data.purges;
                            service.load.cache.domains = response.data.domains;
                            return true;
                        }).catch(function(error) {
                            service.error.status = error.status;
                            service.error.message = error.data.message;
                            console.log(error.data);
                            return false;
                        });
                    case 'ie.home.billinghistory':
                        service.load.billinghistory = {};
                        return ieService.getBillingHistory(args.params.id).then(function(response) {
                            service.load.billinghistory.subscription = response.data.subscription;
                            service.load.billinghistory.transactions = response.data.transactions;
                            service.load.billinghistory.user = response.data.user;
                            return true;
                        }).catch(function(error) {
                            service.error.status = error.status;
                            service.error.message = error.data.message;
                            console.log(error.data);
                            return false;
                        });
                    case 'ie.home.billinginfo':
                        service.load.billinginfo = {};
                        $ocLazyLoad.load(
                            'https://js.braintreegateway.com/web/dropin/1.9.3/js/dropin.min.js'
                        );
                        return ieService.getBillingInfo(args.params.id).then(function(response) {
                            service.load.billinginfo.form = response.data.form;
                            service.load.billinginfo.user = response.data.user;
                            if (_.isNull(service.load.billinginfo.form.email)) {
                                service.load.billinginfo.form.email = service.load.billinginfo.user.email;
                            }
                            return homeService.getCountries('country_id,name,code').then(function(response) {
                                service.load.billinginfo.countries = response.data;
                                service.load.billinginfo.form.country = service.load.billinginfo.form.country_id;
                                return true;
                            }).catch(function(error) {
                                console.log(error.data);
                                service.error.status = error.status;
                                service.error.message = error.data.message;
                                service.error.data = error.data.data;
                                return false;
                            });
                        }).catch(function(error) {
                            service.error.status = error.status;
                            service.error.message = error.data.message;
                            console.log(error.data);
                            return false;
                        });
                    case 'ie.home.planinfo':
                        service.load.planinfo = {};
                        return ieService.getUpgradeAccount(args.params.id).then(function(response) {
                            service.load.planinfo.plan = response.data.current_plan;
                            service.load.planinfo.plans = response.data.plans;
                            service.load.planinfo.direction = response.data.direction;
                            return true;
                        }).catch(function(error) {
                            service.error.status = error.status;
                            service.error.message = error.data.message;
                            console.log(error.data);
                            return false;
                        });
                    case 'ie.home.upgrade':
                        service.load.upgrade = {};
                        return ieService.getPlanUpgrade(args.params.id, args.params.plan).then(function(response) {
                            service.load.upgrade.costs = response.data.costs;
                            service.load.upgrade.currentPlan = response.data.current;
                            service.load.upgrade.difference = response.data.difference;
                            service.load.upgrade.newPlan = response.data.new;
                            service.load.upgrade.nextCycle = response.data.next_cycle;
                            service.load.upgrade.subscription = response.data.subscription;
                            service.load.upgrade.daysLeft = response.data.days_left;
                            service.load.upgrade.user = response.data.user;
                            if (response.data.current.payment_plan == 'IMAGEENGINE_TRIAL') {
                                $ocLazyLoad.load(
                                    'https://js.braintreegateway.com/web/dropin/1.9.3/js/dropin.min.js'
                                );
                                return homeService.getCountries().then(function(response) {
                                    service.load.upgrade.countries = response.data;
                                    return true;
                                }).catch(function(error) {
                                    console.log(error.data);
                                    service.error.status = error.status;
                                    service.error.message = error.data.message;
                                    return false;
                                });
                            }
                            return true;
                        }).catch(function(error) {
                            service.error.status = error.status;
                            service.error.message = error.data.message;
                            console.log(error.data);
                            return false;
                        });
                    case 'ie.home.statistics':
                        service.load.statistics = null;
                        return ieService.getStatistics(args.params.id).then(function(response) {
                            service.load.statistics = response.data;
                            return true;
                        }).catch(function(error) {
                            service.error.status = error.status;
                            service.error.message = error.data.message;
                            console.log(error.data);
                            return false;
                        });

                    default:
                        return Promise.resolve(true);
                }
                console.log(args);
                return Promise.resolve(true);
            }
        }
    }
})();
