(function() {
    'use strict';

    angular.module('customerVault.impersonate')
        .controller('ImpersonateAdminController', ImpersonateAdminController);

    ImpersonateAdminController.$inject = ['$location', '$rootScope', '$state', '$stateParams', 'endSession', 'flashMessage', 'impersonateService', 'loaderService', 'loginService'];

    function ImpersonateAdminController($location, $rootScope, $state, $stateParams, endSession, flashMessage, impersonateService, loaderService, loginService) {
        if (_.isNull($stateParams.session)) {
            runError();
            return;
        }
        if (_.isNull($stateParams.access_token)) {
            runError();
            return;
        }

        var session = $stateParams.session;
        var sm_auth_token = $stateParams.access_token;

        $location.search('session', null);
        $location.search('access_token', null);
        $rootScope.$broadcast('endImpersonation');
        impersonateService.postLoginFromAdmin(session, sm_auth_token);

        function runError() {
            endSession.end();
            loginService.authInfo.authenticated = false;
            loginService.authInfo.user = null;
            $rootScope.$broadcast('authenticated');
            flashMessage.message.message = "Your are not permitted to perform that action. Please log in to continue.";
            flashMessage.message.show = true;
            $state.go('login');
            return;
        }
    }
})();
