(function() {
    'use strict';

    angular.module('customerVault.layout')
        .controller('HeaderController', HeaderController);

    HeaderController.$inject = ['$rootScope', '$scope', '$state', '$window', 'authenticate', 'endSession', 'filexService', 'flashMessage', 'loginService', 'menuService', 'Notification', 'pendingRequestsService'];

    function HeaderController($rootScope, $scope, $state, $window, authenticate, endSession, filexService, flashMessage, loginService, menuService, Notification, pendingRequestsService) {
        var vm = this;

        vm.admin = false;
        vm.adminLink = null;
        vm.authenticated = loginService.authInfo.authenticated;
        vm.expand = expand;
        vm.expanded = false;
        vm.headerLinks1 = null;
        vm.headerLinks2 = null;
        vm.impersonate = loginService.impersonate.mode;
        vm.loggedIn = false;
        vm.login = login;
        vm.logout = logout;
        vm.searchBtnClass = '';
        vm.slideToggle = {};
        vm.subExpand = subExpand;
        vm.subExpanded = [];
        vm.toggleSearch = toggleSearch;
        vm.user = loginService.authInfo.user;
        // Added this variable to check if users are coming from ImageEngine login.
        vm.cameFromImageEngine = $window.document.referrer.indexOf('imageengine.io') > 0 || $window.document.location.href.includes('IE-trial-email');

        var authenticated = $rootScope.$on('authenticated', function() {
            vm.loggedIn = loginService.authInfo.authenticated;
            vm.authenticated = loginService.authInfo.authenticated;
            vm.user = loginService.authInfo.user;
            if (!_.isNull(loginService.authInfo.user)) {
                menuService.getAdmin(loginService.authInfo.user.id).then(function(response) {
                    vm.admin = response.data.admin;
                    vm.adminLink = response.data.url;
                    if (vm.admin) {
                        encrypt();
                    }
                }).catch(function(error) {
                    console.log(error);
                });
            }
            $scope.$on('$destroy', authenticated);
        });

        var startImpersonate = $rootScope.$on('startImpersonateHeader', function() {
            vm.impersonate = loginService.impersonate.mode;
            $scope.$on('$destroy', startImpersonate);
        });
        var endImpersonate = $rootScope.$on('endImpersonateHeader', function() {
            vm.impersonate = loginService.impersonate.mode;
            $scope.$on('$destroy', endImpersonate);
        });

        function encrypt() {
            menuService.getSession(loginService.authInfo.user.id).then(function(response) {
                vm.session = response.data.session;
            });
        }

        setInterval(function() {
            if (loginService.authInfo.authenticated && vm.admin) {
                encrypt();
            }
        }, 60*1000);

        menuService.getHeader().then(function(response) {
            vm.headerLinks1 = _.findWhere(response.data, {id: 'header-nav-1'});
            vm.headerLinks2 = _.findWhere(response.data, {id: 'header-nav-2'});
            angular.forEach(vm.headerLinks1.links, function(val, key) {
                vm.subExpanded[val.section.label] = false;
                vm.slideToggle[val.section.label] = false;
            });
            angular.forEach(vm.headerLinks2.links, function(val, key) {
                vm.subExpanded[val.section.label] = false;
                vm.slideToggle[val.section.label] = false;
            });
        }).catch(function(error) {
            console.log(error);
        });

        function expand() {
            vm.expanded = !vm.expanded;
            $rootScope.$broadcast('header-expand', {expanded: vm.expanded});
            if (vm.expanded) {
                $window.scrollTo(0, 0);
            }
        }

        function login() {
            loginService.intended = undefined;
            vm.expanded = false;
            $rootScope.$broadcast('header-expand', {expanded: vm.expanded});
            $state.go('login');
        }

        function logout() {
            flashMessage.resetMessage();
            loginService.intended = undefined;
            pendingRequestsService.cancelAll();
            vm.expanded = false;
            $rootScope.$broadcast('header-expand', {expanded: vm.expanded});

            return logoutUser().then(function() {});
        }

        function logoutUser() {
            return authenticate.logout()
                .then(function(data) {
                    if (data) {
                        loginService.authInfo.authenticated = false;
                        loginService.authInfo.user = null;
                        $rootScope.$broadcast('authenticated');
                        $rootScope.$broadcast('endImpersonation');
                        endSession.end();
                        $state.go('login');
                        Notification.success("You have successfully logged out!");
                    } else {
                        alert("Logout failed");
                    }
                });
        }

        function subExpand(key, $event) {
            $event.preventDefault();
            vm.subExpanded[key] = !vm.subExpanded[key];
            vm.slideToggle[key] = !vm.slideToggle[key];
            console.log(key);
        }

        function toggleSearch() {
            vm.searchBtnClass = vm.searchBtnClass == '' ? 'opened' : '';
        }
    }
})();
