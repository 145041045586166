(function() {
    'use strict';

    angular.module('customerVault.wurfljs')
        .controller('WurfljsDeleteCertificateController', WurfljsDeleteCertificateController);

    WurfljsDeleteCertificateController.$inject = ['$stateParams', '$uibModalInstance', 'bsLoadingOverlayService', 'cname', 'Notification', 'wurfljsService'];

    function WurfljsDeleteCertificateController($stateParams, $uibModalInstance, bsLoadingOverlayService, cname, Notification, wurfljsService) {
        var vm = this;

        vm.close = close;
        vm.name = cname.cname;
        vm.proceed = proceed;
        vm.type = 'CName';

        function close() {
            $uibModalInstance.dismiss();
        }

        function proceed() {
            bsLoadingOverlayService.start({
                referenceId: 'wjs-delete-confirm'
            });
            wurfljsService.deleteCname($stateParams.id, cname.id).then(function(response) {
                Notification.success("Successfully removed cname");
                $uibModalInstance.close();
            }).catch(function(error) {
                console.log(error);
                Notification.error('Unable to delete cname');
                $uibModalInstance.dismiss();
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'wjs-delete-confirm'
                });
            });
        }
    }
})();
