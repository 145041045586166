(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineCancelController', ImageEngineCancelController);

    ImageEngineCancelController.$inject = ['$q', '$rootScope', '$stateParams', '$uibModalInstance', 'bsLoadingOverlayService', 'ieResolve', 'ieService'];

    function ImageEngineCancelController($q, $rootScope, $stateParams, $uibModalInstance, bsLoadingOverlayService, ieResolve, ieService) {
        var vm = this;

        vm.cancel = cancel;
        vm.close = close;
        vm.message = null;
        vm.messageError = false;

        var domains = ieResolve.load.main.confs;
        var domainPromises = [];

        function cancel() {
            vm.messageError = false;
            if (_.isNull(vm.message) || vm.message == '') {
                vm.messageError = true;
                return;
            }

            bsLoadingOverlayService.start({
                referenceId: 'ie-cancel-account'
            });

            if (domains.length === 0) {
                finish();
            } else {
                angular.forEach(domains, function(val, key) {
                    domainPromises.push(
                        ieService.postDeleteResourceRecords($stateParams.id, val.id, false)
                            .then(function(){})
                            .catch(function(error) {
                                // This will be sent to sentry in the back end
                                console.log(error);
                                $q.reject();
                            })
                    );
                });
                $q.all(domainPromises).then(function() {
                    finish();
                });
            }
        }

        function close() {
            $uibModalInstance.close(true);
        }

        function finish() {
            ieService.postCancelAccount($stateParams.id, vm.message).then(function(response) {
                ieResolve.load.main.subscription.status = 'CANCELED';
                $rootScope.$broadcast('checkPaymentState');
                $uibModalInstance.close(response.data);
            }).catch(function(error) {
                console.log(error);
                Notification.error("Unable to cancel account. Please contact support@scientiamobile.com");
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-cancel-account'
                });
            });
        }
    }
})();
