(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineSummaryController', ImageEngineSummaryController);

    ImageEngineSummaryController.$inject = ['$q', '$rootScope', '$stateParams', '$window', 'bsLoadingOverlayService', 'ieActions', 'ieResolve', 'ieService', 'Notification'];

    function ImageEngineSummaryController($q, $rootScope, $stateParams, $window, bsLoadingOverlayService, ieActions, ieResolve, ieService, Notification) {
        var vm = this;
        var resolveData = ieResolve.load.summary;
        var error = ieResolve.error;

        vm.allowPrefix = _.indexOf(_.pluck(resolveData.confs, 'allow_origin_prefix'), 1) !== -1;
        vm.changeTab = changeTab;
        vm.confs = resolveData.confs;
        vm.deleteDomain = deleteDomain;
        vm.deleteOrigin = deleteOrigin;
        vm.enableSsl = enableSsl;
        vm.error = false;
        vm.getButtonClass = getButtonClass;
        vm.getTextClass = getTextClass;
        vm.iamFlag = !_.every(_.pluck(vm.confs, 'iam_flag'), function(flag) {
            return !flag
        });
        vm.ieOnly = resolveData.ieOnly;
        vm.ieOnlyFail = false;
        vm.isFree = resolveData.isFree;
        vm.issuedEnabled = false;
        vm.generalError = false;
        vm.openDomain = openDomain;
        vm.openOrigin = openOrigin;
        vm.openSettings = openSettings;
        vm.origins = resolveData.origins;
        vm.pageErrorView = 'view/errors.400';
        vm.showOrigins = resolveData.showOrigins;
        vm.statusError = !_.isUndefined(_.find(vm.confs, function(obj) {
            return obj.status.ssl == 'ERROR';
        }));
        vm.subscription = ieResolve.load.main.subscription;
        vm.testsExist = false;
        vm.trialEndDate = ieResolve.load.main.trialEnds;
        vm.token = resolveData.token;
        vm.viewDns = viewDns;
        vm.viewSsl = viewSsl;

        var certificatePromises = [];
        var currentConf = null;
        var enableError = false;
        var trialOver = ieResolve.load.main.trialOver;
        var validation = null;
        var witCertificate = null;

        if (!_.isEmpty(error)) {
            vm.error = true;
        }

        ieService.loadCertificateStatuses(angular.copy(vm.confs))
            .then(function(statuses) {
                vm.confs = statuses.confs;
                vm.testsExist = !ieResolve.load.main.trialOver && statuses.testsExist;
                vm.ieOnlyFail = !_.isUndefined(_.find(vm.confs, function(obj) {
                    return obj.status.ssl == 'FAILED' || obj.status.ssl == 'REVOKED' || obj.status.ssl == 'EXPIRED';
                }));
                ieResolve.load.main.confs = vm.confs;
                vm.issuedEnabled = !_.isUndefined(_.find(vm.confs, function(obj) {
                    return obj.status.ssl == 'ISSUED' && !obj.status.attached;
                })) && vm.ieOnly;
            });

        function changeTab(tab) {
            $rootScope.$broadcast('ieChangeTab', {tab: tab});
        }

        function deleteDomain(domain) {
            var origin = _.findWhere(vm.origins, {origin_conf_id: domain.id});
            var confirmDeleteDomain = ieActions.confirmDeleteDomain(domain, origin);
            confirmDeleteDomain.result.then(function(response) {
                vm.confs = _.reject(vm.confs, function(obj) {
                    return obj.id == domain.id;
                });
                if (vm.confs.length == 0) {
                    $rootScope.$broadcast('enableCache', {status: false});
                }
                if (!_.isUndefined(response.origin)) {
                    _.map(vm.origins, function(obj) {
                        if (obj.id == response.origin.id) {
                            obj.origin_conf_id = response.origin.origin_conf_id;
                            obj.in_use = response.origin.in_use;
                        }

                        return obj;
                    });
                }
                var showOrigins = false;
                angular.forEach(vm.confs, function(val, key) {
                    if (val.url !== '') {
                        showOrigins = true;
                    }
                });
                vm.showOrigins = showOrigins;
                vm.allowPrefix = _.indexOf(_.pluck(vm.confs, 'allow_origin_prefix'), 1) !== -1;
            })
        }

        function deleteOrigin(origin) {
            bsLoadingOverlayService.start({
                referenceId: 'ie-origins'
            });
            ieService.deleteOrigin($stateParams.id, origin.id).then(function(response) {
                Notification.success("Successfully deleted origin");
                vm.origins = _.reject(vm.origins, function(obj) {
                    return obj.id == origin.id;
                });
            }).catch(function(error) {
                console.log(error);
                Notification.error(error.data.message);
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-origins'
                });
            })
        }

        function completeEnable(wildcardEnabled) {
            if (!enableError) {
                _.map(vm.confs, function(obj) {
                    if (obj.cname == currentConf.cname) {
                        obj.status.ssl = 'PENDING_USER_VALIDATION';
                        obj.validation = {
                            type: 'DNS',
                            validation: validation
                        };
                        obj.wit_certificate = witCertificate;
                    }
                    return obj;
                });

                ieActions.openViewSsl(_.findWhere(vm.confs, {cname: currentConf.cname}), true, wildcardEnabled).result
                    .then(function(response) {
                        _.map(vm.confs, function(obj) {
                            if (obj.cname == response.cname) {
                                obj = response;
                            }
                            return obj;
                        });
                });
            } else {
                vm.generalError = "We are unable to generate the certificate at this time. Please contact support@scientiamobile.com for assistance.";
            }
            bsLoadingOverlayService.stop({
                referenceId: 'ie-domains'
            });
            enableError = false;
            validation = null;
            witCertificate = null;
            currentConf = null;
            certificatePromises = [];
        }

        function enableSsl(conf) {
            currentConf = conf;
            bsLoadingOverlayService.start({
                referenceId: 'ie-domains'
            });

            var wildcardEnabled = false;

            ieService.getAwsRegionsByAccount($stateParams.id).then(function(response) {
                angular.forEach(response.data, function(val, key) {
                    certificatePromises.push(
                        ieService.postRequestCertificate(conf.subscription_id, conf.cname, val.RegionName).then(function(response) {
                            validation = response.data.validation;
                            witCertificate = response.data.certificates;
                            wildcardEnabled = response.data.validation.wildcard_test_enabled
                        }).catch(function(error) {
                            console.log(error);
                            enableError = true;
                            $q.reject();
                        })
                    );
                });
                $q.all(certificatePromises).then(function() {
                    var hash = validation.hash;
                    if (!_.isUndefined(hash)) {
                        conf.customWc = true;
                        conf.custom_wildcard_flag = 1;
                        // Run update shard records if need be
                        ieService.patchUpdateShardingForWildcard($stateParams.id, conf.id, hash)
                            .then(function(response) {})
                            .catch(function(error) {
                                console.log(error);
                                conf.attached = false;
                                // Sentry alert triggered. Don't need to show the user anything.
                            }).finally(function() {
                                completeEnable(wildcardEnabled);
                            });
                    } else {
                        completeEnable(wildcardEnabled);
                    }
                });
            }).catch(function(error) {
                vm.generalError = "We are unable to generate the certificate at this time. Please contact support@scientiamobile.com for assistance.";
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-domains'
                });
            });
        }

        function getButtonClass(status, attached) {
            switch (status) {
                case 'ISSUED':
                    return 'small-matching-btn success';
                case 'INCORRECT_VALIDATION_VALUE':
                    return 'small-matching-btn danger';
                case 'INCORRECT_VALIDATION_NAME':
                    return 'small-matching-btn danger';
                case 'PENDING_VALIDATION':
                    return 'small-matching-btn warning';
                case 'PENDING_USER_VALIDATION':
                    return 'small-matching-btn warning';
                case 'loading':
                    return 'small-matching-btn default-disabled';
                case null:
                    return 'small-matching-btn default';
                case 'ERROR':
                    return 'danger small-matching-btn-disabled';
                default:
                    return 'small-matching-btn danger';
            }
        }

        function getTextClass(status, attached) {
            switch (status) {
                case 'ISSUED':
                    if (attached) {
                        return 'text-success';
                    } else {
                        return 'text-warning';
                    }
                case 'PENDING_VALIDATION':
                    return 'text-warning';
                case null:
                case 'loading':
                    return 'text-default';
                default:
                    return 'text-danger';
            }
        }

        function openDomain(isNew, domainId) {
            domainId = _.isUndefined(domainId) ? null : domainId;
            var openEditDomain = ieActions.openEditDomain(isNew, vm.origins, domainId);
            openEditDomain.result.then(function(response) {
                vm.showOrigins = true;
                if (!_.isNull(response)) {
                    if (isNew) {
                        if (!_.isUndefined(response.origin)) {
                            vm.origins.push(response.origin);
                            response.conf.origin_name = response.origin.name;
                            response.conf.url = response.origin.url;
                        } else {
                            var match = _.find(vm.confs, function(conf) {
                                return conf.id == response.conf.origin_conf_id;
                            });
                            if (!_.isUndefined(match)) {
                                response.conf.url = match.url;
                            }
                        }

                        if (
                            !_.isUndefined(response.conf.validation) &&
                            !_.isNull(response.conf.validation) &&
                            !_.isUndefined(response.conf.validation.validation.hash)
                        ) {
                            response.conf.customWc = true;
                        }

                        vm.confs.push(response.conf);
                        vm.allowPrefix = _.indexOf(_.pluck(vm.confs, 'allow_origin_prefix'), 1) !== -1;
                        if (vm.confs.length == 1) {
                            $rootScope.$broadcast('enableCache', {status: true});
                        }

                        if (!_.isUndefined(response.origin_update)) {
                            // Make it in use
                            _.map(vm.origins, function(obj) {
                                if (obj.id == response.origin_update.id) {
                                    obj.origin_conf_id = response.origin_update.origin_conf_id;
                                    obj.in_use = response.origin_update.in_use;
                                    if (!_.isUndefined(response.origin_update.url)) {
                                        obj.url = response.origin_update.url;
                                    }
                                }
                                return obj;
                            });
                        }
                    } else {
                        vm.isFree = response.is_free;
                        var showOrigins = false;
                        angular.forEach(response.confs, function(val, key) {
                            if (val.url !== '') {
                                showOrigins = true;
                            }
                        });
                        vm.showOrigins = showOrigins;
                        vm.token = response.token;
                        vm.origins = response.origins;

                        vm.ieOnly = false;
                        _.map(vm.confs, function(obj) {
                            var match = _.find(response.confs, function(conf) {
                                return conf.id == obj.id;
                            });
                            obj.origin_name = match.origin_name;
                            obj.origin_conf_id = match.origin_conf_id;
                            obj.wit_allowed_prefix = match.wit_allowed_prefix;
                            obj.allow_origin_prefix = match.allow_origin_prefix;
                            if (match.url !== '') {
                                obj.url = match.url;
                            } else {
                                var originMatch = _.find(vm.origins, function(origin) {
                                    return origin.origin_conf_id === match.origin_conf_id;
                                });
                                obj.url = originMatch.url;
                            }
                        });

                        vm.allowPrefix = _.indexOf(_.pluck(vm.confs, 'allow_origin_prefix'), 1) !== -1;
                    }
                }
            }).catch(function(response) {
                if (!_.isUndefined(response.conf)) {
                    vm.confs.push(response.conf);
                }
                if (!_.isUndefined(response.origin)) {
                    vm.origins.push(response.origin);
                }

                if (!_.isUndefined(response.origin_update)) {
                    // Make it in use
                    _.map(vm.origins, function(obj) {
                        if (obj.id == response.origin_update.id) {
                            obj.origin_conf_id = response.origin_update.origin_conf_id;
                            obj.in_use = response.origin_update.in_use;
                            if (!_.isUndefined(response.origin_update.url)) {
                                obj.url = response.origin_update.url;
                            }
                        }
                        return obj;
                    });
                }

                if (!_.isUndefined(response.old_origin)) {
                    _.map(vm.origins, function(obj) {
                        if (obj.id == response.old_origin.id) {
                            obj.origin_conf_id = response.old_origin.origin_conf_id;
                            obj.in_use = response.old_origin.in_use;
                        }
                        return obj;
                    });
                }

                if (!_.isUndefined(response.type)) {
                    var message = "Your domain configuration has been saved, however error(s) occurred:";
                    if (response.type === 'aws') {
                        if (_.indexOf(response.error_type, 'certificate') != -1) {
                            message += "<br />Unable to generate your SSL/TLS certificate";
                            _.map(vm.confs, function(obj) {
                                if (obj.id === response.conf.id) {
                                    obj.status.ssl = null;
                                }
                                return obj;
                            });
                        }
                        if (_.indexOf(response.error_type, 'resource record') != -1) {
                            message += "<br />Unable to configure your DNS record";
                        }
                    }
                    message += "<br />Please contact support@scientiamobile.com for assistance.";
                    vm.generalError = message;
                    $window.scrollTo(0, 0);
                } else {
                    Notification.error("An unknown error occurred. Please contact support@scientiamobile.com for assistance.");
                }
            });
        }

        function openOrigin(isNew, originId) {
            var openEditOrigin = ieActions.openEditOrigin(isNew, originId, vm.confs);
            openEditOrigin.result.then(function(response) {
                if (isNew) {
                    vm.origins.push(response);
                    $rootScope.$broadcast('enableCache', {status: true});
                } else {
                    _.map(vm.origins, function(obj) {
                        if (obj.id === originId) {
                            obj.name = response.name;
                            obj.url = response.url;
                            obj.url_type = response.url_type;
                            obj.hostname = response.hostname;
                            obj.in_use = response.in_use;
                        }
                        return obj;
                    });
                    _.map(vm.confs, function(obj) {
                        if (obj.id == response.origin_conf_id || obj.origin_conf_id == response.origin_conf_id) {
                            obj.origin_name = response.name;
                            obj.url = response.url;
                        }
                        return obj;
                    });
                }
            });
        }

        function openSettings(conf) {
            var openSettings = ieActions.openSettings(conf);
        }

        function viewDns(conf) {
            ieActions.openViewDns(conf);
        }

        function viewSsl(conf, test) {
            if (_.isUndefined(test)) {
                test = true;
                if (trialOver && conf.status.attached) {
                    test = false;
                }
            }
            ieActions.openViewSsl(conf, test);
        }
    }
})();
