(function() {
    'use strict';

    angular.module('customerVault.wurfljs')
        .controller('WurflJsController', WurflJsController);

    WurflJsController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$uibModal', '$window', 'breadcrumbsService', 'bsLoadingOverlayService', 'loaderService', 'loginService', 'myaccountResolve', 'Notification', 'wurfljsActions', 'wurfljsResolve'];

    function WurflJsController($rootScope, $scope, $state, $stateParams, $timeout, $uibModal, $window, breadcrumbsService, bsLoadingOverlayService, loaderService, loginService, myaccountResolve, Notification, wurfljsActions, wurfljsResolve) {
        var vm = this;
        var resolveData = null;
        var error = null;

        vm.accounts = null;
        vm.allowedDetections = null;
        vm.billingPeriod = null;
        vm.cancelAccount = cancelAccount;
        vm.changeTab = changeTab;
        vm.defaults = null;
        vm.documentation = null;
        vm.error = false;
        vm.id = $stateParams.id;
        vm.increaseDetections = increaseDetections;
        vm.millionsDetections = null;
        vm.onlinePurchaseAllowed = null;
        vm.pageErrorView = 'view/errors.400';
        vm.percentClass = null;
        vm.percentDetections = null;
        vm.percentStyle = null;
        vm.plan = null;
        vm.selectedAccount = undefined;
        vm.statusWrapper = false;
        vm.steps = null;
        vm.subscription = null;
        vm.switchAccounts = switchAccounts;
        vm.termsLink = null;
        vm.title = 'WURFL.js Account Setting';
        vm.totalDetections = null;
        vm.user = null;
        vm.viewTab = wurfljsActions.getTab();

        run();

        function run() {
            resolveData = wurfljsResolve.load.main;
            error = wurfljsResolve.mainError;

            if (!_.isEmpty(error) && (_.isEmpty(error.data) || error.data.status != 'invalid')) {
                vm.error = error.status;
                switch (error.status) {
                    case 404:
                        vm.pageErrorView = 'view/errors.404';
                        $rootScope.$broadcast('setTitle', {title: 'Page Not Found'});
                        break;
                    case 403:
                        vm.pageErrorViewMessage = error.message;
                        break;
                    default:
                        vm.pageErrorView = 'view/errors.400';

                }
                return;
            }

            if (!_.isUndefined(resolveData.statusWrapper)) {
                vm.statusWrapper = resolveData.statusWrapper;
                vm.title = 'Account Status';
            } else {
                vm.accounts = resolveData.accounts;
                vm.allowedDetections = resolveData.allowedDetections;
                vm.billingPeriod = resolveData.billingPeriod;
                vm.defaults = resolveData.defaults;
                vm.documentation = resolveData.documentation;
                vm.domains = resolveData.domains;
                vm.error = false;
                vm.onlinePurchaseAllowed = resolveData.onlinePurchaseAllowed;
                vm.millionsDetections = resolveData.millionsDetections;
                vm.percentDetections = resolveData.percentDetections;
                vm.percentClass = resolveData.percentClass;
                vm.percentStyle = resolveData.percentStyle;
                vm.plan = resolveData.plan;
                vm.steps = wurfljsActions.getSteps(resolveData);
                vm.subscription = resolveData.subscription;
                vm.totalDetections = resolveData.totalDetections;
                if (
                    resolveData.domains.length == 0 &&
                    ($state.current.name == 'wurfljs.home.summary' || $state.current.name == 'wurfljs.home')
                ) {
                    changeTab('gettingstarted');
                } else if (resolveData.domains.length != 0 && $state.current.name == 'wurfljs.home') {
                    changeTab('summary');
                }
            }
        }

        var wjsChangeTab = $rootScope.$on('wjsChangeTab', function(event, args) {
            vm.loaded = false;
            $stateParams.plan = args.plan;
            changeTab(args.tab);
            $scope.$on('$destroy', wjsChangeTab);
        });

        var reloadWurflJsAccount = $rootScope.$on('reloadWurflJsAccount', function() {
            myaccountResolve.load = {};
            bsLoadingOverlayService.start({
                referenceId: 'wurfljs-top'
            });
            wurfljsResolve.load.main = {};
            wurfljsResolve.run({name: 'wurfljs.home', params: $stateParams}).then(function(response) {
                if (response) {
                    run();
                } else {
                    vm.error = 400;
                    vm.pageErrorView = 'view/errors.400';
                }
                bsLoadingOverlayService.stop({
                    referenceId: 'wurfljs-top'
                });
            });
            $scope.$on('$destroy', reloadWurflJsAccount);
        });

        var reloadWurfljsBreadcrumbs = $rootScope.$on('reloadWurfljsBreadcrumbs', function() {
            if (!_.isEmpty(error)) {
                wurfljsResolve.mainError = {};
                run();
            } else {
                vm.steps = null;

                $timeout(function() {
                    var params = {};
                    if (vm.subscription.payment_type == 'TRIAL' || vm.subscription.payment_type == 'FREE') {
                        params.parent = {state: 'trials', title: 'Trials'};
                    } else {
                        params.parent = {state: 'products', title: 'Products'};
                    }

                    if (loginService.impersonate.mode || resolveData.user.id == loginService.authInfo.user.id) {
                        vm.steps = breadcrumbsService.get('wurfljs.home', params);
                    } else {
                        params.user = resolveData.user;
                        vm.steps = breadcrumbsService.get('wurfljs.home', params);
                    }
                }, 100);
            }
            $scope.$on('$destroy', reloadWurfljsBreadcrumbs);
        });

        var wurfljsCanceledAccount = $rootScope.$on('wurfljsCanceledAccount', function() {
            vm.statusWrapper = 'view/subscriptions.status_cancelled';
            vm.title = 'Account Status';
            myaccountResolve.load = {};
            $window.scrollTo(0, 0);
            $scope.$on('$destroy', wurfljsCanceledAccount);
        });

        $rootScope.$on('$stateChangeSuccess', function() {
            bsLoadingOverlayService.stop({
                referenceId: 'wurfljs-tabs'
            });
        });

        function cancelAccount() {
            wurfljsActions.openCancelAccount();
        }

        function changeTab(tab) {
            loaderService.show = false;
            bsLoadingOverlayService.start({
                referenceId: 'wurfljs-tabs'
            });
            vm.viewTab = tab;
            $state.go('wurfljs.home.'+tab, $stateParams);
        }

        function increaseDetections() {
            bsLoadingOverlayService.start({
                referenceId: 'wurfljs-tabs'
            });
            wurfljsResolve.run({name: 'wurfljs.home.increasedetections', params: {id: $stateParams.id}}).then(function(response) {
                if (response) {
                    var modal = wurfljsActions.openIncreaseDetections(vm.defaults);
                    modal.result.then(function(response) {
                        vm.millionsDetections = response;
                    });
                } else {
                    bsLoadingOverlayService.stop({
                        referenceId: 'wurfljs-tabs'
                    });
                    Notification.error(wurfljsResolve.error.message);
                }
            });
        }

        function switchAccounts() {
            if (vm.selectedAccount.id != $stateParams.id) {
                $state.go('wurfljs.home', {id: vm.selectedAccount.id});
            }
        }
    }
})();
