(function () {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineSettingsPathValidationController', ImageEngineSettingsPathValidationController);

    ImageEngineSettingsPathValidationController.$inject = ['$uibModalInstance', 'bsLoadingOverlayService', 'correctPattern', 'ieService', 'pattern'];

    function ImageEngineSettingsPathValidationController($uibModalInstance, bsLoadingOverlayService, correctPattern, ieService, pattern) {
        var vm = this;

        vm.changePath = false;
        vm.close = close;
        vm.error = {
            show: false,
            message: null,
            class: null
        };
        vm.form = [null];
        vm.newPattern = null;
        vm.newPatternError = false;
        vm.pattern = pattern;
        vm.running = false;
        vm.showAssumption = showAssumption;
        vm.singlePattern = "/"+pattern;
        vm.updateForm = updateForm;
        vm.updatePath = updatePath;
        vm.valid = [null];
        vm.validate = validate;

        function close() {
            $uibModalInstance.close(vm.pattern);
        }

        function showAssumption() {
            return !vm.pattern.match(/^\//);
        }

        function updateForm(key) {
            if (_.isUndefined(key)) {
                vm.form.push(null);
                vm.valid.push(null);
            } else {
                vm.form.splice(key, 1);
                vm.valid.splice(key, 1);
                if (vm.form.length === 0) {
                    vm.form = [null];
                    vm.valid = [null];
                }
            }
        }

        function updatePath(val) {
            if (val) {
                angular.forEach(vm.valid, function(val, key) {
                    vm.valid[key] = null;
                });
                if (_.isNull(vm.newPattern) || vm.newPattern == '') {
                    vm.newPatternError = true;
                    return;
                }
                vm.pattern = angular.copy(vm.newPattern);
                if (!vm.pattern.match(/^\//)) {
                    vm.singlePattern = "/" + vm.pattern;
                    correctPattern = "/**/"+vm.pattern;
                } else {
                    correctPattern = angular.copy(vm.pattern);
                }
            }
            vm.newPattern = null;
            vm.changePath = false;
        }

        function validate() {
            if (_.every(vm.form, function(val) {
                return val === '' || _.isNull(val);
            })) {
                vm.error.class = "alert-danger";
                vm.error.message = "Please provide at least on file path."
                vm.error.show = true;
                return;
            }

            var form = []
            angular.forEach(vm.form, function(val) {
                if (!val.match(/^\//)) {
                    form.push("/" + val);
                } else {
                    form.push(val);
                }
            });

            bsLoadingOverlayService.start({
                referenceId: 'ie-path-form'
            });
            vm.running = true;
            ieService.postValidatePath(correctPattern, form)
                .then(function(response) {
                    angular.forEach(response.data, function(val, key) {
                        var indx = _.indexOf(form, key);
                        vm.valid[indx] = val;
                    });
                }).catch(function(error) {
                    console.log(error.data);
                    vm.error.class = "alert-danger";
                    vm.error.message = "An error occurred while validating your file paths."
                    vm.error.show = true;
                }).finally(function() {
                    bsLoadingOverlayService.stop({
                        referenceId: 'ie-path-form'
                    });
                    vm.running = false;
                });
        }
    }
})();
