(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .controller('CloudUsageController', CloudUsageController);

    CloudUsageController.$inject = ['$stateParams', 'bsLoadingOverlayService', 'cloudActions', 'cloudResolve', 'cloudService', 'Notification'];

    function CloudUsageController($stateParams, bsLoadingOverlayService, cloudActions, cloudResolve, cloudService, Notification) {
        var vm = this;
        var resolveData = cloudResolve.load.usage;
        var error = cloudResolve.error;

        vm.apiKeys = resolveData.apiKeys;
        vm.chart = null;
        vm.chartLoading = true;
        vm.chartLoaded = false;
        vm.error = false;
        vm.pageErrorView = 'view/errors.400';
        vm.periods = resolveData.periods;
        vm.selectedPeriod = resolveData.selectedPeriod;
        vm.update = update;

        if (!_.isEmpty(error)) {
            vm.error = true;
        } else {
            if (resolveData.totalDetections != 0) {
                init(resolveData.detections);
            } else {
                vm.chartLoaded = false;
                vm.chartLoading = false;
            }
        }

        function getDetections(run) {
            cloudService.getDetections($stateParams.id, vm.selectedPeriod.start_timestamp, vm.selectedPeriod.end_timestamp).then(function(response) {
                if (response.data.detection_data.total_detections != 0) {
                    init(response.data.detection_data.detections);
                } else {
                    vm.chartLoaded = false;
                    vm.chartLoading = false;
                }
            }).catch(function(error) {
                console.log(error);
                Notification.error(error.message);
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'cloud-usage-display'
                });
            });
        }

        function init(detections) {
            vm.chart = cloudActions.usageInit(detections, vm.apiKeys);
            vm.chartLoaded = true;
            vm.chartLoading = false;
        }

        function update() {
            bsLoadingOverlayService.start({
                referenceId: 'cloud-usage-display'
            });
            getDetections();
        }
    }
})();
