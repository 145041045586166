(function() {
    'use strict';

    angular.module('customerVault.quwurfl')
        .controller('QuWURFLController', QuWURFLController);

    QuWURFLController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$window', 'breadcrumbsService', 'bsLoadingOverlayService', 'quwurflActions', 'quwurflResolve', 'quwurflService', 'loaderService', 'loginService', 'myaccountResolve', 'Notification'];

    function QuWURFLController($rootScope, $scope, $state, $stateParams, $timeout, $window, breadcrumbsService, bsLoadingOverlayService, quwurflActions, quwurflResolve, quwurflService, loaderService, loginService, myaccountResolve, Notification) {
        var vm = this;
        var resolveData = null;
        var error = null;

        vm.accounts = null;
        vm.activeCapabilities = null;
        vm.capabilities = null;
        vm.domains = null;
        vm.changeTab = changeTab;
        vm.homeTab = 'view/quwurfl.subpages.home';
        vm.maxCapabilities = null;
        vm.notAvailable = notAvailable;
        vm.pageError = false;
        vm.pageErrorView = 'view/errors.400';
        vm.pageNotFound = false;
        vm.pageNotFoundView = 'view/errors.404'
        vm.selectedAccount = undefined;
        vm.steps = null;
        vm.subscription = {};
        vm.title = 'WURFL Query API Account Settings';
        vm.user = null;
        vm.viewTab = 'home';

        var notAvailable = null;

        vm.viewTab = quwurflActions.getTab($state.current.name);

        run();

        function run() {
            resolveData = quwurflResolve.load;
            error = quwurflResolve.error;

            if (!_.isEmpty(error) && (_.isEmpty(error.data) || error.data.status != 'invalid')) {
                vm.error = error.status;
                if (vm.error == 403) {
                    vm.pageErrorViewMessage = error.message;
                } else if (vm.error == 404) {
                    $rootScope.$broadcast('setTitle', {title: 'Page Not Found'});
                }
                return;
            }

            vm.capabilities = resolveData.capabilities;
            vm.domains = resolveData.domains;
            vm.user = resolveData.user;
            vm.keys = resolveData.apiKeys;


            var params = {title: 'WURFL Query API Account Details'};
            params.parent = {state: 'products', title: 'Products'};
            if (loginService.impersonate.mode) {
                if (loginService.impersonate.user.id != resolveData.user.id) {
                    params.user = resolveData.user;
                }
            } else {
                if (loginService.authInfo.user.id != resolveData.user.id) {
                    params.user = resolveData.user;
                }
            }
            vm.steps = breadcrumbsService.get('quwurfl.home', params);
        }

        var reloadQuWURFLBreadcrumbs = $rootScope.$on('reloadQuWURFLBreadcrumbs', function() {
            if (!_.isEmpty(error)) {
                quwurflResolve.mainError = {};
                run();
            } else {
                vm.steps = null;
                $timeout(function() {
                    var params = {};                    
                    params.parent = {state: 'products', title: 'Products'};
                    params.user = vm.user;
                    vm.steps = breadcrumbsService.get('quwurfl.home', params);
                }, 100);
            }
            $scope.$on('$destroy', reloadQuWURFLBreadcrumbs);
        });

        $rootScope.$on('$stateChangeSuccess', function() {
            bsLoadingOverlayService.stop({
                referenceId: 'quwurfl-tabs'
            });
        });

        function changeTab(tab) {
            if (!_.isNull(notAvailable)) {
                notAvailable.close();
            }
            if (vm.viewTab != tab) {
                if (tab == "home") {
                    window.location.href = '/quwurfl/app/'+$stateParams.id;
                    return;
                }
                vm.viewTab = tab;
                quwurflActions.changeTab(tab);
            }
        }
    }
})();
