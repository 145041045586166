(function() {
    'use strict';

    angular.module('customerVault')
        .service('capabilitiesService', capabilitiesService);

    function capabilitiesService() {
        this.capabilities = null;
    }
})();
