(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineTrialCompleteController', ImageEngineTrialCompleteController);

    ImageEngineTrialCompleteController.$inject = ['$state', '$stateParams', 'breadcrumbsService', 'ieService', 'signupService'];

    function ImageEngineTrialCompleteController($state, $stateParams, breadcrumbsService, ieService, signupService) {
        var vm = this;

        if (!$stateParams.fromTrial) {
            $state.go('myaccount');
            return;
        }

        Date.prototype.addDays = function(days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }

        var trialEnds = new Date(moment(ieService.transfer.subscription.date_started));

        vm.certificate = _.isUndefined(ieService.transfer.certificate) ? null : ieService.transfer.certificate;
        vm.error = $stateParams.error;
        vm.message = signupService.value;
        vm.show = {
            domain: false,
            origin: false,
            certificate: false
        };
        vm.steps = breadcrumbsService.get('ie.trialcomplete');
        vm.subscription = ieService.transfer.subscription;
        vm.trialEnds = trialEnds.addDays(vm.subscription.plan.trial_days);

        if (!vm.error) {
            vm.show.domain = true;
            vm.show.origin = true;
            vm.show.certificate = true;
        } else {
            if (_.indexOf(vm.error, 'domain') === -1) {
                vm.show.domain = true;
            }
            if (_.indexOf(vm.error, 'origin') === -1) {
                vm.show.origin = true;
            }
            if (_.indexOf(vm.error, 'certificate') === -1) {
                vm.show.certificate = true;
            }
        }
    }
})();
