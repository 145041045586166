(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineViewSslController', ImageEngineViewSslController);

    ImageEngineViewSslController.$inject = ['$q', '$stateParams', '$uibModalInstance', 'bsLoadingOverlayService', 'conf', 'ieService', 'Notification', 'test', 'wildcardEnabled'];

    function ImageEngineViewSslController($q, $stateParams, $uibModalInstance, bsLoadingOverlayService, conf, ieService, Notification, test, wildcardEnabled) {
        var vm = this;

        vm.alert = null;
        vm.close = close;
        vm.conf = conf;
        vm.enabled = wildcardEnabled ? wildcardEnabled : conf.test_enabled;
        vm.requestTestCertificate = requestTestCertificate;
        vm.resubmitCertificate = resubmitCertificate;
        vm.status = null;
        vm.test = test;

        var attachError = false;
        var deleteError = false;
        var detachError = false;
        var enableError = false;
        var regionPromises = [];
        var test = false;
        var validation = null;
        var witCertificate = null;

        getStatus();
        alertSet();

        function alertSet() {
            vm.alert = {
                class: null,
                show: false,
                message: null
            };
        }

        function close() {
            $uibModalInstance.close(vm.conf);
        }

        function finishTestEnable() {
            if (!enableError) {
                vm.conf.wit_certificate = witCertificate;
                vm.enabled = true;
                vm.alert = {
                    class: 'alert-success',
                    message: "Your test SSL certificate has been enabled. Please wait a few minutes for the change to take effect.",
                    show: true
                };
            } else {
                vm.alert = {
                    class: 'alert-danger',
                    message: "We are unable to enable the certificate at this time. Please contact support@scientiamobile.com for assistance.",
                    show: true
                };
            }
            enableError = false;
            regionPromises = [];

            bsLoadingOverlayService.stop({
                referenceId: 'ie-ssl-view'
            });
        }

        function getStatus() {
            switch (conf.status.ssl) {
                case 'ISSUED':
                    vm.status = 'SSL Certificate has been issued';
                    if (!conf.status.attached) {
                        vm.status += ' and is propagating to our network.<br />No action is needed at this time.';
                    }
                    break;
                case 'PENDING_VALIDATION':
                    var message = "Waiting for verification";
                    if (vm.conf.validation.type === 'DNS') {
                        message += " through DNS";
                    } else {
                        message += " via email";
                    }
                    vm.status = message;
                    break;
                case 'VALIDATION_TIMED_OUT':
                    vm.status = 'Request has timed out';
                    break;
                case 'EXPIRED':
                    vm.status = 'SSL Certificate is expired';
                    break;
                case 'INACTIVE':
                    vm.status = 'SSL Certificate is inactive'; // TODO Check on this one
                    break;
                case 'REVOKED':
                    vm.status = 'SSL Certificate has been revoked';
                    // vm.revokedAt = conf. TODO Revocation info
                    break;
                case 'FAILED':
                    vm.status = 'Request failed'; // TODO Failure info
                    break;
            }
        }

        function requestTestCertificate(domainName) {
            test = true;
            alertSet();
            bsLoadingOverlayService.start({
                referenceId: 'ie-ssl-view'
            });

            var certificateIds = [];
            var detailsPromises = [];

            ieService.getAwsRegionsByAccount($stateParams.id).then(function(response) {
                angular.forEach(response.data, function(val, key) {
                    regionPromises.push(ieService.postRequestTestCertificate(vm.conf.subscription_id, vm.conf.cname, val.RegionName, val.Deploy)
                        .then(function(response) {
                            witCertificate = response.data.certificates;
                            if (response.data.dns) {
                                certificateIds.push({
                                    region: val.RegionName,
                                    id: response.data.certificate_id
                                });
                            }
                        }).catch(function(error) {
                            console.log(error);
                            enableError = true;
                            $q.reject();
                        })
                    );
                });

                $q.all(regionPromises).then(function() {
                    if (certificateIds.length !== 0) {
                        ieService.getCertificateValidationDetails($stateParams.id, certificateIds[0].id)
                            .then(function(response) {
                                var records = [{
                                    domain: domainName + ".",
                                    region: 'global',
                                    type: 'CNAME',
                                    certificate: response.data.ResourceRecord
                                }];
                                ieService.postResourceRecords($stateParams.id, records)
                                    .then(function() {})
                                    .catch(function(error) {
                                        console.log(error);
                                        enableError = true;
                                    }).finally(function() {
                                        finishTestEnable();
                                    });
                            }).catch(function(error) {
                                console.log(error);
                                enableError = true;
                                finishTestEnable();
                            });
                    } else {
                        finishTestEnable();
                    }
                });
            }).catch(function(error) {
                vm.generalError = "We are unable to generate the certificate at this time. Please contact support@scientiamobile.com for assistance.";
                bsLoadingOverlayService.stop({
                    referenceId: 'ie-ssl-view'
                });
            });
        }

        function resetVariables() {
            attachError = false;
            deleteError = false;
            detachError = false;
            enableError = false;
            regionPromises = [];
            witCertificate = null;
            validation = null;
        }

        function resubmitCertificate() {
            test = false;
            bsLoadingOverlayService.start({
                referenceId: 'ie-ssl-view'
            });

            var allBad = _.isEmpty(_.find(vm.conf.certificates, function(obj) {
                return obj.status === 'ISSUED' || obj.status === 'PENDING_VALIDATION';
            }));
            var allPv = _.every(_.reject(vm.conf.certificates, function(obj) {
                return obj.status !== 'ISSUED' && obj.status !== 'PENDING_VALIDATION';
            }), function(obj) {
                return obj.status === 'PENDING_VALIDATION';
            });
            var issued = !_.isEmpty(_.where(vm.conf.certificates, {status: "ISSUED"}));
            var dbStore = true;

            angular.forEach(vm.conf.certificates, function(info, region) {
                if (info.status !== 'ISSUED' && info.status !== 'PENDING_VALIDATION') {
                    // Delete the certificate
                    var id = _.findWhere(vm.conf.wit_certificate, {region: region, test_certificate: 0}).id;
                    regionPromises.push(
                        ieService.deleteCertificate($stateParams.id, id)
                            .then(function(response) {})
                            .catch(function(error) {
                                console.log(error.data);
                                deleteError = true;
                                $q.reject();
                            })
                    );

                    var records = [{
                        domain: vm.conf.cname,
                        region: region,
                        type: 'A'
                    }];
                    if (allBad && !info.r53_exists) {
                        // Generate and store the R53 record
                        regionPromises.push(
                            ieService.postResourceRecords($stateParams.id, {records: records})
                                .then(function(response) {})
                                .catch(function(error) {
                                    console.log(error.data);
                                    attachError = true;
                                    $q.reject();
                                })
                        );
                    } else if (issued && info.r53_exists) {
                        // Remove the R53 record to ensure that traffic flows to issued certificate
                        dbStore = false;
                        regionPromises.push(
                            ieService.postDetachFromShard($stateParams.id, {records: records, region: region})
                                .then(function(response) {})
                                .catch(function(error) {
                                    console.log(error.data);
                                    detachError = true;
                                    $q.reject();
                                })
                        );
                    }

                    // Create the certificate (`deploy` doesn't matter)
                    regionPromises.push(
                        ieService.postRequestCertificate($stateParams.id, vm.conf.cname, region, 'ALL', dbStore)
                            .then(function(response) {
                                witCertificate = response.data.certificates;
                                validation = response.data.validation;
                            }).catch(function(error) {
                                console.log(error.data);
                                enableError = true;
                                $q.reject();
                            })
                    );
                }
            });

            $q.all(regionPromises).then(function() {
                if (!enableError) {
                    vm.conf.wit_certificate = witCertificate;
                    var message = "The certificate request has been resubmitted.";
                    var alertClass = "alert-success";
                    var allError = false;
                    ieService.getCertificateStatus($stateParams.id, vm.conf.cname)
                        .then(function(response) {
                            if (!vm.conf.ie_only_flag) {
                                vm.conf.certificates = response.data.status.certificates;
                                vm.conf.status.ssl = response.data.status.status;
                                vm.conf.validation = response.data.status.validation;
                                // Added this to return the invalid dns record if the user has input something incorrect
                                vm.conf.invalid_record = response.data.status.invalid_record;

                                if (response.data.status.domain_validation.type === 'DNS') {
                                    message += " Please ensure that the record information is in your DNS for verification.";
                                } else {
                                    message += " Please check your email for validation.";
                                }
                            }
                            getStatus();
                        }).catch(function(error) {
                            console.log(error.data);
                            alertClass = "alert-warning";
                            message += " We are unable to determine the certificate status at this time";
                            allError = true;
                        }).finally(function() {
                            if (deleteError) {
                                message += " Please note that the timed out certificate could not be deleted.";
                                alertClass = 'alert-warning';
                                allError = true;
                            }
                            if (attachError) {
                                message += " Please note that the region could not be attached to the shard.";
                                alertClass = 'alert-warning';
                                allError = true;
                            }
                            if (detachError) {
                                message += " Please note that the region was not detached from the shard.";
                                alertClass = 'alert-danger';
                                allError = true;
                            }

                            if (allError) {
                                message += " Please contact support@scientiamobile.com for assistance.";
                            }
                            vm.alert = {
                                class: alertClass,
                                message: message,
                                show: true
                            };
                            resetVariables();
                            bsLoadingOverlayService.stop({
                                referenceId: 'ie-ssl-view'
                            });
                        });
                } else {
                    vm.alert = {
                        class: 'alert-danger',
                        message: "We are unable to resubmit the certificate at this time. Please contact support@scientiamobile.com for assistance.",
                        show: true
                    };
                    resetVariables();
                    bsLoadingOverlayService.stop({
                        referenceId: 'ie-ssl-view'
                    });
                }
            });
        }
    }
})();
