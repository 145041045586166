(function() {
    'use strict';

    angular.module('customerVault.wurfljs')
        .controller('WurfljsDeleteDomainController', WurfljsDeleteDomainController);

    WurfljsDeleteDomainController.$inject = ['$stateParams', '$uibModalInstance', 'bsLoadingOverlayService', 'domain', 'Notification', 'wurfljsService'];

    function WurfljsDeleteDomainController($stateParams, $uibModalInstance, bsLoadingOverlayService, domain, Notification, wurfljsService) {
        var vm = this;

        vm.close = close;
        vm.name = domain.domain;
        vm.proceed = proceed;
        vm.type = 'Hostname';

        function close() {
            $uibModalInstance.dismiss();
        }

        function proceed() {
            bsLoadingOverlayService.start({
                referenceId: 'wjs-delete-confirm'
            });
            wurfljsService.deleteDomain($stateParams.id, domain.id).then(function(response) {
                Notification.success("Successfully marked hostname for deletion");
                $uibModalInstance.close(response.data);
            }).catch(function(error) {
                console.log(error);
                Notification.error("Unable to delete hostname");
                $uibModalInstance.dismiss();
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'wjs-delete-confirm'
                });
            });
        }
    }
})();
