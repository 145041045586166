(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .controller('CloudReceiptController', CloudReceiptController);

    CloudReceiptController.$inject = ['$state', '$stateParams', '$timeout', '$uibModalInstance', 'bsLoadingOverlayService', 'cloudResolve', 'cloudService', 'transactionId'];

    function CloudReceiptController($state, $stateParams, $timeout, $uibModalInstance, bsLoadingOverlayService, cloudResolve, cloudService, transactionId) {
        var vm = this;
        var resolveData = cloudResolve.load.transaction;

        vm.alertClass = 'alert-success';
        vm.amountDue = resolveData.amountDue;
        vm.billingPeriod = resolveData.billingPeriod;
        vm.close = close;
        vm.email = resolveData.email;
        vm.emailError = null;
        vm.product = resolveData.product;
        vm.sendEmail = sendEmail;
        vm.sendMessage = 'Sending...';
        vm.showMessage = false;
        vm.totalPaid = resolveData.totalPaid;
        vm.transaction = resolveData.transaction;

        function close() {
            $uibModalInstance.dismiss();
        }

        function sendEmail() {
            vm.emailError = null;
            if (_.isNull(vm.email) || vm.email == '') {
                vm.emailError = 'error';
                return;
            }
            bsLoadingOverlayService.start({
                referenceId: 'cloud-receipt'
            });
            cloudService.postSendTransactionReceipt($stateParams.id, transactionId, vm.email).then(function(response) {
                vm.sendMessage = response.message;
                vm.alertClass = 'alert-success';
            }).catch(function(error) {
                console.log(error);
                vm.sendMessage = error.data.message;
                vm.alertClass = 'alert-danger';
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: 'cloud-receipt'
                });
                vm.showMessage = true;
                $timeout(function() {
                    vm.showMessage = false;
                }, 5000);
            });
        }
    }
})();
