(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .controller('CloudNotAvailableController', CloudNotAvailableController);

    CloudNotAvailableController.$inject = ['$rootScope', '$uibModalInstance', 'isPremium', 'subscription'];

    function CloudNotAvailableController($rootScope, $uibModalInstance, isPremium, subscription) {
        var vm = this;

        vm.changeTab = changeTab;
        vm.close = close;
        vm.isPremium = vm.isPremium;
        vm.subscription = vm.subscription;

        function changeTab(tab, params) {
            $rootScope.$broadcast('cloudChangeTab', {tab: tab, other: params});
            $uibModalInstance.close();
        }

        function close() {
            $uibModalInstance.close();
        }
    }
})();
