(function() {
    'use strict';

    angular.module('customerVault')
        .factory('flashMessage', flashMessage);

    flashMessage.$inject = [];

    function flashMessage() {
        var message = {
            message: null,
            show: null,
            admin: false,
            impersonateUser: null,
            dismissible: true,
            class: 'alert-danger'
        };
        var service = {
            resetMessage: resetMessage
        };

        service.message = angular.copy(message);

        return service;

        function resetMessage() {
            service.message = angular.copy(message);
        }
    }
})();
