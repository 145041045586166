(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineDeleteSettingController', ImageEngineDeleteSettingController);

    ImageEngineDeleteSettingController.$inject = ['$stateParams', '$uibModalInstance', 'bsLoadingOverlayService', 'ieService', 'setting'];

    function ImageEngineDeleteSettingController($stateParams, $uibModalInstance, bsLoadingOverlayService, ieService, setting) {
        var vm = this;

        vm.close = close;
        vm.setting = setting;
        vm.submit = submit;

        function close() {
            $uibModalInstance.dismiss();
        }

        function submit() {
            bsLoadingOverlayService.start({
                referenceId: 'ie-delete-setting-form'
            });
            ieService.deleteSetting($stateParams.id, vm.setting.id)
                .then(function(response) {
                    $uibModalInstance.close(true);
                }).catch(function(error) {
                    console.log(error);
                    $uibModalInstance.close(false);
                }).finally(function() {
                    bsLoadingOverlayService.stop({
                        referenceId: 'ie-delete-setting-form'
                    });
                })
        }
    }
})();
