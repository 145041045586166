(function() {
    'use strict';

    angular.module('customerVault')
        .controller('ServerErrorController', ServerErrorController);

    ServerErrorController.$inject = ['$scope'];

    function ServerErrorController($scope) {
        var vm = this;

        vm.message = $scope.message;
        vm.showHomeButton = $scope.showHomeButton;
    }
})();
