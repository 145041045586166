(function() {
    'use strict';

    angular.module('customerVault.quwurfl')
        .factory('quwurflActions', quwurflActions);

    quwurflActions.$inject = ['$state', '$stateParams', '$uibModal', 'breadcrumbsService', 'bsLoadingOverlayService', 'quwurflResolve', 'loaderService', 'loginService'];

    function quwurflActions($state, $stateParams, $uibModal, breadcrumbsService, bsLoadingOverlayService, quwurflResolve, loaderService, loginService) {
        var service = {
            changeTab: changeTab,
            getSteps: getSteps,
            getTab: getTab,
        };

        var states = {
            apikeys: 'apikeys',
            home: '',
            domains: 'domains'
        };

        return service;

        function changeTab(tab) {
            loaderService.show = false;
            bsLoadingOverlayService.start({
                referenceId: 'quwurfl-tabs'
            });
            var state = 'quwurfl.home';
            var params = {id: $stateParams.id};
            if (tab !== 'home') {
                state = state + "." + tab;
            }
            $state.go(state, params);
        }

        function getSteps(data) {
            var params = {};

            if (loginService.impersonate.mode) {
                if (loginService.impersonate.user.id != data.user.id) {
                    params.user = data.user;
                }
            } else {
                if (loginService.authInfo.user.id != data.user.id) {
                    params.user = data.user;
                }
            }
            return breadcrumbsService.get('quwurfl.home', params);
        }

        function getTab(name) {
            switch (name) {
                case 'quwurfl.home.apikeys':
                    return 'apikeys';
                case 'quwurfl.home.domains':
                    return 'domains'
                default:
                    return 'home';
            }
        }
    }
})();
