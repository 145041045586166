(function() {
    'use strict';

    angular.module('customerVault.cloud')
        .controller('CloudBillingHistoryController', CloudBillingHistoryController);

    CloudBillingHistoryController.$inject = ['$state', '$stateParams', 'bsLoadingOverlayService', 'cloudActions', 'cloudResolve'];

    function CloudBillingHistoryController($state, $stateParams, bsLoadingOverlayService, cloudActions, cloudResolve) {
        var vm = this;
        var resolveData = cloudResolve.load.billinghistory;
        var error = cloudResolve.error;

        vm.error = false;
        vm.openReceipt = openReceipt;
        vm.pageErrorView = null;
        vm.pageErrorViewMessage = null;
        vm.transactions = resolveData.transactions;

        if (!_.isEmpty(error)) {
            vm.error = error.status;
            switch (error.status) {
                case 403:
                    vm.pageErrorViewMessage = error.message;
                    break;
                default:
                    vm.pageErrorView = 'view/errors.400';
            }
        }

        function openReceipt(transactionId) {
            cloudActions.openReceipt(transactionId);
        }
    }
})();
