(function() {
    'use strict';

    angular.module('customerVault.ie')
        .factory('statsToolService', statsToolService);

    statsToolService.$inject = ['moment'];

    function statsToolService(moment) {
        var service = {
            extractCurrentCycle: extractCurrentCycle,
            extractLimits: extractLimits,
            formatCycles: formatCycles
        };

        return service;

        function extractCurrentCycle(cycles) {
            if (cycles.length <= 0) {
                return false;
            }

            var latest = null;

            latest = _.reject(cycles, function(c) {
                var end = moment(c.end_date);
                var now = moment();
                return now.isAfter(end, 'day');
            });

            if (_.isEmpty(latest)) {
                return cycles[(cycles.length - 1)].id;
            }

            return latest[0].id;
        }

        function extractLimits(usage_info) {
            var bandwidth_info = _.values(usage_info.bandwidth);
            var bandwidth_limit = _.reduce(bandwidth_info, function(memo, log) {
                return memo + +(log.value);
            } , 0);

            var bytessaved_limit = 0;

            if (!_.isUndefined(usage_info.bytessaved)) {
                var original_sizes = _.values(usage_info.bytessaved.original_size);
                var original_size_total = _.reduce(original_sizes, function(memo, log) {
                    return memo + +(log.value);
                } , 0);

                var reduced_sizes = _.values(usage_info.bytessaved.reduced_size);
                var reduced_size_total = _.reduce(reduced_sizes, function(memo, log) {
                    return memo + +(log.value);
                } , 0);

                bytessaved_limit = original_size_total - reduced_size_total;
            }

            return {
                'bandwidth' : bandwidth_limit,
                'bytessaved' : bytessaved_limit
            }
        }

        function formatCycles(cycles) {
            var formatted = {};

            angular.forEach(cycles, function(c) {
                var cycle_start_date = moment(c.start_date.date);
                var cycle_end_date = moment(c.end_date.date);

                formatted[c.id] =  _.extend(c,{
                    'pretty': cycle_start_date.format('YYYY MMM Do') + ' - ' +   cycle_end_date.format('YYYY MMM Do'),
                    'id':""+c.id,
                    'start_date': c.start_date.date,
                    'end_date' : c.end_date.date
                });
            });
            return formatted;
        }
    }
})();
