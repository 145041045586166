(function() {
    'use strict';

    angular.module('customerVault.ie')
        .factory('ieActions', ieActions);

    ieActions.$inject = ['$rootScope', '$state', '$stateParams', '$uibModal', 'breadcrumbsService', 'bsLoadingOverlayService', 'ieService', 'loginService', 'Notification'];

    function ieActions($rootScope, $state, $stateParams, $uibModal, breadcrumbsService, bsLoadingOverlayService, ieService, loginService, Notification) {
        var service = {
            confirmAllSettings: confirmAllSettings,
            confirmDeleteDomain: confirmDeleteDomain,
            confirmDeleteSetting: confirmDeleteSetting,
            getSteps: getSteps,
            getTab: getTab,
            openAddSetting: openAddSetting,
            openCancelAccount: openCancelAccount,
            openCompareDomainOrigin: openCompareDomainOrigin,
            openEditDomain: openEditDomain,
            openEditOrigin: openEditOrigin,
            openPathValidation: openPathValidation,
            openPatternExplanation: openPatternExplanation,
            openRestrictionExplanation: openRestrictionExplanation,
            openSettings: openSettings,
            openSettingsExplanation: openSettingsExplanation,
            openTrialEmailExplanation: openTrialEmailExplanation,
            openUpgrade: openUpgrade,
            openUpgradeConfirm: openUpgradeConfirm,
            openViewDns: openViewDns,
            openViewReceipt: openViewReceipt,
            openViewSsl: openViewSsl
        };

        return service;

        function confirmAllSettings(message) {
            return $uibModal.open({
                templateUrl: "view/imageengine.partials.confirm_settings",
                controller: function($uibModalInstance) {
                    var vm = this;

                    vm.close = close;
                    vm.message = message;
                    vm.proceed = proceed;

                    function close() {
                        $uibModalInstance.dismiss()
                    }

                    function proceed() {
                        $uibModalInstance.close();
                    }
                },
                controllerAs: 'vm',
                size: 'md',
                keyboard: false,
                windowClass: 'zindex-double-plus',
                backdrop: 'static'
            });
        }

        function confirmDeleteDomain(domain, origin) {
            return $uibModal.open({
                templateUrl: 'view/imageengine.partials.delete_domain',
                controller: 'ImageEngineDeleteDomainController',
                controllerAs: 'vm',
                size: 'md',
                keyboard: false,
                windowClass: 'zindex',
                backdrop: 'static',
                resolve: {
                    domain: function() {
                        return domain;
                    },
                    origin: function() {
                        return origin;
                    }
                }
            });
        }

        function confirmDeleteSetting(setting) {
            return $uibModal.open({
                templateUrl: 'view/imageengine.partials.delete_setting',
                controller: 'ImageEngineDeleteSettingController',
                controllerAs: 'vm',
                size: 'md',
                keyboard: false,
                windowClass: 'zindex-plus',
                backdrop: 'static',
                resolve: {
                    setting: function() {
                        return setting;
                    }
                }
            });
        }

        function getSteps(data) {
            var params = {};

            if (data.subscription.payment_type == 'TRIAL' || data.subscription.payment_type == 'FREE') {
                params.parent = {state: 'trials', title: 'Trials'};
            } else {
                params.parent = {state: 'products', title: 'Products'};
            }

            if (loginService.impersonate.mode) {
                if (loginService.impersonate.user.id != data.user.id) {
                    params.user = data.user;
                }
            } else {
                if (loginService.authInfo.user.id != data.user.id) {
                    params.user = data.user;
                }
            }
            return breadcrumbsService.get('ie.home', params);
        }

        function getTab() {
            switch ($state.current.name) {
                case 'ie.home.summary':
                    return 'summary';
                case 'ie.home.cache':
                    return 'cache';
                case 'ie.home.billinghistory':
                    return 'billinghistory';
                case 'ie.home.billinginfo':
                    return 'billinginfo';
                case 'ie.home.planinfo':
                    return 'planinfo';
                case 'ie.home.upgrade':
                    return 'upgrade';
                case 'ie.home.statistics':
                    return 'statistics';
                default:
                    return 'summary';
            }
        }

        function openAddSetting(domain, isNew, setting) {
            return $uibModal.open({
                templateUrl: 'view/imageengine.partials.settings',
                controller: 'ImageEngineSettingsController',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                windowClass: 'zindex-plus',
                backdrop: 'static',
                resolve: {
                    domain: function() {
                        return domain;
                    },
                    isNew: function() {
                        return isNew;
                    },
                    setting: function() {
                        return setting;
                    }
                }
            });
        }

        function openCancelAccount() {
            return $uibModal.open({
                templateUrl: 'view/imageengine.partials.cancel',
                controller: 'ImageEngineCancelController',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                windowClass: 'zindex',
                backdrop: 'static'
            });
        }

        function openCompareDomainOrigin() {
            return $uibModal.open({
                templateUrl: 'view/imageengine.partials.compare_domain_origin',
                controller: function($uibModalInstance) {
                    var vm = this;

                    vm.close = close;
                    vm.confirm = confirm;

                    function close() {
                        $uibModalInstance.dismiss();
                    }

                    function confirm() {
                        $uibModalInstance.close();
                    }
                },
                controllerAs: 'vm',
                size: 'md',
                keyboard: false,
                windowClass: 'zindex-plus',
                backdrop: 'static'
            });
        }

        function openEditDomain(isNew, origins, domainId) {
            domainId = _.isUndefined(domainId) ? null : domainId;
            return $uibModal.open({
                templateUrl: 'view/imageengine.partials.domain',
                controller: 'ImageEngineDomainController',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                windowClass: 'zindex',
                backdrop: 'static',
                resolve: {
                    isNew: function() {
                        return isNew;
                    },
                    origins: function() {
                        return origins;
                    },
                    domainId: function() {
                        return domainId;
                    }
                }
            });
        }

        function openEditOrigin(isNew, originId, domains) {
            originId = _.isUndefined(originId) ? null : originId;
            return $uibModal.open({
                templateUrl: 'view/imageengine.partials.origin',
                controller: 'ImageEngineOriginController',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                windowClass: 'zindex',
                backdrop: 'static',
                resolve: {
                    isNew: function() {
                        return isNew;
                    },
                    originId: function() {
                        return originId;
                    },
                    domains: function() {
                        return domains;
                    }
                }
            });
        }

        function openPathValidation(pattern, correctPattern) {
            return $uibModal.open({
                templateUrl: 'view/imageengine.partials.path_validation',
                size: 'lg',
                keyboard: false,
                windowClass: 'zindex-double-plus',
                backdrop: 'static',
                controller: 'ImageEngineSettingsPathValidationController',
                controllerAs: 'vm',
                resolve: {
                    correctPattern: function() {
                        return correctPattern;
                    },
                    pattern: function() {
                        return pattern;
                    }
                }
            });
        }

        function openPatternExplanation() {
            $uibModal.open({
                templateUrl: 'view/imageengine.partials.settings_pattern',
                size: 'lg',
                keyboard: false,
                windowClass: 'zindex-double-plus',
                backdrop: 'static',
                controller: function($uibModalInstance) {
                    var vm = this;

                    vm.close = close;

                    function close() {
                        $uibModalInstance.close();
                    }
                },
                controllerAs: 'vm'
            });
        }

        function openRestrictionExplanation() {
            $uibModal.open({
                templateUrl: 'view/imageengine.partials.restriction_explanation',
                size: 'md',
                keyboard: false,
                windowClass: 'zindex-plus',
                backdrop: 'static',
                controller: function($uibModalInstance) {
                    var vm = this;

                    vm.close = function() {
                        $uibModalInstance.close();
                    };
                },
                controllerAs: 'vm'
            });
        }

        function openSettings(conf) {
            return $uibModal.open({
                templateUrl: 'view/imageengine.partials.edit_settings',
                size: 'lg',
                keyboard: false,
                windowClass: 'zindex',
                backdrop: 'static',
                controller: 'ImageEngineEditSettingsController',
                controllerAs: 'vm',
                resolve: {
                    domain: function() {
                        return conf;
                    }
                }
            });
        }

        function openSettingsExplanation(title, message) {
            $uibModal.open({
                templateUrl: 'view/imageengine.partials.settings_explanation',
                size: 'md',
                keyboard: false,
                windowClass: 'zindex-double-plus',
                backdrop: 'static',
                controller: function($uibModalInstance) {
                    var vm = this;

                    vm.close = close;
                    vm.message = message;
                    vm.title = title;

                    function close() {
                        $uibModalInstance.close();
                    }
                },
                controllerAs: 'vm'
            });
        }

        function openTrialEmailExplanation() {
            $uibModal.open({
                templateUrl: 'view/imageengine.partials.trialemail_explanation',
                size: 'md',
                keyboard: false,
                windowClass: 'zindex',
                backdrop: 'static',
                controller: function($scope, $uibModalInstance) {
                    $scope.close = close;

                    function close() {
                        $uibModalInstance.close();
                    }
                }
            });
        }

        function openUpgrade(plan) {
            if (plan == 'IMAGEENGINE_PRO') {
                $uibModal.open({
                    templateUrl: 'view/imageengine.partials.pro_upgrade',
                    size: 'md',
                    keyboard: false,
                    backdrop: 'static',
                    windowClass: 'zindex',
                    controller: 'GenericModalController',
                    controllerAs: 'vm'
                });
                return;
            }

            var shortPlan = null;
            switch (plan) {
                case 'IMAGEENGINE_BASIC':
                    shortPlan = 'basic';
                    break;
                case 'IMAGEENGINE_STANDARD':
                    shortPlan = 'standard';
                    break;
                default:
                    Notification.error(plan+" is not a valid plan");
                    return;
            }

            $rootScope.$broadcast('ieChangeTab', {tab: 'upgrade', plan: shortPlan});
        }

        function openUpgradeConfirm(obj) {
            return $uibModal.open({
                templateUrl: 'view/imageengine.partials.upgrade_confirm',
                size: 'lg',
                keyboard: false,
                windowClass: 'zindex',
                backdrop: 'static',
                controller: 'ImageEngineUpgradeConfirmController',
                controllerAs: 'vm',
                resolve: {
                    obj: function() {
                        return obj;
                    }
                }
            });
        }

        function openViewDns(conf) {
            $uibModal.open({
                templateUrl: 'view/imageengine.partials.dns_view',
                controller: 'ImageEngineViewDnsController',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                windowClass: 'zindex',
                resolve: {
                    conf: function() {
                        return conf;
                    }
                }
            });
        }

        function openViewReceipt(subscription, transaction, user) {
            $uibModal.open({
                templateUrl: 'view/imageengine.partials.receipt',
                controller: 'ImageEngineReceiptController',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                windowClass: 'zindex',
                resolve: {
                    subscription: function() {
                        return subscription;
                    },
                    transaction: function() {
                        return transaction;
                    },
                    user: function() {
                        return user;
                    }
                }
            });
        }

        function openViewSsl(conf, test, wildcardEnabled) {
            return $uibModal.open({
                templateUrl: 'view/imageengine.partials.ssl_view',
                controller: 'ImageEngineViewSslController',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                windowClass: 'zindex',
                resolve: {
                    conf: function() {
                        return conf;
                    },
                    test: function() {
                        return test;
                    },
                    wildcardEnabled: function() {
                        return wildcardEnabled;
                    }
                }
            });
        }
    }
})();
