(function() {
    'use strict';

    angular.module('customerVault.license')
        .factory('licenseAction', licenseAction);

    licenseAction.$inject = ['$rootScope', '$state', '$stateParams', '$uibModal', '$window', 'breadcrumbsService', 'loginService'];

    function licenseAction($rootScope, $state, $stateParams, $uibModal, $window, breadcrumbsService, loginService) {
        var service = {
            createEvalLicense: createEvalLicense,
            createLicense: createLicense,
            download: download,
            getSteps: getSteps,
            getTab: getTab,
            getWurflArchive: getWurflArchive,
            openToggleModal: openToggleModal,
            openViewArchives: openViewArchives
        };

        return service;

        function createEvalLicense(response) {
            var blob = new Blob([response.data.text], {type: 'text/plain'}),
                url = $window.URL || $window.webkitURL;
            var a = $("<a style='display: none;'/>");
            var url = url.createObjectURL(blob);
            a.attr("href", url);
            a.attr("download", "EvalLicense30Days.txt");
            $("body").append(a);
            a[0].click();
            window.URL.revokeObjectURL(url);
            a.remove();
        }

        function createLicense(response) {
            var blob = new Blob([response.data[0]], {type: 'text/plain'}),
                url = $window.URL || $window.webkitURL;
            var a = $("<a style='display: none;'/>");
            var url = url.createObjectURL(blob);
            a.attr("href", url);
            a.attr("download", "license.txt");
            $("body").append(a);
            a[0].click();
            window.URL.revokeObjectURL(url);
            a.remove();
        }

        function download(type, obj) {
            var file = null;
            switch (type) {
                case 'zip':
                    file = 'wurfl.zip';
                    break;
                case 'gz':
                    file = 'wurfl.xml.gz';
                    break;
            }

            if (obj.isExpired) {
                $uibModal.open({
                    templateUrl: "view/license.partials.download_expired",
                    size: 'md',
                    keyboard: false,
                    windowClass: 'zindex',
                    backdrop: 'static',
                    controller: 'GenericModalController',
                    controllerAs: 'vm'
                });
            } else {
                $window.open(obj.config.snapshot_generator + "/" + obj.downloadKey + "/" + file, "_blank");
            }
        }

        function getSteps(data) {
            var currentStep = data.product.product_name;
            currentStep += data.isEvaluation ? ' Evaluation' : '';
            currentStep += " (" + $stateParams.id + ")";

            var params = {title: currentStep};

            if (data.license.license_type_id == 1 || data.license.license_type_id == 5) {
                params.parent = {state: 'trials', title: 'Trials'};
            } else {
                params.parent = {state: 'products', title: 'Products'};
            }

            if (loginService.impersonate.mode) {
                if (loginService.impersonate.user.id != data.user.id) {
                    params.user = data.user;
                }
            } else {
                if (loginService.authInfo.user.id != data.user.id) {
                    params.user = data.user;
                }
            }

            return breadcrumbsService.get('license.home', params);
        }

        function getTab(data) {
            $rootScope.$broadcast('setTitle', {title: data.product.product_name});
            if (data.isDataOnly && $state.current.name == 'license.home') {
                $state.go('license.home.capabilities', $stateParams);
                return 'capabilities';
            } else if ($state.current.name == 'license.home') {
                $state.go('license.home.guide', $stateParams);
            }

            switch ($state.current.name) {
                case 'license.home.capabilities':
                    return 'capabilities';
                case 'license.home.license':
                    return 'license';
                default:
                    return 'gettingStarted';
            }
        }

        function getWurflArchive(downloadKey, snapshotGenerator) {
            var returns = [];
            var wurfl_releases = {};
            wurfl_releases.currentDate = undefined;
            wurfl_releases.previousRelease = function(current) {
                if (current === undefined) {
                    var now = new Date();
                    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                    return new Date(today.setDate(today.getDate()-today.getDay()));
                };

                return new Date(current.setDate(current.getDate() - 7));
            };
            wurfl_releases.appendString = function(date) {
                    var friendlyDate = date.toLocaleDateString();
                    var downloadMonth = (date.getMonth() + 1 < 10) ? "0"+(date.getMonth()+1) : (date.getMonth()+1);
                    var downloadDay = (date.getDate() < 10) ? "0"+date.getDate() : date.getDate();
                    var downloadDate = date.getFullYear()+"-"+downloadMonth+"-"+downloadDay;
                    returns.push({
                        date: friendlyDate,
                        gzip: snapshotGenerator + "/" + downloadKey + "/" + downloadDate + "/wurfl.xml.gz",
                        zip: snapshotGenerator + "/" + downloadKey + "/" + downloadDate + "/wurfl.zip",

                    });
                };
            for (var i = 1; i <= 5; i++) {
                wurfl_releases.currentDate = wurfl_releases.previousRelease(wurfl_releases.currentDate);
                wurfl_releases.appendString(wurfl_releases.currentDate);
            };
            var currentDate = undefined;

            return returns;
        }

        function openToggleModal(capability, groups) {
            $uibModal.open({
                templateUrl: 'view/license.subpages.details',
                size: 'lg',
                keyboard: false,
                windowClass: 'zindex',
                controller: 'LicenseCapabilitiesModalController',
                resolve: {
                    capability: function() {
                        return capability;
                    },
                    groups: function() {
                        return groups;
                    }
                }
            });
        }

        function openViewArchives(downloadKey, config) {
            $uibModal.open({
                templateUrl: 'view/license.partials.wurfl_archive',
                controller: 'WurflArchiveController',
                controllerAs: 'vm',
                size: 'md',
                keyboard: false,
                windowClass: 'zindex',
                backdrop: 'static',
                resolve: {
                    downloadKey: function() {
                        return downloadKey;
                    },
                    snapshotGenerator: function() {
                        return config.snapshot_generator
                    }
                }
            });
        }
    }
})();
