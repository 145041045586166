(function() {
    'use strict';

    angular.module('customerVault')
        .controller('PageNotFoundController', PageNotFoundController);

    PageNotFoundController.$inject = ['$rootScope', 'authenticate', 'loginService'];

    function PageNotFoundController($rootScope, authenticate, loginService) {
        $rootScope.$broadcast('setTitle', {title: 'Page Not Found'});
        loginService.notFound = true;
        authenticate.authCheck(false);
    }
})();
