(function() {
    'use strict';

    angular.module('customerVault.layout')
        .factory('menuService', menuService);

    menuService.$inject = ['$resource'];

    function menuService($resource) {
        var resource = new $resource('/menu/:action/:id', {id: '@id'}, {
            getAdmin: {
                method: 'GET',
                params: {
                    action: 'admin'
                }
            },
            getFooter: {
                method: 'GET',
                params: {
                    action: 'footer'
                }
            },
            getHeader: {
                method: 'GET',
                params: {
                    action: 'header'
                }
            },
            getNavFooter: {
                method: 'GET',
                params: {
                    action: 'footernav'
                }
            },
            getSession: {
                method: 'GET',
                params: {
                    action: 'session'
                },
                ignoreLoadingBar: true
            }
        });

        var service = {
            getAdmin: getAdmin,
            getFooter: getFooter,
            getHeader: getHeader,
            getNavFooter: getNavFooter,
            getSession: getSession
        };

        return service;

        function getAdmin(id) {
            return resource.getAdmin({id: id}).$promise;
        }

        function getFooter() {
            return resource.getFooter().$promise;
        }

        function getHeader() {
            return resource.getHeader().$promise;
        }

        function getNavFooter() {
            return resource.getNavFooter().$promise;
        }

        function getSession(id) {
            return resource.getSession({id: id}).$promise;
        }
    }
})();
