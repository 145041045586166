(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineCacheController', ImageEngineCacheController);

    ImageEngineCacheController.$inject = ['$stateParams', 'bsLoadingOverlayService', 'ieResolve', 'ieService', 'Notification'];

    function ImageEngineCacheController($stateParams, bsLoadingOverlayService, ieResolve, ieService, Notification) {
        var vm = this;

        run();

        function run() {
            var resolveData = ieResolve.load.cache;
            var error = ieResolve.error;

            vm.addNewPurgeRequest = addNewPurgeRequest;
            vm.domains = resolveData.domains;
            vm.error = false;
            vm.errors = {
                expression: false,
                domain: false
            };
            vm.examples = true;
            vm.newPurgeRequest = {
                expression: null,
                domain: null
            };
            vm.origins = resolveData.origins;
            vm.pageErrorView = null;
            vm.pageErrorViewMessage = null;
            vm.purges = resolveData.purges;

            if (!_.isEmpty(error)) {
                vm.error = error.status;
                switch (error.status) {
                    case 403:
                        vm.pageErrorView = 'view/errors.403';
                        vm.pageErrorViewMessage = error.message;
                        break;
                    case 500:
                        vm.pageErrorViewMessage = error.message;
                        break;
                    default:
                        vm.pageErrorView = 'view/errors.400';
                }
            }
        }

        function addNewPurgeRequest(request, index) {
            var refId = _.isUndefined(index) ? 'ie-cache-ban' : 'ie-cache-purge-'+index;
            vm.errors = {
                expression: null,
                domain: null
            };

            if (_.isNull(request.expression)) {
                vm.errors.expression = 'An expression is required.';
            }
            if (_.isNull(request.domain)) {
                vm.errors.domain = true;
            }

            if (vm.errors.expression || vm.errors.domain) {
                return;
            }

            vm.errors.expression = isInvalidExpression(request.expression);
            if (vm.errors.expression) {
                return;
            }

            var submitRequest = angular.copy(request);
            if (_.isArray(request.domain)) {
                submitRequest.domain = submitRequest.domain[0].id;
            }

            bsLoadingOverlayService.start({
                referenceId: refId
            });
            ieService.postAddPurgeRequest($stateParams.id, submitRequest).then(function(response) {
                ieResolve.load.cache = {};
                ieResolve.run({name: 'ie.home.cache', params: $stateParams}).then(function(response) {
                    if (response) {
                        run();
                    }
                })
            }).catch(function(error) {
                console.log(error);
                switch (error.status) {
                    case 500:
                        Notification.error(error.data.message);
                        break;
                    default:
                        Notification.error("There is a problem with your account configuration. Please contact us at support@scientiamobile.com.");
                }
            }).finally(function() {
                bsLoadingOverlayService.stop({
                    referenceId: refId
                });
            });
        }

        function isInvalidExpression(expression) {
            if (expression.indexOf('\n') !== -1) {
                return 'Multiple expressions cannot be submitted at once. Try creating a single expression that matches all your intended images, or submit separate requests.';
            }
            if (/\s/.test(expression)) {
                return 'Expressions cannot contain spaces. If you intend to have a space, make sure it\'s URL encoded.';
            }
            if (expression.length > 1000) {
                return 'Expressions are limited to 1,000 characters.'
            }
            return false;
        }
    }
})();
