(function() {
    'use strict';

    angular.module('authApp')
        .factory('sessionService', sessionService);

    sessionService.$inject = ['$q', '$rootScope', '$state', 'authenticate', 'loaderService', 'registerResolve'];

    function sessionService($q, $rootScope, $state, authenticate, loaderService, registerResolve) {
        var freshLoad = true;

        var session = {
            freshLoad: freshLoad,
            get: get,
            runSessionCheck: runSessionCheck,
            swap: swap
        };

        return session;

        function get() {
            return session.freshLoad;
        }

        function runSessionCheck(event, toState, toParams) {
            if (session.get()) {
                if (toState.name != 'login' && loaderService.show) {
                    loaderService.confirm();
                } else if (!loaderService.show) {
                    loaderService.show = true;
                }

                session.swap();
                event.preventDefault();
                var check = authenticate.checkSession(toState, toParams);
                stateChange(check, toState, toParams);
            } else {
                session.swap();
            }
        }

        function stateChange(promise, toState, toParams) {
            $q.when(promise).then(function(data) {
                if (data) {
                    if (toState.name === 'login') {
                        $state.go('myaccount', {});
                        return;
                    }
                    $rootScope.$broadcast('loading', {name: toState.name, params: toParams});
                    return;
                }
                $rootScope.$broadcast('loading', {name: 'login', params: {}});
                $state.go('login', {});
                return;
            });
        }

        function swap() {
            if (this.freshLoad) {
                session.freshLoad = false;
            } else {
                session.freshLoad = true;
            }
        }

    }
})();
