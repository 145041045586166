(function() {
    'use strict';

    angular.module('authApp')
        .factory('authenticate', authenticate);

    authenticate.$inject = ['$location', '$log', '$resource', '$rootScope', 'endSession', 'impersonateService', 'loginService', 'pendingRequestsService', 'smAuthService'];

    function authenticate($location, $log, $resource, $rootScope, endSession, impersonateService, loginService, pendingRequestsService, smAuthService) {
        var authFactory = {
            auth: auth,
            authCheck: authCheck,
            checkSession: checkSession,
            createIntended: createIntended,
            logout: logout,
            setRemember: setRemember
        };
        authFactory.error = {
            message: null,
            status: null,
            type: null
        };

        var resource = new $resource('api/:action/:action_id', {}, {
            check: {
                method: 'POST',
                params: {action: 'auth', action_id: 'check'}
            },
            login: {
                method: 'POST',
                params: {action: 'login', action_id: 'auth'}
            },
            logout: {
                method: 'POST',
                params: {action: 'login', action_id: 'destroy'}
            }
        });

        return authFactory;

        function auth(credentials) {
            return resource.login({}, credentials).$promise
                .then(authSuccess)
                .catch(authFailed);

            function authSuccess(response) {
                localStorage.setItem('auth_token', response.data.token);
                localStorage.setItem('access_time', Math.round((new Date()).getTime() / 1000));
                localStorage.setItem('refresh_time', Math.round((new Date()).getTime() / 1000));
                
                var user = response.data.user;

                smAuthService.login(credentials.username, credentials.password).then(function (response) {
                    smAuthService.token(response.data.token).then(function (response) {
                        localStorage.setItem('sm_auth_token', response.data.token.token);
                        localStorage.setItem('sm_auth_token_expiration', response.data.token.expiration);    
                    }).catch(authFailed);

                }).catch(authFailed);

                return user;
            }

            function authFailed(error) {
                $log.error(error);
                authFactory.error.message = error.data.message;
                authFactory.error.status = error.status;
                authFactory.error.type = error.data.data.type;
                return false;
            }
        }

        function authCheck(failRun) {
            failRun = _.isUndefined(failRun) ? true : failRun;
            var storageVariables = {
                auth_token: localStorage.getItem('auth_token'),
                access_time: localStorage.getItem('access_time'),
                refresh_time: localStorage.getItem('refresh_time'),
                remember_token: localStorage.getItem('remember_token'),
                remember_time: localStorage.getItem('remember_time')
            };
            return resource.check({}, storageVariables).$promise
                .then(checkSuccess)
                .catch(checkFailed);

            function checkSuccess(response) {
                if (!_.isNull(localStorage.getItem('impersonate'))) {
                    return impersonateService.getBasicIsAdmin(response.data.user.id).then(function(resp) {
                        if (!resp.data.admin) {
                            localStorage.removeItem('impersonate');
                        }
                        return runSuccess(response);
                    });
                }
                return runSuccess(response);
            }

            function runSuccess(response) {
                loginService.authInfo.authenticated = true;
                loginService.authInfo.user = response.data.user;
                localStorage.setItem('auth_token', response.data.token);
                if (response.data.time_change) {
                    localStorage.setItem('access_time', Math.round((new Date()).getTime() / 1000));
                }
                if (!_.isNull(localStorage.getItem('impersonate'))) {
                    var impersonateUser = JSON.parse(localStorage.getItem('impersonate'));
                    if (!loginService.impersonate.mode || loginService.impersonate.user.id != impersonateUser.id) {
                        $rootScope.$broadcast('startImpersonate', {user: impersonateUser});
                    }
                }
                $rootScope.$broadcast('authenticated');
                return true;
            }

            function checkFailed(error) {
                if (failRun) {
                    $log.error(error);
                    pendingRequestsService.cancelAll();
                    authFactory.logout().then(function() { return false; });
                } else {
                    loginService.notFound = false;
                }
                return false;
            }
        }

        function checkSession(toState, toParams) {
            if (!toState.data.sessionCheck) {
                return true;
            }

            if (localStorage.getItem('auth_token') !== null) {
                return authFactory.authCheck()
                    .then(function(data) {
                        if (!data && !toState.data.allow) {
                            loginService.authInfo.authenticated = false;
                            loginService.authInfo.user = null;
                            endSession.end();
                            return false;
                        }
                        return true;
                    });
            } else if ($location.path() !== '/login' && !toState.data.allow) {
                loginService.authInfo.authenticated = false;
                loginService.authInfo.user = null;
                endSession.end();
                return false;
            } else if (toState.data.allow) {
                return true;
            }
        }

        function createIntended(toState, toParams) {
            if (loginService.intended === undefined && toState.name !== 'login' && toState.name != 'register') {
                loginService.intended = {name: toState.name, params: toParams};
            }
            return;
        }

        function logout() {
            return resource.logout({}).$promise
                .then(logoutSuccess)
                .catch(logoutFailed);

            function logoutSuccess() {
                return true;
            }

            function logoutFailed() {
                return false;
            }
        }

        function setRemember(token) {
            localStorage.setItem('remember_token', token);
            localStorage.setItem('remember_time', Math.round((new Date()).getTime() / 1000));
        }
    }
})();
