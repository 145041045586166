(function() {
    'use strict';

    angular.module('customerVault.ie')
        .controller('ImageEngineUpgradeController', ImageEngineUpgradeController);

    ImageEngineUpgradeController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$uibModal', 'ieActions', 'ieResolve', 'ieService', 'Notification', 'paymentService'];

    function ImageEngineUpgradeController($rootScope, $scope, $state, $stateParams, $timeout, $uibModal, ieActions, ieResolve, ieService, Notification, paymentService) {
        var vm = this;
        var resolveData = ieResolve.load.upgrade;
        var error = ieResolve.error;

        vm.cancel = cancel;
        vm.costs = resolveData.costs;
        vm.countries = !_.isUndefined(resolveData.countries) ? resolveData.countries : null;
        vm.currentPlan = resolveData.currentPlan;
        vm.daysLeft = resolveData.daysLeft;
        vm.difference = resolveData.difference;
        vm.error = false;
        vm.errors = {
            first_name: false,
            last_name: false,
            address: false,
            city: false,
            state: false,
            zip: false,
            country: false
        };
        vm.form = {
            first_name: null,
            last_name: null,
            address: null,
            city: null,
            state: null,
            zip: null,
            country: null
        };
        vm.newPlan = resolveData.newPlan;
        vm.nextCycle = resolveData.nextCycle;
        vm.pageErrorView = 'view/errors.400';
        vm.pageErrorViewMessage = null;
        vm.subscription = resolveData.subscription;
        vm.upgrade = upgrade;

        var loadedInstance = null;
        if (!_.isEmpty(error)) {
            vm.error = error.status;
            switch (error.status) {
                case 403:
                    vm.pageErrorView = 'view/errors.403';
                    vm.pageErrorViewMessage = error.message;
                    break;
                default:
                    break;
            }
        } else if (vm.currentPlan.payment_plan == 'IMAGEENGINE_TRIAL') {
            generateToken();
            vm.form.first_name = resolveData.user.first_name;
            vm.form.last_name = resolveData.user.last_name;
        }

        $scope.$on('tokenGenerated', function(event, args) {
            braintree.dropin.create({
                authorization: args.token,
                container: '#ie-update-payment-form',
                card: {
                    cardholderName: {
                        required: true
                    }
                }
            }, function(createErr, instance) {
                $timeout(function() {
                    var disablePay = false;
                }, 1);
                loadedInstance = instance;
            });
        });

        function cancel() {
            $rootScope.$broadcast('ieChangeTab', {tab: 'planinfo'});
        }

        function generateToken() {
            return paymentService.getBraintreeAuthorization().then(function(response) {
                $scope.$broadcast('tokenGenerated', {token: response.data.authorization});
            });
        }

        function upgrade() {
            if (vm.currentPlan.payment_plan == 'IMAGEENGINE_TRIAL') {
                vm.errors = {
                    first_name: false,
                    last_name: false,
                    address: false,
                    city: false,
                    state: false,
                    zip: false,
                    country: false
                };
                var errors = ieService.validateBilling(vm.form, false);
                if (!_.isEmpty(errors)) {
                    angular.forEach(errors, function(val, key) {
                        vm.errors[val] = true;
                    });
                    return;
                }
                loadedInstance.requestPaymentMethod(function(requestPaymentMethodErr, payload) {
                    if (!_.isUndefined(payload)) {
                        vm.form.nonce = payload.nonce;
                        vm.form.country_id = angular.copy(vm.form.country);
                    } else {
                        Notification.error("Please provide payment information");
                        return;
                    }
                });

            }
            var confirm = ieActions.openUpgradeConfirm(vm);

            confirm.result.then(function(response) {
                if (response == 'reload') {
                    Notification.success("Account successfully changed");
                    $rootScope.$broadcast('reloadIeAccount');
                    $state.go('ie.home.summary', {id: $stateParams.id});
                }
            }).catch(function(error) {
                if (error.data.data.tier) {
                    Notification.warning("Your plan changed successfully, however your support permissions were not updated. Please contact support@scientiamobile.com for assistance");
                    $timeout(function() {
                        $rootScope.$broadcast('reloadIeAccount');
                        $state.go('ie.home.summary', {id: $stateParams.id});
                    }, 2000);
                } else {
                    Notification.error("We are unable to change your ImageEngine account plan at this time. Please contact support@scientiamobile.com");
                    if (!_.isNull(loadedInstance)) {
                        loadedInstance.teardown(function(err) {
                            if (!err) {
                                generateToken();
                            }
                        });
                    }
                }
                console.log(error);
            });
        }
    }
})();
